import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(tz);
import { showNotification } from '@mantine/notifications';
import { BaseMutationOptions } from '@apollo/client';

type AddressComponents = {
  long_name: string;
  short_name: string;
  types: string[];
};

const onApolloError = (content?: string) => {
  const curry: BaseMutationOptions['onError'] = (error) =>
    showNotification({ message: content || error.message || 'An error occurred', color: 'red' });

  return curry;
};

export const makeAddress = ({
  address1,
  address2,
  city,
  state,
  zipcode,
}: {
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  state?: string | null;
  zipcode?: string | null;
}) => {
  return [address1, address2, city, state, zipcode].filter((a) => a).join(', ');
};

export const hasAddress = ({
  address1,
  city,
  state,
  zipcode,
}: {
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  state?: string | null;
  zipcode?: string | null;
}) => {
  return [address1, city, state, zipcode].every((a) => !!a);
};

const findMatchingAddress = (addressComponents: AddressComponents[], addressType: string) => {
  const matchingAddress = addressComponents.find((addressComponent) => {
    return addressComponent.types.indexOf(addressType) !== -1;
  });
  return matchingAddress;
};

export const parseAddress = (addressComponents: AddressComponents[], addressType: string) => {
  const matchedAddress = findMatchingAddress(addressComponents, addressType);
  return matchedAddress?.long_name;
};

export const parseAddressState = (addressComponents: AddressComponents[], addressType: string) => {
  const matchedAddress = findMatchingAddress(addressComponents, addressType);
  return matchedAddress?.short_name;
};

export const getCorrectDateWithTimezoneOffset = (date: string | Date): Date => {
  return dayjs(date).tz('America/Los_Angeles').toDate();
};

// default to PST timezone unless otherwise specific
export const getDateStringTimezone = (date: Date, timeZoneProvided?: string): string =>
  date.toLocaleDateString('en-US', {
    timeZone: timeZoneProvided ?? 'America/Los_Angeles',
  });

export const generateBuildoutScheduleInitialValues = (
  effectiveDate: string,
  expirationDate: string
) => {
  const parsedEffectiveDate = dayjs(effectiveDate);
  const parsedExpirationDate = dayjs(expirationDate);
  const projectLengthMonths = parsedExpirationDate.diff(parsedEffectiveDate, 'month');
  const projectLengthYears = Math.ceil(projectLengthMonths / 12);
  const initialData = new Array(projectLengthYears).fill(null).map((x, i) => {
    return {
      year: i + 1,
      percentage: 0,
    };
  });
  return initialData;
};

export type Nullable<T> = { [K in keyof T]: T[K] | null };

export default onApolloError;
