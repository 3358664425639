export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: string;
  DateTime: string;
  Decimal: any;
  EmailAddress: string;
  JSON: any;
  JSONObject: any;
  MoneyFloat: number;
  Option: string | number;
  SafeInt: number;
  UploadFile: any;
};

export type ActionActivityData =
  | ExperienceRatingChangedActivityDataObject
  | QuoteStatusChangedActivityDataObject
  | UnderwriterAdjustmentUpdatedDataObject;

export type ActionActivityObject = {
  __typename?: 'ActionActivityObject';
  createdAt: Scalars['DateTime'];
  data?: Maybe<ActionActivityData>;
  id: Scalars['ID'];
  quoteActivityId: Scalars['ID'];
  type: ActionActivityTypeEnum | `${ActionActivityTypeEnum}`;
  updatedAt: Scalars['DateTime'];
};

export enum ActionActivityTypeEnum {
  BinderGenerated = 'BINDER_GENERATED',
  ExperienceRatingChanged = 'EXPERIENCE_RATING_CHANGED',
  PolicyPdfGenerated = 'POLICY_PDF_GENERATED',
  QuoteCreated = 'QUOTE_CREATED',
  QuotePdfGenerated = 'QUOTE_PDF_GENERATED',
  QuoteReferred = 'QUOTE_REFERRED',
  QuoteStatusChanged = 'QUOTE_STATUS_CHANGED',
  UnderwriterAdjustmentUpdated = 'UNDERWRITER_ADJUSTMENT_UPDATED',
}

export type ActivityObject = ActionActivityObject | CommentActivityObject;

export type AdditionalPolicyNumber = {
  __typename?: 'AdditionalPolicyNumber';
  id: Scalars['ID'];
  lineOfBusiness: LineOfBusiness | `${LineOfBusiness}`;
  policy: Policy;
  policyId: Scalars['ID'];
  policyNumber: Scalars['String'];
};

export type AgentSyncCheckObject = {
  __typename?: 'AgentSyncCheckObject';
  checkType: Scalars['String'];
  createdAt: Scalars['Date'];
  id: Scalars['ID'];
  licenseChecks: Array<LicenseCheckObject>;
  passed: Scalars['Boolean'];
  policyId: Scalars['String'];
  state: Scalars['String'];
  updatedAt?: Maybe<Scalars['Date']>;
};

export type AggregateLossRun = {
  __typename?: 'AggregateLossRun';
  alaeOutstanding: Scalars['Float'];
  effectiveDate: Scalars['Date'];
  evaluationDate: Scalars['Date'];
  expirationDate: Scalars['Date'];
  historicalExposure: Scalars['Float'];
  indemnityOutstanding: Scalars['Float'];
  nonZeroClaimCount: Scalars['Float'];
  paidAlae: Scalars['Float'];
  paidIndemnity: Scalars['Float'];
  totalClaimCount: Scalars['Float'];
};

export type ArchiveInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']>;
  id: Scalars['ID'];
};

export type AssigneeObject = {
  __typename?: 'AssigneeObject';
  id: Scalars['ID'];
  imageUrl?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type AttachmentCapacityOutput = {
  __typename?: 'AttachmentCapacityOutput';
  attach: Scalars['Float'];
  capacity: Scalars['Float'];
  tier: TierEnum | `${TierEnum}`;
};

export enum AutoBiOptionEnum {
  Limit_500 = 'LIMIT_500',
  Limit_1000 = 'LIMIT_1000',
  Limit_2000 = 'LIMIT_2000',
  Limit_2500 = 'LIMIT_2500',
  Limit_5000 = 'LIMIT_5000',
  Limit_10000 = 'LIMIT_10000',
  LimitAccidentalDeathBenefits = 'LIMIT_ACCIDENTAL_DEATH_BENEFITS',
  LimitAllAutos = 'LIMIT_ALL_AUTOS',
  LimitAllOther = 'LIMIT_ALL_OTHER',
  LimitALossCostWhenTheNamedInsuredHasRejectedTheTortLimitation = 'LIMIT_A_LOSS_COST_WHEN_THE_NAMED_INSURED_HAS_REJECTED_THE_TORT_LIMITATION',
  LimitBasicLimits = 'LIMIT_BASIC_LIMITS',
  LimitBasicLimits_1500030000 = 'LIMIT_BASIC_LIMITS_1500030000',
  LimitBasicLimits_1500030000NotPrincipallyOperatedByEmployees = 'LIMIT_BASIC_LIMITS_1500030000_NOT_PRINCIPALLY_OPERATED_BY_EMPLOYEES',
  LimitBasicLimits_1500030000PrincipallyOperatedByEmployees = 'LIMIT_BASIC_LIMITS_1500030000_PRINCIPALLY_OPERATED_BY_EMPLOYEES',
  LimitBasicPersonalInjuryProtectionBenefits = 'LIMIT_BASIC_PERSONAL_INJURY_PROTECTION_BENEFITS',
  LimitBLossCostWhereTheInsuredAndHalfOrMoreThanHalfOfTheRegularOperatorsOfTheInsuredsAutosHaveAcceptedTheTortLimitation = 'LIMIT_B_LOSS_COST_WHERE_THE_INSURED_AND_HALF_OR_MORE_THAN_HALF_OF_THE_REGULAR_OPERATORS_OF_THE_INSUREDS_AUTOS_HAVE_ACCEPTED_THE_TORT_LIMITATION',
  LimitCollision = 'LIMIT_COLLISION',
  LimitComp = 'LIMIT_COMP',
  LimitCoveredByWc = 'LIMIT_COVERED_BY_WC',
  LimitCoveredByWcAndPrincipallyOperatedByEmployees = 'LIMIT_COVERED_BY_WC_AND_PRINCIPALLY_OPERATED_BY_EMPLOYEES',
  LimitCLossCostWhereTheInsuredAndAllOfTheRegularOperatorsOfTheInsuredsAutosHaveAcceptedTheTortLimitation = 'LIMIT_C_LOSS_COST_WHERE_THE_INSURED_AND_ALL_OF_THE_REGULAR_OPERATORS_OF_THE_INSUREDS_AUTOS_HAVE_ACCEPTED_THE_TORT_LIMITATION',
  LimitFuneralBenefitsLimit_4000CoveredByWorkerscomp = 'LIMIT_FUNERAL_BENEFITS_LIMIT_4000_COVERED_BY_WORKERSCOMP',
  LimitFuneralBenefitsLimit_4000NotCoveredByWorkerscomp = 'LIMIT_FUNERAL_BENEFITS_LIMIT_4000_NOT_COVERED_BY_WORKERSCOMP',
  LimitFuneralBenefitsLimit_4000NotPrincipallyOperatedByEmployees = 'LIMIT_FUNERAL_BENEFITS_LIMIT_4000_NOT_PRINCIPALLY_OPERATED_BY_EMPLOYEES',
  LimitFuneralBenefitsLimit_4000PrincipallyOperatedByEmployees = 'LIMIT_FUNERAL_BENEFITS_LIMIT_4000_PRINCIPALLY_OPERATED_BY_EMPLOYEES',
  LimitFurnishedForRegularUse = 'LIMIT_FURNISHED_FOR_REGULAR_USE',
  LimitGuestPip = 'LIMIT_GUEST_PIP',
  LimitIncomeBenefitsLimit_12000CoveredByWorkerscomp = 'LIMIT_INCOME_BENEFITS_LIMIT_12000_COVERED_BY_WORKERSCOMP',
  LimitIncomeBenefitsLimit_12000NotCoveredByWorkerscomp = 'LIMIT_INCOME_BENEFITS_LIMIT_12000_NOT_COVERED_BY_WORKERSCOMP',
  LimitIncomeBenefitsLimit_12000NotPrincipallyOperatedByEmployees = 'LIMIT_INCOME_BENEFITS_LIMIT_12000_NOT_PRINCIPALLY_OPERATED_BY_EMPLOYEES',
  LimitIncomeBenefitsLimit_12000PrincipallyOperatedByEmployees = 'LIMIT_INCOME_BENEFITS_LIMIT_12000_PRINCIPALLY_OPERATED_BY_EMPLOYEES',
  LimitIncomeBenefitsLimit_24000CoveredByWorkerscomp = 'LIMIT_INCOME_BENEFITS_LIMIT_24000_COVERED_BY_WORKERSCOMP',
  LimitIncomeBenefitsLimit_24000NotCoveredByWorkerscomp = 'LIMIT_INCOME_BENEFITS_LIMIT_24000_NOT_COVERED_BY_WORKERSCOMP',
  LimitIncomeBenefitsLimit_24000NotPrincipallyOperatedByEmployees = 'LIMIT_INCOME_BENEFITS_LIMIT_24000_NOT_PRINCIPALLY_OPERATED_BY_EMPLOYEES',
  LimitIncomeBenefitsLimit_24000PrincipallyOperatedByEmployees = 'LIMIT_INCOME_BENEFITS_LIMIT_24000_PRINCIPALLY_OPERATED_BY_EMPLOYEES',
  LimitIncomeLossBenefitsCoverageAllOther = 'LIMIT_INCOME_LOSS_BENEFITS_COVERAGE__ALL_OTHER',
  LimitIncomeLossBenefitsCoverageNotPrincipallyOperatedByEmployees = 'LIMIT_INCOME_LOSS_BENEFITS_COVERAGE__NOT_PRINCIPALLY_OPERATED_BY_EMPLOYEES',
  LimitIncomeLossBenefitsCoveragePrincipallyOperatedByEmployees = 'LIMIT_INCOME_LOSS_BENEFITS_COVERAGE__PRINCIPALLY_OPERATED_BY_EMPLOYEES',
  LimitInterstate = 'LIMIT_INTERSTATE',
  LimitInterstateAllAutosMedical_250000 = 'LIMIT_INTERSTATE_ALL_AUTOS__MEDICAL_250000',
  LimitInterstateAllAutosMedical_500000 = 'LIMIT_INTERSTATE_ALL_AUTOS__MEDICAL_500000',
  LimitInterstateAllAutosMedicalUnlimited = 'LIMIT_INTERSTATE_ALL_AUTOS__MEDICAL_UNLIMITED',
  LimitInterstateLimitOfLiab_100000 = 'LIMIT_INTERSTATE_LIMIT_OF_LIAB_100000',
  LimitIntrastate = 'LIMIT_INTRASTATE',
  LimitIntrastateCoveredByWcMedical_250000 = 'LIMIT_INTRASTATE_COVERED_BY_WC__MEDICAL_250000',
  LimitIntrastateCoveredByWcMedical_500000 = 'LIMIT_INTRASTATE_COVERED_BY_WC__MEDICAL_500000',
  LimitIntrastateCoveredByWcMedicalUnlimited = 'LIMIT_INTRASTATE_COVERED_BY_WC__MEDICAL_UNLIMITED',
  LimitIntrastateLimitOfLiab_100000 = 'LIMIT_INTRASTATE_LIMIT_OF_LIAB_100000',
  LimitIntrastateNotCoveredByWcMedical_250000 = 'LIMIT_INTRASTATE_NOT_COVERED_BY_WC__MEDICAL_250000',
  LimitIntrastateNotCoveredByWcMedical_500000 = 'LIMIT_INTRASTATE_NOT_COVERED_BY_WC__MEDICAL_500000',
  LimitIntrastateNotCoveredByWcMedicalUnlimited = 'LIMIT_INTRASTATE_NOT_COVERED_BY_WC__MEDICAL_UNLIMITED',
  LimitLimitOfLiab_100000 = 'LIMIT_LIMIT_OF_LIAB_100000',
  LimitMedicalBenefitsLimit_50000CoveredByWorkerscomp = 'LIMIT_MEDICAL_BENEFITS_LIMIT_50000_COVERED_BY_WORKERSCOMP',
  LimitMedicalBenefitsLimit_50000NotCoveredByWorkerscomp = 'LIMIT_MEDICAL_BENEFITS_LIMIT_50000_NOT_COVERED_BY_WORKERSCOMP',
  LimitMedicalBenefitsLimit_50000PrincipallyOperatedByEmployees = 'LIMIT_MEDICAL_BENEFITS_LIMIT_50000_PRINCIPALLY_OPERATED_BY_EMPLOYEES',
  LimitMedicalBenefitsLimit_100000CoveredByWorkerscomp = 'LIMIT_MEDICAL_BENEFITS_LIMIT_100000_COVERED_BY_WORKERSCOMP',
  LimitMedicalBenefitsLimit_100000NotCoveredByWorkerscomp = 'LIMIT_MEDICAL_BENEFITS_LIMIT_100000_NOT_COVERED_BY_WORKERSCOMP',
  LimitMedicalBenefitsLimit_100000PrincipallyOperatedByEmployees = 'LIMIT_MEDICAL_BENEFITS_LIMIT_100000_PRINCIPALLY_OPERATED_BY_EMPLOYEES',
  LimitNotCoveredByWc = 'LIMIT_NOT_COVERED_BY_WC',
  LimitNotPrincipallyOperatedByEmployees = 'LIMIT_NOT_PRINCIPALLY_OPERATED_BY_EMPLOYEES',
  LimitNotPrincipallyOperatedByEmployeesMedical_250000 = 'LIMIT_NOT_PRINCIPALLY_OPERATED_BY_EMPLOYEES__MEDICAL_250000',
  LimitNotPrincipallyOperatedByEmployeesMedical_500000 = 'LIMIT_NOT_PRINCIPALLY_OPERATED_BY_EMPLOYEES__MEDICAL_500000',
  LimitNotPrincipallyOperatedByEmployeesMedicalUnlimited = 'LIMIT_NOT_PRINCIPALLY_OPERATED_BY_EMPLOYEES__MEDICAL_UNLIMITED',
  LimitNotSubjectToNofault = 'LIMIT_NOT_SUBJECT_TO_NOFAULT',
  LimitNotSubjectToNofault_500 = 'LIMIT_NOT_SUBJECT_TO_NOFAULT_500',
  LimitNotSubjectToNofault_1000 = 'LIMIT_NOT_SUBJECT_TO_NOFAULT_1000',
  LimitNotSubjectToNofault_2000 = 'LIMIT_NOT_SUBJECT_TO_NOFAULT_2000',
  LimitNotSubjectToNofault_5000 = 'LIMIT_NOT_SUBJECT_TO_NOFAULT_5000',
  LimitPrincipallyOperatedByEmployees = 'LIMIT_PRINCIPALLY_OPERATED_BY_EMPLOYEES',
  LimitPrincipallyOperatedByEmployeesMedical_250000 = 'LIMIT_PRINCIPALLY_OPERATED_BY_EMPLOYEES__MEDICAL_250000',
  LimitPrincipallyOperatedByEmployeesMedical_500000 = 'LIMIT_PRINCIPALLY_OPERATED_BY_EMPLOYEES__MEDICAL_500000',
  LimitPrincipallyOperatedByEmployeesMedicalUnlimited = 'LIMIT_PRINCIPALLY_OPERATED_BY_EMPLOYEES__MEDICAL_UNLIMITED',
  LimitSpecifiedCausesOfLoss = 'LIMIT_SPECIFIED_CAUSES_OF_LOSS',
  LimitSubjectToNofault = 'LIMIT_SUBJECT_TO_NOFAULT',
  LimitSubjectToNofault_500 = 'LIMIT_SUBJECT_TO_NOFAULT_500',
  LimitSubjectToNofault_1000 = 'LIMIT_SUBJECT_TO_NOFAULT_1000',
  LimitSubjectToNofault_2000 = 'LIMIT_SUBJECT_TO_NOFAULT_2000',
  LimitSubjectToNofault_5000 = 'LIMIT_SUBJECT_TO_NOFAULT_5000',
  LimitSubjectToNofaultNotPrincipallyOperatedByEmployees = 'LIMIT_SUBJECT_TO_NOFAULT_NOT_PRINCIPALLY_OPERATED_BY_EMPLOYEES',
  LimitSubjectToNofaultPrincipallyOperatedByEmployees = 'LIMIT_SUBJECT_TO_NOFAULT_PRINCIPALLY_OPERATED_BY_EMPLOYEES',
  LimitWorkLossCoverage = 'LIMIT_WORK_LOSS_COVERAGE',
}

export type AutoFleetSummaryInput = {
  count: Scalars['Int'];
  id?: InputMaybe<Scalars['ID']>;
  sizeClass: Scalars['String'];
  state: Scalars['String'];
  typeOfVehicle: Scalars['String'];
};

export type AutoFleetSummaryRowObject = {
  __typename?: 'AutoFleetSummaryRowObject';
  count: Scalars['Int'];
  id?: Maybe<Scalars['ID']>;
  sizeClass: Scalars['String'];
  state: Scalars['String'];
  typeOfVehicle: Scalars['String'];
};

export enum AutoFleetSummaryVariant {
  ExcessPractice = 'EXCESS_PRACTICE',
  PrimaryPractice = 'PRIMARY_PRACTICE',
  PrimaryProject = 'PRIMARY_PROJECT',
}

export enum AutoHackingCoverageOptionEnum {
  None = 'NONE',
  RansomCoverageIncluded = 'RANSOM_COVERAGE_INCLUDED',
  RansomCoverageNotIncluded = 'RANSOM_COVERAGE_NOT_INCLUDED',
}

export type AutoHackingCoverageOptionsObject = {
  __typename?: 'AutoHackingCoverageOptionsObject';
  label: Scalars['String'];
  value: AutoHackingCoverageOptionEnum | `${AutoHackingCoverageOptionEnum}`;
};

export type AutoIndividualRiskRatingSupportingInfoObject = {
  __typename?: 'AutoIndividualRiskRatingSupportingInfoObject';
  basicLimitPremiumTotalLiability: Scalars['String'];
  basicLimitPremiumTotalLiabilityDefaultValue?: Maybe<Scalars['Boolean']>;
  coverageLimits: Scalars['String'];
  increasedLimitsPremium: Scalars['String'];
};

export type AutoLargeLossRunInput = {
  accidentDate: Scalars['Date'];
  alaeOutstanding: Scalars['Float'];
  effectiveDate: Scalars['Date'];
  expirationDate: Scalars['Date'];
  indemnityOutstanding: Scalars['Float'];
  paidAlae: Scalars['Float'];
  paidIndemnity: Scalars['Float'];
};

export enum AutoLiabilityDefenseExpenseTreatmentsEnum {
  AlaeDoesNotErodeDeductible = 'ALAE_DOES_NOT_ERODE_DEDUCTIBLE',
  AlaeErodesDeductible = 'ALAE_ERODES_DEDUCTIBLE',
}

export type AutoLiabilityDefenseExpenseTreatmentsObject = {
  __typename?: 'AutoLiabilityDefenseExpenseTreatmentsObject';
  label: Scalars['String'];
  value: AutoLiabilityDefenseExpenseTreatmentsEnum | `${AutoLiabilityDefenseExpenseTreatmentsEnum}`;
};

export type AutoLiabilityLossDevelopmentModelSelectedObject = {
  __typename?: 'AutoLiabilityLossDevelopmentModelSelectedObject';
  finalSummarySelectedLossRate?: Maybe<Scalars['Decimal']>;
  finalSummarySelectedLossRateOptions?: Maybe<Array<LossRateLabelValue>>;
  finalSummarySelectedLossRateType?: Maybe<
    LossRateSelectionTypeEnum | `${LossRateSelectionTypeEnum}`
  >;
  selectedFrequency?: Maybe<Scalars['Decimal']>;
  selectedFrequencyOptions?: Maybe<Array<LossRateLabelValue>>;
  selectedFrequencyType?: Maybe<LossRateSelectionTypeEnum | `${LossRateSelectionTypeEnum}`>;
  selectedLossRate?: Maybe<Scalars['Decimal']>;
  selectedLossRateOptions?: Maybe<Array<LossRateLabelValue>>;
  selectedLossRateType?: Maybe<LossRateSelectionTypeEnum | `${LossRateSelectionTypeEnum}`>;
};

export type AutoLossRunInput = {
  alaeOutstanding: Scalars['Float'];
  effectiveDate: Scalars['Date'];
  evaluationDate: Scalars['Date'];
  expirationDate: Scalars['Date'];
  exposureId?: InputMaybe<Scalars['ID']>;
  historicalExposure: Scalars['Float'];
  indemnityOutstanding: Scalars['Float'];
  nonZeroClaimCount: Scalars['Float'];
  paidAlae: Scalars['Float'];
  paidIndemnity: Scalars['Float'];
  totalClaimCount: Scalars['Float'];
};

export enum AutoModelYearEnum {
  Year_1900 = 'YEAR_1900',
  Year_1901 = 'YEAR_1901',
  Year_1902 = 'YEAR_1902',
  Year_1903 = 'YEAR_1903',
  Year_1904 = 'YEAR_1904',
  Year_1905 = 'YEAR_1905',
  Year_1906 = 'YEAR_1906',
  Year_1907 = 'YEAR_1907',
  Year_1908 = 'YEAR_1908',
  Year_1909 = 'YEAR_1909',
  Year_1910 = 'YEAR_1910',
  Year_1911 = 'YEAR_1911',
  Year_1912 = 'YEAR_1912',
  Year_1913 = 'YEAR_1913',
  Year_1914 = 'YEAR_1914',
  Year_1915 = 'YEAR_1915',
  Year_1916 = 'YEAR_1916',
  Year_1917 = 'YEAR_1917',
  Year_1918 = 'YEAR_1918',
  Year_1919 = 'YEAR_1919',
  Year_1920 = 'YEAR_1920',
  Year_1921 = 'YEAR_1921',
  Year_1922 = 'YEAR_1922',
  Year_1923 = 'YEAR_1923',
  Year_1924 = 'YEAR_1924',
  Year_1925 = 'YEAR_1925',
  Year_1926 = 'YEAR_1926',
  Year_1927 = 'YEAR_1927',
  Year_1928 = 'YEAR_1928',
  Year_1929 = 'YEAR_1929',
  Year_1930 = 'YEAR_1930',
  Year_1931 = 'YEAR_1931',
  Year_1932 = 'YEAR_1932',
  Year_1933 = 'YEAR_1933',
  Year_1934 = 'YEAR_1934',
  Year_1935 = 'YEAR_1935',
  Year_1936 = 'YEAR_1936',
  Year_1937 = 'YEAR_1937',
  Year_1938 = 'YEAR_1938',
  Year_1939 = 'YEAR_1939',
  Year_1940 = 'YEAR_1940',
  Year_1941 = 'YEAR_1941',
  Year_1942 = 'YEAR_1942',
  Year_1943 = 'YEAR_1943',
  Year_1944 = 'YEAR_1944',
  Year_1945 = 'YEAR_1945',
  Year_1946 = 'YEAR_1946',
  Year_1947 = 'YEAR_1947',
  Year_1948 = 'YEAR_1948',
  Year_1949 = 'YEAR_1949',
  Year_1950 = 'YEAR_1950',
  Year_1951 = 'YEAR_1951',
  Year_1952 = 'YEAR_1952',
  Year_1953 = 'YEAR_1953',
  Year_1954 = 'YEAR_1954',
  Year_1955 = 'YEAR_1955',
  Year_1956 = 'YEAR_1956',
  Year_1957 = 'YEAR_1957',
  Year_1958 = 'YEAR_1958',
  Year_1959 = 'YEAR_1959',
  Year_1960 = 'YEAR_1960',
  Year_1961 = 'YEAR_1961',
  Year_1962 = 'YEAR_1962',
  Year_1963 = 'YEAR_1963',
  Year_1964 = 'YEAR_1964',
  Year_1965 = 'YEAR_1965',
  Year_1966 = 'YEAR_1966',
  Year_1967 = 'YEAR_1967',
  Year_1968 = 'YEAR_1968',
  Year_1969 = 'YEAR_1969',
  Year_1970 = 'YEAR_1970',
  Year_1971 = 'YEAR_1971',
  Year_1972 = 'YEAR_1972',
  Year_1973 = 'YEAR_1973',
  Year_1974 = 'YEAR_1974',
  Year_1975 = 'YEAR_1975',
  Year_1976 = 'YEAR_1976',
  Year_1977 = 'YEAR_1977',
  Year_1978 = 'YEAR_1978',
  Year_1979 = 'YEAR_1979',
  Year_1980 = 'YEAR_1980',
  Year_1981 = 'YEAR_1981',
  Year_1982 = 'YEAR_1982',
  Year_1983 = 'YEAR_1983',
  Year_1984 = 'YEAR_1984',
  Year_1985 = 'YEAR_1985',
  Year_1986 = 'YEAR_1986',
  Year_1987 = 'YEAR_1987',
  Year_1988 = 'YEAR_1988',
  Year_1989 = 'YEAR_1989',
  Year_1990 = 'YEAR_1990',
  Year_1991 = 'YEAR_1991',
  Year_1992 = 'YEAR_1992',
  Year_1993 = 'YEAR_1993',
  Year_1994 = 'YEAR_1994',
  Year_1995 = 'YEAR_1995',
  Year_1996 = 'YEAR_1996',
  Year_1997 = 'YEAR_1997',
  Year_1998 = 'YEAR_1998',
  Year_1999 = 'YEAR_1999',
  Year_2000 = 'YEAR_2000',
  Year_2001 = 'YEAR_2001',
  Year_2002 = 'YEAR_2002',
  Year_2003 = 'YEAR_2003',
  Year_2004 = 'YEAR_2004',
  Year_2005 = 'YEAR_2005',
  Year_2006 = 'YEAR_2006',
  Year_2007 = 'YEAR_2007',
  Year_2008 = 'YEAR_2008',
  Year_2009 = 'YEAR_2009',
  Year_2010 = 'YEAR_2010',
  Year_2011 = 'YEAR_2011',
  Year_2012 = 'YEAR_2012',
  Year_2013 = 'YEAR_2013',
  Year_2014 = 'YEAR_2014',
  Year_2015 = 'YEAR_2015',
  Year_2016 = 'YEAR_2016',
  Year_2017 = 'YEAR_2017',
  Year_2018 = 'YEAR_2018',
  Year_2019 = 'YEAR_2019',
  Year_2020 = 'YEAR_2020',
  Year_2021 = 'YEAR_2021',
  Year_2022 = 'YEAR_2022',
  Year_2023 = 'YEAR_2023',
  Year_2024 = 'YEAR_2024',
  Year_2025 = 'YEAR_2025',
}

export enum AutoOcnStatedAmountEnum {
  OriginalCostNew = 'ORIGINAL_COST_NEW',
  StatedAmount = 'STATED_AMOUNT',
}

export type AutoPremiumDrivers = {
  __typename?: 'AutoPremiumDrivers';
  lossCosts: Scalars['Float'];
  name: Scalars['String'];
  state: Scalars['String'];
  vehicleCount: Scalars['Int'];
};

export enum AutoRadiusEnum {
  Intermediate = 'INTERMEDIATE',
  Local = 'LOCAL',
  Long = 'LONG',
}

export type AutoRiskInputTableConstant = {
  __typename?: 'AutoRiskInputTableConstant';
  autoHackingCoverageOptions: Array<AutoHackingCoverageOptionsObject>;
  biOptions: Array<BiOptionsConstant>;
  collisionDeductibleCostOfHireBasisOptions: Array<CollisionDeductibleCostOfHireBasisObject>;
  collisionDeductibleOptions: Array<CollisionDeductibleOptionsObject>;
  combinedSingleLimitOptions: Array<CombinedSingleLimitOptionsObject>;
  comprehensiveDeductibleCostOfHireBasisOptions: Array<ComprehensiveDeductibleCostOfHireBasis>;
  comprehensiveDeductibleOptions: Array<ComprehensiveDeductibleOptionsObject>;
  comprehensiveOptions: Array<ComprehensiveOptionsObject>;
  defenseExpenseTreatments: Array<AutoLiabilityDefenseExpenseTreatmentsObject>;
  hiredAutosCoverageOptions: Array<HiredAutosCoverageObject>;
  hiredOwned: Array<HiredOwnedObject>;
  liabilityDeductibleOptions: Array<LiablityDeductibleOptionsObject>;
  medPayOptions: Array<MedPayOptionsObject>;
  modelYear: Array<ModelYear>;
  numberOfEmployeesOptions: Array<NumberOfEmployeesOptionsObject>;
  ocnStatedAmount: Array<OcnStatedAmountConstant>;
  pipOptions: Array<PipOptionsObject>;
  ppiOptions: Array<PpiOptionsObject>;
  radius: Array<RadiusConstant>;
  rentalDays: Array<RentalReimbursementDaysObject>;
  rentalReimbursementLimitPerDayOptions: Array<RentalReimbursementObject>;
  sclOptions: Array<SclOptionsObject>;
  secondaryClassPrivateClass: Array<SecondaryClassPrivateClass>;
  secondaryPrivateClassDetail: Array<SecondaryClassPrivateClassDetail>;
  sizeClass: Array<SizeClassConstant>;
  statedAmount: Array<StatedAmount>;
  states: Array<StateConstant>;
  towAndLaborCostOptions: Array<TowAndLaborCostObject>;
  typeOfVehicle: Array<TypeOfVehicleConstant>;
  uimbiCoverageOptions: Array<UimbiCoverageOptionsConstant>;
  uimbiLimitOptions: Array<UimbiLimitOptionsConstant>;
  umbiCoverageOptions: Array<UmbiCoverageOptionsConstant>;
  umbiLimitOptions: Array<UmbiLimitOptionsConstant>;
  umpdCoverageOptions: Array<UmpdCoverageOptionsConstantObject>;
  umpdLimitOptions: Array<UmpdLimitOptionsConstantObject>;
  use: Array<VehicleUseConstant>;
};

export type AutoRiskTierVehicleSummary = {
  __typename?: 'AutoRiskTierVehicleSummary';
  count: Scalars['Float'];
  sizeClass: Scalars['String'];
  state: Scalars['String'];
  typeOfVehicle: Scalars['String'];
};

export enum AutoSecondaryClassDetailPrivateClassDetailEnum {
  AllOther = 'ALL_OTHER',
  AllOtherContractors = 'ALL_OTHER_CONTRACTORS',
  AllOtherDumpAndTransitMix = 'ALL_OTHER_DUMP_AND_TRANSIT_MIX',
  AllOtherFarmers = 'ALL_OTHER_FARMERS',
  AllOtherFoodDelivery = 'ALL_OTHER_FOOD_DELIVERY',
  AllOtherSpecializedDelivery = 'ALL_OTHER_SPECIALIZED_DELIVERY',
  AllOtherTruckers = 'ALL_OTHER_TRUCKERS',
  AllOtherWasteDisposal = 'ALL_OTHER_WASTE_DISPOSAL',
  ArmoredCars = 'ARMORED_CARS',
  AutoDismantlers = 'AUTO_DISMANTLERS',
  BuildingCommercial = 'BUILDING_COMMERCIAL',
  BuildingPrivateDwellings = 'BUILDING_PRIVATE_DWELLINGS',
  BuildingWreckingOperators = 'BUILDING_WRECKING_OPERATORS',
  CanneriesAndPackingPlants = 'CANNERIES_AND_PACKING_PLANTS',
  CarriersEngagedInBothPrivateCarriageAndTransportingGoodsMaterialsOrCommoditiesForOther = 'CARRIERS_ENGAGED_IN_BOTH_PRIVATE_CARRIAGE_AND_TRANSPORTING_GOODS_MATERIALS_OR_COMMODITIES_FOR_OTHER',
  CommonCarriers = 'COMMON_CARRIERS',
  ContractCarriersHaulingChemicals = 'CONTRACT_CARRIERS_HAULING_CHEMICALS',
  ContractCarriersHaulingIronAndSteel = 'CONTRACT_CARRIERS_HAULING_IRON_AND_STEEL',
  ContractCarriersOtherThanChemicalOrIronAndSteelHaulers = 'CONTRACT_CARRIERS_OTHER_THAN_CHEMICAL_OR_IRON_AND_STEEL_HAULERS',
  ElectricalPlumbingMasonryPlasteringAndOtherRepairOrService = 'ELECTRICAL_PLUMBING_MASONRY_PLASTERING_AND_OTHER_REPAIR_OR_SERVICE',
  Excavating = 'EXCAVATING',
  ExemptCarriersHaulingLivestock = 'EXEMPT_CARRIERS_HAULING_LIVESTOCK',
  ExemptCarriersOtherThanLivestockHaulers = 'EXEMPT_CARRIERS_OTHER_THAN_LIVESTOCK_HAULERS',
  FilmDelivery = 'FILM_DELIVERY',
  FishAndSeafood = 'FISH_AND_SEAFOOD',
  FrozenFood = 'FROZEN_FOOD',
  FruitAndVegetable = 'FRUIT_AND_VEGETABLE',
  Garbage = 'GARBAGE',
  IndividuallyOwnedOrFamilyCorpOtherThanLivestockHauling = 'INDIVIDUALLY_OWNED_OR_FAMILY_CORP_OTHER_THAN_LIVESTOCK_HAULING',
  JunkDealers = 'JUNK_DEALERS',
  LivestockHauling = 'LIVESTOCK_HAULING',
  LoggingAndLumbering = 'LOGGING_AND_LUMBERING',
  MagazinesOrNewspapers = 'MAGAZINES_OR_NEWSPAPERS',
  MailAndParcelPost = 'MAIL_AND_PARCEL_POST',
  MeatOrPoultry = 'MEAT_OR_POULTRY',
  Mining = 'MINING',
  NoOperatorLicensedLessThanFiveYears = 'NO_OPERATOR_LICENSED_LESS_THAN_FIVE_YEARS',
  OperatorLicensedLessThanFiveYearsIsNotOwnerOrPrincipalOperator = 'OPERATOR_LICENSED_LESS_THAN_FIVE_YEARS_IS_NOT_OWNER_OR_PRINCIPAL_OPERATOR',
  OwnerOrPrincipalOperatorLicensedLessThanFiveYears = 'OWNER_OR_PRINCIPAL_OPERATOR_LICENSED_LESS_THAN_FIVE_YEARS',
  Quarrying = 'QUARRYING',
  SandAndGravelOtherThanQuarrying = 'SAND_AND_GRAVEL_OTHER_THAN_QUARRYING',
  StreetAndRoad = 'STREET_AND_ROAD',
  TowTrucksForhire = 'TOW_TRUCKS_FORHIRE',
}

export enum AutoSecondaryClassPrivateClassEnum {
  ContractorsOtherThanDumpTrucks = 'CONTRACTORS_OTHER_THAN_DUMP_TRUCKS',
  DumpAndTransitMixUseTheseFactorsAndCodesOnlyWhenNoOtherSecondaryClassificationApplies = 'DUMP_AND_TRANSIT_MIX_USE_THESE_FACTORS_AND_CODES_ONLY_WHEN_NO_OTHER_SECONDARY_CLASSIFICATION_APPLIES',
  FarmersAutosOwnedByAFarmerUsedInConnectionWithTheOperationOfHisOwnFarmAndOccasionallyUsedToHaulCommoditiesForOtherFarmers = 'FARMERS_AUTOS_OWNED_BY_A_FARMER_USED_IN_CONNECTION_WITH_THE_OPERATION_OF_HIS_OWN_FARM_AND_OCCASIONALLY_USED_TO_HAUL_COMMODITIES_FOR_OTHER_FARMERS',
  FleetsAndVehiclesUsedForBusinessUseOnly = 'FLEETS_AND_VEHICLES_USED_FOR_BUSINESS_USE_ONLY',
  FoodDeliveryAutosUsedByFoodManufacturersToTransportRawAndFinishedProductsOrUsedInWholesaleDistributionOfFood = 'FOOD_DELIVERY_AUTOS_USED_BY_FOOD_MANUFACTURERS_TO_TRANSPORT_RAW_AND_FINISHED_PRODUCTS_OR_USED_IN_WHOLESALE_DISTRIBUTION_OF_FOOD',
  NonfleetVehiclesUsedForFarmingOrAvailableForPersonalUse = 'NONFLEET_VEHICLES_USED_FOR_FARMING_OR_AVAILABLE_FOR_PERSONAL_USE',
  Other = 'OTHER',
  SpecializedDeliveryAutosUsedInDeliveriesSubjectToTimeAndSimilarConstraints = 'SPECIALIZED_DELIVERY_AUTOS_USED_IN_DELIVERIES_SUBJECT_TO_TIME_AND_SIMILAR_CONSTRAINTS',
  TruckersAutosUsedToHaulOrTransportGoodsMaterialsOrCommoditiesForAnotherOtherThanAutosUsedInMovingOperations = 'TRUCKERS_AUTOS_USED_TO_HAUL_OR_TRANSPORT_GOODS_MATERIALS_OR_COMMODITIES_FOR_ANOTHER_OTHER_THAN_AUTOS_USED_IN_MOVING_OPERATIONS',
  WasteDisposalAutosTransportingSalvageAndWasteMaterialForDisposalOrResale = 'WASTE_DISPOSAL_AUTOS_TRANSPORTING_SALVAGE_AND_WASTE_MATERIAL_FOR_DISPOSAL_OR_RESALE',
}

export enum AutoSizeClassEnum {
  ExtraHeavyTruck = 'EXTRA_HEAVY_TRUCK',
  ExtraHeavyTruckTractor = 'EXTRA_HEAVY_TRUCK_TRACTOR',
  HeavyTruck = 'HEAVY_TRUCK',
  HeavyTruckTractor = 'HEAVY_TRUCK_TRACTOR',
  LightTruck = 'LIGHT_TRUCK',
  MediumTruck = 'MEDIUM_TRUCK',
  PrivatePassengerType = 'PRIVATE_PASSENGER_TYPE',
  SemiTrailer = 'SEMI_TRAILER',
  ServiceOrUtilityTrailer = 'SERVICE_OR_UTILITY_TRAILER',
  Trailers = 'TRAILERS',
}

export enum AutoStatedAmountEnum {
  Amount_0To_999 = 'AMOUNT_0_TO_999',
  Amount_1000To_1999 = 'AMOUNT_1000_TO_1999',
  Amount_2000To_2999 = 'AMOUNT_2000_TO_2999',
  Amount_3000To_3999 = 'AMOUNT_3000_TO_3999',
  Amount_4000To_4999 = 'AMOUNT_4000_TO_4999',
  Amount_5000To_5999 = 'AMOUNT_5000_TO_5999',
  Amount_6000To_7999 = 'AMOUNT_6000_TO_7999',
  Amount_8000To_9999 = 'AMOUNT_8000_TO_9999',
  Amount_10000To_11999 = 'AMOUNT_10000_TO_11999',
  Amount_12000To_13999 = 'AMOUNT_12000_TO_13999',
  Amount_14000To_15999 = 'AMOUNT_14000_TO_15999',
  Amount_16000To_17999 = 'AMOUNT_16000_TO_17999',
  Amount_18000To_19999 = 'AMOUNT_18000_TO_19999',
  Amount_20000To_24999 = 'AMOUNT_20000_TO_24999',
  Amount_25000To_29999 = 'AMOUNT_25000_TO_29999',
  Amount_30000To_34999 = 'AMOUNT_30000_TO_34999',
  Amount_35000To_39999 = 'AMOUNT_35000_TO_39999',
  Amount_40000To_44999 = 'AMOUNT_40000_TO_44999',
  Amount_45000To_49999 = 'AMOUNT_45000_TO_49999',
  Amount_50000To_54999 = 'AMOUNT_50000_TO_54999',
  Amount_55000To_64999 = 'AMOUNT_55000_TO_64999',
  Amount_65000To_74999 = 'AMOUNT_65000_TO_74999',
  Amount_75000To_84999 = 'AMOUNT_75000_TO_84999',
  Amount_85000To_99999 = 'AMOUNT_85000_TO_99999',
  Amount_100000To_114999 = 'AMOUNT_100000_TO_114999',
  Amount_115000To_129999 = 'AMOUNT_115000_TO_129999',
  Amount_130000To_149999 = 'AMOUNT_130000_TO_149999',
  Amount_150000To_174999 = 'AMOUNT_150000_TO_174999',
  Amount_175000To_199999 = 'AMOUNT_175000_TO_199999',
  Amount_200000To_229999 = 'AMOUNT_200000_TO_229999',
  Amount_230000To_259999 = 'AMOUNT_230000_TO_259999',
  Amount_260000To_299999 = 'AMOUNT_260000_TO_299999',
  Amount_300000To_349999 = 'AMOUNT_300000_TO_349999',
  Amount_350000To_399999 = 'AMOUNT_350000_TO_399999',
  Amount_400000To_449999 = 'AMOUNT_400000_TO_449999',
  Amount_450000To_499999 = 'AMOUNT_450000_TO_499999',
  Amount_500000To_599999 = 'AMOUNT_500000_TO_599999',
  Amount_600000To_699999 = 'AMOUNT_600000_TO_699999',
  Amount_700000To_799999 = 'AMOUNT_700000_TO_799999',
  Amount_800000To_899999 = 'AMOUNT_800000_TO_899999',
  Amount_900000OrGreater = 'AMOUNT_900000_OR_GREATER',
}

export type AutoSummary = {
  __typename?: 'AutoSummary';
  isGridValid: Scalars['Boolean'];
  rows: Array<AutoSummaryRow>;
};

export type AutoSummaryRow = {
  __typename?: 'AutoSummaryRow';
  extraHeavyTruck: Scalars['Int'];
  extraHeavyTruckTractor: Scalars['Int'];
  heavyTruck: Scalars['Int'];
  heavyTruckTractor: Scalars['Int'];
  lightTruck: Scalars['Int'];
  mediumTruck: Scalars['Int'];
  privatePassengerType: Scalars['Int'];
  semiTrailer: Scalars['Int'];
  serviceOrUtilityTrailer: Scalars['Int'];
  state: Scalars['String'];
  trailers: Scalars['Int'];
};

export enum AutoUimbiOptionEnum {
  HiredAutosPer_100OfCostOfHire = 'HIRED_AUTOS_PER_100_OF_COST_OF_HIRE',
  IntrapolicyStackingUnderinsuredMotoristsBodilyInjuryAndPropertyDamageIndividualNamedInsured = 'INTRAPOLICY_STACKING__UNDERINSURED_MOTORISTS_BODILY_INJURY_AND_PROPERTY_DAMAGE_INDIVIDUAL_NAMED_INSURED',
  None = 'NONE',
  NonownedAutosPerEmployee = 'NONOWNED_AUTOS_PER_EMPLOYEE',
  NonstackedUnderinsuredMotoristsBi = 'NONSTACKED_UNDERINSURED_MOTORISTS_BI',
  NonstackedUnderinsuredMotoristsBiIndividualNamedInsured = 'NONSTACKED_UNDERINSURED_MOTORISTS_BI_INDIVIDUAL_NAMED_INSURED',
  StackedUnderinsuredMotoristsBi = 'STACKED_UNDERINSURED_MOTORISTS_BI',
  StackedUnderinsuredMotoristsBiIndividualNamedInsured = 'STACKED_UNDERINSURED_MOTORISTS_BI_INDIVIDUAL_NAMED_INSURED',
  StackedUnderinsuredMotoristsBiOtherThanIndividualNamedInsured = 'STACKED_UNDERINSURED_MOTORISTS_BI_OTHER_THAN_INDIVIDUAL_NAMED_INSURED',
  SupplementaryUnderinsuredMotoristsBodilyInjury = 'SUPPLEMENTARY_UNDERINSURED_MOTORISTS_BODILY_INJURY',
  UnderinsuredMotoristsBodilyInjury = 'UNDERINSURED_MOTORISTS_BODILY_INJURY',
  UnderinsuredMotoristsBodilyInjuryAndPropertyDamage = 'UNDERINSURED_MOTORISTS_BODILY_INJURY_AND_PROPERTY_DAMAGE',
  UnderinsuredMotoristsBodilyInjuryAndPropertyDamageOtherThanIndividualNamedInsured = 'UNDERINSURED_MOTORISTS_BODILY_INJURY_AND_PROPERTY_DAMAGE_OTHER_THAN_INDIVIDUAL_NAMED_INSURED',
  UnderinsuredMotoristsBodilyInjuryIndividualNamedInsureds = 'UNDERINSURED_MOTORISTS_BODILY_INJURY__INDIVIDUAL_NAMED_INSUREDS',
  UnderinsuredMotoristsBodilyInjuryOtherThanIndividualNamedInsureds = 'UNDERINSURED_MOTORISTS_BODILY_INJURY__OTHER_THAN_INDIVIDUAL_NAMED_INSUREDS',
  UnderinsuredMotoristsConversionBodilyInjury = 'UNDERINSURED_MOTORISTS_CONVERSION_BODILY_INJURY',
}

export enum AutoUmbiOptionEnum {
  HiredAutosPer_100OfCostOfHire = 'HIRED_AUTOS_PER_100_OF_COST_OF_HIRE',
  IndividualNamedInsured = 'INDIVIDUAL_NAMED_INSURED',
  InterpolicyAndIntrapolicyStackingUninsuredIncludesUnderinsuredMotoristsBi = 'INTERPOLICY_AND_INTRAPOLICY_STACKING__UNINSURED_INCLUDES_UNDERINSURED_MOTORISTS_BI',
  InterpolicyAndIntrapolicyStackingUninsuredIncludesUnderinsuredMotoristsBiAndPd = 'INTERPOLICY_AND_INTRAPOLICY_STACKING__UNINSURED_INCLUDES_UNDERINSURED_MOTORISTS_BI_AND_PD',
  InterpolicyAndIntrapolicyStackingUninsuredIncludesUnderinsuredMotoristsBiIndividualNamedInsured = 'INTERPOLICY_AND_INTRAPOLICY_STACKING__UNINSURED_INCLUDES_UNDERINSURED_MOTORISTS_BI_INDIVIDUAL_NAMED_INSURED',
  InterpolicyAndIntrapolicyStackingUninsuredIncludesUnderinsuredMotoristsBiIndividualNamedInsuredNotSubjectToNofault = 'INTERPOLICY_AND_INTRAPOLICY_STACKING__UNINSURED_INCLUDES_UNDERINSURED_MOTORISTS_BI_INDIVIDUAL_NAMED_INSURED__NOT_SUBJECT_TO_NOFAULT',
  InterpolicyAndIntrapolicyStackingUninsuredIncludesUnderinsuredMotoristsBiIndividualNamedInsuredSubjectToNofault = 'INTERPOLICY_AND_INTRAPOLICY_STACKING__UNINSURED_INCLUDES_UNDERINSURED_MOTORISTS_BI_INDIVIDUAL_NAMED_INSURED__SUBJECT_TO_NOFAULT',
  InterpolicyAndIntrapolicyStackingUninsuredIncludesUnderinsuredMotoristsBodilyInjuryAndPropertyDamage = 'INTERPOLICY_AND_INTRAPOLICY_STACKING__UNINSURED_INCLUDES_UNDERINSURED_MOTORISTS_BODILY_INJURY_AND_PROPERTY_DAMAGE',
  InterpolicyAndIntrapolicyStackingUninsuredMotoristsBiIndividualNamedInsured = 'INTERPOLICY_AND_INTRAPOLICY_STACKING__UNINSURED_MOTORISTS_BI_INDIVIDUAL_NAMED_INSURED',
  InterpolicyStackingUninsuredIncludesUnderinsuredMotoristsBi = 'INTERPOLICY_STACKING__UNINSURED_INCLUDES_UNDERINSURED_MOTORISTS_BI',
  InterpolicyStackingUninsuredIncludesUnderinsuredMotoristsBiAndPd = 'INTERPOLICY_STACKING__UNINSURED_INCLUDES_UNDERINSURED_MOTORISTS_BI_AND_PD',
  InterpolicyStackingUninsuredIncludesUnderinsuredMotoristsBodilyInjuryAndPropertyDamage = 'INTERPOLICY_STACKING__UNINSURED_INCLUDES_UNDERINSURED_MOTORISTS_BODILY_INJURY_AND_PROPERTY_DAMAGE',
  InterpolicyStackingUninsuredMotoristsBiOtherThanIndividualNamedInsured = 'INTERPOLICY_STACKING__UNINSURED_MOTORISTS_BI_OTHER_THAN_INDIVIDUAL_NAMED_INSURED',
  InterpolicyStackingUninsuredIncludesUnderinsuredMotoristsBiOtherThanIndividualNamedInsured = 'INTERPOLICY__STACKING__UNINSURED_INCLUDES_UNDERINSURED_MOTORISTS_BI_OTHER_THAN_INDIVIDUAL_NAMED_INSURED',
  IntrapolicyStackingUninsuredMotoristsBodilyInjuryAndPropertyDamageIndividualNamedInsured = 'INTRAPOLICY_STACKING__UNINSURED_MOTORISTS_BODILY_INJURY_AND_PROPERTY_DAMAGE_INDIVIDUAL_NAMED_INSURED',
  None = 'NONE',
  NonownedAutosPerEmployee = 'NONOWNED_AUTOS_PER_EMPLOYEE',
  NonstackedUninsuredIncludesUnderinsuredMotoristsBi = 'NONSTACKED_UNINSURED_INCLUDES_UNDERINSURED_MOTORISTS_BI',
  NonstackedUninsuredIncludesUnderinsuredMotoristsBiNotSubjectToNofault = 'NONSTACKED_UNINSURED_INCLUDES_UNDERINSURED_MOTORISTS_BI__NOT_SUBJECT_TO_NOFAULT',
  NonstackedUninsuredIncludesUnderinsuredMotoristsBiSubjectToNofault = 'NONSTACKED_UNINSURED_INCLUDES_UNDERINSURED_MOTORISTS_BI__SUBJECT_TO_NOFAULT',
  NonstackedUninsuredMotoristsBi = 'NONSTACKED_UNINSURED_MOTORISTS_BI',
  NonstackedUninsuredMotoristsBiIndividualNamedInsured = 'NONSTACKED_UNINSURED_MOTORISTS_BI_INDIVIDUAL_NAMED_INSURED',
  OtherThanIndividualNamedInsured = 'OTHER_THAN_INDIVIDUAL_NAMED_INSURED',
  StackedUninsuredMotoristsBi = 'STACKED_UNINSURED_MOTORISTS_BI',
  StackedUninsuredMotoristsBiIndividualNamedInsured = 'STACKED_UNINSURED_MOTORISTS_BI_INDIVIDUAL_NAMED_INSURED',
  StackedUninsuredMotoristsBiOtherThanIndividualNamedInsured = 'STACKED_UNINSURED_MOTORISTS_BI_OTHER_THAN_INDIVIDUAL_NAMED_INSURED',
  SupplementaryUninsuredMotoristsBodilyInjury = 'SUPPLEMENTARY_UNINSURED_MOTORISTS_BODILY_INJURY',
  UninsuredAndUnderinsuredMotoristsBodilyInjury = 'UNINSURED_AND_UNDERINSURED_MOTORISTS_BODILY_INJURY',
  UninsuredAndUnderinsuredMotoristsBodilyInjuryAndPropertyDamage = 'UNINSURED_AND_UNDERINSURED_MOTORISTS_BODILY_INJURY_AND_PROPERTY_DAMAGE',
  UninsuredIncludesUnderinsuredMotoristsBi = 'UNINSURED_INCLUDES_UNDERINSURED_MOTORISTS_BI',
  UninsuredIncludesUnderinsuredMotoristsBodilyInjuryAndPropertyDamage = 'UNINSURED_INCLUDES_UNDERINSURED_MOTORISTS_BODILY_INJURY_AND_PROPERTY_DAMAGE',
  UninsuredIncludesUnderinsuredMotoristsBodilyInjuryAndPropertyDamageIndividualNamedInsuredAddedOnToAtfaultLiability = 'UNINSURED_INCLUDES_UNDERINSURED_MOTORISTS_BODILY_INJURY_AND_PROPERTY_DAMAGE_INDIVIDUAL_NAMED_INSURED___ADDED_ON_TO_ATFAULT_LIABILITY',
  UninsuredIncludesUnderinsuredMotoristsBodilyInjuryAndPropertyDamageIndividualNamedInsuredReducedByAtfaultLiability = 'UNINSURED_INCLUDES_UNDERINSURED_MOTORISTS_BODILY_INJURY_AND_PROPERTY_DAMAGE_INDIVIDUAL_NAMED_INSURED___REDUCED_BY_ATFAULT_LIABILITY',
  UninsuredIncludesUnderinsuredMotoristsBodilyInjuryAndPropertyDamageOtherThanIndividualNamedInsuredAddedOnToAtfaultLiability = 'UNINSURED_INCLUDES_UNDERINSURED_MOTORISTS_BODILY_INJURY_AND_PROPERTY_DAMAGE_OTHER_THAN_INDIVIDUAL_NAMED_INSURED___ADDED_ON_TO_ATFAULT_LIABILITY',
  UninsuredIncludesUnderinsuredMotoristsBodilyInjuryAndPropertyDamageOtherThanIndividualNamedInsuredReducedByAtfaultLiability = 'UNINSURED_INCLUDES_UNDERINSURED_MOTORISTS_BODILY_INJURY_AND_PROPERTY_DAMAGE_OTHER_THAN_INDIVIDUAL_NAMED_INSURED___REDUCED_BY_ATFAULT_LIABILITY',
  UninsuredIncludesUnderinsuredMotoristsBodilyInjuryAndPropertyDamageAlternativeCoverage = 'UNINSURED_INCLUDES_UNDERINSURED_MOTORISTS_BODILY_INJURY_AND_PROPERTY_DAMAGE__ALTERNATIVE_COVERAGE',
  UninsuredMotoristsBodilyInjury = 'UNINSURED_MOTORISTS_BODILY_INJURY',
  UninsuredMotoristsBodilyInjuryAndPropertyDamage = 'UNINSURED_MOTORISTS_BODILY_INJURY_AND_PROPERTY_DAMAGE',
  UninsuredMotoristsBodilyInjuryAndPropertyDamageOtherThanIndividualNamedInsured = 'UNINSURED_MOTORISTS_BODILY_INJURY_AND_PROPERTY_DAMAGE_OTHER_THAN_INDIVIDUAL_NAMED_INSURED',
  UninsuredMotoristsBodilyInjuryIndividualNamedInsureds = 'UNINSURED_MOTORISTS_BODILY_INJURY__INDIVIDUAL_NAMED_INSUREDS',
  UninsuredMotoristsBodilyInjuryOtherThanIndividualNamedInsureds = 'UNINSURED_MOTORISTS_BODILY_INJURY__OTHER_THAN_INDIVIDUAL_NAMED_INSUREDS',
}

export type AutoVehicleAggregateInput = {
  count: Scalars['Float'];
  exposureId?: InputMaybe<Scalars['ID']>;
  sizeClass: Scalars['String'];
  state: State | `${State}`;
  typeOfVehicle: Scalars['String'];
};

export type AutoVehicleInput = {
  biOption: Scalars['String'];
  exposureId?: InputMaybe<Scalars['ID']>;
  modelYear: Scalars['String'];
  ocnStatedAmount: Scalars['String'];
  radius?: InputMaybe<Scalars['String']>;
  secondaryClassDetailPrivateClassDetail: Scalars['String'];
  secondaryClassPrivateClass: Scalars['String'];
  sizeClass: Scalars['String'];
  state: State | `${State}`;
  statedAmount: Scalars['String'];
  typeOfVehicle: Scalars['String'];
  uimbiLimit?: InputMaybe<Scalars['String']>;
  uimbiOption?: InputMaybe<Scalars['String']>;
  umbiLimit?: InputMaybe<Scalars['String']>;
  umbiOption?: InputMaybe<Scalars['String']>;
  use: Scalars['String'];
  vin?: InputMaybe<Scalars['String']>;
  zipCode: Scalars['String'];
};

export enum AutoVehicleUseEnum {
  Commercial = 'COMMERCIAL',
  NotDrivenToWorkOrSchool = 'NOT_DRIVEN_TO_WORK_OR_SCHOOL',
  Retail = 'RETAIL',
  Service = 'SERVICE',
  ToOrFromWork_15OrMoreMiles = 'TO_OR_FROM_WORK_15_OR_MORE_MILES',
  ToOrFromWorkLessThan_15Miles = 'TO_OR_FROM_WORK_LESS_THAN_15_MILES',
}

export type AutoVinDataErrorObject = {
  __typename?: 'AutoVinDataErrorObject';
  errorCode: Scalars['String'];
  errorText: Scalars['String'];
  vin: Scalars['String'];
};

export type AutoVinDataObject = AutoVinDataErrorObject | AutoVinDataSuccessObject;

export type AutoVinDataSuccessObject = {
  __typename?: 'AutoVinDataSuccessObject';
  amountRange?: Maybe<AutoStatedAmountEnum | `${AutoStatedAmountEnum}`>;
  errorCode: Scalars['String'];
  make?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  modelYear?: Maybe<Scalars['String']>;
  sizeClass?: Maybe<Array<AutoSizeClassEnum | `${AutoSizeClassEnum}`>>;
  vehicleType?: Maybe<VehicleTypesEnum | `${VehicleTypesEnum}`>;
  vin: Scalars['String'];
};

export type AutodeskHub = {
  __typename?: 'AutodeskHub';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type AvailableFormsInput = {
  quoteId: Scalars['ID'];
};

export type BaseError = Error & {
  __typename?: 'BaseError';
  message: Scalars['String'];
  stack?: Maybe<Scalars['String']>;
};

export type BatchGetOrganizationMembersInput = {
  organizationId?: InputMaybe<Scalars['ID']>;
};

export type BatchGetUnderlyingPoliciesInput = {
  quoteId: Scalars['ID'];
};

export type BiOptionsConstant = {
  __typename?: 'BiOptionsConstant';
  label: Scalars['String'];
  state: State | `${State}`;
  value: Scalars['String'];
  vehicleType: Scalars['String'];
};

export enum BusinessType {
  BidOnly = 'BID_ONLY',
  NewBusiness = 'NEW_BUSINESS',
  Renewal = 'RENEWAL',
}

export type CaseStudiesGeneratedOutput = {
  __typename?: 'CaseStudiesGeneratedOutput';
  createdAt: Scalars['DateTime'];
  fileUrl: Scalars['String'];
  folderUrl: Scalars['String'];
};

export type CaseStudiesInput = {
  policyholderId: Scalars['String'];
};

export type CasualyProIntegrations = {
  __typename?: 'CasualyProIntegrations';
  autodesk: Scalars['Boolean'];
  openspace: Scalars['Boolean'];
  procore: Scalars['Boolean'];
};

export type CmsTeamMember = {
  __typename?: 'CmsTeamMember';
  email: Scalars['String'];
  imageUrl: Scalars['String'];
  linkedin?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  title: Scalars['String'];
};

export type CollisionDeductibleCostOfHireBasisObject = {
  __typename?: 'CollisionDeductibleCostOfHireBasisObject';
  label: Scalars['String'];
  value:
    | CollisionDeductibleCostOfHireBasisObjectValueEnum
    | `${CollisionDeductibleCostOfHireBasisObjectValueEnum}`;
};

export enum CollisionDeductibleCostOfHireBasisObjectValueEnum {
  CollisionDeductibleCostOfHireBasis_100 = 'COLLISION_DEDUCTIBLE_COST_OF_HIRE_BASIS_100',
  CollisionDeductibleCostOfHireBasis_250 = 'COLLISION_DEDUCTIBLE_COST_OF_HIRE_BASIS_250',
  CollisionDeductibleCostOfHireBasis_500 = 'COLLISION_DEDUCTIBLE_COST_OF_HIRE_BASIS_500',
  CollisionDeductibleCostOfHireBasis_1000 = 'COLLISION_DEDUCTIBLE_COST_OF_HIRE_BASIS_1000',
}

export type CollisionDeductibleOptionsObject = {
  __typename?: 'CollisionDeductibleOptionsObject';
  label: Scalars['String'];
  value: CollisionDeductibleOptionsObjectValueEnum | `${CollisionDeductibleOptionsObjectValueEnum}`;
};

export enum CollisionDeductibleOptionsObjectValueEnum {
  CollisionDeductibleOptions_50 = 'COLLISION_DEDUCTIBLE_OPTIONS_50',
  CollisionDeductibleOptions_100 = 'COLLISION_DEDUCTIBLE_OPTIONS_100',
  CollisionDeductibleOptions_200 = 'COLLISION_DEDUCTIBLE_OPTIONS_200',
  CollisionDeductibleOptions_250 = 'COLLISION_DEDUCTIBLE_OPTIONS_250',
  CollisionDeductibleOptions_500 = 'COLLISION_DEDUCTIBLE_OPTIONS_500',
  CollisionDeductibleOptions_1000 = 'COLLISION_DEDUCTIBLE_OPTIONS_1000',
  CollisionDeductibleOptions_2000 = 'COLLISION_DEDUCTIBLE_OPTIONS_2000',
  CollisionDeductibleOptions_2500 = 'COLLISION_DEDUCTIBLE_OPTIONS_2500',
  CollisionDeductibleOptions_3000 = 'COLLISION_DEDUCTIBLE_OPTIONS_3000',
  CollisionDeductibleOptions_5000 = 'COLLISION_DEDUCTIBLE_OPTIONS_5000',
}

export type ColumnsMatrixSchemaObject = {
  __typename?: 'ColumnsMatrixSchemaObject';
  key: Scalars['String'];
  label: Scalars['String'];
};

export type CombinedSingleLimitOptionsObject = {
  __typename?: 'CombinedSingleLimitOptionsObject';
  label: Scalars['String'];
  value: CombinedSingleLimitOptionsObjectValueEnum | `${CombinedSingleLimitOptionsObjectValueEnum}`;
};

export enum CombinedSingleLimitOptionsObjectValueEnum {
  LimitOptions_25000 = 'LIMIT_OPTIONS_25000',
  LimitOptions_35000 = 'LIMIT_OPTIONS_35000',
  LimitOptions_50000 = 'LIMIT_OPTIONS_50000',
  LimitOptions_55000 = 'LIMIT_OPTIONS_55000',
  LimitOptions_60000 = 'LIMIT_OPTIONS_60000',
  LimitOptions_65000 = 'LIMIT_OPTIONS_65000',
  LimitOptions_70000 = 'LIMIT_OPTIONS_70000',
  LimitOptions_75000 = 'LIMIT_OPTIONS_75000',
  LimitOptions_80000 = 'LIMIT_OPTIONS_80000',
  LimitOptions_85000 = 'LIMIT_OPTIONS_85000',
  LimitOptions_100000 = 'LIMIT_OPTIONS_100000',
  LimitOptions_110000 = 'LIMIT_OPTIONS_110000',
  LimitOptions_125000 = 'LIMIT_OPTIONS_125000',
  LimitOptions_150000 = 'LIMIT_OPTIONS_150000',
  LimitOptions_200000 = 'LIMIT_OPTIONS_200000',
  LimitOptions_250000 = 'LIMIT_OPTIONS_250000',
  LimitOptions_300000 = 'LIMIT_OPTIONS_300000',
  LimitOptions_350000 = 'LIMIT_OPTIONS_350000',
  LimitOptions_400000 = 'LIMIT_OPTIONS_400000',
  LimitOptions_500000 = 'LIMIT_OPTIONS_500000',
  LimitOptions_510000 = 'LIMIT_OPTIONS_510000',
  LimitOptions_600000 = 'LIMIT_OPTIONS_600000',
  LimitOptions_750000 = 'LIMIT_OPTIONS_750000',
  LimitOptions_1000000 = 'LIMIT_OPTIONS_1000000',
  LimitOptions_1500000 = 'LIMIT_OPTIONS_1500000',
  LimitOptions_2000000 = 'LIMIT_OPTIONS_2000000',
  LimitOptions_2500000 = 'LIMIT_OPTIONS_2500000',
  LimitOptions_3000000 = 'LIMIT_OPTIONS_3000000',
  LimitOptions_5000000 = 'LIMIT_OPTIONS_5000000',
  LimitOptions_7500000 = 'LIMIT_OPTIONS_7500000',
  LimitOptions_10000000 = 'LIMIT_OPTIONS_10000000',
  LimitOptions_15000000 = 'LIMIT_OPTIONS_15000000',
  LimitOptions_20000000 = 'LIMIT_OPTIONS_20000000',
  LimitOptions_25000000 = 'LIMIT_OPTIONS_25000000',
  LimitOptions_30000000 = 'LIMIT_OPTIONS_30000000',
  LimitOptions_35000000 = 'LIMIT_OPTIONS_35000000',
  LimitOptions_40000000 = 'LIMIT_OPTIONS_40000000',
  LimitOptions_45000000 = 'LIMIT_OPTIONS_45000000',
  LimitOptions_50000000 = 'LIMIT_OPTIONS_50000000',
  LimitOptions_55000000 = 'LIMIT_OPTIONS_55000000',
  LimitOptions_60000000 = 'LIMIT_OPTIONS_60000000',
  LimitOptions_65000000 = 'LIMIT_OPTIONS_65000000',
  LimitOptions_70000000 = 'LIMIT_OPTIONS_70000000',
  LimitOptions_75000000 = 'LIMIT_OPTIONS_75000000',
  LimitOptions_80000000 = 'LIMIT_OPTIONS_80000000',
  LimitOptions_85000000 = 'LIMIT_OPTIONS_85000000',
  LimitOptions_90000000 = 'LIMIT_OPTIONS_90000000',
  LimitOptions_95000000 = 'LIMIT_OPTIONS_95000000',
  LimitOptions_100000000 = 'LIMIT_OPTIONS_100000000',
  LimitOptions_105000000 = 'LIMIT_OPTIONS_105000000',
  LimitOptions_110000000 = 'LIMIT_OPTIONS_110000000',
  LimitOptions_115000000 = 'LIMIT_OPTIONS_115000000',
  LimitOptions_120000000 = 'LIMIT_OPTIONS_120000000',
  LimitOptions_125000000 = 'LIMIT_OPTIONS_125000000',
  LimitOptions_130000000 = 'LIMIT_OPTIONS_130000000',
  LimitOptions_135000000 = 'LIMIT_OPTIONS_135000000',
  LimitOptions_140000000 = 'LIMIT_OPTIONS_140000000',
  LimitOptions_145000000 = 'LIMIT_OPTIONS_145000000',
  LimitOptions_150000000 = 'LIMIT_OPTIONS_150000000',
  LimitOptions_155000000 = 'LIMIT_OPTIONS_155000000',
  LimitOptions_160000000 = 'LIMIT_OPTIONS_160000000',
  LimitOptions_165000000 = 'LIMIT_OPTIONS_165000000',
  LimitOptions_170000000 = 'LIMIT_OPTIONS_170000000',
  LimitOptions_175000000 = 'LIMIT_OPTIONS_175000000',
  LimitOptions_180000000 = 'LIMIT_OPTIONS_180000000',
  LimitOptions_185000000 = 'LIMIT_OPTIONS_185000000',
  LimitOptions_190000000 = 'LIMIT_OPTIONS_190000000',
  LimitOptions_195000000 = 'LIMIT_OPTIONS_195000000',
  LimitOptions_200000000 = 'LIMIT_OPTIONS_200000000',
  LimitOptions_225000000 = 'LIMIT_OPTIONS_225000000',
  LimitOptions_250000000 = 'LIMIT_OPTIONS_250000000',
  LimitOptions_275000000 = 'LIMIT_OPTIONS_275000000',
  LimitOptions_300000000 = 'LIMIT_OPTIONS_300000000',
  LimitOptions_325000000 = 'LIMIT_OPTIONS_325000000',
  LimitOptions_350000000 = 'LIMIT_OPTIONS_350000000',
  LimitOptions_375000000 = 'LIMIT_OPTIONS_375000000',
  LimitOptions_400000000 = 'LIMIT_OPTIONS_400000000',
  LimitOptions_425000000 = 'LIMIT_OPTIONS_425000000',
  LimitOptions_450000000 = 'LIMIT_OPTIONS_450000000',
  LimitOptions_475000000 = 'LIMIT_OPTIONS_475000000',
  LimitOptions_500000000 = 'LIMIT_OPTIONS_500000000',
  LimitOptions_525000000 = 'LIMIT_OPTIONS_525000000',
  LimitOptions_550000000 = 'LIMIT_OPTIONS_550000000',
  LimitOptions_575000000 = 'LIMIT_OPTIONS_575000000',
  LimitOptions_600000000 = 'LIMIT_OPTIONS_600000000',
  LimitOptions_625000000 = 'LIMIT_OPTIONS_625000000',
  LimitOptions_650000000 = 'LIMIT_OPTIONS_650000000',
  LimitOptions_675000000 = 'LIMIT_OPTIONS_675000000',
  LimitOptions_700000000 = 'LIMIT_OPTIONS_700000000',
  LimitOptions_725000000 = 'LIMIT_OPTIONS_725000000',
  LimitOptions_750000000 = 'LIMIT_OPTIONS_750000000',
  LimitOptions_775000000 = 'LIMIT_OPTIONS_775000000',
  LimitOptions_800000000 = 'LIMIT_OPTIONS_800000000',
  LimitOptions_825000000 = 'LIMIT_OPTIONS_825000000',
  LimitOptions_850000000 = 'LIMIT_OPTIONS_850000000',
  LimitOptions_875000000 = 'LIMIT_OPTIONS_875000000',
  LimitOptions_900000000 = 'LIMIT_OPTIONS_900000000',
  LimitOptions_925000000 = 'LIMIT_OPTIONS_925000000',
  LimitOptions_950000000 = 'LIMIT_OPTIONS_950000000',
  LimitOptions_975000000 = 'LIMIT_OPTIONS_975000000',
  LimitOptions_1000000000 = 'LIMIT_OPTIONS_1000000000',
}

export type CommentActivityObject = {
  __typename?: 'CommentActivityObject';
  content: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  quoteActivityId: Scalars['ID'];
  type: CommentActivityTypeEnum | `${CommentActivityTypeEnum}`;
  updatedAt: Scalars['DateTime'];
};

export enum CommentActivityTypeEnum {
  Comment = 'COMMENT',
}

export enum ComparisonSentiment {
  Negative = 'NEGATIVE',
  Neutral = 'NEUTRAL',
  Positive = 'POSITIVE',
}

export type ComprehensiveDeductibleCostOfHireBasis = {
  __typename?: 'ComprehensiveDeductibleCostOfHireBasis';
  label: Scalars['String'];
  value:
    | ComprehensiveDeductibleCostOfHireBasisObjectValueEnum
    | `${ComprehensiveDeductibleCostOfHireBasisObjectValueEnum}`;
};

export enum ComprehensiveDeductibleCostOfHireBasisObjectValueEnum {
  ComprehensiveDeductibleCostOfHireBasis_0 = 'COMPREHENSIVE_DEDUCTIBLE_COST_OF_HIRE_BASIS_0',
  ComprehensiveDeductibleCostOfHireBasis_50 = 'COMPREHENSIVE_DEDUCTIBLE_COST_OF_HIRE_BASIS_50',
  ComprehensiveDeductibleCostOfHireBasis_100 = 'COMPREHENSIVE_DEDUCTIBLE_COST_OF_HIRE_BASIS_100',
}

export type ComprehensiveDeductibleOptionsObject = {
  __typename?: 'ComprehensiveDeductibleOptionsObject';
  label: Scalars['String'];
  value:
    | ComprehensiveDeductibleOptionsObjectValueEnum
    | `${ComprehensiveDeductibleOptionsObjectValueEnum}`;
};

export enum ComprehensiveDeductibleOptionsObjectValueEnum {
  ComprehensiveDeductibleOptions_0 = 'COMPREHENSIVE_DEDUCTIBLE_OPTIONS_0',
  ComprehensiveDeductibleOptions_50 = 'COMPREHENSIVE_DEDUCTIBLE_OPTIONS_50',
  ComprehensiveDeductibleOptions_100 = 'COMPREHENSIVE_DEDUCTIBLE_OPTIONS_100',
  ComprehensiveDeductibleOptions_200 = 'COMPREHENSIVE_DEDUCTIBLE_OPTIONS_200',
  ComprehensiveDeductibleOptions_250 = 'COMPREHENSIVE_DEDUCTIBLE_OPTIONS_250',
  ComprehensiveDeductibleOptions_500 = 'COMPREHENSIVE_DEDUCTIBLE_OPTIONS_500',
  ComprehensiveDeductibleOptions_1000 = 'COMPREHENSIVE_DEDUCTIBLE_OPTIONS_1000',
  ComprehensiveDeductibleOptions_2000 = 'COMPREHENSIVE_DEDUCTIBLE_OPTIONS_2000',
  ComprehensiveDeductibleOptions_2500 = 'COMPREHENSIVE_DEDUCTIBLE_OPTIONS_2500',
  ComprehensiveDeductibleOptions_3000 = 'COMPREHENSIVE_DEDUCTIBLE_OPTIONS_3000',
  ComprehensiveDeductibleOptions_5000 = 'COMPREHENSIVE_DEDUCTIBLE_OPTIONS_5000',
  ComprehensiveDeductibleOptions_10000 = 'COMPREHENSIVE_DEDUCTIBLE_OPTIONS_10000',
  ComprehensiveDeductibleOptions_15000 = 'COMPREHENSIVE_DEDUCTIBLE_OPTIONS_15000',
  ComprehensiveDeductibleOptions_20000 = 'COMPREHENSIVE_DEDUCTIBLE_OPTIONS_20000',
}

export type ComprehensiveOptionsObject = {
  __typename?: 'ComprehensiveOptionsObject';
  comprehensiveOption: Scalars['String'];
  comprehensiveOptionValue:
    | ComprehensiveOptionsObjectValueEnum
    | `${ComprehensiveOptionsObjectValueEnum}`;
  state: State | `${State}`;
  type: Scalars['String'];
};

export enum ComprehensiveOptionsObjectValueEnum {
  ComprehensiveAllPerils = 'COMPREHENSIVE_ALL_PERILS',
  ComprehensiveAllPerilsWithFullGlassCoverage = 'COMPREHENSIVE_ALL_PERILS_WITH_FULL_GLASS_COVERAGE',
  ComprehensiveTheftMischiefAndVandalism = 'COMPREHENSIVE_THEFT_MISCHIEF_AND_VANDALISM',
  ComprehensiveTheftMischiefAndVandalismWithFullGlassCoverage = 'COMPREHENSIVE_THEFT_MISCHIEF_AND_VANDALISM_WITH_FULL_GLASS_COVERAGE',
  None = 'NONE',
  SpecifiedCausesOfLoss = 'SPECIFIED_CAUSES_OF_LOSS',
}

export type ConstructionTechCohort = {
  __typename?: 'ConstructionTechCohort';
  hasOpenspace: Scalars['Boolean'];
  hasProcore: Scalars['Boolean'];
  openspace: Scalars['Float'];
  procore: Scalars['Float'];
};

export enum ContractorType {
  GeneralContractor = 'GENERAL_CONTRACTOR',
  TradeContractor = 'TRADE_CONTRACTOR',
}

export enum CostOfHireVariant {
  PrimaryPractice = 'PRIMARY_PRACTICE',
  PrimaryProject = 'PRIMARY_PROJECT',
}

export type CoverageInputsConstantsObject = {
  __typename?: 'CoverageInputsConstantsObject';
  damageToPremisesRentedToYouOptions: Array<LabelValueObject>;
  deductibleGeneralLiabilityOptions: Array<DeductibleGeneralLiabilityOptionsObject>;
  deductibleOptions: Array<DeductibleTypeOptionsObject>;
  defenseExpenseTreatments: Array<GeneralLiabilityDefenseExpenseTreatmentsObject>;
  employeeBenefitLiabilityLimitOptions: Array<EmployeeBenefitLiabilityLimitOptionsObject>;
  employeeBenefitLiabilityNumberOfEmployeesOptions: Array<EmployeeBenefitLiabilityNumberOfEmployeesOptionsObject>;
  medicalExpenseLimitOptions: Array<MedicalExpenseLimitOptionsObject>;
  overallAggregateCapTypeOptions: Array<OverallAggregateCapTypeOptionsObject>;
  policyLimitPolicyAggregateLimitILFPcoOptions: Array<PolicyLimitPolicyAggregateLimitIlfPremOpsOptionsObject>;
  policyLimitPolicyAggregateLimitILFPremOpsOptions: Array<PolicyLimitPolicyAggregateLimitIlfPremOpsOptionsObject>;
  projectGeneralAggregateOptions: Array<ProjectGeneralAggregateOptionsObject>;
  unmannedAircraftOwnershipAndOperationOptions: Array<UnmannedAircraftOwnershipAndOperationModifiersOptionsObject>;
  unmannedAircraftPlaceOfOperationModifiersOptions: Array<UnmannedAircraftOwnershipAndOperationOptionsObject>;
  unmannedAircraftTakeOffWeightOptions: Array<UnmannedAircraftTakeOffWeightOptionsObject>;
  unmannedAircraftUsageModifiersOptions: Array<UnmannedAircraftUsageModifiersOptionsObject>;
};

export type CoverageOptionsConstants = {
  __typename?: 'CoverageOptionsConstants';
  biOptions: Array<BiOptionsConstant>;
  uimbiCoverageOptions: Array<UimbiCoverageOptionsConstant>;
  uimbiLimitOptions: Array<UimbiLimitOptionsConstant>;
  umbiCoverageOptions: Array<UmbiCoverageOptionsConstant>;
  umbiLimitOptions: Array<UmbiLimitOptionsConstant>;
  umpdCoverageOptions: Array<UmpdCoverageOptionsConstantObject>;
  umpdLimitOptions: Array<UmpdLimitOptionsConstantObject>;
};

export type CreateCommentActivity = {
  content: Scalars['String'];
};

export type CreateCommentQuoteActivityInput = {
  activity: CreateCommentActivity;
  quoteId: Scalars['String'];
  userId?: InputMaybe<Scalars['ID']>;
};

export type CreateFileInput = {
  file: Scalars['UploadFile'];
  policyId?: InputMaybe<Scalars['ID']>;
  quoteId?: InputMaybe<Scalars['ID']>;
  type: FileType | `${FileType}`;
};

export type CreateFormGroupInput = {
  formVendor: FormVendor | `${FormVendor}`;
  name: Scalars['String'];
};

export type CreateFormPdfInput = {
  active: Scalars['Boolean'];
  category?: InputMaybe<Scalars['String']>;
  defaultStatus: FormStatus | `${FormStatus}`;
  file?: InputMaybe<Scalars['UploadFile']>;
  formGroupId: Scalars['ID'];
  formGroupInsertionIndex: Scalars['Int'];
  formNumber: Scalars['String'];
  formType: Scalars['String'];
  googleDocUrl?: InputMaybe<Scalars['String']>;
  hasEndorsementNumber: Scalars['Boolean'];
  isPdf?: InputMaybe<Scalars['Boolean']>;
  lineOfBusiness?: InputMaybe<LineOfBusiness | `${LineOfBusiness}`>;
  notes?: InputMaybe<Scalars['String']>;
  stateExceptionRules?: InputMaybe<Array<StateExceptionRulesInput>>;
  stateExceptions?: InputMaybe<Array<Scalars['String']>>;
  tria: TriaOption | `${TriaOption}`;
};

export type CreateOrganizationInput = {
  city?: InputMaybe<Scalars['String']>;
  einNumber?: InputMaybe<Scalars['String']>;
  logoUrl?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  npn?: InputMaybe<Scalars['String']>;
  parentOrganizationId?: InputMaybe<Scalars['ID']>;
  postalCode?: InputMaybe<Scalars['String']>;
  salesforceRecordId?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<State | `${State}`>;
  street?: InputMaybe<Scalars['String']>;
  street2?: InputMaybe<Scalars['String']>;
  type: OrganizationType | `${OrganizationType}`;
};

export type CreateOrganizationIntegrationInput = {
  accessToken: Scalars['String'];
  provider: Scalars['String'];
  providerOrganizationId?: InputMaybe<Scalars['String']>;
  providerOrganizationName?: InputMaybe<Scalars['String']>;
  providerUserEmail?: InputMaybe<Scalars['String']>;
  providerUserId: Scalars['String'];
  refreshToken?: InputMaybe<Scalars['String']>;
  tokenCreatedAt: Scalars['Int'];
  tokenExpiresIn?: InputMaybe<Scalars['Int']>;
  tokenType?: InputMaybe<Scalars['String']>;
};

export type CreateOrganizationIntegrationMutationPayload = {
  __typename?: 'CreateOrganizationIntegrationMutationPayload';
  organizationIntegration: OrganizationIntegration;
};

export type CreateOrganizationLocationInput = {
  address1?: InputMaybe<Scalars['String']>;
  address2?: InputMaybe<Scalars['String']>;
  assigneeId?: InputMaybe<Scalars['ID']>;
  city?: InputMaybe<Scalars['String']>;
  excessSurplusLinesPaidByPartner?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  organizationId: Scalars['ID'];
  salesforceRecordId?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<State | `${State}`>;
  zipcode?: InputMaybe<Scalars['String']>;
};

export type CreateOrganizationUserInput = {
  email: Scalars['EmailAddress'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  npn?: InputMaybe<Scalars['String']>;
  organizationId: Scalars['ID'];
  organizationLocationId?: InputMaybe<Scalars['ID']>;
  role: OrganizationMemberRole | `${OrganizationMemberRole}`;
  salesforceRecordId?: InputMaybe<Scalars['String']>;
};

export type CreatePolicyChangeEndorsementInput = {
  brokerCommissionPercentage: Scalars['Float'];
  effectiveDate: Scalars['Date'];
  employersLiabilityFeePercentage: Scalars['Float'];
  frontingFeePercentage: Scalars['Float'];
  issuedDate: Scalars['Date'];
  name: Scalars['String'];
  premium: Scalars['Float'];
  profitLoadFeePercentage: Scalars['Float'];
  quoteId: Scalars['ID'];
  shepherdMgaFeePercentage: Scalars['Float'];
  totalExpenseLoadPercentage: Scalars['Float'];
  tpaFeePercentage: Scalars['Float'];
  triaFeePercentage: Scalars['Float'];
};

export type CreatePolicyInput = {
  assigneeId?: InputMaybe<Scalars['ID']>;
  brokerOrganizationMemberId?: InputMaybe<Scalars['ID']>;
  brokerageId: Scalars['ID'];
  effectiveDate: Scalars['Date'];
  expirationDate: Scalars['Date'];
  expiringEffectiveDate: Scalars['Date'];
  exposures: Array<ExposureType | `${ExposureType}`>;
  licensedUnderwriterId?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
  namedInsureds?: InputMaybe<Array<Scalars['String']>>;
  policyNumber?: InputMaybe<Scalars['String']>;
  policyholderId: Scalars['ID'];
  product: PolicyProduct | `${PolicyProduct}`;
  quoteSelection: UpdateQuoteSelectionInput;
  submissionAt?: InputMaybe<Scalars['DateTime']>;
};

export type CreatePolicyholderInput = {
  address1?: InputMaybe<Scalars['String']>;
  address2?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  fein?: InputMaybe<Scalars['String']>;
  hasAutodesk?: InputMaybe<Scalars['Boolean']>;
  insuredContactEmail?: InputMaybe<Scalars['EmailAddress']>;
  insuredContactName?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  state: State | `${State}`;
  website?: InputMaybe<Scalars['String']>;
  zipcode?: InputMaybe<Scalars['String']>;
};

export type CreatePrimaryAutoCoverageOptionInput = {
  exposureId: Scalars['ID'];
  isProject: Scalars['Boolean'];
  state: Scalars['String'];
};

export type CreateProcoreUsageInput = {
  adoptionDate?: InputMaybe<Scalars['Date']>;
  annualRevenue?: InputMaybe<Scalars['Float']>;
  coreToolsEngagement?: InputMaybe<Scalars['Float']>;
  csEngagement?: InputMaybe<Scalars['Float']>;
  overallUsage?: InputMaybe<Scalars['Float']>;
  policyholderId: Scalars['ID'];
  projectFinancials?: InputMaybe<Scalars['Float']>;
  projectManagement?: InputMaybe<Scalars['Float']>;
  qualitySafety?: InputMaybe<Scalars['Float']>;
  totalActiveUsers?: InputMaybe<Scalars['Float']>;
};

export type CreateQuoteInput = {
  exposures: Array<ExposureType | `${ExposureType}`>;
  name: Scalars['String'];
  policyId: Scalars['ID'];
  quoteSelection: UpdateQuoteSelectionInput;
};

export type CreateScheduleRatings = {
  appliedModification: Scalars['Float'];
  identifier: Scalars['String'];
  rationale: Scalars['String'];
};

export type CreateScheduleRatingsInput = {
  exposureType: ScheduleRatingTypes | `${ScheduleRatingTypes}`;
  quoteId: Scalars['String'];
  scheduleRatings: Array<CreateScheduleRatings>;
};

export type CreateUnderlyingPolicyInput = {
  attachmentOrderRank: Scalars['Int'];
  displayType: Scalars['String'];
  effectiveDate: Scalars['Date'];
  expirationDate: Scalars['Date'];
  limits: Array<UnderlyingPolicyLimitInput>;
  policyNumber: Scalars['String'];
  quoteId: Scalars['ID'];
  type: UnderlyingPolicyType | `${UnderlyingPolicyType}`;
  underlyingPolicyCarrierId?: InputMaybe<Scalars['String']>;
};

export type DataFillMapInput = {
  path?: InputMaybe<Scalars['String']>;
  tag: Scalars['String'];
};

export type DataFillObject = {
  __typename?: 'DataFillObject';
  path?: Maybe<Scalars['String']>;
  tag: Scalars['String'];
};

export enum DeclinedReason {
  Duration = 'DURATION',
  FabricationOrOperationalExposure = 'FABRICATION_OR_OPERATIONAL_EXPOSURE',
  IncompleteSubmission = 'INCOMPLETE_SUBMISSION',
  LicenseRestriction = 'LICENSE_RESTRICTION',
  LimitsAttachment = 'LIMITS_ATTACHMENT',
  PoorLossExperience = 'POOR_LOSS_EXPERIENCE',
  RollingWrap = 'ROLLING_WRAP',
  SizeOfRiskTooLargeOrTooSmall = 'SIZE_OF_RISK_TOO_LARGE_OR_TOO_SMALL',
  Tier_5NotWorthPursuing = 'TIER_5_NOT_WORTH_PURSUING',
  Timing = 'TIMING',
  UnappointedBroker = 'UNAPPOINTED_BROKER',
  UnsupportedPoliciesCoverage = 'UNSUPPORTED_POLICIES_COVERAGE',
}

export type DeductibleGeneralLiabilityOptionsObject = {
  __typename?: 'DeductibleGeneralLiabilityOptionsObject';
  label: Scalars['String'];
  value: Scalars['String'];
};

export enum DeductibleTypeOptionsEnum {
  Deductible = 'DEDUCTIBLE',
  None = 'NONE',
  Sir = 'SIR',
}

export type DeductibleTypeOptionsObject = {
  __typename?: 'DeductibleTypeOptionsObject';
  label: Scalars['String'];
  value: DeductibleTypeOptionsEnum | `${DeductibleTypeOptionsEnum}`;
};

export type DefaultAutoData = {
  __typename?: 'DefaultAutoData';
  make: Scalars['String'];
  model: Scalars['String'];
  modelYear: Scalars['Int'];
  sizeClass: Scalars['String'];
  statedAmount?: Maybe<Scalars['String']>;
};

export type DefaultAutoDataInput = {
  make: Scalars['String'];
  model: Scalars['String'];
  modelYear: Scalars['String'];
};

export type DefaultAutoDataInputReturnObject = {
  __typename?: 'DefaultAutoDataInputReturnObject';
  make: Scalars['String'];
  model: Scalars['String'];
  modelYear: Scalars['String'];
};

export type DefaultAutoDataLookup = {
  __typename?: 'DefaultAutoDataLookup';
  defaultData: DefaultAutoData;
  input: DefaultAutoDataInputReturnObject;
};

export type DeleteFormPdfInput = {
  formGroupId: Scalars['ID'];
  formPdfId: Scalars['ID'];
};

export type DeleteUnderlyingPolicyInput = {
  id: Scalars['ID'];
};

export enum DemoQuote {
  Sanzari = 'SANZARI',
  Valiant = 'VALIANT',
  Vault = 'VAULT',
}

export type DigitalQuote = {
  __typename?: 'DigitalQuote';
  additionalStates?: Maybe<Scalars['String']>;
  assignee?: Maybe<CmsTeamMember>;
  brokerAddressLine1: Scalars['String'];
  brokerAddressLine2: Scalars['String'];
  brokerCommission: Scalars['String'];
  brokerCommissionNumber: Scalars['Float'];
  brokerCommissionPercentage: Scalars['String'];
  brokerEmail: Scalars['String'];
  brokerFullName: Scalars['String'];
  brokerageName: Scalars['String'];
  casualtyProIntegrations: CasualyProIntegrations;
  coverholderEmailAddress?: Maybe<Scalars['String']>;
  coverholderPhoneNumber?: Maybe<Scalars['String']>;
  effectiveDate: Scalars['String'];
  endeffdt: Scalars['String'];
  endnbr?: Maybe<Scalars['Int']>;
  expirationDate: Scalars['String'];
  finalPremium: Scalars['String'];
  firstAndLastNameOfCoverholder: Scalars['String'];
  fullLimitPremiumLanguage?: Maybe<Scalars['String']>;
  insuredRetentionAmount: Scalars['String'];
  isLead: Scalars['Boolean'];
  isQuotaShare: Scalars['Boolean'];
  mappedUnderlyingPolicies: Scalars['JSON'];
  minimumEarnedPremium?: Maybe<Scalars['String']>;
  namedInsured: Scalars['String'];
  namedInsuredAddressLine1: Scalars['String'];
  namedInsuredAddressLine2: Scalars['String'];
  namedinsured: Scalars['String'];
  paymentTerms: Scalars['String'];
  pdfTitle: Scalars['String'];
  pdfType: PdfType | `${PdfType}`;
  policyEffectiveDate: Scalars['String'];
  policyExpirationDate: Scalars['String'];
  policyLimitEachOccurence: Scalars['String'];
  policyLimitEachOccurenceQuotaShare: Scalars['String'];
  policyLimitGeneralAggregate: Scalars['String'];
  policyLimitGeneralAggregateQuotaShare: Scalars['String'];
  policyLimitProductsCompletedOperationsAggregate?: Maybe<Scalars['String']>;
  policyLimitProductsCompletedOperationsAggregateQuotaShare?: Maybe<Scalars['String']>;
  policyNumber: Scalars['String'];
  policyProduct: PolicyProduct | `${PolicyProduct}`;
  policyType: Scalars['String'];
  polnbr: Scalars['String'];
  projectAddressLine1?: Maybe<Scalars['String']>;
  projectAddressLine2?: Maybe<Scalars['String']>;
  projectName?: Maybe<Scalars['String']>;
  quoteCreatedAt: Scalars['String'];
  quotePdf?: Maybe<QuotePdf>;
  selectedForms: Array<SelectedForm>;
  stateSurplusLinesTax: Scalars['String'];
  subjectivities: Array<Subjectivity>;
  surplusLinesStampingFee: Scalars['String'];
  titleOfCoverholder?: Maybe<Scalars['String']>;
  totalExcessUimPremiumWV?: Maybe<Scalars['String']>;
  totalExcessUmPremiumWV?: Maybe<Scalars['String']>;
  totalExcessUmUimPremiumFL?: Maybe<Scalars['String']>;
  totalExcessUmUimPremiumLA?: Maybe<Scalars['String']>;
  totalExcessUmUimPremiumNH?: Maybe<Scalars['String']>;
  totalExcessUmUimPremiumVT?: Maybe<Scalars['String']>;
  totalPremiumIncludingTriaAndComissions: Scalars['String'];
  tria: Scalars['Boolean'];
  triaPremium: Scalars['String'];
  vehicles: Array<ExcessAutoVehicleV2>;
};

export type DuplicateFormPdfInput = {
  formGroupId: Scalars['ID'];
  formGroupInsertionIndex: Scalars['Int'];
  id: Scalars['ID'];
};

export type E2eTestSetup = {
  __typename?: 'E2eTestSetup';
  jwt: Scalars['String'];
  organization?: Maybe<Organization>;
  user?: Maybe<User>;
};

export type E2eTestSetupPayload = {
  __typename?: 'E2eTestSetupPayload';
  brokerage: E2eTestSetup;
  policies: Array<Policy>;
  policyholder: Policyholder;
  shepherd: E2eTestSetup;
};

export type EmployeeBenefitLiabilityLimitOptionsObject = {
  __typename?: 'EmployeeBenefitLiabilityLimitOptionsObject';
  label: Scalars['String'];
  value: EmployeeBenefitLiabilityLimitValueEnum | `${EmployeeBenefitLiabilityLimitValueEnum}`;
};

export enum EmployeeBenefitLiabilityLimitValueEnum {
  SplitLimit_1M_1M = 'SPLIT_LIMIT_1M_1M',
  SplitLimit_1M_2M = 'SPLIT_LIMIT_1M_2M',
  SplitLimit_500K_1M = 'SPLIT_LIMIT_500K_1M',
  SplitLimit_500K_500K = 'SPLIT_LIMIT_500K_500K',
}

export type EmployeeBenefitLiabilityNumberOfEmployeesOptionsObject = {
  __typename?: 'EmployeeBenefitLiabilityNumberOfEmployeesOptionsObject';
  label: Scalars['String'];
  value:
    | EmployeeBenefitLiabilityNumberOfEmployeesValueEnum
    | `${EmployeeBenefitLiabilityNumberOfEmployeesValueEnum}`;
};

export enum EmployeeBenefitLiabilityNumberOfEmployeesValueEnum {
  Number_0_9 = 'NUMBER_0_9',
  Number_10_19 = 'NUMBER_10_19',
  Number_20_25 = 'NUMBER_20_25',
  Number_26_100 = 'NUMBER_26_100',
  Number_101_500 = 'NUMBER_101_500',
  Number_501_700 = 'NUMBER_501_700',
  Number_701_1000 = 'NUMBER_701_1000',
  NumberOver_1000 = 'NUMBER_OVER_1000',
}

export type Error = {
  message: Scalars['String'];
  stack?: Maybe<Scalars['String']>;
};

export type EventInput = {
  eventName: Scalars['String'];
  payload: Scalars['JSON'];
};

export type EventOutput = {
  __typename?: 'EventOutput';
  eventName: Scalars['String'];
  id: Scalars['ID'];
  payload: Scalars['JSON'];
};

export type ExcessAutoCoverageOptions = {
  __typename?: 'ExcessAutoCoverageOptions';
  biOptionPPT?: Maybe<Scalars['String']>;
  biOptionTTT?: Maybe<Scalars['String']>;
  biOptionTrailer?: Maybe<Scalars['String']>;
  exposureId: Scalars['String'];
  id: Scalars['String'];
  state: Scalars['String'];
  uimbiLimit?: Maybe<Scalars['String']>;
  uimbiOption?: Maybe<Scalars['String']>;
  umbiLimit?: Maybe<Scalars['String']>;
  umbiOption?: Maybe<Scalars['String']>;
  vehicleTypeFlags: CoverageOptionVehicleTypes;
};

export type ExcessAutoCoverageOptionsIdInput = {
  exposureId: Scalars['ID'];
};

export type ExcessAutoCoverageOptionsInput = {
  biOptionPPT?: InputMaybe<Scalars['String']>;
  biOptionTTT?: InputMaybe<Scalars['String']>;
  biOptionTrailer?: InputMaybe<Scalars['String']>;
  state: Scalars['String'];
  uimbiLimit?: InputMaybe<Scalars['String']>;
  uimbiOption?: InputMaybe<Scalars['String']>;
  umbiLimit?: InputMaybe<Scalars['String']>;
  umbiOption?: InputMaybe<Scalars['String']>;
};

export type ExcessAutoExposure = {
  __typename?: 'ExcessAutoExposure';
  attachmentPoint?: Maybe<Scalars['Float']>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  finalSummarySelectedLossRate?: Maybe<Scalars['Decimal']>;
  finalSummarySelectedLossRateType?: Maybe<
    LossRateSelectionTypeEnum | `${LossRateSelectionTypeEnum}`
  >;
  fleetExposure?: Maybe<Array<ExcessAutoVehicle>>;
  id: Scalars['ID'];
  individualNamedInsured?: Maybe<Scalars['Boolean']>;
  isCredibilityCapEnabled: Scalars['Boolean'];
  isExperienceRatingEnabled: Scalars['Boolean'];
  isUmUimIncluded?: Maybe<Scalars['Boolean']>;
  largeLossRuns?: Maybe<Array<LargeLossRun>>;
  lossRuns?: Maybe<Array<AggregateLossRun>>;
  naicsClassCode?: Maybe<Scalars['String']>;
  quoteId: Scalars['ID'];
  scheduleRatings: Array<ScheduleRatingsObject>;
  selectedFrequency?: Maybe<Scalars['Decimal']>;
  selectedFrequencyType?: Maybe<LossRateSelectionTypeEnum | `${LossRateSelectionTypeEnum}`>;
  selectedLossRate?: Maybe<Scalars['Decimal']>;
  selectedLossRateType?: Maybe<LossRateSelectionTypeEnum | `${LossRateSelectionTypeEnum}`>;
  underlyingPolicyLimit?: Maybe<Scalars['Float']>;
  updatedAt: Scalars['DateTime'];
  vehicleFleet?: Maybe<Array<ExcessAutoVehicleAggregate>>;
};

export type ExcessAutoVehicle = {
  __typename?: 'ExcessAutoVehicle';
  biOption: Scalars['String'];
  exposureId: Scalars['ID'];
  modelYear: Scalars['String'];
  ocnStatedAmount: Scalars['String'];
  radius?: Maybe<Scalars['String']>;
  secondaryClassDetailPrivateClassDetail: Scalars['String'];
  secondaryClassPrivateClass: Scalars['String'];
  sizeClass: Scalars['String'];
  state: Scalars['String'];
  statedAmount: Scalars['String'];
  typeOfVehicle: Scalars['String'];
  uimbiLimit?: Maybe<Scalars['String']>;
  uimbiOption?: Maybe<Scalars['String']>;
  umbiLimit?: Maybe<Scalars['String']>;
  umbiOption?: Maybe<Scalars['String']>;
  use: Scalars['String'];
  vin?: Maybe<Scalars['String']>;
  zipCode: Scalars['String'];
};

export type ExcessAutoVehicleAggregate = {
  __typename?: 'ExcessAutoVehicleAggregate';
  count: Scalars['Int'];
  sizeClass: Scalars['String'];
  state: Scalars['String'];
  typeOfVehicle: Scalars['String'];
};

export type ExcessAutoVehicleV2 = {
  __typename?: 'ExcessAutoVehicleV2';
  createdAt: Scalars['Date'];
  exposureId: Scalars['String'];
  id: Scalars['String'];
  make?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  modelYear?: Maybe<Scalars['String']>;
  ocnStatedAmount?: Maybe<Scalars['String']>;
  preciseAmount?: Maybe<Scalars['Float']>;
  radius?: Maybe<Scalars['String']>;
  secondaryClassDetailPrivateClassDetail?: Maybe<Scalars['String']>;
  secondaryClassPrivateClass?: Maybe<Scalars['String']>;
  sizeClass?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  statedAmount?: Maybe<Scalars['String']>;
  typeOfVehicle?: Maybe<Scalars['String']>;
  updatedAt: Scalars['Date'];
  use?: Maybe<Scalars['String']>;
  vin?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
};

export type ExcessAutoVehicleV2IdInput = {
  exposureId: Scalars['ID'];
};

export type ExcessAutoVehicleV2Input = {
  exposureId: Scalars['String'];
  make?: InputMaybe<Scalars['String']>;
  model?: InputMaybe<Scalars['String']>;
  modelYear?: InputMaybe<Scalars['String']>;
  ocnStatedAmount?: InputMaybe<Scalars['String']>;
  preciseAmount?: InputMaybe<Scalars['Float']>;
  radius?: InputMaybe<Scalars['String']>;
  secondaryClassDetailPrivateClassDetail?: InputMaybe<Scalars['String']>;
  secondaryClassPrivateClass?: InputMaybe<Scalars['String']>;
  sizeClass?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  statedAmount?: InputMaybe<Scalars['String']>;
  typeOfVehicle?: InputMaybe<Scalars['String']>;
  use?: InputMaybe<Scalars['String']>;
  vin?: InputMaybe<Scalars['String']>;
  zipCode?: InputMaybe<Scalars['String']>;
};

export type ExcessGeneralLiabilityExpectedPayroll = {
  __typename?: 'ExcessGeneralLiabilityExpectedPayroll';
  exposureBase: Scalars['String'];
  exposureId: Scalars['ID'];
  isoClass: Scalars['String'];
  ncciClass?: Maybe<Scalars['String']>;
  payroll: Scalars['Float'];
  state: Scalars['String'];
  territory: Scalars['String'];
};

export type ExcessGeneralLiabilityExpectedSubcontractedCost = {
  __typename?: 'ExcessGeneralLiabilityExpectedSubcontractedCost';
  exposureId: Scalars['ID'];
  isoClass: Scalars['String'];
  ncciClass?: Maybe<Scalars['String']>;
  payroll: Scalars['Float'];
  state: Scalars['String'];
  territory: Scalars['String'];
};

export type ExcessGeneralLiabilityExposure = {
  __typename?: 'ExcessGeneralLiabilityExposure';
  attachmentPoint?: Maybe<Scalars['Float']>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  expectedPayrolls?: Maybe<Array<ExcessGeneralLiabilityExpectedPayroll>>;
  expectedSubcontractedCosts?: Maybe<Array<ExcessGeneralLiabilityExpectedSubcontractedCost>>;
  finalSummarySelectedLossRate?: Maybe<Scalars['Decimal']>;
  finalSummarySelectedLossRateType?: Maybe<
    LossRateSelectionTypeEnum | `${LossRateSelectionTypeEnum}`
  >;
  id: Scalars['ID'];
  isCredibilityCapEnabled: Scalars['Boolean'];
  isExperienceRatingEnabled: Scalars['Boolean'];
  largeLossRuns?: Maybe<Array<LargeLossRun>>;
  lossRuns?: Maybe<Array<AggregateLossRun>>;
  numberOfProjectsLocations?: Maybe<Scalars['Float']>;
  overallAggregateCap?: Maybe<Scalars['Float']>;
  programVolume?: Maybe<Scalars['Float']>;
  projectsGeneralAggregateOption?: Maybe<Scalars['String']>;
  quoteId: Scalars['ID'];
  residentialExclusion?: Maybe<Scalars['Boolean']>;
  scheduleRatings: Array<ScheduleRatingsObject>;
  selectedFrequency?: Maybe<Scalars['Decimal']>;
  selectedFrequencyType?: Maybe<LossRateSelectionTypeEnum | `${LossRateSelectionTypeEnum}`>;
  selectedLossRate?: Maybe<Scalars['Decimal']>;
  selectedLossRateType?: Maybe<LossRateSelectionTypeEnum | `${LossRateSelectionTypeEnum}`>;
  underlyingPolicyLimit?: Maybe<Scalars['Float']>;
  underlyingPolicyPremium?: Maybe<Scalars['Float']>;
  updatedAt: Scalars['DateTime'];
  wildfireExclusion?: Maybe<Scalars['Boolean']>;
};

export enum ExcessIsoClass {
  Iso_10255 = 'ISO_10255',
  Iso_11201 = 'ISO_11201',
  Iso_11202 = 'ISO_11202',
  Iso_11203 = 'ISO_11203',
  Iso_11204 = 'ISO_11204',
  Iso_11205 = 'ISO_11205',
  Iso_11206 = 'ISO_11206',
  Iso_11207 = 'ISO_11207',
  Iso_11208 = 'ISO_11208',
  Iso_11209 = 'ISO_11209',
  Iso_11210 = 'ISO_11210',
  Iso_11211 = 'ISO_11211',
  Iso_11212 = 'ISO_11212',
  Iso_11213 = 'ISO_11213',
  Iso_11214 = 'ISO_11214',
  Iso_13590 = 'ISO_13590',
  Iso_13590Pco = 'ISO_13590_PCO',
  Iso_13590Prem = 'ISO_13590_PREM',
  Iso_47367 = 'ISO_47367',
  Iso_51241 = 'ISO_51241',
  Iso_51896 = 'ISO_51896',
  Iso_56915 = 'ISO_56915',
  Iso_56916 = 'ISO_56916',
  Iso_58397 = 'ISO_58397',
  Iso_58713 = 'ISO_58713',
  Iso_59914 = 'ISO_59914',
  Iso_90089 = 'ISO_90089',
  Iso_91111 = 'ISO_91111',
  Iso_91111Pco = 'ISO_91111_PCO',
  Iso_91111Prem = 'ISO_91111_PREM',
  Iso_91125 = 'ISO_91125',
  Iso_91127 = 'ISO_91127',
  Iso_91130 = 'ISO_91130',
  Iso_91135 = 'ISO_91135',
  Iso_91150 = 'ISO_91150',
  Iso_91155 = 'ISO_91155',
  Iso_91160 = 'ISO_91160',
  Iso_91190 = 'ISO_91190',
  Iso_91200 = 'ISO_91200',
  Iso_91210 = 'ISO_91210',
  Iso_91235 = 'ISO_91235',
  Iso_91250 = 'ISO_91250',
  Iso_91265 = 'ISO_91265',
  Iso_91266 = 'ISO_91266',
  Iso_91280 = 'ISO_91280',
  Iso_91302 = 'ISO_91302',
  Iso_91324 = 'ISO_91324',
  Iso_91325 = 'ISO_91325',
  Iso_91340 = 'ISO_91340',
  Iso_91341 = 'ISO_91341',
  Iso_91341Pco = 'ISO_91341_PCO',
  Iso_91341Prem = 'ISO_91341_PREM',
  Iso_91342 = 'ISO_91342',
  Iso_91342Pco = 'ISO_91342_PCO',
  Iso_91342Prem = 'ISO_91342_PREM',
  Iso_91343 = 'ISO_91343',
  Iso_91405 = 'ISO_91405',
  Iso_91436 = 'ISO_91436',
  Iso_91481 = 'ISO_91481',
  Iso_91507 = 'ISO_91507',
  Iso_91523 = 'ISO_91523',
  Iso_91551 = 'ISO_91551',
  Iso_91555 = 'ISO_91555',
  Iso_91560 = 'ISO_91560',
  Iso_91560Pco = 'ISO_91560_PCO',
  Iso_91560Prem = 'ISO_91560_PREM',
  Iso_91562 = 'ISO_91562',
  Iso_91577 = 'ISO_91577',
  Iso_91580 = 'ISO_91580',
  Iso_91580Pco = 'ISO_91580_PCO',
  Iso_91580Prem = 'ISO_91580_PREM',
  Iso_91590 = 'ISO_91590',
  Iso_91606 = 'ISO_91606',
  Iso_91618 = 'ISO_91618',
  Iso_91629 = 'ISO_91629',
  Iso_91636 = 'ISO_91636',
  Iso_91641 = 'ISO_91641',
  Iso_91666 = 'ISO_91666',
  Iso_91722 = 'ISO_91722',
  Iso_91746 = 'ISO_91746',
  Iso_91746Pco = 'ISO_91746_PCO',
  Iso_91746Prem = 'ISO_91746_PREM',
  Iso_92053 = 'ISO_92053',
  Iso_92054 = 'ISO_92054',
  Iso_92055 = 'ISO_92055',
  Iso_92101 = 'ISO_92101',
  Iso_92102 = 'ISO_92102',
  Iso_92215 = 'ISO_92215',
  Iso_92215Pco = 'ISO_92215_PCO',
  Iso_92215Prem = 'ISO_92215_PREM',
  Iso_92338 = 'ISO_92338',
  Iso_92338Pco = 'ISO_92338_PCO',
  Iso_92338Prem = 'ISO_92338_PREM',
  Iso_92445 = 'ISO_92445',
  Iso_92446 = 'ISO_92446',
  Iso_92446Pco = 'ISO_92446_PCO',
  Iso_92446Prem = 'ISO_92446_PREM',
  Iso_92447 = 'ISO_92447',
  Iso_92451 = 'ISO_92451',
  Iso_92451Pco = 'ISO_92451_PCO',
  Iso_92451Prem = 'ISO_92451_PREM',
  Iso_92453 = 'ISO_92453',
  Iso_92478 = 'ISO_92478',
  Iso_92478Pco = 'ISO_92478_PCO',
  Iso_92478Prem = 'ISO_92478_PREM',
  Iso_92593 = 'ISO_92593',
  Iso_92663 = 'ISO_92663',
  Iso_94007 = 'ISO_94007',
  Iso_94007Pco = 'ISO_94007_PCO',
  Iso_94007Prem = 'ISO_94007_PREM',
  Iso_94225 = 'ISO_94225',
  Iso_94276 = 'ISO_94276',
  Iso_94304 = 'ISO_94304',
  Iso_94381 = 'ISO_94381',
  Iso_94381Pco = 'ISO_94381_PCO',
  Iso_94381Prem = 'ISO_94381_PREM',
  Iso_94404 = 'ISO_94404',
  Iso_94444 = 'ISO_94444',
  Iso_94569 = 'ISO_94569',
  Iso_94590 = 'ISO_94590',
  Iso_94617 = 'ISO_94617',
  Iso_94638 = 'ISO_94638',
  Iso_95124 = 'ISO_95124',
  Iso_95233 = 'ISO_95233',
  Iso_95305 = 'ISO_95305',
  Iso_95306 = 'ISO_95306',
  Iso_95310 = 'ISO_95310',
  Iso_95357 = 'ISO_95357',
  Iso_95358 = 'ISO_95358',
  Iso_95410 = 'ISO_95410',
  Iso_95455 = 'ISO_95455',
  Iso_95487 = 'ISO_95487',
  Iso_95505 = 'ISO_95505',
  Iso_95625 = 'ISO_95625',
  Iso_95630 = 'ISO_95630',
  Iso_95647 = 'ISO_95647',
  Iso_95648 = 'ISO_95648',
  Iso_96053 = 'ISO_96053',
  Iso_96317 = 'ISO_96317',
  Iso_96408 = 'ISO_96408',
  Iso_96409 = 'ISO_96409',
  Iso_96410 = 'ISO_96410',
  Iso_96611 = 'ISO_96611',
  Iso_96702 = 'ISO_96702',
  Iso_96703 = 'ISO_96703',
  Iso_96816 = 'ISO_96816',
  Iso_96872 = 'ISO_96872',
  Iso_96930 = 'ISO_96930',
  Iso_97047 = 'ISO_97047',
  Iso_97050 = 'ISO_97050',
  Iso_97111 = 'ISO_97111',
  Iso_97220 = 'ISO_97220',
  Iso_97221 = 'ISO_97221',
  Iso_97222 = 'ISO_97222',
  Iso_97223 = 'ISO_97223',
  Iso_97223Pco = 'ISO_97223_PCO',
  Iso_97223Prem = 'ISO_97223_PREM',
  Iso_97447 = 'ISO_97447',
  Iso_97447Pco = 'ISO_97447_PCO',
  Iso_97447Prem = 'ISO_97447_PREM',
  Iso_97650 = 'ISO_97650',
  Iso_97651 = 'ISO_97651',
  Iso_97651Pco = 'ISO_97651_PCO',
  Iso_97651Prem = 'ISO_97651_PREM',
  Iso_97652 = 'ISO_97652',
  Iso_97653 = 'ISO_97653',
  Iso_97654 = 'ISO_97654',
  Iso_97655 = 'ISO_97655',
  Iso_98002 = 'ISO_98002',
  Iso_98003 = 'ISO_98003',
  Iso_98111 = 'ISO_98111',
  Iso_98150 = 'ISO_98150',
  Iso_98151 = 'ISO_98151',
  Iso_98152 = 'ISO_98152',
  Iso_98153 = 'ISO_98153',
  Iso_98154 = 'ISO_98154',
  Iso_98155 = 'ISO_98155',
  Iso_98156 = 'ISO_98156',
  Iso_98157 = 'ISO_98157',
  Iso_98158 = 'ISO_98158',
  Iso_98159 = 'ISO_98159',
  Iso_98160 = 'ISO_98160',
  Iso_98161 = 'ISO_98161',
  Iso_98162 = 'ISO_98162',
  Iso_98163 = 'ISO_98163',
  Iso_98164 = 'ISO_98164',
  Iso_98303 = 'ISO_98303',
  Iso_98304 = 'ISO_98304',
  Iso_98305 = 'ISO_98305',
  Iso_98305Pco = 'ISO_98305_PCO',
  Iso_98305Prem = 'ISO_98305_PREM',
  Iso_98306 = 'ISO_98306',
  Iso_98307 = 'ISO_98307',
  Iso_98308 = 'ISO_98308',
  Iso_98309 = 'ISO_98309',
  Iso_98344 = 'ISO_98344',
  Iso_98413 = 'ISO_98413',
  Iso_98414 = 'ISO_98414',
  Iso_98414Pco = 'ISO_98414_PCO',
  Iso_98414Prem = 'ISO_98414_PREM',
  Iso_98415 = 'ISO_98415',
  Iso_98423 = 'ISO_98423',
  Iso_98424 = 'ISO_98424',
  Iso_98425 = 'ISO_98425',
  Iso_98426 = 'ISO_98426',
  Iso_98427 = 'ISO_98427',
  Iso_98428 = 'ISO_98428',
  Iso_98429 = 'ISO_98429',
  Iso_98430 = 'ISO_98430',
  Iso_98449 = 'ISO_98449',
  Iso_98482 = 'ISO_98482',
  Iso_98482Pco = 'ISO_98482_PCO',
  Iso_98482Prem = 'ISO_98482_PREM',
  Iso_98483 = 'ISO_98483',
  Iso_98502 = 'ISO_98502',
  Iso_98555 = 'ISO_98555',
  Iso_98601 = 'ISO_98601',
  Iso_98601Pco = 'ISO_98601_PCO',
  Iso_98601Prem = 'ISO_98601_PREM',
  Iso_98622 = 'ISO_98622',
  Iso_98623 = 'ISO_98623',
  Iso_98624 = 'ISO_98624',
  Iso_98636 = 'ISO_98636',
  Iso_98640 = 'ISO_98640',
  Iso_98658 = 'ISO_98658',
  Iso_98659 = 'ISO_98659',
  Iso_98677 = 'ISO_98677',
  Iso_98677Pco = 'ISO_98677_PCO',
  Iso_98677Prem = 'ISO_98677_PREM',
  Iso_98678 = 'ISO_98678',
  Iso_98698 = 'ISO_98698',
  Iso_98699 = 'ISO_98699',
  Iso_98705 = 'ISO_98705',
  Iso_98710 = 'ISO_98710',
  Iso_98751 = 'ISO_98751',
  Iso_98806 = 'ISO_98806',
  Iso_98810 = 'ISO_98810',
  Iso_98813 = 'ISO_98813',
  Iso_98813Pco = 'ISO_98813_PCO',
  Iso_98813Prem = 'ISO_98813_PREM',
  Iso_98820 = 'ISO_98820',
  Iso_98820Pco = 'ISO_98820_PCO',
  Iso_98820Prem = 'ISO_98820_PREM',
  Iso_98871 = 'ISO_98871',
  Iso_98884 = 'ISO_98884',
  Iso_98914 = 'ISO_98914',
  Iso_98949 = 'ISO_98949',
  Iso_98967 = 'ISO_98967',
  Iso_98993 = 'ISO_98993',
  Iso_99003 = 'ISO_99003',
  Iso_99004 = 'ISO_99004',
  Iso_99080 = 'ISO_99080',
  Iso_99085 = 'ISO_99085',
  Iso_99163 = 'ISO_99163',
  Iso_99165 = 'ISO_99165',
  Iso_99220 = 'ISO_99220',
  Iso_99221 = 'ISO_99221',
  Iso_99222 = 'ISO_99222',
  Iso_99223 = 'ISO_99223',
  Iso_99303 = 'ISO_99303',
  Iso_99310 = 'ISO_99310',
  Iso_99315 = 'ISO_99315',
  Iso_99315Pco = 'ISO_99315_PCO',
  Iso_99315Prem = 'ISO_99315_PREM',
  Iso_99321 = 'ISO_99321',
  Iso_99471 = 'ISO_99471',
  Iso_99505 = 'ISO_99505',
  Iso_99506 = 'ISO_99506',
  Iso_99507 = 'ISO_99507',
  Iso_99570 = 'ISO_99570',
  Iso_99571 = 'ISO_99571',
  Iso_99572 = 'ISO_99572',
  Iso_99573 = 'ISO_99573',
  Iso_99600 = 'ISO_99600',
  Iso_99613 = 'ISO_99613',
  Iso_99613Pco = 'ISO_99613_PCO',
  Iso_99613Prem = 'ISO_99613_PREM',
  Iso_99650 = 'ISO_99650',
  Iso_99709 = 'ISO_99709',
  Iso_99746 = 'ISO_99746',
  Iso_99777 = 'ISO_99777',
  Iso_99793 = 'ISO_99793',
  Iso_99798 = 'ISO_99798',
  Iso_99803 = 'ISO_99803',
  Iso_99826 = 'ISO_99826',
  Iso_99827 = 'ISO_99827',
  Iso_99917 = 'ISO_99917',
  Iso_99938 = 'ISO_99938',
  Iso_99946 = 'ISO_99946',
  Iso_99948 = 'ISO_99948',
  Iso_99952 = 'ISO_99952',
  Iso_99953 = 'ISO_99953',
  Iso_99954 = 'ISO_99954',
  Iso_99955 = 'ISO_99955',
  Iso_99963 = 'ISO_99963',
  Iso_99969 = 'ISO_99969',
  Iso_99975 = 'ISO_99975',
  Iso_99986 = 'ISO_99986',
  Iso_99987 = 'ISO_99987',
  Iso_99988 = 'ISO_99988',
}

export type ExcessPracticePricingObject = {
  __typename?: 'ExcessPracticePricingObject';
  autoLiabilityLossDevelopmentCalcsDetail?: Maybe<Pricing>;
  finalPremium?: Maybe<Scalars['MoneyFloat']>;
  generalLiabilityLossDevelopmentCalcsDetail?: Maybe<Pricing>;
  isLive: Scalars['Boolean'];
  portfolioClass?: Maybe<Scalars['String']>;
  premiumBeforeUnderwriterAdjustment?: Maybe<Scalars['Float']>;
  premiumClass?: Maybe<PremiumClass | `${PremiumClass}`>;
  pricingDebug: Scalars['JSON'];
  pricingDisplay: Pricing;
  shouldUseAutoLiabilityLossDevelopment: Scalars['Boolean'];
  shouldUseGeneralLiabilityLossDevelopment: Scalars['Boolean'];
};

export type ExcessProjectPricingObject = {
  __typename?: 'ExcessProjectPricingObject';
  isLive: Scalars['Boolean'];
  portfolioClass?: Maybe<Scalars['String']>;
  premiumClass?: Maybe<PremiumClass | `${PremiumClass}`>;
  pricingDebug: Scalars['JSON'];
  pricingDisplay: Pricing;
};

export type ExcessQuoteRiskTierDetailsAutoObject = {
  __typename?: 'ExcessQuoteRiskTierDetailsAutoObject';
  isLive: Scalars['Boolean'];
  pricingDisplay: Pricing;
  riskTier: RiskTierAutoModelOutput;
};

export type ExcessQuoteRiskTierDetailsGeneralLiabilityObject = {
  __typename?: 'ExcessQuoteRiskTierDetailsGeneralLiabilityObject';
  isLive: Scalars['Boolean'];
  pricingDisplay: Pricing;
  riskTier: RiskTierGlModelOutput;
};

export type ExcessQuoteRiskTierDetailsObject = {
  __typename?: 'ExcessQuoteRiskTierDetailsObject';
  auto?: Maybe<ExcessQuoteRiskTierDetailsAutoObject>;
  generalLiability?: Maybe<ExcessQuoteRiskTierDetailsGeneralLiabilityObject>;
};

export type ExcessQuoteRiskTierObject = {
  __typename?: 'ExcessQuoteRiskTierObject';
  product: PolicyProduct | `${PolicyProduct}`;
  riskTiers: ExcessQuoteRiskTierDetailsObject;
};

export type Exclusions = {
  __typename?: 'Exclusions';
  residential: Scalars['Boolean'];
  wildfire: Scalars['Boolean'];
};

export type ExclusionsInput = {
  residential: Scalars['Boolean'];
  wildfire: Scalars['Boolean'];
};

export type ExpectedPayrollRiskTier = {
  __typename?: 'ExpectedPayrollRiskTier';
  exposureBase: Scalars['String'];
  isoClass: Scalars['String'];
  payroll: Scalars['Int'];
  state: Scalars['String'];
  territory: Scalars['String'];
};

export type ExpectedPayrollRiskTierInput = {
  exposureBase: Scalars['String'];
  isoClass: ExcessIsoClass | `${ExcessIsoClass}`;
  payroll: Scalars['Int'];
  state: State | `${State}`;
  territory: Scalars['String'];
};

export type ExpectedPayrollTableConstant = {
  __typename?: 'ExpectedPayrollTableConstant';
  isoClasses: Array<IsoClassConstant>;
  ncciCodes: Array<NcciCodeConstant>;
  states: Array<StateConstant>;
  territories: Array<TerritoryConstant>;
};

export enum ExpectedPayrollVariant {
  ExcessPractice = 'EXCESS_PRACTICE',
  ExcessProject = 'EXCESS_PROJECT',
  PrimaryPractice = 'PRIMARY_PRACTICE',
  PrimaryProject = 'PRIMARY_PROJECT',
}

export type ExpectedSubcontractedCostConstant = {
  __typename?: 'ExpectedSubcontractedCostConstant';
  isoClasses: Array<SubcontractedIsoClassConstant>;
  states: Array<StateConstant>;
  territories: Array<TerritoryConstant>;
};

export type ExperienceRatingChangedActivityDataObject = {
  __typename?: 'ExperienceRatingChangedActivityDataObject';
  exposureType: ExposureType | `${ExposureType}`;
  previousValue: Scalars['Boolean'];
  reason?: Maybe<Scalars['String']>;
  updatedValue: Scalars['Boolean'];
};

export type ExposureIdInput = {
  exposureId: Scalars['ID'];
  isProject: Scalars['Boolean'];
};

export enum ExposureStep {
  AutoFleetDetails = 'AUTO_FLEET_DETAILS',
  ManualRating = 'MANUAL_RATING',
  ProjectDetails = 'PROJECT_DETAILS',
  RiskTier = 'RISK_TIER',
}

export enum ExposureType {
  Auto = 'AUTO',
  GeneralLiability = 'GENERAL_LIABILITY',
  OwnersAndContractorsProtective = 'OWNERS_AND_CONTRACTORS_PROTECTIVE',
  Railroad = 'RAILROAD',
  WorkersCompensation = 'WORKERS_COMPENSATION',
}

export type File = {
  __typename?: 'File';
  createdAt: Scalars['DateTime'];
  fileName: Scalars['String'];
  fileType: FileFileType | `${FileFileType}`;
  id: Scalars['ID'];
  lastModifiedAt: Scalars['DateTime'];
  name: Scalars['String'];
  policyId?: Maybe<Scalars['ID']>;
  quoteId?: Maybe<Scalars['ID']>;
  quotePdf?: Maybe<QuotePdf>;
  source: FileSource | `${FileSource}`;
  sourceUrl?: Maybe<Scalars['String']>;
  sourceUuid: Scalars['String'];
  type: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  user?: Maybe<User>;
  userId?: Maybe<Scalars['ID']>;
};

export enum FileFileType {
  Csv = 'CSV',
  Doc = 'DOC',
  Docx = 'DOCX',
  Jpeg = 'JPEG',
  Jpg = 'JPG',
  Pdf = 'PDF',
  Png = 'PNG',
  Rtf = 'RTF',
  Xls = 'XLS',
  Xlsm = 'XLSM',
  Xlsx = 'XLSX',
  Zip = 'ZIP',
}

export type FileMutationPayload = {
  __typename?: 'FileMutationPayload';
  file: File;
};

export enum FileObjectType {
  AccountCorrespondance = 'ACCOUNT_CORRESPONDANCE',
  AutoPacket = 'AUTO_PACKET',
  Binder = 'BINDER',
  Certificate = 'CERTIFICATE',
  Form = 'FORM',
  Info = 'INFO',
  LicensingAndClearance = 'LICENSING_AND_CLEARANCE',
  ManagementClaims = 'MANAGEMENT_CLAIMS',
  Miscellaneous = 'MISCELLANEOUS',
  Policy = 'POLICY',
  PolicyGenerated = 'POLICY_GENERATED',
  PolicySpecimen = 'POLICY_SPECIMEN',
  Pricing = 'PRICING',
  Quote = 'QUOTE',
  Referral = 'REFERRAL',
  SelfAuditChecklist = 'SELF_AUDIT_CHECKLIST',
  SignedTriaDisclosure = 'SIGNED_TRIA_DISCLOSURE',
  SignedUmUimForm = 'SIGNED_UM_UIM_FORM',
  Submission = 'SUBMISSION',
  SubmissionCorrespondance = 'SUBMISSION_CORRESPONDANCE',
  TechReports = 'TECH_REPORTS',
  TechUsage = 'TECH_USAGE',
  UnderlyingPolicy = 'UNDERLYING_POLICY',
  UnderlyingQuote = 'UNDERLYING_QUOTE',
  UnderwriterRiskAssessment = 'UNDERWRITER_RISK_ASSESSMENT',
  UnderwritingCorrespondance = 'UNDERWRITING_CORRESPONDANCE',
}

export enum FileSource {
  Filestack = 'FILESTACK',
  S3 = 'S3',
}

export enum FileType {
  AccountCorrespondance = 'ACCOUNT_CORRESPONDANCE',
  AutoPacket = 'AUTO_PACKET',
  Binder = 'BINDER',
  Certificate = 'CERTIFICATE',
  Form = 'FORM',
  Info = 'INFO',
  LicensingAndClearance = 'LICENSING_AND_CLEARANCE',
  ManagementClaims = 'MANAGEMENT_CLAIMS',
  Miscellaneous = 'MISCELLANEOUS',
  Policy = 'POLICY',
  PolicyGenerated = 'POLICY_GENERATED',
  PolicySpecimen = 'POLICY_SPECIMEN',
  Pricing = 'PRICING',
  Quote = 'QUOTE',
  Referral = 'REFERRAL',
  SelfAuditChecklist = 'SELF_AUDIT_CHECKLIST',
  SignedTriaDisclosure = 'SIGNED_TRIA_DISCLOSURE',
  SignedUmUimForm = 'SIGNED_UM_UIM_FORM',
  Submission = 'SUBMISSION',
  SubmissionCorrespondance = 'SUBMISSION_CORRESPONDANCE',
  TechReports = 'TECH_REPORTS',
  TechUsage = 'TECH_USAGE',
  UnderlyingPolicy = 'UNDERLYING_POLICY',
  UnderlyingQuote = 'UNDERLYING_QUOTE',
  UnderwriterRiskAssessment = 'UNDERWRITER_RISK_ASSESSMENT',
  UnderwritingCorrespondance = 'UNDERWRITING_CORRESPONDANCE',
}

export enum FileTypeObject {
  Csv = 'CSV',
  Doc = 'DOC',
  Docx = 'DOCX',
  Jpeg = 'JPEG',
  Jpg = 'JPG',
  Pdf = 'PDF',
  Png = 'PNG',
  Rtf = 'RTF',
  Xls = 'XLS',
  Xlsm = 'XLSM',
  Xlsx = 'XLSX',
  Zip = 'ZIP',
}

export type FormGroupObject = {
  __typename?: 'FormGroupObject';
  createdAt: Scalars['DateTime'];
  formVendor?: Maybe<FormVendor | `${FormVendor}`>;
  forms?: Maybe<Array<PartialFormPdfObject>>;
  id: Scalars['ID'];
  name: Scalars['String'];
  ordering: Array<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type FormPdfObject = {
  __typename?: 'FormPdfObject';
  active: Scalars['Boolean'];
  category?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  dataFillMap?: Maybe<Array<DataFillObject>>;
  defaultStatus?: Maybe<FormStatus | `${FormStatus}`>;
  fileType?: Maybe<FileTypeObject | `${FileTypeObject}`>;
  formGroup?: Maybe<PartialFormGroupObject>;
  formGroupId?: Maybe<Scalars['String']>;
  formNumber: Scalars['String'];
  formType: Scalars['String'];
  hasEndorsementNumber: Scalars['Boolean'];
  id: Scalars['ID'];
  lineOfBusiness?: Maybe<LineOfBusiness | `${LineOfBusiness}`>;
  notes?: Maybe<Scalars['String']>;
  stateForms?: Maybe<Array<StateForm>>;
  tria: TriaOption | `${TriaOption}`;
  updatedAt: Scalars['DateTime'];
  uploaded: Scalars['Boolean'];
};

export type FormPdfsInput = {
  includeAttachedToGroup: Scalars['Boolean'];
};

export enum FormStatus {
  Approved = 'APPROVED',
  Mandatory = 'MANDATORY',
  Rejected = 'REJECTED',
}

export enum FormVendor {
  AonShort = 'AON_SHORT',
  MarshXsellence = 'MARSH_XSELLENCE',
  Shepherd = 'SHEPHERD',
}

export type GeneralLiabilityBuildoutScheduleInput = {
  exposureId?: InputMaybe<Scalars['ID']>;
  percentage: Scalars['Float'];
  year: Scalars['String'];
};

export enum GeneralLiabilityDefenseExpenseTreatmentsEnum {
  AlaeDoesNotErodeDeductible = 'ALAE_DOES_NOT_ERODE_DEDUCTIBLE',
  AlaeErodesDeductible = 'ALAE_ERODES_DEDUCTIBLE',
  AlaeNotCoveredSirOnly = 'ALAE_NOT_COVERED_SIR_ONLY',
  AlaeProRataDoesNotErodeDeductible = 'ALAE_PRO_RATA_DOES_NOT_ERODE_DEDUCTIBLE',
}

export type GeneralLiabilityDefenseExpenseTreatmentsObject = {
  __typename?: 'GeneralLiabilityDefenseExpenseTreatmentsObject';
  deductible: Scalars['Boolean'];
  label: Scalars['String'];
  sir: Scalars['Boolean'];
  value:
    | GeneralLiabilityDefenseExpenseTreatmentsEnum
    | `${GeneralLiabilityDefenseExpenseTreatmentsEnum}`;
};

export type GeneralLiabilityIndividualRiskRatingSupportingInfoObject = {
  __typename?: 'GeneralLiabilityIndividualRiskRatingSupportingInfoObject';
  basicLimitPremiumTotalLiability: Scalars['String'];
  basicLimitPremiumTotalLiabilityDefaultValue?: Maybe<Scalars['Boolean']>;
  coverageLimits: Scalars['String'];
  increasedLimitsPremium: Scalars['String'];
  premiumPco: Scalars['String'];
};

export type GeneralLiabilityLossDevelopmentModelSelectedObject = {
  __typename?: 'GeneralLiabilityLossDevelopmentModelSelectedObject';
  finalSummarySelectedLossRate?: Maybe<Scalars['Decimal']>;
  finalSummarySelectedLossRateOptions?: Maybe<Array<LossRateLabelValue>>;
  finalSummarySelectedLossRateType?: Maybe<
    LossRateSelectionTypeEnum | `${LossRateSelectionTypeEnum}`
  >;
  selectedFrequency?: Maybe<Scalars['Decimal']>;
  selectedFrequencyOptions?: Maybe<Array<LossRateLabelValue>>;
  selectedFrequencyType?: Maybe<LossRateSelectionTypeEnum | `${LossRateSelectionTypeEnum}`>;
  selectedLossRate?: Maybe<Scalars['Decimal']>;
  selectedLossRateOptions?: Maybe<Array<LossRateLabelValue>>;
  selectedLossRateType?: Maybe<LossRateSelectionTypeEnum | `${LossRateSelectionTypeEnum}`>;
};

export type GoogleDriveUrl = {
  __typename?: 'GoogleDriveUrl';
  googleDriveUrl: Scalars['String'];
};

export type HasNpnsObject = {
  __typename?: 'HasNpnsObject';
  brokerage: Scalars['String'];
  brokerageId: Scalars['String'];
  hasBrokerageNpn: Scalars['Boolean'];
  hasProducerNpn: Scalars['Boolean'];
  hasUnderwriterNpn: Scalars['Boolean'];
  producer: Scalars['String'];
  producerId?: Maybe<Scalars['String']>;
  shepherdLine: ShepherdLine | `${ShepherdLine}`;
  underwriter: Scalars['String'];
};

export enum HiredAutosCoverageEnum {
  CostOfHireBasis = 'COST_OF_HIRE_BASIS',
  None = 'NONE',
  VehicleBasis = 'VEHICLE_BASIS',
}

export type HiredAutosCoverageObject = {
  __typename?: 'HiredAutosCoverageObject';
  label: Scalars['String'];
  value: HiredAutosCoverageEnum | `${HiredAutosCoverageEnum}`;
};

export enum HiredOwnedEnum {
  Hired = 'HIRED',
  Owned = 'OWNED',
}

export type HiredOwnedObject = {
  __typename?: 'HiredOwnedObject';
  label: Scalars['String'];
  value: HiredOwnedEnum | `${HiredOwnedEnum}`;
};

export type IdInput = {
  id: Scalars['ID'];
};

export enum IndicatedLostReason {
  AdditionalLimits = 'ADDITIONAL_LIMITS',
  Attachment = 'ATTACHMENT',
  Broker = 'BROKER',
  Capacity = 'CAPACITY',
  FinancialRatingPaper = 'FINANCIAL_RATING_PAPER',
  Nonadmitted = 'NONADMITTED',
  Other = 'OTHER',
  Price = 'PRICE',
  Renewed = 'RENEWED',
  RequestedStructureOutOfAppetite = 'REQUESTED_STRUCTURE_OUT_OF_APPETITE',
  Terms = 'TERMS',
}

export enum InsightComparison {
  AboveAverage = 'ABOVE_AVERAGE',
  ApproximatelyAverage = 'APPROXIMATELY_AVERAGE',
  Average = 'AVERAGE',
  BelowAverage = 'BELOW_AVERAGE',
}

export type InsightsLinkObject = {
  __typename?: 'InsightsLinkObject';
  status: InsightsLinkResponse | `${InsightsLinkResponse}`;
  url?: Maybe<Scalars['String']>;
};

export enum InsightsLinkResponse {
  Allowed = 'ALLOWED',
  ComingSoon = 'COMING_SOON',
  DenyList = 'DENY_LIST',
  None = 'NONE',
}

export type InsightsReportFleetCohort = {
  __typename?: 'InsightsReportFleetCohort';
  averageLossRate: Scalars['Float'];
  averageLossRateComparison: InsightComparison | `${InsightComparison}`;
  averageLossRateSentiment: ComparisonSentiment | `${ComparisonSentiment}`;
  averageVehicleCount: Scalars['Float'];
  averageVehicleCountComparison: InsightComparison | `${InsightComparison}`;
  averageVehicleCountSentiment: ComparisonSentiment | `${ComparisonSentiment}`;
  lossFrequency: Scalars['Float'];
  lossFrequencyAverageComparison: InsightComparison | `${InsightComparison}`;
  lossFrequencyAverageSentiment: ComparisonSentiment | `${ComparisonSentiment}`;
  lossSeverity: Scalars['Float'];
  lossSeverityAverageComparison: InsightComparison | `${InsightComparison}`;
  lossSeverityAverageSentiment: ComparisonSentiment | `${ComparisonSentiment}`;
  losses: Array<InsightsReportFleetCohortLoss>;
};

export type InsightsReportFleetCohortLoss = {
  __typename?: 'InsightsReportFleetCohortLoss';
  averageFleetSizeCohort: Scalars['Float'];
  averageFleetSizeContractor: Scalars['Float'];
  frequencyCohort: Scalars['Float'];
  frequencyContractor: Scalars['Float'];
  severityCohort: Scalars['Float'];
  severityContractor: Scalars['Float'];
  year: Scalars['Int'];
};

export type InsightsReportFleetSection = {
  __typename?: 'InsightsReportFleetSection';
  fleetDetails: Array<FleetDetails>;
  fleetSize: Scalars['Int'];
  heavyTruckPercentage: Scalars['Float'];
  mainVehicleClass: Scalars['String'];
  mainVehicleState: Scalars['String'];
  premiumDrivers: Array<AutoPremiumDrivers>;
};

export type InsightsReportObject = {
  __typename?: 'InsightsReportObject';
  autoLossData: InsightsReportFleetCohort;
  constructionTechData: ConstructionTechCohort;
  fleetData: InsightsReportFleetSection;
  generalLiabilityLossData: InsightsReportPayrollCohort;
  payrollData: InsightsReportPayrollSection;
  peerCohortData: PeerCohortData;
  submissionDetails: SubmissionDetails;
};

export type InsightsReportPayrollCohort = {
  __typename?: 'InsightsReportPayrollCohort';
  averageLossRate: Scalars['Float'];
  averageLossRateComparison: InsightComparison | `${InsightComparison}`;
  averageLossRateSentiment: ComparisonSentiment | `${ComparisonSentiment}`;
  averageSalesExposure: Scalars['Float'];
  averageSalesExposureComparison: InsightComparison | `${InsightComparison}`;
  averageSalesExposureSentiment: ComparisonSentiment | `${ComparisonSentiment}`;
  lossFrequency: Scalars['Float'];
  lossFrequencyAverageComparison: InsightComparison | `${InsightComparison}`;
  lossFrequencyAverageSentiment: ComparisonSentiment | `${ComparisonSentiment}`;
  lossSeverity: Scalars['Float'];
  lossSeverityAverageComparison: InsightComparison | `${InsightComparison}`;
  lossSeverityAverageSentiment: ComparisonSentiment | `${ComparisonSentiment}`;
  losses: Array<InsightsReportPayrollCohortLoss>;
};

export type InsightsReportPayrollCohortLoss = {
  __typename?: 'InsightsReportPayrollCohortLoss';
  averageAnnualSalesCohort: Scalars['Float'];
  averageAnnualSalesContractor: Scalars['Float'];
  frequencyCohort: Scalars['Float'];
  frequencyContractor: Scalars['Float'];
  severityCohort: Scalars['Float'];
  severityContractor: Scalars['Float'];
  year: Scalars['Int'];
};

export type InsightsReportPayrollSection = {
  __typename?: 'InsightsReportPayrollSection';
  mainPayrollDriver: Scalars['String'];
  mainPayrollPercentage: Scalars['Float'];
  mainPayrollState: Scalars['String'];
  payrollBreakdown: Array<PayrollBreakdown>;
  premiumDrivers: Array<PremiumDrivers>;
};

export type InsuranceProduct = {
  __typename?: 'InsuranceProduct';
  exposures: Array<InsuranceProductExposure>;
  label: Scalars['String'];
  type: PolicyProduct | `${PolicyProduct}`;
};

export type InsuranceProductExposure = {
  __typename?: 'InsuranceProductExposure';
  isRequired: Scalars['Boolean'];
  label: Scalars['String'];
  type: ExposureType | `${ExposureType}`;
};

export enum IntegrationLocations {
  Client = 'CLIENT',
  Companyinformationaddress = 'COMPANYINFORMATIONADDRESS',
  Organization = 'ORGANIZATION',
  Projects = 'PROJECTS',
  Users = 'USERS',
}

export enum Integrations {
  Autodesk = 'AUTODESK',
  Db = 'DB',
  Plangrid = 'PLANGRID',
  Procore = 'PROCORE',
}

export type Invoice = {
  __typename?: 'Invoice';
  createdAt: Scalars['DateTime'];
  excessSurplusLinesPaidByPartner: Scalars['Boolean'];
  id: Scalars['ID'];
  invoiceNumber: Scalars['String'];
  quote: Quote;
  quoteId: Scalars['ID'];
  sourceId: Scalars['ID'];
  status: InvoiceStatus | `${InvoiceStatus}`;
  updatedAt: Scalars['DateTime'];
};

export enum InvoiceStatus {
  Draft = 'DRAFT',
  Overdue = 'OVERDUE',
  Paid = 'PAID',
  Sent = 'SENT',
}

export type IsoClassConstant = {
  __typename?: 'IsoClassConstant';
  description: Scalars['String'];
  exposureBase: Scalars['String'];
  isoCglCode: Scalars['String'];
  label: Scalars['String'];
  value: Scalars['String'];
};

export enum JurisdictionRiskType {
  High = 'HIGH',
  Low = 'LOW',
}

export type KeyValueObject = {
  __typename?: 'KeyValueObject';
  key: Scalars['String'];
  value: Scalars['String'];
};

export type LabelValueObject = {
  __typename?: 'LabelValueObject';
  label: Scalars['String'];
  value: Scalars['String'];
};

export type LargeLossRun = {
  __typename?: 'LargeLossRun';
  accidentDate: Scalars['Date'];
  alaeOutstanding: Scalars['Float'];
  effectiveDate: Scalars['Date'];
  expirationDate: Scalars['Date'];
  indemnityOutstanding: Scalars['Float'];
  paidAlae: Scalars['Float'];
  paidIndemnity: Scalars['Float'];
};

export type LargeLossRunCalculatedFields = {
  __typename?: 'LargeLossRunCalculatedFields';
  accidentDate: Scalars['Date'];
  alaeOutstanding: Scalars['Float'];
  effectiveDate: Scalars['Date'];
  expirationDate: Scalars['Date'];
  includable?: Maybe<Scalars['Float']>;
  indemnityOutstanding: Scalars['Float'];
  paidAlae: Scalars['Float'];
  paidIndemnity: Scalars['Float'];
  totalIncurred: Scalars['Float'];
  totalPaid: Scalars['Float'];
};

export type LargeLossRunInput = {
  accidentDate: Scalars['Date'];
  alaeOutstanding: Scalars['Float'];
  effectiveDate: Scalars['Date'];
  expirationDate: Scalars['Date'];
  indemnityOutstanding: Scalars['Float'];
  paidAlae: Scalars['Float'];
  paidIndemnity: Scalars['Float'];
};

export type LargeLossRunObject = {
  __typename?: 'LargeLossRunObject';
  accidentDate: Scalars['Date'];
  alaeOutstanding: Scalars['Float'];
  effectiveDate: Scalars['Date'];
  expirationDate: Scalars['Date'];
  id?: Maybe<Scalars['ID']>;
  indemnityOutstanding: Scalars['Float'];
  paidAlae: Scalars['Float'];
  paidIndemnity: Scalars['Float'];
};

export type LargeLossRunPhysDamObject = {
  __typename?: 'LargeLossRunPhysDamObject';
  accidentDate: Scalars['Date'];
  effectiveDate: Scalars['Date'];
  expirationDate: Scalars['Date'];
  id?: Maybe<Scalars['ID']>;
  indemnityOutstanding: Scalars['Float'];
  paidIndemnity: Scalars['Float'];
};

export type LargeLossRunPhysDamRowInput = {
  accidentDate: Scalars['Date'];
  effectiveDate: Scalars['Date'];
  expirationDate: Scalars['Date'];
  id?: InputMaybe<Scalars['ID']>;
  indemnityOutstanding: Scalars['Float'];
  paidIndemnity: Scalars['Float'];
};

export type LargeLossRunRowInput = {
  accidentDate: Scalars['Date'];
  alaeOutstanding: Scalars['Float'];
  effectiveDate: Scalars['Date'];
  expirationDate: Scalars['Date'];
  id?: InputMaybe<Scalars['ID']>;
  indemnityOutstanding: Scalars['Float'];
  paidAlae: Scalars['Float'];
  paidIndemnity: Scalars['Float'];
};

export type LiablityDeductibleOptionsObject = {
  __typename?: 'LiablityDeductibleOptionsObject';
  label: Scalars['String'];
  value: LiablityDeductibleOptionsObjectValueEnum | `${LiablityDeductibleOptionsObjectValueEnum}`;
};

export enum LiablityDeductibleOptionsObjectValueEnum {
  LiabilityDeductibleOptions_0 = 'LIABILITY_DEDUCTIBLE_OPTIONS_0',
  LiabilityDeductibleOptions_250 = 'LIABILITY_DEDUCTIBLE_OPTIONS_250',
  LiabilityDeductibleOptions_500 = 'LIABILITY_DEDUCTIBLE_OPTIONS_500',
  LiabilityDeductibleOptions_1000 = 'LIABILITY_DEDUCTIBLE_OPTIONS_1000',
  LiabilityDeductibleOptions_2500 = 'LIABILITY_DEDUCTIBLE_OPTIONS_2500',
  LiabilityDeductibleOptions_5000 = 'LIABILITY_DEDUCTIBLE_OPTIONS_5000',
  LiabilityDeductibleOptions_10000 = 'LIABILITY_DEDUCTIBLE_OPTIONS_10000',
  LiabilityDeductibleOptions_20000 = 'LIABILITY_DEDUCTIBLE_OPTIONS_20000',
  LiabilityDeductibleOptions_25000 = 'LIABILITY_DEDUCTIBLE_OPTIONS_25000',
  LiabilityDeductibleOptions_50000 = 'LIABILITY_DEDUCTIBLE_OPTIONS_50000',
  LiabilityDeductibleOptions_75000 = 'LIABILITY_DEDUCTIBLE_OPTIONS_75000',
  LiabilityDeductibleOptions_100000 = 'LIABILITY_DEDUCTIBLE_OPTIONS_100000',
  LiabilityDeductibleOptions_250000 = 'LIABILITY_DEDUCTIBLE_OPTIONS_250000',
  LiabilityDeductibleOptions_500000 = 'LIABILITY_DEDUCTIBLE_OPTIONS_500000',
  LiabilityDeductibleOptions_750000 = 'LIABILITY_DEDUCTIBLE_OPTIONS_750000',
  LiabilityDeductibleOptions_1000000 = 'LIABILITY_DEDUCTIBLE_OPTIONS_1000000',
}

export type LicenseCheckObject = {
  __typename?: 'LicenseCheckObject';
  effectiveDate: Scalars['Date'];
  expirationDate?: Maybe<Scalars['Date']>;
  licenseClassAgentTypes: Array<Scalars['String']>;
  licenseClassType?: Maybe<Scalars['String']>;
  licenseNumber?: Maybe<Scalars['String']>;
  lineOfAuthorityChecks: Array<LineOfAuthorityCheckObject>;
  passedLicenseCheck: Scalars['Boolean'];
  producerText?: Maybe<Scalars['String']>;
};

export type LicenseRecordObject = {
  __typename?: 'LicenseRecordObject';
  agentType: Scalars['String'];
  licenseNumber: Scalars['String'];
  state: Scalars['String'];
};

export type LicensedState = {
  __typename?: 'LicensedState';
  licensed: Scalars['Boolean'];
  state: Scalars['String'];
};

export type LineOfAuthorityCheckObject = {
  __typename?: 'LineOfAuthorityCheckObject';
  lineOfAuthority: Scalars['String'];
  passed: Scalars['Boolean'];
};

export enum LineOfBusiness {
  Any = 'ANY',
  AutoLiability = 'AUTO_LIABILITY',
  GeneralLiability = 'GENERAL_LIABILITY',
  OwnersAndContractorsProtective = 'OWNERS_AND_CONTRACTORS_PROTECTIVE',
  Railroad = 'RAILROAD',
  WorkersCompensation = 'WORKERS_COMPENSATION',
}

export type LossDevelopmentModelConfigsObject = {
  __typename?: 'LossDevelopmentModelConfigsObject';
  autoLiability?: Maybe<AutoLiabilityLossDevelopmentModelSelectedObject>;
  generalLiability: GeneralLiabilityLossDevelopmentModelSelectedObject;
  isLive: Scalars['Boolean'];
  shouldUseAutoLiabilityLossDevelopment: Scalars['Boolean'];
  shouldUseGeneralLiabilityLossDevelopment: Scalars['Boolean'];
};

export type LossRateLabelValue = {
  __typename?: 'LossRateLabelValue';
  label: Scalars['String'];
  numberValue: Scalars['Float'];
  value: LossRateSelectionTypeEnum | `${LossRateSelectionTypeEnum}`;
};

export enum LossRateSelectionTypeEnum {
  AllYearAvgExHlExLast = 'ALL_YEAR_AVG_EX_HL_EX_LAST',
  FiveYearAvgExHlExLast = 'FIVE_YEAR_AVG_EX_HL_EX_LAST',
  FiveYearAvgExLast = 'FIVE_YEAR_AVG_EX_LAST',
  Override = 'OVERRIDE',
  ThreeYearAvgExLast = 'THREE_YEAR_AVG_EX_LAST',
}

export type LossRun = {
  __typename?: 'LossRun';
  alaeOutstanding: Scalars['Float'];
  effectiveDate: Scalars['Date'];
  evaluationDate: Scalars['Date'];
  expirationDate: Scalars['Date'];
  historicalExposure: Scalars['Float'];
  id?: Maybe<Scalars['ID']>;
  indemnityOutstanding: Scalars['Float'];
  nonZeroClaimCount: Scalars['Int'];
  paidAlae: Scalars['Float'];
  paidIndemnity: Scalars['Float'];
  totalClaimCount: Scalars['Int'];
};

export type LossRunInput = {
  alaeOutstanding: Scalars['Float'];
  effectiveDate: Scalars['Date'];
  evaluationDate: Scalars['Date'];
  expirationDate: Scalars['Date'];
  historicalExposure: Scalars['Float'];
  id?: InputMaybe<Scalars['ID']>;
  indemnityOutstanding: Scalars['Float'];
  nonZeroClaimCount: Scalars['Float'];
  paidAlae: Scalars['Float'];
  paidIndemnity: Scalars['Float'];
  totalClaimCount: Scalars['Float'];
};

export type LossRunPhysDam = {
  __typename?: 'LossRunPhysDam';
  effectiveDate: Scalars['Date'];
  evaluationDate: Scalars['Date'];
  expirationDate: Scalars['Date'];
  historicalExposure: Scalars['Float'];
  id?: Maybe<Scalars['ID']>;
  indemnityOutstanding: Scalars['Float'];
  nonZeroClaimCount: Scalars['Int'];
  paidIndemnity: Scalars['Float'];
  totalClaimCount: Scalars['Int'];
};

export type LossRunPhysDamInput = {
  effectiveDate: Scalars['Date'];
  evaluationDate: Scalars['Date'];
  expirationDate: Scalars['Date'];
  historicalExposure: Scalars['Float'];
  id?: InputMaybe<Scalars['ID']>;
  indemnityOutstanding: Scalars['Float'];
  nonZeroClaimCount: Scalars['Float'];
  paidIndemnity: Scalars['Float'];
  totalClaimCount: Scalars['Float'];
};

export enum LossRunVariant {
  ExcessPracticeAuto = 'EXCESS_PRACTICE_AUTO',
  ExcessPracticeGeneralLiability = 'EXCESS_PRACTICE_GENERAL_LIABILITY',
  ExcessProjectGeneralLiability = 'EXCESS_PROJECT_GENERAL_LIABILITY',
  PrimaryPracticeAutoLiability = 'PRIMARY_PRACTICE_AUTO_LIABILITY',
  PrimaryPracticeAutoPhysDam = 'PRIMARY_PRACTICE_AUTO_PHYS_DAM',
  PrimaryPracticeGeneralLiability = 'PRIMARY_PRACTICE_GENERAL_LIABILITY',
  PrimaryProjectAutoLiability = 'PRIMARY_PROJECT_AUTO_LIABILITY',
  PrimaryProjectAutoPhysDam = 'PRIMARY_PROJECT_AUTO_PHYS_DAM',
  PrimaryProjectGeneralLiability = 'PRIMARY_PROJECT_GENERAL_LIABILITY',
}

export type MarketplaceOffer = {
  __typename?: 'MarketplaceOffer';
  activated: Scalars['Boolean'];
  casualtyPro?: Maybe<Scalars['Boolean']>;
  category: Scalars['String'];
  description: Scalars['String'];
  discount: Scalars['String'];
  excerpt: Scalars['String'];
  image: Scalars['String'];
  offerDetails: Scalars['String'];
  title: Scalars['String'];
  websiteUrl: Scalars['String'];
};

export type MarketplaceOfferRedemptionObject = {
  __typename?: 'MarketplaceOfferRedemptionObject';
  partner: Scalars['String'];
  policyholderId: Scalars['String'];
};

export type MedPayOptionsObject = {
  __typename?: 'MedPayOptionsObject';
  label: Scalars['String'];
  state: State | `${State}`;
  value: Scalars['String'];
  vehicleType?: Maybe<VehicleTypesEnum | `${VehicleTypesEnum}`>;
};

export type MedicalExpenseLimitOptionsObject = {
  __typename?: 'MedicalExpenseLimitOptionsObject';
  label: Scalars['String'];
  value: Scalars['String'];
};

export type ModelYear = {
  __typename?: 'ModelYear';
  label: Scalars['String'];
  modelYear: Scalars['String'];
  value: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  askShepsy: ShepsyResponseOutput;
  autoVinData: Array<AutoVinDataObject>;
  clearPolicy: Success;
  createBinderPdf: QuotePdf;
  createCommentQuoteActivity: MutationCreateCommentQuoteActivityResult;
  createEvent: EventOutput;
  createFile: FileMutationPayload;
  createFormGroup: FormGroupObject;
  createFormPdf: FormPdfObject;
  createGiraffe: Scalars['String'];
  createGoogleSession: SessionMutationPayload;
  createGraySession: SessionMutationPayload;
  createInvoice: Invoice;
  createOrganization: OrganizationMutationPayload;
  createOrganizationIntegration: CreateOrganizationIntegrationMutationPayload;
  createOrganizationLocation: OrganizationLocationMutationPayload;
  createOrganizationUser: OrganizationUserMutationPayload;
  createPolicy: MutationCreatePolicyResult;
  createPolicyChangeEndorsement: PolicyChangeEndorsement;
  createPolicyholder: Policyholder;
  createPolicyholderSlackChannel: Policyholder;
  createPrimaryAutoCoverageOption: PrimaryAutoCoverageOptions;
  createProcoreUsage: ProcoreUsage;
  createQuote: Quote;
  createQuoteDoc: GoogleDriveUrl;
  createQuotePdf: QuotePdf;
  createScheduleRatings: Array<ScheduleRatingsObject>;
  createSession: SessionMutationPayload;
  createUnderlyingPolicy: UnderlyingPolicy;
  /** @deprecated Use "login" instead */
  createVerificationRequest: Success;
  deleteFile: Success;
  deleteFormGroup: Success;
  deleteFormPdf: Success;
  deleteInvoice: Invoice;
  deleteOrganization: Organization;
  deleteOrganizationIntegration: Success;
  deleteOrganizationLocation: OrganizationLocation;
  deleteOrganizationUser: OrganizationMember;
  deletePolicy: Policy;
  deletePolicyChangeEndorsement: Success;
  deletePolicyholder: Policyholder;
  deleteProcoreUsage: ProcoreUsage;
  deleteUnderlyingPolicy: Success;
  duplicateExcessQuoteAsPrimary: Quote;
  duplicateFormPdf: FormPdfObject;
  duplicateQuote: Quote;
  e2eTestSetup: E2eTestSetupPayload;
  emptyPolicyDriveFolder: Success;
  exportQuoteToGSheet: MutationExportQuoteToGSheetResult;
  exportQuoteToGSheetIntermediatePricing: MutationExportQuoteToGSheetIntermediatePricingResult;
  formDownloadUrl?: Maybe<PresignedUrlObject>;
  formPdfTags?: Maybe<Success>;
  generateAiRiskAssessment: GoogleDriveUrl;
  generateAutoPacket: Success;
  generateCaseStudy: CaseStudiesGeneratedOutput;
  generateDrivePolicyDocs: GoogleDriveUrl;
  generateLicensePdf: Success;
  issuePolicyPdf: QuotePdf;
  issuePolicySpecimenPdf: QuotePdf;
  /** @deprecated No dont use */
  login: Success;
  mergeDrivePolicyDocs: QuotePdf;
  redeemMarketplaceOffer: MarketplaceOfferRedemptionObject;
  referQuote: MutationReferQuoteResult;
  refreshFormsSelection: Array<SelectedForm>;
  renewPolicy: MutationRenewPolicyResult;
  rerunCrossbeam: Scalars['Boolean'];
  runLicenseChecks: MutationRunLicenseChecksResult;
  seedVehiclesAndCoverages: SeedFleetResponse;
  setAutoFleetSummaryFromFleetDetails: Success;
  setDemoData: Success;
  updateAutoFleetSummary: Array<AutoFleetSummaryRowObject>;
  updateCostOfHires: Array<PrimaryAutoCostOfHire>;
  updateDefaultAutoData: Success;
  updateExcessAutoCoverageOption?: Maybe<ExcessAutoCoverageOptions>;
  updateExcessAutoCoverageOptions?: Maybe<Array<ExcessAutoCoverageOptions>>;
  updateExcessAutoExposure: MutationUpdateExcessAutoExposureResult;
  updateExcessAutoVehiclesV2: Array<ExcessAutoVehicleV2>;
  updateExcessGeneralLiabilityExposure: MutationUpdateExcessGeneralLiabilityExposureResult;
  updateExpectedPayrolls: Array<PayrollObject>;
  updateFeedbackShepsy: ShepsyResponseOutput;
  updateFile: FileMutationPayload;
  updateFormGroup: FormGroupObject;
  updateFormPdf: FormPdfObject;
  updateInvoice: Invoice;
  updateLargeLossRun: Array<LargeLossRunObject>;
  updateLargeLossRunPhysDam: Array<LargeLossRunPhysDamObject>;
  updateLossRun: Array<LossRun>;
  updateLossRunPhysDam: Array<LossRunPhysDam>;
  updateOrganization: OrganizationMutationPayload;
  updateOrganizationIntegration: CreateOrganizationIntegrationMutationPayload;
  updateOrganizationLocation: OrganizationLocationMutationPayload;
  updateOrganizationUser: OrganizationUserMutationPayload;
  updatePolicy: Policy;
  updatePolicyChangeEndorsement: PolicyChangeEndorsement;
  updatePolicyholder: Policyholder;
  updatePrimaryAutoCoverageOption: PrimaryAutoCoverageOptions;
  updatePrimaryAutoExposure: MutationUpdatePrimaryAutoExposureResult;
  updatePrimaryAutoVehicles?: Maybe<Array<PrimaryAutoVehicleObject>>;
  updatePrimaryGeneralLiabilityExposure: MutationUpdatePrimaryGeneralLiabilityExposureResult;
  updatePrimaryOcpExposure: MutationUpdatePrimaryOcpExposureResult;
  updatePrimaryRailroadExposure: MutationUpdatePrimaryRailroadExposureResult;
  updatePrimaryUnderwritingAdjustments: PrimaryUnderwritingAdjustmentsObject;
  updateProcoreUsage: ProcoreUsage;
  updateProjectExcessGeneralLiabilityExposure: MutationUpdateProjectExcessGeneralLiabilityExposureResult;
  updateProjectPrimaryAutoExposure: MutationUpdateProjectPrimaryAutoExposureResult;
  updateProjectPrimaryGeneralLiabilityExposure: MutationUpdateProjectPrimaryGeneralLiabilityExposureResult;
  updateQuote: MutationUpdateQuoteResult;
  updateQuoteRiskTier: MutationUpdateQuoteRiskTierResult;
  updateQuoteStatus: MutationUpdateQuoteStatusResult;
  updateSelectedContainmentFactor?: Maybe<Scalars['Float']>;
  updateSelectedForm: SelectedForm;
  updateSelectedForms: Array<SelectedForm>;
  updateSubcontractedCost: Array<SubcontractedObject>;
  updateSubjectivities: Array<Subjectivity>;
  updateUnderlyingPolicy: UnderlyingPolicy;
  updateUnmannedAircraft: Array<UnmannedAircraftObject>;
  updateUser: UserMutationPayload;
  updateWorkersCompensationExposure: WorkersCompensationExposure;
  voidQuotePdfs: Array<QuotePdf>;
};

export type MutationAskShepsyArgs = {
  input: ShepsyInput;
};

export type MutationAutoVinDataArgs = {
  vins: Array<Scalars['String']>;
};

export type MutationClearPolicyArgs = {
  input: MutationClearPolicyInput;
};

export type MutationCreateBinderPdfArgs = {
  quoteId: Scalars['ID'];
};

export type MutationCreateCommentQuoteActivityArgs = {
  input: CreateCommentQuoteActivityInput;
};

export type MutationCreateEventArgs = {
  eventName: Scalars['String'];
  payload: Scalars['JSON'];
};

export type MutationCreateFileArgs = {
  input: CreateFileInput;
};

export type MutationCreateFormGroupArgs = {
  input: CreateFormGroupInput;
};

export type MutationCreateFormPdfArgs = {
  input: CreateFormPdfInput;
};

export type MutationCreateGoogleSessionArgs = {
  input: MutationCreateGoogleSessionInput;
};

export type MutationCreateGraySessionArgs = {
  input: MutationCreateGraySessionInput;
};

export type MutationCreateInvoiceArgs = {
  input: MutationCreateInvoiceInput;
};

export type MutationCreateOrganizationArgs = {
  input: CreateOrganizationInput;
};

export type MutationCreateOrganizationIntegrationArgs = {
  input: CreateOrganizationIntegrationInput;
};

export type MutationCreateOrganizationLocationArgs = {
  input: CreateOrganizationLocationInput;
};

export type MutationCreateOrganizationUserArgs = {
  input: CreateOrganizationUserInput;
};

export type MutationCreatePolicyArgs = {
  input: CreatePolicyInput;
};

export type MutationCreatePolicyChangeEndorsementArgs = {
  input: CreatePolicyChangeEndorsementInput;
};

export type MutationCreatePolicyholderArgs = {
  input: CreatePolicyholderInput;
};

export type MutationCreatePolicyholderSlackChannelArgs = {
  id: Scalars['ID'];
};

export type MutationCreatePrimaryAutoCoverageOptionArgs = {
  input: CreatePrimaryAutoCoverageOptionInput;
};

export type MutationCreateProcoreUsageArgs = {
  input: CreateProcoreUsageInput;
};

export type MutationCreateQuoteArgs = {
  input: CreateQuoteInput;
};

export type MutationCreateQuoteDocArgs = {
  quoteId: Scalars['ID'];
};

export type MutationCreateQuotePdfArgs = {
  quoteId: Scalars['ID'];
};

export type MutationCreateScheduleRatingsArgs = {
  input: CreateScheduleRatingsInput;
};

export type MutationCreateSessionArgs = {
  input: MutationCreateSessionInput;
};

export type MutationCreateUnderlyingPolicyArgs = {
  input: CreateUnderlyingPolicyInput;
};

export type MutationCreateVerificationRequestArgs = {
  input: MutationCreateVerificationRequestInput;
};

export type MutationDeleteFileArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteFormGroupArgs = {
  input: IdInput;
};

export type MutationDeleteFormPdfArgs = {
  input: DeleteFormPdfInput;
};

export type MutationDeleteInvoiceArgs = {
  invoiceId: Scalars['ID'];
};

export type MutationDeleteOrganizationArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteOrganizationIntegrationArgs = {
  organizationIntegrationId: Scalars['ID'];
};

export type MutationDeleteOrganizationLocationArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteOrganizationUserArgs = {
  id: Scalars['ID'];
};

export type MutationDeletePolicyArgs = {
  id: Scalars['ID'];
  unarchive?: InputMaybe<Scalars['Boolean']>;
};

export type MutationDeletePolicyChangeEndorsementArgs = {
  policyChangeEndorsementId: Scalars['ID'];
};

export type MutationDeletePolicyholderArgs = {
  id: Scalars['ID'];
  unarchive?: InputMaybe<Scalars['Boolean']>;
};

export type MutationDeleteProcoreUsageArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteUnderlyingPolicyArgs = {
  input: DeleteUnderlyingPolicyInput;
};

export type MutationDuplicateExcessQuoteAsPrimaryArgs = {
  quoteId: Scalars['ID'];
};

export type MutationDuplicateFormPdfArgs = {
  input: DuplicateFormPdfInput;
};

export type MutationDuplicateQuoteArgs = {
  quoteId: Scalars['ID'];
};

export type MutationEmptyPolicyDriveFolderArgs = {
  quoteId: Scalars['ID'];
};

export type MutationExportQuoteToGSheetArgs = {
  quoteId: Scalars['ID'];
};

export type MutationExportQuoteToGSheetIntermediatePricingArgs = {
  quoteId: Scalars['ID'];
};

export type MutationFormDownloadUrlArgs = {
  input: IdInput;
};

export type MutationFormPdfTagsArgs = {
  input: UpdateFormPdfTagsInput;
};

export type MutationGenerateAiRiskAssessmentArgs = {
  quoteId: Scalars['ID'];
};

export type MutationGenerateAutoPacketArgs = {
  quoteId: Scalars['ID'];
};

export type MutationGenerateCaseStudyArgs = {
  input: CaseStudiesInput;
};

export type MutationGenerateDrivePolicyDocsArgs = {
  quoteId: Scalars['ID'];
};

export type MutationGenerateLicensePdfArgs = {
  input: MutationGenerateLicensePdfInput;
};

export type MutationIssuePolicyPdfArgs = {
  quoteId: Scalars['ID'];
};

export type MutationIssuePolicySpecimenPdfArgs = {
  quoteId: Scalars['ID'];
};

export type MutationLoginArgs = {
  input: MutationLoginInput;
};

export type MutationMergeDrivePolicyDocsArgs = {
  quoteId: Scalars['ID'];
};

export type MutationRedeemMarketplaceOfferArgs = {
  partner: Scalars['String'];
  quoteId: Scalars['ID'];
};

export type MutationReferQuoteArgs = {
  quoteId: Scalars['ID'];
};

export type MutationRefreshFormsSelectionArgs = {
  quoteId: Scalars['ID'];
};

export type MutationRenewPolicyArgs = {
  policyId: Scalars['ID'];
};

export type MutationRunLicenseChecksArgs = {
  input: MutationRunLicenseChecksInput;
};

export type MutationSeedVehiclesAndCoveragesArgs = {
  input: SeedVehiclesAndCoveragesInput;
};

export type MutationSetAutoFleetSummaryFromFleetDetailsArgs = {
  exposureId: Scalars['ID'];
  variant: AutoFleetSummaryVariant;
};

export type MutationSetDemoDataArgs = {
  demoQuote: DemoQuote;
  exposureStep: ExposureStep;
  exposureType: ExposureType;
  productType: PolicyProduct;
  quoteId: Scalars['ID'];
};

export type MutationUpdateAutoFleetSummaryArgs = {
  autoFleetSummary: Array<AutoFleetSummaryInput>;
  exposureId: Scalars['ID'];
  variant: AutoFleetSummaryVariant;
};

export type MutationUpdateCostOfHiresArgs = {
  costOfHires: Array<PrimaryAutoCostOfHireInput>;
  exposureId: Scalars['ID'];
  variant: CostOfHireVariant;
};

export type MutationUpdateDefaultAutoDataArgs = {
  input: MutationUpdateDefaultAutoDataInput;
};

export type MutationUpdateExcessAutoCoverageOptionArgs = {
  input: UpdateExcessAutoCoverageOptionInput;
};

export type MutationUpdateExcessAutoCoverageOptionsArgs = {
  input: UpdateExcessAutoCoverageOptionsInput;
};

export type MutationUpdateExcessAutoExposureArgs = {
  input: UpdateExcessAutoExposureInput;
};

export type MutationUpdateExcessAutoVehiclesV2Args = {
  input: UpdateExcessAutoVehicleV2Input;
};

export type MutationUpdateExcessGeneralLiabilityExposureArgs = {
  input: UpdateExcessGeneralLiabilityExposureInput;
};

export type MutationUpdateExpectedPayrollsArgs = {
  exposureId: Scalars['ID'];
  payrolls: Array<PayrollInput>;
  variant: ExpectedPayrollVariant;
};

export type MutationUpdateFeedbackShepsyArgs = {
  input: UpdateShepsyFeedbackInput;
};

export type MutationUpdateFileArgs = {
  input: UpdateFileInput;
};

export type MutationUpdateFormGroupArgs = {
  input: UpdateFormGroupInput;
};

export type MutationUpdateFormPdfArgs = {
  input: UpdateFormPdfInput;
};

export type MutationUpdateInvoiceArgs = {
  input: MutationUpdateInvoiceInput;
};

export type MutationUpdateLargeLossRunArgs = {
  exposureId: Scalars['ID'];
  largeLossRun: Array<LargeLossRunRowInput>;
  variant: LossRunVariant;
};

export type MutationUpdateLargeLossRunPhysDamArgs = {
  exposureId: Scalars['ID'];
  largeLossRun: Array<LargeLossRunPhysDamRowInput>;
  variant: LossRunVariant;
};

export type MutationUpdateLossRunArgs = {
  exposureId: Scalars['ID'];
  lossRun: Array<LossRunInput>;
  variant: LossRunVariant;
};

export type MutationUpdateLossRunPhysDamArgs = {
  exposureId: Scalars['ID'];
  lossRun: Array<LossRunPhysDamInput>;
  variant: LossRunVariant;
};

export type MutationUpdateOrganizationArgs = {
  input: UpdateOrganizationInput;
};

export type MutationUpdateOrganizationIntegrationArgs = {
  input: SafeOrganizationIntegrationPayload;
};

export type MutationUpdateOrganizationLocationArgs = {
  input: UpdateOrganizationLocationInput;
};

export type MutationUpdateOrganizationUserArgs = {
  input: UpdateOrganizationUserInput;
};

export type MutationUpdatePolicyArgs = {
  input: UpdatePolicyInput;
};

export type MutationUpdatePolicyChangeEndorsementArgs = {
  input: UpdatePolicyChangeEndorsementInput;
};

export type MutationUpdatePolicyholderArgs = {
  input: UpdatePolicyholderInput;
};

export type MutationUpdatePrimaryAutoCoverageOptionArgs = {
  input: UpdatePrimaryAutoCoverageOptionInput;
};

export type MutationUpdatePrimaryAutoExposureArgs = {
  input: UpdatePrimaryAutoExposureInput;
};

export type MutationUpdatePrimaryAutoVehiclesArgs = {
  input: UpdatePrimaryAutoVehiclesInput;
};

export type MutationUpdatePrimaryGeneralLiabilityExposureArgs = {
  input: UpdatePrimaryGeneralLiabilityExposureInput;
};

export type MutationUpdatePrimaryOcpExposureArgs = {
  input: UpdatePrimaryOcpExposure;
};

export type MutationUpdatePrimaryRailroadExposureArgs = {
  input: UpdatePrimaryRailroadCoverageInput;
};

export type MutationUpdatePrimaryUnderwritingAdjustmentsArgs = {
  input: PrimaryUnderwritingAdjustmentsInput;
};

export type MutationUpdateProcoreUsageArgs = {
  input: UpdateProcoreUsageInput;
};

export type MutationUpdateProjectExcessGeneralLiabilityExposureArgs = {
  input: UpdateProjectExcessGeneralLiabilityExposureInput;
};

export type MutationUpdateProjectPrimaryAutoExposureArgs = {
  input: UpdateProjectPrimaryAutoExposureInput;
};

export type MutationUpdateProjectPrimaryGeneralLiabilityExposureArgs = {
  input: UpdateProjectPrimaryGeneralLiabilityExposure;
};

export type MutationUpdateQuoteArgs = {
  input: UpdateQuoteInput;
};

export type MutationUpdateQuoteRiskTierArgs = {
  quoteId: Scalars['ID'];
  riskTierFinal: TierEnum;
};

export type MutationUpdateQuoteStatusArgs = {
  input: UpdateQuoteStatusInput;
};

export type MutationUpdateSelectedContainmentFactorArgs = {
  input: MutationUpdateSelectedContainmentFactorInput;
};

export type MutationUpdateSelectedFormArgs = {
  input: UpdateSelectedFormInput;
};

export type MutationUpdateSelectedFormsArgs = {
  input: UpdateSelectedFormsInput;
};

export type MutationUpdateSubcontractedCostArgs = {
  exposureId: Scalars['ID'];
  payrolls: Array<SubcontractedInput>;
  variant: SubcontractedCostVariant;
};

export type MutationUpdateSubjectivitiesArgs = {
  input: UpdateSubjectivitiesInput;
};

export type MutationUpdateUnderlyingPolicyArgs = {
  input: UpdateUnderlyingPolicyInput;
};

export type MutationUpdateUnmannedAircraftArgs = {
  aircraft: Array<UnmannedAircraftInput>;
  exposureId: Scalars['ID'];
  variant: UnmannedAircraftVariant;
};

export type MutationUpdateUserArgs = {
  input: MutationUpdateUserInput;
};

export type MutationUpdateWorkersCompensationExposureArgs = {
  input: UpdateWorkersCompensationExposureInput;
};

export type MutationVoidQuotePdfsArgs = {
  pdfType: PdfType;
  quoteId: Scalars['ID'];
};

export type MutationClearPolicyInput = {
  blocked: Scalars['Boolean'];
  policyId: Scalars['ID'];
};

export type MutationCreateCommentQuoteActivityResult =
  | BaseError
  | MutationCreateCommentQuoteActivitySuccess
  | ZodError;

export type MutationCreateCommentQuoteActivitySuccess = {
  __typename?: 'MutationCreateCommentQuoteActivitySuccess';
  data: QuoteActivity;
};

export type MutationCreateGoogleSessionInput = {
  accessToken: Scalars['String'];
};

export type MutationCreateGraySessionInput = {
  email: Scalars['EmailAddress'];
  quoteId: Scalars['ID'];
  source: Scalars['String'];
};

export type MutationCreateInvoiceInput = {
  quoteId: Scalars['ID'];
};

export type MutationCreatePolicyResult = BaseError | MutationCreatePolicySuccess | ZodError;

export type MutationCreatePolicySuccess = {
  __typename?: 'MutationCreatePolicySuccess';
  data: Policy;
};

export type MutationCreateSessionInput = {
  code: Scalars['String'];
  email: Scalars['EmailAddress'];
};

export type MutationCreateVerificationRequestInput = {
  email: Scalars['EmailAddress'];
};

export type MutationExportQuoteToGSheetIntermediatePricingResult =
  | BaseError
  | MutationExportQuoteToGSheetIntermediatePricingSuccess
  | ZodError;

export type MutationExportQuoteToGSheetIntermediatePricingSuccess = {
  __typename?: 'MutationExportQuoteToGSheetIntermediatePricingSuccess';
  data: Success;
};

export type MutationExportQuoteToGSheetResult =
  | BaseError
  | MutationExportQuoteToGSheetSuccess
  | ZodError;

export type MutationExportQuoteToGSheetSuccess = {
  __typename?: 'MutationExportQuoteToGSheetSuccess';
  data: Success;
};

export type MutationGenerateLicensePdfInput = {
  policyId: Scalars['String'];
};

export type MutationLoginInput = {
  email: Scalars['EmailAddress'];
};

export type MutationReferQuoteResult = BaseError | MutationReferQuoteSuccess | ZodError;

export type MutationReferQuoteSuccess = {
  __typename?: 'MutationReferQuoteSuccess';
  data: Quote;
};

export type MutationRenewPolicyResult = BaseError | MutationRenewPolicySuccess | ZodError;

export type MutationRenewPolicySuccess = {
  __typename?: 'MutationRenewPolicySuccess';
  data: Policy;
};

export type MutationRunLicenseChecksInput = {
  policyId: Scalars['String'];
};

export type MutationRunLicenseChecksResult = BaseError | MutationRunLicenseChecksSuccess | NpnError;

export type MutationRunLicenseChecksSuccess = {
  __typename?: 'MutationRunLicenseChecksSuccess';
  data: Array<AgentSyncCheckObject>;
};

export type MutationUpdateDefaultAutoDataInput = {
  toUpdate: Array<UpdateDefaultAutoDataInput>;
};

export type MutationUpdateExcessAutoExposureResult =
  | BaseError
  | MutationUpdateExcessAutoExposureSuccess
  | ZodError;

export type MutationUpdateExcessAutoExposureSuccess = {
  __typename?: 'MutationUpdateExcessAutoExposureSuccess';
  data: ExcessAutoExposure;
};

export type MutationUpdateExcessGeneralLiabilityExposureResult =
  | BaseError
  | MutationUpdateExcessGeneralLiabilityExposureSuccess
  | ZodError;

export type MutationUpdateExcessGeneralLiabilityExposureSuccess = {
  __typename?: 'MutationUpdateExcessGeneralLiabilityExposureSuccess';
  data: ExcessGeneralLiabilityExposure;
};

export type MutationUpdateInvoiceInput = {
  id: Scalars['ID'];
  status: InvoiceStatus | `${InvoiceStatus}`;
};

export type MutationUpdatePrimaryAutoExposureResult =
  | BaseError
  | MutationUpdatePrimaryAutoExposureSuccess
  | ZodError;

export type MutationUpdatePrimaryAutoExposureSuccess = {
  __typename?: 'MutationUpdatePrimaryAutoExposureSuccess';
  data: PrimaryAutoExposure;
};

export type MutationUpdatePrimaryGeneralLiabilityExposureResult =
  | BaseError
  | MutationUpdatePrimaryGeneralLiabilityExposureSuccess
  | ZodError;

export type MutationUpdatePrimaryGeneralLiabilityExposureSuccess = {
  __typename?: 'MutationUpdatePrimaryGeneralLiabilityExposureSuccess';
  data: PrimaryGeneralLiabilityExposure;
};

export type MutationUpdatePrimaryOcpExposureResult =
  | BaseError
  | MutationUpdatePrimaryOcpExposureSuccess
  | ZodError;

export type MutationUpdatePrimaryOcpExposureSuccess = {
  __typename?: 'MutationUpdatePrimaryOcpExposureSuccess';
  data: PrimaryOcpExposure;
};

export type MutationUpdatePrimaryRailroadExposureResult =
  | BaseError
  | MutationUpdatePrimaryRailroadExposureSuccess
  | ZodError;

export type MutationUpdatePrimaryRailroadExposureSuccess = {
  __typename?: 'MutationUpdatePrimaryRailroadExposureSuccess';
  data: PrimaryRailroadExposure;
};

export type MutationUpdateProjectExcessGeneralLiabilityExposureResult =
  | BaseError
  | MutationUpdateProjectExcessGeneralLiabilityExposureSuccess
  | ZodError;

export type MutationUpdateProjectExcessGeneralLiabilityExposureSuccess = {
  __typename?: 'MutationUpdateProjectExcessGeneralLiabilityExposureSuccess';
  data: ProjectExcessGeneralLiabilityExposure;
};

export type MutationUpdateProjectPrimaryAutoExposureResult =
  | BaseError
  | MutationUpdateProjectPrimaryAutoExposureSuccess
  | ZodError;

export type MutationUpdateProjectPrimaryAutoExposureSuccess = {
  __typename?: 'MutationUpdateProjectPrimaryAutoExposureSuccess';
  data: ProjectPrimaryAutoExposureObject;
};

export type MutationUpdateProjectPrimaryGeneralLiabilityExposureResult =
  | BaseError
  | MutationUpdateProjectPrimaryGeneralLiabilityExposureSuccess
  | ZodError;

export type MutationUpdateProjectPrimaryGeneralLiabilityExposureSuccess = {
  __typename?: 'MutationUpdateProjectPrimaryGeneralLiabilityExposureSuccess';
  data: ProjectPrimaryGeneralLiabilityExposure;
};

export type MutationUpdateQuoteResult = BaseError | MutationUpdateQuoteSuccess | ZodError;

export type MutationUpdateQuoteRiskTierResult =
  | BaseError
  | MutationUpdateQuoteRiskTierSuccess
  | ZodError;

export type MutationUpdateQuoteRiskTierSuccess = {
  __typename?: 'MutationUpdateQuoteRiskTierSuccess';
  data: Quote;
};

export type MutationUpdateQuoteStatusResult =
  | BaseError
  | MutationUpdateQuoteStatusSuccess
  | ZodError;

export type MutationUpdateQuoteStatusSuccess = {
  __typename?: 'MutationUpdateQuoteStatusSuccess';
  data: Quote;
};

export type MutationUpdateQuoteSuccess = {
  __typename?: 'MutationUpdateQuoteSuccess';
  data: Quote;
};

export type MutationUpdateSelectedContainmentFactorInput = {
  quoteDetailId: Scalars['ID'];
  selectedContainmentFactor?: InputMaybe<Scalars['Float']>;
};

export type MutationUpdateUserInput = {
  email: Scalars['EmailAddress'];
  firstName?: InputMaybe<Scalars['String']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  salesforceRecordId?: InputMaybe<Scalars['String']>;
  slackUserId?: InputMaybe<Scalars['String']>;
};

export enum Naics2017SixDigitCodes {
  Naics_111110 = 'NAICS_111110',
  Naics_111120 = 'NAICS_111120',
  Naics_111130 = 'NAICS_111130',
  Naics_111140 = 'NAICS_111140',
  Naics_111150 = 'NAICS_111150',
  Naics_111160 = 'NAICS_111160',
  Naics_111191 = 'NAICS_111191',
  Naics_111199 = 'NAICS_111199',
  Naics_111211 = 'NAICS_111211',
  Naics_111219 = 'NAICS_111219',
  Naics_111310 = 'NAICS_111310',
  Naics_111320 = 'NAICS_111320',
  Naics_111331 = 'NAICS_111331',
  Naics_111332 = 'NAICS_111332',
  Naics_111333 = 'NAICS_111333',
  Naics_111334 = 'NAICS_111334',
  Naics_111335 = 'NAICS_111335',
  Naics_111336 = 'NAICS_111336',
  Naics_111339 = 'NAICS_111339',
  Naics_111411 = 'NAICS_111411',
  Naics_111419 = 'NAICS_111419',
  Naics_111421 = 'NAICS_111421',
  Naics_111422 = 'NAICS_111422',
  Naics_111910 = 'NAICS_111910',
  Naics_111920 = 'NAICS_111920',
  Naics_111930 = 'NAICS_111930',
  Naics_111940 = 'NAICS_111940',
  Naics_111991 = 'NAICS_111991',
  Naics_111992 = 'NAICS_111992',
  Naics_111998 = 'NAICS_111998',
  Naics_112111 = 'NAICS_112111',
  Naics_112112 = 'NAICS_112112',
  Naics_112120 = 'NAICS_112120',
  Naics_112130 = 'NAICS_112130',
  Naics_112210 = 'NAICS_112210',
  Naics_112310 = 'NAICS_112310',
  Naics_112320 = 'NAICS_112320',
  Naics_112330 = 'NAICS_112330',
  Naics_112340 = 'NAICS_112340',
  Naics_112390 = 'NAICS_112390',
  Naics_112410 = 'NAICS_112410',
  Naics_112420 = 'NAICS_112420',
  Naics_112511 = 'NAICS_112511',
  Naics_112512 = 'NAICS_112512',
  Naics_112519 = 'NAICS_112519',
  Naics_112910 = 'NAICS_112910',
  Naics_112920 = 'NAICS_112920',
  Naics_112930 = 'NAICS_112930',
  Naics_112990 = 'NAICS_112990',
  Naics_113110 = 'NAICS_113110',
  Naics_113210 = 'NAICS_113210',
  Naics_113310 = 'NAICS_113310',
  Naics_114111 = 'NAICS_114111',
  Naics_114112 = 'NAICS_114112',
  Naics_114119 = 'NAICS_114119',
  Naics_114210 = 'NAICS_114210',
  Naics_115111 = 'NAICS_115111',
  Naics_115112 = 'NAICS_115112',
  Naics_115113 = 'NAICS_115113',
  Naics_115114 = 'NAICS_115114',
  Naics_115115 = 'NAICS_115115',
  Naics_115116 = 'NAICS_115116',
  Naics_115210 = 'NAICS_115210',
  Naics_115310 = 'NAICS_115310',
  Naics_211120 = 'NAICS_211120',
  Naics_211130 = 'NAICS_211130',
  Naics_212111 = 'NAICS_212111',
  Naics_212112 = 'NAICS_212112',
  Naics_212113 = 'NAICS_212113',
  Naics_212210 = 'NAICS_212210',
  Naics_212221 = 'NAICS_212221',
  Naics_212222 = 'NAICS_212222',
  Naics_212230 = 'NAICS_212230',
  Naics_212291 = 'NAICS_212291',
  Naics_212299 = 'NAICS_212299',
  Naics_212311 = 'NAICS_212311',
  Naics_212312 = 'NAICS_212312',
  Naics_212313 = 'NAICS_212313',
  Naics_212319 = 'NAICS_212319',
  Naics_212321 = 'NAICS_212321',
  Naics_212322 = 'NAICS_212322',
  Naics_212324 = 'NAICS_212324',
  Naics_212325 = 'NAICS_212325',
  Naics_212391 = 'NAICS_212391',
  Naics_212392 = 'NAICS_212392',
  Naics_212393 = 'NAICS_212393',
  Naics_212399 = 'NAICS_212399',
  Naics_213111 = 'NAICS_213111',
  Naics_213112 = 'NAICS_213112',
  Naics_213113 = 'NAICS_213113',
  Naics_213114 = 'NAICS_213114',
  Naics_213115 = 'NAICS_213115',
  Naics_221111 = 'NAICS_221111',
  Naics_221112 = 'NAICS_221112',
  Naics_221113 = 'NAICS_221113',
  Naics_221114 = 'NAICS_221114',
  Naics_221115 = 'NAICS_221115',
  Naics_221116 = 'NAICS_221116',
  Naics_221117 = 'NAICS_221117',
  Naics_221118 = 'NAICS_221118',
  Naics_221121 = 'NAICS_221121',
  Naics_221122 = 'NAICS_221122',
  Naics_221210 = 'NAICS_221210',
  Naics_221310 = 'NAICS_221310',
  Naics_221320 = 'NAICS_221320',
  Naics_221330 = 'NAICS_221330',
  Naics_236115 = 'NAICS_236115',
  Naics_236116 = 'NAICS_236116',
  Naics_236117 = 'NAICS_236117',
  Naics_236118 = 'NAICS_236118',
  Naics_236210 = 'NAICS_236210',
  Naics_236220 = 'NAICS_236220',
  Naics_237110 = 'NAICS_237110',
  Naics_237120 = 'NAICS_237120',
  Naics_237130 = 'NAICS_237130',
  Naics_237210 = 'NAICS_237210',
  Naics_237310 = 'NAICS_237310',
  Naics_237990 = 'NAICS_237990',
  Naics_238110 = 'NAICS_238110',
  Naics_238120 = 'NAICS_238120',
  Naics_238130 = 'NAICS_238130',
  Naics_238140 = 'NAICS_238140',
  Naics_238150 = 'NAICS_238150',
  Naics_238160 = 'NAICS_238160',
  Naics_238170 = 'NAICS_238170',
  Naics_238190 = 'NAICS_238190',
  Naics_238210 = 'NAICS_238210',
  Naics_238220 = 'NAICS_238220',
  Naics_238290 = 'NAICS_238290',
  Naics_238310 = 'NAICS_238310',
  Naics_238320 = 'NAICS_238320',
  Naics_238330 = 'NAICS_238330',
  Naics_238340 = 'NAICS_238340',
  Naics_238350 = 'NAICS_238350',
  Naics_238390 = 'NAICS_238390',
  Naics_238910 = 'NAICS_238910',
  Naics_238990 = 'NAICS_238990',
  Naics_311111 = 'NAICS_311111',
  Naics_311119 = 'NAICS_311119',
  Naics_311211 = 'NAICS_311211',
  Naics_311212 = 'NAICS_311212',
  Naics_311213 = 'NAICS_311213',
  Naics_311221 = 'NAICS_311221',
  Naics_311224 = 'NAICS_311224',
  Naics_311225 = 'NAICS_311225',
  Naics_311230 = 'NAICS_311230',
  Naics_311313 = 'NAICS_311313',
  Naics_311314 = 'NAICS_311314',
  Naics_311340 = 'NAICS_311340',
  Naics_311351 = 'NAICS_311351',
  Naics_311352 = 'NAICS_311352',
  Naics_311411 = 'NAICS_311411',
  Naics_311412 = 'NAICS_311412',
  Naics_311421 = 'NAICS_311421',
  Naics_311422 = 'NAICS_311422',
  Naics_311423 = 'NAICS_311423',
  Naics_311511 = 'NAICS_311511',
  Naics_311512 = 'NAICS_311512',
  Naics_311513 = 'NAICS_311513',
  Naics_311514 = 'NAICS_311514',
  Naics_311520 = 'NAICS_311520',
  Naics_311611 = 'NAICS_311611',
  Naics_311612 = 'NAICS_311612',
  Naics_311613 = 'NAICS_311613',
  Naics_311615 = 'NAICS_311615',
  Naics_311710 = 'NAICS_311710',
  Naics_311811 = 'NAICS_311811',
  Naics_311812 = 'NAICS_311812',
  Naics_311813 = 'NAICS_311813',
  Naics_311821 = 'NAICS_311821',
  Naics_311824 = 'NAICS_311824',
  Naics_311830 = 'NAICS_311830',
  Naics_311911 = 'NAICS_311911',
  Naics_311919 = 'NAICS_311919',
  Naics_311920 = 'NAICS_311920',
  Naics_311930 = 'NAICS_311930',
  Naics_311941 = 'NAICS_311941',
  Naics_311942 = 'NAICS_311942',
  Naics_311991 = 'NAICS_311991',
  Naics_311999 = 'NAICS_311999',
  Naics_312111 = 'NAICS_312111',
  Naics_312112 = 'NAICS_312112',
  Naics_312113 = 'NAICS_312113',
  Naics_312120 = 'NAICS_312120',
  Naics_312130 = 'NAICS_312130',
  Naics_312140 = 'NAICS_312140',
  Naics_312230 = 'NAICS_312230',
  Naics_313110 = 'NAICS_313110',
  Naics_313210 = 'NAICS_313210',
  Naics_313220 = 'NAICS_313220',
  Naics_313230 = 'NAICS_313230',
  Naics_313240 = 'NAICS_313240',
  Naics_313310 = 'NAICS_313310',
  Naics_313320 = 'NAICS_313320',
  Naics_314110 = 'NAICS_314110',
  Naics_314120 = 'NAICS_314120',
  Naics_314910 = 'NAICS_314910',
  Naics_314994 = 'NAICS_314994',
  Naics_314999 = 'NAICS_314999',
  Naics_315110 = 'NAICS_315110',
  Naics_315190 = 'NAICS_315190',
  Naics_315210 = 'NAICS_315210',
  Naics_315220 = 'NAICS_315220',
  Naics_315240 = 'NAICS_315240',
  Naics_315280 = 'NAICS_315280',
  Naics_315990 = 'NAICS_315990',
  Naics_316110 = 'NAICS_316110',
  Naics_316210 = 'NAICS_316210',
  Naics_316992 = 'NAICS_316992',
  Naics_316998 = 'NAICS_316998',
  Naics_321113 = 'NAICS_321113',
  Naics_321114 = 'NAICS_321114',
  Naics_321211 = 'NAICS_321211',
  Naics_321212 = 'NAICS_321212',
  Naics_321213 = 'NAICS_321213',
  Naics_321214 = 'NAICS_321214',
  Naics_321219 = 'NAICS_321219',
  Naics_321911 = 'NAICS_321911',
  Naics_321912 = 'NAICS_321912',
  Naics_321918 = 'NAICS_321918',
  Naics_321920 = 'NAICS_321920',
  Naics_321991 = 'NAICS_321991',
  Naics_321992 = 'NAICS_321992',
  Naics_321999 = 'NAICS_321999',
  Naics_322110 = 'NAICS_322110',
  Naics_322121 = 'NAICS_322121',
  Naics_322122 = 'NAICS_322122',
  Naics_322130 = 'NAICS_322130',
  Naics_322211 = 'NAICS_322211',
  Naics_322212 = 'NAICS_322212',
  Naics_322219 = 'NAICS_322219',
  Naics_322220 = 'NAICS_322220',
  Naics_322230 = 'NAICS_322230',
  Naics_322291 = 'NAICS_322291',
  Naics_322299 = 'NAICS_322299',
  Naics_323111 = 'NAICS_323111',
  Naics_323113 = 'NAICS_323113',
  Naics_323117 = 'NAICS_323117',
  Naics_323120 = 'NAICS_323120',
  Naics_324110 = 'NAICS_324110',
  Naics_324121 = 'NAICS_324121',
  Naics_324122 = 'NAICS_324122',
  Naics_324191 = 'NAICS_324191',
  Naics_324199 = 'NAICS_324199',
  Naics_325110 = 'NAICS_325110',
  Naics_325120 = 'NAICS_325120',
  Naics_325130 = 'NAICS_325130',
  Naics_325180 = 'NAICS_325180',
  Naics_325193 = 'NAICS_325193',
  Naics_325194 = 'NAICS_325194',
  Naics_325199 = 'NAICS_325199',
  Naics_325211 = 'NAICS_325211',
  Naics_325212 = 'NAICS_325212',
  Naics_325220 = 'NAICS_325220',
  Naics_325311 = 'NAICS_325311',
  Naics_325312 = 'NAICS_325312',
  Naics_325314 = 'NAICS_325314',
  Naics_325320 = 'NAICS_325320',
  Naics_325411 = 'NAICS_325411',
  Naics_325412 = 'NAICS_325412',
  Naics_325413 = 'NAICS_325413',
  Naics_325414 = 'NAICS_325414',
  Naics_325510 = 'NAICS_325510',
  Naics_325520 = 'NAICS_325520',
  Naics_325611 = 'NAICS_325611',
  Naics_325612 = 'NAICS_325612',
  Naics_325613 = 'NAICS_325613',
  Naics_325620 = 'NAICS_325620',
  Naics_325910 = 'NAICS_325910',
  Naics_325920 = 'NAICS_325920',
  Naics_325991 = 'NAICS_325991',
  Naics_325992 = 'NAICS_325992',
  Naics_325998 = 'NAICS_325998',
  Naics_326111 = 'NAICS_326111',
  Naics_326112 = 'NAICS_326112',
  Naics_326113 = 'NAICS_326113',
  Naics_326121 = 'NAICS_326121',
  Naics_326122 = 'NAICS_326122',
  Naics_326130 = 'NAICS_326130',
  Naics_326140 = 'NAICS_326140',
  Naics_326150 = 'NAICS_326150',
  Naics_326160 = 'NAICS_326160',
  Naics_326191 = 'NAICS_326191',
  Naics_326199 = 'NAICS_326199',
  Naics_326211 = 'NAICS_326211',
  Naics_326212 = 'NAICS_326212',
  Naics_326220 = 'NAICS_326220',
  Naics_326291 = 'NAICS_326291',
  Naics_326299 = 'NAICS_326299',
  Naics_327110 = 'NAICS_327110',
  Naics_327120 = 'NAICS_327120',
  Naics_327211 = 'NAICS_327211',
  Naics_327212 = 'NAICS_327212',
  Naics_327213 = 'NAICS_327213',
  Naics_327215 = 'NAICS_327215',
  Naics_327310 = 'NAICS_327310',
  Naics_327320 = 'NAICS_327320',
  Naics_327331 = 'NAICS_327331',
  Naics_327332 = 'NAICS_327332',
  Naics_327390 = 'NAICS_327390',
  Naics_327410 = 'NAICS_327410',
  Naics_327420 = 'NAICS_327420',
  Naics_327910 = 'NAICS_327910',
  Naics_327991 = 'NAICS_327991',
  Naics_327992 = 'NAICS_327992',
  Naics_327993 = 'NAICS_327993',
  Naics_327999 = 'NAICS_327999',
  Naics_331110 = 'NAICS_331110',
  Naics_331210 = 'NAICS_331210',
  Naics_331221 = 'NAICS_331221',
  Naics_331222 = 'NAICS_331222',
  Naics_331313 = 'NAICS_331313',
  Naics_331314 = 'NAICS_331314',
  Naics_331315 = 'NAICS_331315',
  Naics_331318 = 'NAICS_331318',
  Naics_331410 = 'NAICS_331410',
  Naics_331420 = 'NAICS_331420',
  Naics_331491 = 'NAICS_331491',
  Naics_331492 = 'NAICS_331492',
  Naics_331511 = 'NAICS_331511',
  Naics_331512 = 'NAICS_331512',
  Naics_331513 = 'NAICS_331513',
  Naics_331523 = 'NAICS_331523',
  Naics_331524 = 'NAICS_331524',
  Naics_331529 = 'NAICS_331529',
  Naics_332111 = 'NAICS_332111',
  Naics_332112 = 'NAICS_332112',
  Naics_332114 = 'NAICS_332114',
  Naics_332117 = 'NAICS_332117',
  Naics_332119 = 'NAICS_332119',
  Naics_332215 = 'NAICS_332215',
  Naics_332216 = 'NAICS_332216',
  Naics_332311 = 'NAICS_332311',
  Naics_332312 = 'NAICS_332312',
  Naics_332313 = 'NAICS_332313',
  Naics_332321 = 'NAICS_332321',
  Naics_332322 = 'NAICS_332322',
  Naics_332323 = 'NAICS_332323',
  Naics_332410 = 'NAICS_332410',
  Naics_332420 = 'NAICS_332420',
  Naics_332431 = 'NAICS_332431',
  Naics_332439 = 'NAICS_332439',
  Naics_332510 = 'NAICS_332510',
  Naics_332613 = 'NAICS_332613',
  Naics_332618 = 'NAICS_332618',
  Naics_332710 = 'NAICS_332710',
  Naics_332721 = 'NAICS_332721',
  Naics_332722 = 'NAICS_332722',
  Naics_332811 = 'NAICS_332811',
  Naics_332812 = 'NAICS_332812',
  Naics_332813 = 'NAICS_332813',
  Naics_332911 = 'NAICS_332911',
  Naics_332912 = 'NAICS_332912',
  Naics_332913 = 'NAICS_332913',
  Naics_332919 = 'NAICS_332919',
  Naics_332991 = 'NAICS_332991',
  Naics_332992 = 'NAICS_332992',
  Naics_332993 = 'NAICS_332993',
  Naics_332994 = 'NAICS_332994',
  Naics_332996 = 'NAICS_332996',
  Naics_332999 = 'NAICS_332999',
  Naics_333111 = 'NAICS_333111',
  Naics_333112 = 'NAICS_333112',
  Naics_333120 = 'NAICS_333120',
  Naics_333131 = 'NAICS_333131',
  Naics_333132 = 'NAICS_333132',
  Naics_333241 = 'NAICS_333241',
  Naics_333242 = 'NAICS_333242',
  Naics_333243 = 'NAICS_333243',
  Naics_333244 = 'NAICS_333244',
  Naics_333249 = 'NAICS_333249',
  Naics_333314 = 'NAICS_333314',
  Naics_333316 = 'NAICS_333316',
  Naics_333318 = 'NAICS_333318',
  Naics_333413 = 'NAICS_333413',
  Naics_333414 = 'NAICS_333414',
  Naics_333415 = 'NAICS_333415',
  Naics_333511 = 'NAICS_333511',
  Naics_333514 = 'NAICS_333514',
  Naics_333515 = 'NAICS_333515',
  Naics_333517 = 'NAICS_333517',
  Naics_333519 = 'NAICS_333519',
  Naics_333611 = 'NAICS_333611',
  Naics_333612 = 'NAICS_333612',
  Naics_333613 = 'NAICS_333613',
  Naics_333618 = 'NAICS_333618',
  Naics_333912 = 'NAICS_333912',
  Naics_333914 = 'NAICS_333914',
  Naics_333921 = 'NAICS_333921',
  Naics_333922 = 'NAICS_333922',
  Naics_333923 = 'NAICS_333923',
  Naics_333924 = 'NAICS_333924',
  Naics_333991 = 'NAICS_333991',
  Naics_333992 = 'NAICS_333992',
  Naics_333993 = 'NAICS_333993',
  Naics_333994 = 'NAICS_333994',
  Naics_333995 = 'NAICS_333995',
  Naics_333996 = 'NAICS_333996',
  Naics_333997 = 'NAICS_333997',
  Naics_333999 = 'NAICS_333999',
  Naics_334111 = 'NAICS_334111',
  Naics_334112 = 'NAICS_334112',
  Naics_334118 = 'NAICS_334118',
  Naics_334210 = 'NAICS_334210',
  Naics_334220 = 'NAICS_334220',
  Naics_334290 = 'NAICS_334290',
  Naics_334310 = 'NAICS_334310',
  Naics_334412 = 'NAICS_334412',
  Naics_334413 = 'NAICS_334413',
  Naics_334416 = 'NAICS_334416',
  Naics_334417 = 'NAICS_334417',
  Naics_334418 = 'NAICS_334418',
  Naics_334419 = 'NAICS_334419',
  Naics_334510 = 'NAICS_334510',
  Naics_334511 = 'NAICS_334511',
  Naics_334512 = 'NAICS_334512',
  Naics_334513 = 'NAICS_334513',
  Naics_334514 = 'NAICS_334514',
  Naics_334515 = 'NAICS_334515',
  Naics_334516 = 'NAICS_334516',
  Naics_334517 = 'NAICS_334517',
  Naics_334519 = 'NAICS_334519',
  Naics_334613 = 'NAICS_334613',
  Naics_334614 = 'NAICS_334614',
  Naics_335110 = 'NAICS_335110',
  Naics_335121 = 'NAICS_335121',
  Naics_335122 = 'NAICS_335122',
  Naics_335129 = 'NAICS_335129',
  Naics_335210 = 'NAICS_335210',
  Naics_335220 = 'NAICS_335220',
  Naics_335311 = 'NAICS_335311',
  Naics_335312 = 'NAICS_335312',
  Naics_335313 = 'NAICS_335313',
  Naics_335314 = 'NAICS_335314',
  Naics_335911 = 'NAICS_335911',
  Naics_335912 = 'NAICS_335912',
  Naics_335921 = 'NAICS_335921',
  Naics_335929 = 'NAICS_335929',
  Naics_335931 = 'NAICS_335931',
  Naics_335932 = 'NAICS_335932',
  Naics_335991 = 'NAICS_335991',
  Naics_335999 = 'NAICS_335999',
  Naics_336111 = 'NAICS_336111',
  Naics_336112 = 'NAICS_336112',
  Naics_336120 = 'NAICS_336120',
  Naics_336211 = 'NAICS_336211',
  Naics_336212 = 'NAICS_336212',
  Naics_336213 = 'NAICS_336213',
  Naics_336214 = 'NAICS_336214',
  Naics_336310 = 'NAICS_336310',
  Naics_336320 = 'NAICS_336320',
  Naics_336330 = 'NAICS_336330',
  Naics_336340 = 'NAICS_336340',
  Naics_336350 = 'NAICS_336350',
  Naics_336360 = 'NAICS_336360',
  Naics_336370 = 'NAICS_336370',
  Naics_336390 = 'NAICS_336390',
  Naics_336411 = 'NAICS_336411',
  Naics_336412 = 'NAICS_336412',
  Naics_336413 = 'NAICS_336413',
  Naics_336414 = 'NAICS_336414',
  Naics_336415 = 'NAICS_336415',
  Naics_336419 = 'NAICS_336419',
  Naics_336510 = 'NAICS_336510',
  Naics_336611 = 'NAICS_336611',
  Naics_336612 = 'NAICS_336612',
  Naics_336991 = 'NAICS_336991',
  Naics_336992 = 'NAICS_336992',
  Naics_336999 = 'NAICS_336999',
  Naics_337110 = 'NAICS_337110',
  Naics_337121 = 'NAICS_337121',
  Naics_337122 = 'NAICS_337122',
  Naics_337124 = 'NAICS_337124',
  Naics_337125 = 'NAICS_337125',
  Naics_337127 = 'NAICS_337127',
  Naics_337211 = 'NAICS_337211',
  Naics_337212 = 'NAICS_337212',
  Naics_337214 = 'NAICS_337214',
  Naics_337215 = 'NAICS_337215',
  Naics_337910 = 'NAICS_337910',
  Naics_337920 = 'NAICS_337920',
  Naics_339112 = 'NAICS_339112',
  Naics_339113 = 'NAICS_339113',
  Naics_339114 = 'NAICS_339114',
  Naics_339115 = 'NAICS_339115',
  Naics_339116 = 'NAICS_339116',
  Naics_339910 = 'NAICS_339910',
  Naics_339920 = 'NAICS_339920',
  Naics_339930 = 'NAICS_339930',
  Naics_339940 = 'NAICS_339940',
  Naics_339950 = 'NAICS_339950',
  Naics_339991 = 'NAICS_339991',
  Naics_339992 = 'NAICS_339992',
  Naics_339993 = 'NAICS_339993',
  Naics_339994 = 'NAICS_339994',
  Naics_339995 = 'NAICS_339995',
  Naics_339999 = 'NAICS_339999',
  Naics_423110 = 'NAICS_423110',
  Naics_423120 = 'NAICS_423120',
  Naics_423130 = 'NAICS_423130',
  Naics_423140 = 'NAICS_423140',
  Naics_423210 = 'NAICS_423210',
  Naics_423220 = 'NAICS_423220',
  Naics_423310 = 'NAICS_423310',
  Naics_423320 = 'NAICS_423320',
  Naics_423330 = 'NAICS_423330',
  Naics_423390 = 'NAICS_423390',
  Naics_423410 = 'NAICS_423410',
  Naics_423420 = 'NAICS_423420',
  Naics_423430 = 'NAICS_423430',
  Naics_423440 = 'NAICS_423440',
  Naics_423450 = 'NAICS_423450',
  Naics_423460 = 'NAICS_423460',
  Naics_423490 = 'NAICS_423490',
  Naics_423510 = 'NAICS_423510',
  Naics_423520 = 'NAICS_423520',
  Naics_423610 = 'NAICS_423610',
  Naics_423620 = 'NAICS_423620',
  Naics_423690 = 'NAICS_423690',
  Naics_423710 = 'NAICS_423710',
  Naics_423720 = 'NAICS_423720',
  Naics_423730 = 'NAICS_423730',
  Naics_423740 = 'NAICS_423740',
  Naics_423810 = 'NAICS_423810',
  Naics_423820 = 'NAICS_423820',
  Naics_423830 = 'NAICS_423830',
  Naics_423840 = 'NAICS_423840',
  Naics_423850 = 'NAICS_423850',
  Naics_423860 = 'NAICS_423860',
  Naics_423910 = 'NAICS_423910',
  Naics_423920 = 'NAICS_423920',
  Naics_423930 = 'NAICS_423930',
  Naics_423940 = 'NAICS_423940',
  Naics_423990 = 'NAICS_423990',
  Naics_424110 = 'NAICS_424110',
  Naics_424120 = 'NAICS_424120',
  Naics_424130 = 'NAICS_424130',
  Naics_424210 = 'NAICS_424210',
  Naics_424310 = 'NAICS_424310',
  Naics_424320 = 'NAICS_424320',
  Naics_424330 = 'NAICS_424330',
  Naics_424340 = 'NAICS_424340',
  Naics_424410 = 'NAICS_424410',
  Naics_424420 = 'NAICS_424420',
  Naics_424430 = 'NAICS_424430',
  Naics_424440 = 'NAICS_424440',
  Naics_424450 = 'NAICS_424450',
  Naics_424460 = 'NAICS_424460',
  Naics_424470 = 'NAICS_424470',
  Naics_424480 = 'NAICS_424480',
  Naics_424490 = 'NAICS_424490',
  Naics_424510 = 'NAICS_424510',
  Naics_424520 = 'NAICS_424520',
  Naics_424590 = 'NAICS_424590',
  Naics_424610 = 'NAICS_424610',
  Naics_424690 = 'NAICS_424690',
  Naics_424710 = 'NAICS_424710',
  Naics_424720 = 'NAICS_424720',
  Naics_424810 = 'NAICS_424810',
  Naics_424820 = 'NAICS_424820',
  Naics_424910 = 'NAICS_424910',
  Naics_424920 = 'NAICS_424920',
  Naics_424930 = 'NAICS_424930',
  Naics_424940 = 'NAICS_424940',
  Naics_424950 = 'NAICS_424950',
  Naics_424990 = 'NAICS_424990',
  Naics_425110 = 'NAICS_425110',
  Naics_425120 = 'NAICS_425120',
  Naics_441110 = 'NAICS_441110',
  Naics_441120 = 'NAICS_441120',
  Naics_441210 = 'NAICS_441210',
  Naics_441222 = 'NAICS_441222',
  Naics_441228 = 'NAICS_441228',
  Naics_441310 = 'NAICS_441310',
  Naics_441320 = 'NAICS_441320',
  Naics_442110 = 'NAICS_442110',
  Naics_442210 = 'NAICS_442210',
  Naics_442291 = 'NAICS_442291',
  Naics_442299 = 'NAICS_442299',
  Naics_443141 = 'NAICS_443141',
  Naics_443142 = 'NAICS_443142',
  Naics_444110 = 'NAICS_444110',
  Naics_444120 = 'NAICS_444120',
  Naics_444130 = 'NAICS_444130',
  Naics_444190 = 'NAICS_444190',
  Naics_444210 = 'NAICS_444210',
  Naics_444220 = 'NAICS_444220',
  Naics_445110 = 'NAICS_445110',
  Naics_445120 = 'NAICS_445120',
  Naics_445210 = 'NAICS_445210',
  Naics_445220 = 'NAICS_445220',
  Naics_445230 = 'NAICS_445230',
  Naics_445291 = 'NAICS_445291',
  Naics_445292 = 'NAICS_445292',
  Naics_445299 = 'NAICS_445299',
  Naics_445310 = 'NAICS_445310',
  Naics_446110 = 'NAICS_446110',
  Naics_446120 = 'NAICS_446120',
  Naics_446130 = 'NAICS_446130',
  Naics_446191 = 'NAICS_446191',
  Naics_446199 = 'NAICS_446199',
  Naics_447110 = 'NAICS_447110',
  Naics_447190 = 'NAICS_447190',
  Naics_448110 = 'NAICS_448110',
  Naics_448120 = 'NAICS_448120',
  Naics_448130 = 'NAICS_448130',
  Naics_448140 = 'NAICS_448140',
  Naics_448150 = 'NAICS_448150',
  Naics_448190 = 'NAICS_448190',
  Naics_448210 = 'NAICS_448210',
  Naics_448310 = 'NAICS_448310',
  Naics_448320 = 'NAICS_448320',
  Naics_451110 = 'NAICS_451110',
  Naics_451120 = 'NAICS_451120',
  Naics_451130 = 'NAICS_451130',
  Naics_451140 = 'NAICS_451140',
  Naics_451211 = 'NAICS_451211',
  Naics_451212 = 'NAICS_451212',
  Naics_452210 = 'NAICS_452210',
  Naics_452311 = 'NAICS_452311',
  Naics_452319 = 'NAICS_452319',
  Naics_453110 = 'NAICS_453110',
  Naics_453210 = 'NAICS_453210',
  Naics_453220 = 'NAICS_453220',
  Naics_453310 = 'NAICS_453310',
  Naics_453910 = 'NAICS_453910',
  Naics_453920 = 'NAICS_453920',
  Naics_453930 = 'NAICS_453930',
  Naics_453991 = 'NAICS_453991',
  Naics_453998 = 'NAICS_453998',
  Naics_454110 = 'NAICS_454110',
  Naics_454210 = 'NAICS_454210',
  Naics_454310 = 'NAICS_454310',
  Naics_454390 = 'NAICS_454390',
  Naics_481111 = 'NAICS_481111',
  Naics_481112 = 'NAICS_481112',
  Naics_481211 = 'NAICS_481211',
  Naics_481212 = 'NAICS_481212',
  Naics_481219 = 'NAICS_481219',
  Naics_482111 = 'NAICS_482111',
  Naics_482112 = 'NAICS_482112',
  Naics_483111 = 'NAICS_483111',
  Naics_483112 = 'NAICS_483112',
  Naics_483113 = 'NAICS_483113',
  Naics_483114 = 'NAICS_483114',
  Naics_483211 = 'NAICS_483211',
  Naics_483212 = 'NAICS_483212',
  Naics_484110 = 'NAICS_484110',
  Naics_484121 = 'NAICS_484121',
  Naics_484122 = 'NAICS_484122',
  Naics_484210 = 'NAICS_484210',
  Naics_484220 = 'NAICS_484220',
  Naics_484230 = 'NAICS_484230',
  Naics_485111 = 'NAICS_485111',
  Naics_485112 = 'NAICS_485112',
  Naics_485113 = 'NAICS_485113',
  Naics_485119 = 'NAICS_485119',
  Naics_485210 = 'NAICS_485210',
  Naics_485310 = 'NAICS_485310',
  Naics_485320 = 'NAICS_485320',
  Naics_485410 = 'NAICS_485410',
  Naics_485510 = 'NAICS_485510',
  Naics_485991 = 'NAICS_485991',
  Naics_485999 = 'NAICS_485999',
  Naics_486110 = 'NAICS_486110',
  Naics_486210 = 'NAICS_486210',
  Naics_486910 = 'NAICS_486910',
  Naics_486990 = 'NAICS_486990',
  Naics_487110 = 'NAICS_487110',
  Naics_487210 = 'NAICS_487210',
  Naics_487990 = 'NAICS_487990',
  Naics_488111 = 'NAICS_488111',
  Naics_488119 = 'NAICS_488119',
  Naics_488190 = 'NAICS_488190',
  Naics_488210 = 'NAICS_488210',
  Naics_488310 = 'NAICS_488310',
  Naics_488320 = 'NAICS_488320',
  Naics_488330 = 'NAICS_488330',
  Naics_488390 = 'NAICS_488390',
  Naics_488410 = 'NAICS_488410',
  Naics_488490 = 'NAICS_488490',
  Naics_488510 = 'NAICS_488510',
  Naics_488991 = 'NAICS_488991',
  Naics_488999 = 'NAICS_488999',
  Naics_491110 = 'NAICS_491110',
  Naics_492110 = 'NAICS_492110',
  Naics_492210 = 'NAICS_492210',
  Naics_493110 = 'NAICS_493110',
  Naics_493120 = 'NAICS_493120',
  Naics_493130 = 'NAICS_493130',
  Naics_493190 = 'NAICS_493190',
  Naics_511110 = 'NAICS_511110',
  Naics_511120 = 'NAICS_511120',
  Naics_511130 = 'NAICS_511130',
  Naics_511140 = 'NAICS_511140',
  Naics_511191 = 'NAICS_511191',
  Naics_511199 = 'NAICS_511199',
  Naics_511210 = 'NAICS_511210',
  Naics_512110 = 'NAICS_512110',
  Naics_512120 = 'NAICS_512120',
  Naics_512131 = 'NAICS_512131',
  Naics_512132 = 'NAICS_512132',
  Naics_512191 = 'NAICS_512191',
  Naics_512199 = 'NAICS_512199',
  Naics_512230 = 'NAICS_512230',
  Naics_512240 = 'NAICS_512240',
  Naics_512250 = 'NAICS_512250',
  Naics_512290 = 'NAICS_512290',
  Naics_515111 = 'NAICS_515111',
  Naics_515112 = 'NAICS_515112',
  Naics_515120 = 'NAICS_515120',
  Naics_515210 = 'NAICS_515210',
  Naics_517311 = 'NAICS_517311',
  Naics_517312 = 'NAICS_517312',
  Naics_517410 = 'NAICS_517410',
  Naics_517911 = 'NAICS_517911',
  Naics_517919 = 'NAICS_517919',
  Naics_518210 = 'NAICS_518210',
  Naics_519110 = 'NAICS_519110',
  Naics_519120 = 'NAICS_519120',
  Naics_519130 = 'NAICS_519130',
  Naics_519190 = 'NAICS_519190',
  Naics_521110 = 'NAICS_521110',
  Naics_522110 = 'NAICS_522110',
  Naics_522120 = 'NAICS_522120',
  Naics_522130 = 'NAICS_522130',
  Naics_522190 = 'NAICS_522190',
  Naics_522210 = 'NAICS_522210',
  Naics_522220 = 'NAICS_522220',
  Naics_522291 = 'NAICS_522291',
  Naics_522292 = 'NAICS_522292',
  Naics_522293 = 'NAICS_522293',
  Naics_522294 = 'NAICS_522294',
  Naics_522298 = 'NAICS_522298',
  Naics_522310 = 'NAICS_522310',
  Naics_522320 = 'NAICS_522320',
  Naics_522390 = 'NAICS_522390',
  Naics_523110 = 'NAICS_523110',
  Naics_523120 = 'NAICS_523120',
  Naics_523130 = 'NAICS_523130',
  Naics_523140 = 'NAICS_523140',
  Naics_523210 = 'NAICS_523210',
  Naics_523910 = 'NAICS_523910',
  Naics_523920 = 'NAICS_523920',
  Naics_523930 = 'NAICS_523930',
  Naics_523991 = 'NAICS_523991',
  Naics_523999 = 'NAICS_523999',
  Naics_524113 = 'NAICS_524113',
  Naics_524114 = 'NAICS_524114',
  Naics_524126 = 'NAICS_524126',
  Naics_524127 = 'NAICS_524127',
  Naics_524128 = 'NAICS_524128',
  Naics_524130 = 'NAICS_524130',
  Naics_524210 = 'NAICS_524210',
  Naics_524291 = 'NAICS_524291',
  Naics_524292 = 'NAICS_524292',
  Naics_524298 = 'NAICS_524298',
  Naics_525110 = 'NAICS_525110',
  Naics_525120 = 'NAICS_525120',
  Naics_525190 = 'NAICS_525190',
  Naics_525910 = 'NAICS_525910',
  Naics_525920 = 'NAICS_525920',
  Naics_525990 = 'NAICS_525990',
  Naics_531110 = 'NAICS_531110',
  Naics_531120 = 'NAICS_531120',
  Naics_531130 = 'NAICS_531130',
  Naics_531190 = 'NAICS_531190',
  Naics_531210 = 'NAICS_531210',
  Naics_531311 = 'NAICS_531311',
  Naics_531312 = 'NAICS_531312',
  Naics_531320 = 'NAICS_531320',
  Naics_531390 = 'NAICS_531390',
  Naics_532111 = 'NAICS_532111',
  Naics_532112 = 'NAICS_532112',
  Naics_532120 = 'NAICS_532120',
  Naics_532210 = 'NAICS_532210',
  Naics_532281 = 'NAICS_532281',
  Naics_532282 = 'NAICS_532282',
  Naics_532283 = 'NAICS_532283',
  Naics_532284 = 'NAICS_532284',
  Naics_532289 = 'NAICS_532289',
  Naics_532310 = 'NAICS_532310',
  Naics_532411 = 'NAICS_532411',
  Naics_532412 = 'NAICS_532412',
  Naics_532420 = 'NAICS_532420',
  Naics_532490 = 'NAICS_532490',
  Naics_533110 = 'NAICS_533110',
  Naics_541110 = 'NAICS_541110',
  Naics_541120 = 'NAICS_541120',
  Naics_541191 = 'NAICS_541191',
  Naics_541199 = 'NAICS_541199',
  Naics_541211 = 'NAICS_541211',
  Naics_541213 = 'NAICS_541213',
  Naics_541214 = 'NAICS_541214',
  Naics_541219 = 'NAICS_541219',
  Naics_541310 = 'NAICS_541310',
  Naics_541320 = 'NAICS_541320',
  Naics_541330 = 'NAICS_541330',
  Naics_541340 = 'NAICS_541340',
  Naics_541350 = 'NAICS_541350',
  Naics_541360 = 'NAICS_541360',
  Naics_541370 = 'NAICS_541370',
  Naics_541380 = 'NAICS_541380',
  Naics_541410 = 'NAICS_541410',
  Naics_541420 = 'NAICS_541420',
  Naics_541430 = 'NAICS_541430',
  Naics_541490 = 'NAICS_541490',
  Naics_541511 = 'NAICS_541511',
  Naics_541512 = 'NAICS_541512',
  Naics_541513 = 'NAICS_541513',
  Naics_541519 = 'NAICS_541519',
  Naics_541611 = 'NAICS_541611',
  Naics_541612 = 'NAICS_541612',
  Naics_541613 = 'NAICS_541613',
  Naics_541614 = 'NAICS_541614',
  Naics_541618 = 'NAICS_541618',
  Naics_541620 = 'NAICS_541620',
  Naics_541690 = 'NAICS_541690',
  Naics_541713 = 'NAICS_541713',
  Naics_541714 = 'NAICS_541714',
  Naics_541715 = 'NAICS_541715',
  Naics_541720 = 'NAICS_541720',
  Naics_541810 = 'NAICS_541810',
  Naics_541820 = 'NAICS_541820',
  Naics_541830 = 'NAICS_541830',
  Naics_541840 = 'NAICS_541840',
  Naics_541850 = 'NAICS_541850',
  Naics_541860 = 'NAICS_541860',
  Naics_541870 = 'NAICS_541870',
  Naics_541890 = 'NAICS_541890',
  Naics_541910 = 'NAICS_541910',
  Naics_541921 = 'NAICS_541921',
  Naics_541922 = 'NAICS_541922',
  Naics_541930 = 'NAICS_541930',
  Naics_541940 = 'NAICS_541940',
  Naics_541990 = 'NAICS_541990',
  Naics_551111 = 'NAICS_551111',
  Naics_551112 = 'NAICS_551112',
  Naics_551114 = 'NAICS_551114',
  Naics_561110 = 'NAICS_561110',
  Naics_561210 = 'NAICS_561210',
  Naics_561311 = 'NAICS_561311',
  Naics_561312 = 'NAICS_561312',
  Naics_561320 = 'NAICS_561320',
  Naics_561330 = 'NAICS_561330',
  Naics_561410 = 'NAICS_561410',
  Naics_561421 = 'NAICS_561421',
  Naics_561422 = 'NAICS_561422',
  Naics_561431 = 'NAICS_561431',
  Naics_561439 = 'NAICS_561439',
  Naics_561440 = 'NAICS_561440',
  Naics_561450 = 'NAICS_561450',
  Naics_561491 = 'NAICS_561491',
  Naics_561492 = 'NAICS_561492',
  Naics_561499 = 'NAICS_561499',
  Naics_561510 = 'NAICS_561510',
  Naics_561520 = 'NAICS_561520',
  Naics_561591 = 'NAICS_561591',
  Naics_561599 = 'NAICS_561599',
  Naics_561611 = 'NAICS_561611',
  Naics_561612 = 'NAICS_561612',
  Naics_561613 = 'NAICS_561613',
  Naics_561621 = 'NAICS_561621',
  Naics_561622 = 'NAICS_561622',
  Naics_561710 = 'NAICS_561710',
  Naics_561720 = 'NAICS_561720',
  Naics_561730 = 'NAICS_561730',
  Naics_561740 = 'NAICS_561740',
  Naics_561790 = 'NAICS_561790',
  Naics_561910 = 'NAICS_561910',
  Naics_561920 = 'NAICS_561920',
  Naics_561990 = 'NAICS_561990',
  Naics_562111 = 'NAICS_562111',
  Naics_562112 = 'NAICS_562112',
  Naics_562119 = 'NAICS_562119',
  Naics_562211 = 'NAICS_562211',
  Naics_562212 = 'NAICS_562212',
  Naics_562213 = 'NAICS_562213',
  Naics_562219 = 'NAICS_562219',
  Naics_562910 = 'NAICS_562910',
  Naics_562920 = 'NAICS_562920',
  Naics_562991 = 'NAICS_562991',
  Naics_562998 = 'NAICS_562998',
  Naics_611110 = 'NAICS_611110',
  Naics_611210 = 'NAICS_611210',
  Naics_611310 = 'NAICS_611310',
  Naics_611410 = 'NAICS_611410',
  Naics_611420 = 'NAICS_611420',
  Naics_611430 = 'NAICS_611430',
  Naics_611511 = 'NAICS_611511',
  Naics_611512 = 'NAICS_611512',
  Naics_611513 = 'NAICS_611513',
  Naics_611519 = 'NAICS_611519',
  Naics_611610 = 'NAICS_611610',
  Naics_611620 = 'NAICS_611620',
  Naics_611630 = 'NAICS_611630',
  Naics_611691 = 'NAICS_611691',
  Naics_611692 = 'NAICS_611692',
  Naics_611699 = 'NAICS_611699',
  Naics_611710 = 'NAICS_611710',
  Naics_621111 = 'NAICS_621111',
  Naics_621112 = 'NAICS_621112',
  Naics_621210 = 'NAICS_621210',
  Naics_621310 = 'NAICS_621310',
  Naics_621320 = 'NAICS_621320',
  Naics_621330 = 'NAICS_621330',
  Naics_621340 = 'NAICS_621340',
  Naics_621391 = 'NAICS_621391',
  Naics_621399 = 'NAICS_621399',
  Naics_621410 = 'NAICS_621410',
  Naics_621420 = 'NAICS_621420',
  Naics_621491 = 'NAICS_621491',
  Naics_621492 = 'NAICS_621492',
  Naics_621493 = 'NAICS_621493',
  Naics_621498 = 'NAICS_621498',
  Naics_621511 = 'NAICS_621511',
  Naics_621512 = 'NAICS_621512',
  Naics_621610 = 'NAICS_621610',
  Naics_621910 = 'NAICS_621910',
  Naics_621991 = 'NAICS_621991',
  Naics_621999 = 'NAICS_621999',
  Naics_622110 = 'NAICS_622110',
  Naics_622210 = 'NAICS_622210',
  Naics_622310 = 'NAICS_622310',
  Naics_623110 = 'NAICS_623110',
  Naics_623210 = 'NAICS_623210',
  Naics_623220 = 'NAICS_623220',
  Naics_623311 = 'NAICS_623311',
  Naics_623312 = 'NAICS_623312',
  Naics_623990 = 'NAICS_623990',
  Naics_624110 = 'NAICS_624110',
  Naics_624120 = 'NAICS_624120',
  Naics_624190 = 'NAICS_624190',
  Naics_624210 = 'NAICS_624210',
  Naics_624221 = 'NAICS_624221',
  Naics_624229 = 'NAICS_624229',
  Naics_624230 = 'NAICS_624230',
  Naics_624310 = 'NAICS_624310',
  Naics_624410 = 'NAICS_624410',
  Naics_711110 = 'NAICS_711110',
  Naics_711120 = 'NAICS_711120',
  Naics_711130 = 'NAICS_711130',
  Naics_711190 = 'NAICS_711190',
  Naics_711211 = 'NAICS_711211',
  Naics_711212 = 'NAICS_711212',
  Naics_711219 = 'NAICS_711219',
  Naics_711310 = 'NAICS_711310',
  Naics_711320 = 'NAICS_711320',
  Naics_711410 = 'NAICS_711410',
  Naics_711510 = 'NAICS_711510',
  Naics_712110 = 'NAICS_712110',
  Naics_712120 = 'NAICS_712120',
  Naics_712130 = 'NAICS_712130',
  Naics_712190 = 'NAICS_712190',
  Naics_713110 = 'NAICS_713110',
  Naics_713120 = 'NAICS_713120',
  Naics_713210 = 'NAICS_713210',
  Naics_713290 = 'NAICS_713290',
  Naics_713910 = 'NAICS_713910',
  Naics_713920 = 'NAICS_713920',
  Naics_713930 = 'NAICS_713930',
  Naics_713940 = 'NAICS_713940',
  Naics_713950 = 'NAICS_713950',
  Naics_713990 = 'NAICS_713990',
  Naics_721110 = 'NAICS_721110',
  Naics_721120 = 'NAICS_721120',
  Naics_721191 = 'NAICS_721191',
  Naics_721199 = 'NAICS_721199',
  Naics_721211 = 'NAICS_721211',
  Naics_721214 = 'NAICS_721214',
  Naics_721310 = 'NAICS_721310',
  Naics_722310 = 'NAICS_722310',
  Naics_722320 = 'NAICS_722320',
  Naics_722330 = 'NAICS_722330',
  Naics_722410 = 'NAICS_722410',
  Naics_722511 = 'NAICS_722511',
  Naics_722513 = 'NAICS_722513',
  Naics_722514 = 'NAICS_722514',
  Naics_722515 = 'NAICS_722515',
  Naics_811111 = 'NAICS_811111',
  Naics_811112 = 'NAICS_811112',
  Naics_811113 = 'NAICS_811113',
  Naics_811118 = 'NAICS_811118',
  Naics_811121 = 'NAICS_811121',
  Naics_811122 = 'NAICS_811122',
  Naics_811191 = 'NAICS_811191',
  Naics_811192 = 'NAICS_811192',
  Naics_811198 = 'NAICS_811198',
  Naics_811211 = 'NAICS_811211',
  Naics_811212 = 'NAICS_811212',
  Naics_811213 = 'NAICS_811213',
  Naics_811219 = 'NAICS_811219',
  Naics_811310 = 'NAICS_811310',
  Naics_811411 = 'NAICS_811411',
  Naics_811412 = 'NAICS_811412',
  Naics_811420 = 'NAICS_811420',
  Naics_811430 = 'NAICS_811430',
  Naics_811490 = 'NAICS_811490',
  Naics_812111 = 'NAICS_812111',
  Naics_812112 = 'NAICS_812112',
  Naics_812113 = 'NAICS_812113',
  Naics_812191 = 'NAICS_812191',
  Naics_812199 = 'NAICS_812199',
  Naics_812210 = 'NAICS_812210',
  Naics_812220 = 'NAICS_812220',
  Naics_812310 = 'NAICS_812310',
  Naics_812320 = 'NAICS_812320',
  Naics_812331 = 'NAICS_812331',
  Naics_812332 = 'NAICS_812332',
  Naics_812910 = 'NAICS_812910',
  Naics_812921 = 'NAICS_812921',
  Naics_812922 = 'NAICS_812922',
  Naics_812930 = 'NAICS_812930',
  Naics_812990 = 'NAICS_812990',
  Naics_813110 = 'NAICS_813110',
  Naics_813211 = 'NAICS_813211',
  Naics_813212 = 'NAICS_813212',
  Naics_813219 = 'NAICS_813219',
  Naics_813311 = 'NAICS_813311',
  Naics_813312 = 'NAICS_813312',
  Naics_813319 = 'NAICS_813319',
  Naics_813410 = 'NAICS_813410',
  Naics_813910 = 'NAICS_813910',
  Naics_813920 = 'NAICS_813920',
  Naics_813930 = 'NAICS_813930',
  Naics_813940 = 'NAICS_813940',
  Naics_813990 = 'NAICS_813990',
  Naics_814110 = 'NAICS_814110',
  Naics_921110 = 'NAICS_921110',
  Naics_921120 = 'NAICS_921120',
  Naics_921130 = 'NAICS_921130',
  Naics_921140 = 'NAICS_921140',
  Naics_921150 = 'NAICS_921150',
  Naics_921190 = 'NAICS_921190',
  Naics_922110 = 'NAICS_922110',
  Naics_922120 = 'NAICS_922120',
  Naics_922130 = 'NAICS_922130',
  Naics_922140 = 'NAICS_922140',
  Naics_922150 = 'NAICS_922150',
  Naics_922160 = 'NAICS_922160',
  Naics_922190 = 'NAICS_922190',
  Naics_923110 = 'NAICS_923110',
  Naics_923120 = 'NAICS_923120',
  Naics_923130 = 'NAICS_923130',
  Naics_923140 = 'NAICS_923140',
  Naics_924110 = 'NAICS_924110',
  Naics_924120 = 'NAICS_924120',
  Naics_925110 = 'NAICS_925110',
  Naics_925120 = 'NAICS_925120',
  Naics_926110 = 'NAICS_926110',
  Naics_926120 = 'NAICS_926120',
  Naics_926130 = 'NAICS_926130',
  Naics_926140 = 'NAICS_926140',
  Naics_926150 = 'NAICS_926150',
  Naics_927110 = 'NAICS_927110',
  Naics_928110 = 'NAICS_928110',
  Naics_928120 = 'NAICS_928120',
}

export type NaicsClassCode = {
  __typename?: 'NaicsClassCode';
  label: Scalars['String'];
  naics2017SixDigitCode: Scalars['String'];
  value: Scalars['String'];
};

export type NcciCodeConstant = {
  __typename?: 'NcciCodeConstant';
  cgl?: Maybe<Scalars['String']>;
  ncci?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
};

export type NpnError = {
  __typename?: 'NpnError';
  message: Scalars['String'];
};

export type NumberOfEmployeesOptionsObject = {
  __typename?: 'NumberOfEmployeesOptionsObject';
  label: Scalars['String'];
  state: Scalars['String'];
  value: Scalars['String'];
};

export enum NumberOfTrainsEnum {
  NumberOfTrains_5OrLess = 'NUMBER_OF_TRAINS_5_OR_LESS',
  NumberOfTrains_6_20 = 'NUMBER_OF_TRAINS_6_20',
  NumberOfTrains_21_40 = 'NUMBER_OF_TRAINS_21_40',
  NumberOfTrains_41_60 = 'NUMBER_OF_TRAINS_41_60',
  NumberOfTrains_61_100 = 'NUMBER_OF_TRAINS_61_100',
  NumberOfTrainsOver_100 = 'NUMBER_OF_TRAINS_OVER_100',
}

export type NumberOfTrainsObject = {
  __typename?: 'NumberOfTrainsObject';
  label: Scalars['String'];
  value: NumberOfTrainsEnum | `${NumberOfTrainsEnum}`;
};

export type OcnStatedAmountConstant = {
  __typename?: 'OCNStatedAmountConstant';
  label: Scalars['String'];
  ocnStatedAmt: Scalars['String'];
  value: Scalars['String'];
};

export enum OcpLimitEnum {
  Ilf_25000_50000 = 'ILF_25000_50000',
  Ilf_25000_100000 = 'ILF_25000_100000',
  Ilf_25000_200000 = 'ILF_25000_200000',
  Ilf_25000_300000 = 'ILF_25000_300000',
  Ilf_50000_50000 = 'ILF_50000_50000',
  Ilf_50000_100000 = 'ILF_50000_100000',
  Ilf_50000_200000 = 'ILF_50000_200000',
  Ilf_50000_300000 = 'ILF_50000_300000',
  Ilf_50000_500000 = 'ILF_50000_500000',
  Ilf_50000_600000 = 'ILF_50000_600000',
  Ilf_100000_100000 = 'ILF_100000_100000',
  Ilf_100000_200000 = 'ILF_100000_200000',
  Ilf_100000_300000 = 'ILF_100000_300000',
  Ilf_100000_500000 = 'ILF_100000_500000',
  Ilf_100000_600000 = 'ILF_100000_600000',
  Ilf_100000_1000000 = 'ILF_100000_1000000',
  Ilf_200000_200000 = 'ILF_200000_200000',
  Ilf_200000_300000 = 'ILF_200000_300000',
  Ilf_200000_500000 = 'ILF_200000_500000',
  Ilf_200000_600000 = 'ILF_200000_600000',
  Ilf_200000_1000000 = 'ILF_200000_1000000',
  Ilf_200000_1500000 = 'ILF_200000_1500000',
  Ilf_200000_2000000 = 'ILF_200000_2000000',
  Ilf_250000_250000 = 'ILF_250000_250000',
  Ilf_300000_300000 = 'ILF_300000_300000',
  Ilf_300000_500000 = 'ILF_300000_500000',
  Ilf_300000_600000 = 'ILF_300000_600000',
  Ilf_300000_1000000 = 'ILF_300000_1000000',
  Ilf_300000_1500000 = 'ILF_300000_1500000',
  Ilf_300000_2000000 = 'ILF_300000_2000000',
  Ilf_300000_2500000 = 'ILF_300000_2500000',
  Ilf_300000_3000000 = 'ILF_300000_3000000',
  Ilf_500000_500000 = 'ILF_500000_500000',
  Ilf_500000_600000 = 'ILF_500000_600000',
  Ilf_500000_1000000 = 'ILF_500000_1000000',
  Ilf_500000_1500000 = 'ILF_500000_1500000',
  Ilf_500000_2000000 = 'ILF_500000_2000000',
  Ilf_500000_2500000 = 'ILF_500000_2500000',
  Ilf_500000_3000000 = 'ILF_500000_3000000',
  Ilf_500000_4000000 = 'ILF_500000_4000000',
  Ilf_500000_5000000 = 'ILF_500000_5000000',
  Ilf_750000_750000 = 'ILF_750000_750000',
  Ilf_1000000_1000000 = 'ILF_1000000_1000000',
  Ilf_1000000_1500000 = 'ILF_1000000_1500000',
  Ilf_1000000_2000000 = 'ILF_1000000_2000000',
  Ilf_1000000_2500000 = 'ILF_1000000_2500000',
  Ilf_1000000_3000000 = 'ILF_1000000_3000000',
  Ilf_1000000_4000000 = 'ILF_1000000_4000000',
  Ilf_1000000_5000000 = 'ILF_1000000_5000000',
  Ilf_1000000_10000000 = 'ILF_1000000_10000000',
  Ilf_1500000_1500000 = 'ILF_1500000_1500000',
  Ilf_1500000_2000000 = 'ILF_1500000_2000000',
  Ilf_1500000_2500000 = 'ILF_1500000_2500000',
  Ilf_1500000_3000000 = 'ILF_1500000_3000000',
  Ilf_1500000_4000000 = 'ILF_1500000_4000000',
  Ilf_1500000_5000000 = 'ILF_1500000_5000000',
  Ilf_1500000_10000000 = 'ILF_1500000_10000000',
  Ilf_2000000_2000000 = 'ILF_2000000_2000000',
  Ilf_2000000_2500000 = 'ILF_2000000_2500000',
  Ilf_2000000_3000000 = 'ILF_2000000_3000000',
  Ilf_2000000_4000000 = 'ILF_2000000_4000000',
  Ilf_2000000_5000000 = 'ILF_2000000_5000000',
  Ilf_2000000_10000000 = 'ILF_2000000_10000000',
  Ilf_3000000_3000000 = 'ILF_3000000_3000000',
  Ilf_3000000_4000000 = 'ILF_3000000_4000000',
  Ilf_3000000_5000000 = 'ILF_3000000_5000000',
  Ilf_3000000_10000000 = 'ILF_3000000_10000000',
  Ilf_4000000_4000000 = 'ILF_4000000_4000000',
  Ilf_4000000_5000000 = 'ILF_4000000_5000000',
  Ilf_4000000_10000000 = 'ILF_4000000_10000000',
  Ilf_5000000_5000000 = 'ILF_5000000_5000000',
  Ilf_5000000_10000000 = 'ILF_5000000_10000000',
  Ilf_10000000_10000000 = 'ILF_10000000_10000000',
  Ilf_10000000_20000000 = 'ILF_10000000_20000000',
  Ilf_11000000_11000000 = 'ILF_11000000_11000000',
  Ilf_12000000_12000000 = 'ILF_12000000_12000000',
  Ilf_13000000_13000000 = 'ILF_13000000_13000000',
  Ilf_14000000_14000000 = 'ILF_14000000_14000000',
  Ilf_15000000_15000000 = 'ILF_15000000_15000000',
  Ilf_16000000_16000000 = 'ILF_16000000_16000000',
  Ilf_17000000_17000000 = 'ILF_17000000_17000000',
  Ilf_18000000_18000000 = 'ILF_18000000_18000000',
  Ilf_19000000_19000000 = 'ILF_19000000_19000000',
  Ilf_20000000_20000000 = 'ILF_20000000_20000000',
  Ilf_21000000_21000000 = 'ILF_21000000_21000000',
  Ilf_22000000_22000000 = 'ILF_22000000_22000000',
  Ilf_23000000_23000000 = 'ILF_23000000_23000000',
  Ilf_24000000_24000000 = 'ILF_24000000_24000000',
  Ilf_25000000_25000000 = 'ILF_25000000_25000000',
  Ilf_26000000_26000000 = 'ILF_26000000_26000000',
  Ilf_27000000_27000000 = 'ILF_27000000_27000000',
  Ilf_28000000_28000000 = 'ILF_28000000_28000000',
  Ilf_29000000_29000000 = 'ILF_29000000_29000000',
  Ilf_30000000_30000000 = 'ILF_30000000_30000000',
  Ilf_31000000_31000000 = 'ILF_31000000_31000000',
  Ilf_32000000_32000000 = 'ILF_32000000_32000000',
  Ilf_33000000_33000000 = 'ILF_33000000_33000000',
  Ilf_34000000_34000000 = 'ILF_34000000_34000000',
  Ilf_35000000_35000000 = 'ILF_35000000_35000000',
  Ilf_36000000_36000000 = 'ILF_36000000_36000000',
  Ilf_37000000_37000000 = 'ILF_37000000_37000000',
  Ilf_38000000_38000000 = 'ILF_38000000_38000000',
  Ilf_39000000_39000000 = 'ILF_39000000_39000000',
  Ilf_40000000_40000000 = 'ILF_40000000_40000000',
  Ilf_41000000_41000000 = 'ILF_41000000_41000000',
  Ilf_42000000_42000000 = 'ILF_42000000_42000000',
  Ilf_43000000_43000000 = 'ILF_43000000_43000000',
  Ilf_44000000_44000000 = 'ILF_44000000_44000000',
  Ilf_45000000_45000000 = 'ILF_45000000_45000000',
  Ilf_46000000_46000000 = 'ILF_46000000_46000000',
  Ilf_47000000_47000000 = 'ILF_47000000_47000000',
  Ilf_48000000_48000000 = 'ILF_48000000_48000000',
  Ilf_49000000_49000000 = 'ILF_49000000_49000000',
  Ilf_50000000_50000000 = 'ILF_50000000_50000000',
  Ilf_51000000_51000000 = 'ILF_51000000_51000000',
  Ilf_52000000_52000000 = 'ILF_52000000_52000000',
  Ilf_53000000_53000000 = 'ILF_53000000_53000000',
  Ilf_54000000_54000000 = 'ILF_54000000_54000000',
  Ilf_55000000_55000000 = 'ILF_55000000_55000000',
  Ilf_56000000_56000000 = 'ILF_56000000_56000000',
  Ilf_57000000_57000000 = 'ILF_57000000_57000000',
  Ilf_58000000_58000000 = 'ILF_58000000_58000000',
  Ilf_59000000_59000000 = 'ILF_59000000_59000000',
  Ilf_60000000_60000000 = 'ILF_60000000_60000000',
  Ilf_61000000_61000000 = 'ILF_61000000_61000000',
  Ilf_62000000_62000000 = 'ILF_62000000_62000000',
  Ilf_63000000_63000000 = 'ILF_63000000_63000000',
  Ilf_64000000_64000000 = 'ILF_64000000_64000000',
  Ilf_65000000_65000000 = 'ILF_65000000_65000000',
  Ilf_66000000_66000000 = 'ILF_66000000_66000000',
  Ilf_67000000_67000000 = 'ILF_67000000_67000000',
  Ilf_68000000_68000000 = 'ILF_68000000_68000000',
  Ilf_69000000_69000000 = 'ILF_69000000_69000000',
  Ilf_70000000_70000000 = 'ILF_70000000_70000000',
  Ilf_71000000_71000000 = 'ILF_71000000_71000000',
  Ilf_72000000_72000000 = 'ILF_72000000_72000000',
  Ilf_73000000_73000000 = 'ILF_73000000_73000000',
  Ilf_74000000_74000000 = 'ILF_74000000_74000000',
  Ilf_75000000_75000000 = 'ILF_75000000_75000000',
  Ilf_76000000_76000000 = 'ILF_76000000_76000000',
  Ilf_77000000_77000000 = 'ILF_77000000_77000000',
  Ilf_78000000_78000000 = 'ILF_78000000_78000000',
  Ilf_79000000_79000000 = 'ILF_79000000_79000000',
  Ilf_80000000_80000000 = 'ILF_80000000_80000000',
  Ilf_81000000_81000000 = 'ILF_81000000_81000000',
  Ilf_82000000_82000000 = 'ILF_82000000_82000000',
  Ilf_83000000_83000000 = 'ILF_83000000_83000000',
  Ilf_84000000_84000000 = 'ILF_84000000_84000000',
  Ilf_85000000_85000000 = 'ILF_85000000_85000000',
  Ilf_86000000_86000000 = 'ILF_86000000_86000000',
  Ilf_87000000_87000000 = 'ILF_87000000_87000000',
  Ilf_88000000_88000000 = 'ILF_88000000_88000000',
  Ilf_89000000_89000000 = 'ILF_89000000_89000000',
  Ilf_90000000_90000000 = 'ILF_90000000_90000000',
  Ilf_91000000_91000000 = 'ILF_91000000_91000000',
  Ilf_92000000_92000000 = 'ILF_92000000_92000000',
  Ilf_93000000_93000000 = 'ILF_93000000_93000000',
  Ilf_94000000_94000000 = 'ILF_94000000_94000000',
  Ilf_95000000_95000000 = 'ILF_95000000_95000000',
  Ilf_96000000_96000000 = 'ILF_96000000_96000000',
  Ilf_97000000_97000000 = 'ILF_97000000_97000000',
  Ilf_98000000_98000000 = 'ILF_98000000_98000000',
  Ilf_99000000_99000000 = 'ILF_99000000_99000000',
  Ilf_100000000_100000000 = 'ILF_100000000_100000000',
  Ilf_101000000_101000000 = 'ILF_101000000_101000000',
  Ilf_102000000_102000000 = 'ILF_102000000_102000000',
  Ilf_103000000_103000000 = 'ILF_103000000_103000000',
  Ilf_104000000_104000000 = 'ILF_104000000_104000000',
  Ilf_105000000_105000000 = 'ILF_105000000_105000000',
  Ilf_106000000_106000000 = 'ILF_106000000_106000000',
  Ilf_107000000_107000000 = 'ILF_107000000_107000000',
  Ilf_108000000_108000000 = 'ILF_108000000_108000000',
  Ilf_109000000_109000000 = 'ILF_109000000_109000000',
  Ilf_110000000_110000000 = 'ILF_110000000_110000000',
  Ilf_115000000_115000000 = 'ILF_115000000_115000000',
  Ilf_120000000_120000000 = 'ILF_120000000_120000000',
  Ilf_125000000_125000000 = 'ILF_125000000_125000000',
  Ilf_130000000_130000000 = 'ILF_130000000_130000000',
  Ilf_135000000_135000000 = 'ILF_135000000_135000000',
  Ilf_140000000_140000000 = 'ILF_140000000_140000000',
  Ilf_145000000_145000000 = 'ILF_145000000_145000000',
  Ilf_150000000_150000000 = 'ILF_150000000_150000000',
  Ilf_155000000_155000000 = 'ILF_155000000_155000000',
  Ilf_160000000_160000000 = 'ILF_160000000_160000000',
  Ilf_165000000_165000000 = 'ILF_165000000_165000000',
  Ilf_170000000_170000000 = 'ILF_170000000_170000000',
  Ilf_175000000_175000000 = 'ILF_175000000_175000000',
  Ilf_180000000_180000000 = 'ILF_180000000_180000000',
  Ilf_185000000_185000000 = 'ILF_185000000_185000000',
  Ilf_190000000_190000000 = 'ILF_190000000_190000000',
  Ilf_195000000_195000000 = 'ILF_195000000_195000000',
  Ilf_200000000_200000000 = 'ILF_200000000_200000000',
  Ilf_225000000_225000000 = 'ILF_225000000_225000000',
  Ilf_250000000_250000000 = 'ILF_250000000_250000000',
  Ilf_275000000_275000000 = 'ILF_275000000_275000000',
  Ilf_300000000_300000000 = 'ILF_300000000_300000000',
  Ilf_325000000_325000000 = 'ILF_325000000_325000000',
  Ilf_350000000_350000000 = 'ILF_350000000_350000000',
  Ilf_375000000_375000000 = 'ILF_375000000_375000000',
  Ilf_400000000_400000000 = 'ILF_400000000_400000000',
  Ilf_425000000_425000000 = 'ILF_425000000_425000000',
  Ilf_450000000_450000000 = 'ILF_450000000_450000000',
  Ilf_475000000_475000000 = 'ILF_475000000_475000000',
  Ilf_500000000_500000000 = 'ILF_500000000_500000000',
  Ilf_525000000_525000000 = 'ILF_525000000_525000000',
  Ilf_550000000_550000000 = 'ILF_550000000_550000000',
  Ilf_575000000_575000000 = 'ILF_575000000_575000000',
  Ilf_600000000_600000000 = 'ILF_600000000_600000000',
  Ilf_625000000_625000000 = 'ILF_625000000_625000000',
  Ilf_650000000_650000000 = 'ILF_650000000_650000000',
  Ilf_675000000_675000000 = 'ILF_675000000_675000000',
  Ilf_700000000_700000000 = 'ILF_700000000_700000000',
  Ilf_725000000_725000000 = 'ILF_725000000_725000000',
  Ilf_750000000_750000000 = 'ILF_750000000_750000000',
  Ilf_775000000_775000000 = 'ILF_775000000_775000000',
  Ilf_800000000_800000000 = 'ILF_800000000_800000000',
  Ilf_825000000_825000000 = 'ILF_825000000_825000000',
  Ilf_850000000_850000000 = 'ILF_850000000_850000000',
  Ilf_875000000_875000000 = 'ILF_875000000_875000000',
  Ilf_900000000_900000000 = 'ILF_900000000_900000000',
  Ilf_925000000_925000000 = 'ILF_925000000_925000000',
  Ilf_950000000_950000000 = 'ILF_950000000_950000000',
  Ilf_975000000_975000000 = 'ILF_975000000_975000000',
  Ilf_1000000000_1000000000 = 'ILF_1000000000_1000000000',
}

export type OcpLimitObject = {
  __typename?: 'OcpLimitObject';
  label: Scalars['String'];
  value: OcpLimitEnum | `${OcpLimitEnum}`;
};

export type OcpOptionsObject = {
  __typename?: 'OcpOptionsObject';
  ocpLimit: Array<OcpLimitObject>;
  typeOfOperations: Array<OcpTypeOfOperationsObject>;
};

export enum OcpTypeOfOperationsEnum {
  Iso_16291 = 'ISO_16291',
  Iso_16292 = 'ISO_16292',
  Iso_27111 = 'ISO_27111',
  Iso_27112 = 'ISO_27112',
  Iso_40011 = 'ISO_40011',
  Iso_40012 = 'ISO_40012',
  Iso_40013 = 'ISO_40013',
  Iso_40014 = 'ISO_40014',
  Iso_91181 = 'ISO_91181',
  Iso_93040 = 'ISO_93040',
  Iso_93161 = 'ISO_93161',
  Iso_93163 = 'ISO_93163',
}

export type OcpTypeOfOperationsObject = {
  __typename?: 'OcpTypeOfOperationsObject';
  label: Scalars['String'];
  value: OcpTypeOfOperationsEnum | `${OcpTypeOfOperationsEnum}`;
};

export enum OfacStatus {
  Cleared = 'CLEARED',
  Failed = 'FAILED',
  Pending = 'PENDING',
}

export type Organization = {
  __typename?: 'Organization';
  childrenOrganizations: Array<Organization>;
  city?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  einNumber?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  logoUrl?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  npn?: Maybe<Scalars['String']>;
  organizationLocations: Array<OrganizationLocation>;
  parentOrganization?: Maybe<Organization>;
  parentOrganizationId?: Maybe<Scalars['ID']>;
  postalCode?: Maybe<Scalars['String']>;
  salesforceRecordId?: Maybe<Scalars['String']>;
  salesforceRecordUrl?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  street2?: Maybe<Scalars['String']>;
  type: OrganizationType | `${OrganizationType}`;
  updatedAt: Scalars['DateTime'];
};

export type OrganizationIntegration = {
  __typename?: 'OrganizationIntegration';
  id: Scalars['ID'];
  provider: Scalars['String'];
  providerOrganizationId?: Maybe<Scalars['String']>;
  providerOrganizationName?: Maybe<Scalars['String']>;
  providerUserId: Scalars['String'];
};

export type OrganizationLocation = {
  __typename?: 'OrganizationLocation';
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  assignee?: Maybe<User>;
  assigneeId?: Maybe<Scalars['ID']>;
  city?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  excessSurplusLinesPaidByPartner?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  organization: Organization;
  organizationId: Scalars['ID'];
  organizationMembers: Array<OrganizationMember>;
  salesforceRecordId?: Maybe<Scalars['String']>;
  salesforceRecordUrl?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  zipcode?: Maybe<Scalars['String']>;
};

export type OrganizationLocationMutationPayload = {
  __typename?: 'OrganizationLocationMutationPayload';
  organizationLocation: OrganizationLocation;
};

export type OrganizationMember = {
  __typename?: 'OrganizationMember';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  organization: Organization;
  policies: Array<Policy>;
  role: OrganizationMemberRole | `${OrganizationMemberRole}`;
  updatedAt: Scalars['DateTime'];
  user: User;
};

export enum OrganizationMemberRole {
  Admin = 'ADMIN',
  Member = 'MEMBER',
}

export type OrganizationMutationPayload = {
  __typename?: 'OrganizationMutationPayload';
  organization: Organization;
};

export enum OrganizationType {
  Applicant = 'APPLICANT',
  Brokerage = 'BROKERAGE',
  Carrier = 'CARRIER',
  Shepherd = 'SHEPHERD',
}

export type OrganizationUserMutationPayload = {
  __typename?: 'OrganizationUserMutationPayload';
  organizationMember: OrganizationMember;
  user: User;
};

export type OverallAggregateCapTypeOptionsObject = {
  __typename?: 'OverallAggregateCapTypeOptionsObject';
  label: Scalars['String'];
  value: Scalars['String'];
};

export type PaginatedPolicyInput = {
  assigneeIdSet?: InputMaybe<Array<Scalars['ID']>>;
  brokerIdSet?: InputMaybe<Array<Scalars['ID']>>;
  brokerageIdSet?: InputMaybe<Array<Scalars['ID']>>;
  productSet?: InputMaybe<Array<PolicyProduct | `${PolicyProduct}`>>;
  skip: Scalars['Int'];
  sortDate: SortOrder | `${SortOrder}`;
  sortName?: InputMaybe<SortOrder | `${SortOrder}`>;
  sortProduct?: InputMaybe<SortOrder | `${SortOrder}`>;
  take: Scalars['Int'];
};

export type Pagination = {
  __typename?: 'Pagination';
  currentPage: Scalars['Int'];
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  totalCount: Scalars['Int'];
  totalPages: Scalars['Int'];
};

export type PartialFormGroupObject = {
  __typename?: 'PartialFormGroupObject';
  createdAt: Scalars['DateTime'];
  formVendor?: Maybe<FormVendor | `${FormVendor}`>;
  id: Scalars['ID'];
  name: Scalars['String'];
  ordering: Array<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type PartialFormPdfObject = {
  __typename?: 'PartialFormPdfObject';
  active: Scalars['Boolean'];
  category?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  dataFillMap?: Maybe<Array<DataFillObject>>;
  defaultStatus?: Maybe<FormStatus | `${FormStatus}`>;
  fileType?: Maybe<FileTypeObject | `${FileTypeObject}`>;
  formGroupId?: Maybe<Scalars['String']>;
  formNumber: Scalars['String'];
  formType: Scalars['String'];
  hasEndorsementNumber: Scalars['Boolean'];
  id: Scalars['ID'];
  notes?: Maybe<Scalars['String']>;
  tria: TriaOption | `${TriaOption}`;
  updatedAt: Scalars['DateTime'];
  uploaded: Scalars['Boolean'];
};

export type PayrollBreakdown = {
  __typename?: 'PayrollBreakdown';
  isoClass: Scalars['String'];
  percentage: Scalars['Float'];
};

export type PayrollInput = {
  exposureBase: Scalars['String'];
  isoClass: Scalars['String'];
  ncciClass?: InputMaybe<Scalars['String']>;
  payroll: Scalars['Float'];
  state: State | `${State}`;
  territory: Scalars['String'];
};

export type PayrollObject = {
  __typename?: 'PayrollObject';
  exposureBase: Scalars['String'];
  id: Scalars['ID'];
  isoClass: Scalars['String'];
  ncciClass?: Maybe<Scalars['String']>;
  payroll: Scalars['Float'];
  state: Scalars['String'];
  territory: Scalars['String'];
};

export enum PdfType {
  AutoPacket = 'AUTO_PACKET',
  Binder = 'BINDER',
  Policy = 'POLICY',
  PolicyGenerated = 'POLICY_GENERATED',
  PolicySpecimen = 'POLICY_SPECIMEN',
  Quote = 'QUOTE',
}

export type PeerCohortData = {
  __typename?: 'PeerCohortData';
  annualRevenue: Scalars['Float'];
  annualRevenueComparison: InsightComparison | `${InsightComparison}`;
  annualRevenueComparisonSentiment: ComparisonSentiment | `${ComparisonSentiment}`;
  contractorType: ContractorType | `${ContractorType}`;
  fleetComparison: InsightComparison | `${InsightComparison}`;
  fleetComparisonSentiment: ComparisonSentiment | `${ComparisonSentiment}`;
  heavyVehiclesComparison: InsightComparison | `${InsightComparison}`;
  heavyVehiclesComparisonSentiment: ComparisonSentiment | `${ComparisonSentiment}`;
  jurisdictionRisk: JurisdictionRiskType | `${JurisdictionRiskType}`;
  numberOfContractors: Scalars['Float'];
  payroll: Scalars['Float'];
  payrollComparison: InsightComparison | `${InsightComparison}`;
  payrollComparisonSentiment: ComparisonSentiment | `${ComparisonSentiment}`;
  revenue: RevenueType | `${RevenueType}`;
};

export type PipOptionsObject = {
  __typename?: 'PipOptionsObject';
  label: Scalars['String'];
  state: State | `${State}`;
  value: Scalars['String'];
  vehicleType: VehicleTypesEnum | `${VehicleTypesEnum}`;
};

export type Policy = {
  __typename?: 'Policy';
  additionalPolicyNumbers?: Maybe<Array<AdditionalPolicyNumber>>;
  assignee?: Maybe<User>;
  assigneeId?: Maybe<Scalars['ID']>;
  brokerOrganizationMember?: Maybe<OrganizationMember>;
  brokerOrganizationMemberId?: Maybe<Scalars['ID']>;
  brokerage: Organization;
  brokerageId: Scalars['ID'];
  businessType?: Maybe<BusinessType | `${BusinessType}`>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  effectiveDate: Scalars['Date'];
  expirationDate: Scalars['Date'];
  expiringEffectiveDate: Scalars['Date'];
  expiringPolicyNumber?: Maybe<Scalars['String']>;
  files?: Maybe<Array<File>>;
  id: Scalars['ID'];
  insuranceProduct: InsuranceProduct;
  licensedUnderwriter?: Maybe<User>;
  licensedUnderwriterId?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  namedInsureds: Array<Scalars['String']>;
  policyNumber: Scalars['String'];
  policyholder: Policyholder;
  policyholderId: Scalars['ID'];
  primaryQuote?: Maybe<Quote>;
  product: PolicyProduct | `${PolicyProduct}`;
  productLabel: Scalars['String'];
  quotes: Array<Quote>;
  salesforceRecordUrl?: Maybe<Scalars['String']>;
  submissionAt?: Maybe<Scalars['DateTime']>;
  updatedAt: Scalars['DateTime'];
};

export type PolicyChangeEndorsement = {
  __typename?: 'PolicyChangeEndorsement';
  brokerCommission: Scalars['Float'];
  brokerCommissionPercentage: Scalars['Float'];
  effectiveDate: Scalars['Date'];
  employersLiabilityFee: Scalars['Float'];
  employersLiabilityFeePercentage: Scalars['Float'];
  finalEndorsementPremium: Scalars['Float'];
  frontingFee: Scalars['Float'];
  frontingFeePercentage: Scalars['Float'];
  id: Scalars['ID'];
  issuedDate?: Maybe<Scalars['Date']>;
  name: Scalars['String'];
  premium: Scalars['Float'];
  profitLoadFee: Scalars['Float'];
  profitLoadFeePercentage: Scalars['Float'];
  quoteId: Scalars['ID'];
  shepherdMgaFee: Scalars['Float'];
  shepherdMgaFeePercentage: Scalars['Float'];
  stampingFeeRate: Scalars['Float'];
  stampingFlatFee: Scalars['Float'];
  surplusLinesStampingFee: Scalars['Float'];
  surplusLinesTaxRate: Scalars['Float'];
  surplusLinesTaxes: Scalars['Float'];
  totalExpenseLoadPercentage: Scalars['Float'];
  totalTax: Scalars['Float'];
  tpaFee: Scalars['Float'];
  tpaFeePercentage: Scalars['Float'];
  triaFee: Scalars['Float'];
  triaFeePercentage: Scalars['Float'];
};

export type PolicyClearanceResultObject = {
  __typename?: 'PolicyClearanceResultObject';
  address1: Scalars['String'];
  address2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  insuredName: Scalars['String'];
  policyId: Scalars['ID'];
  state: Scalars['String'];
  zipCode: Scalars['String'];
};

export type PolicyHoldersV3Input = {
  archivedFilter?: InputMaybe<Scalars['Boolean']>;
  createdByIdFilter?: InputMaybe<Array<Scalars['ID']>>;
  hasAutoDeskFilter?: InputMaybe<Scalars['Boolean']>;
  hasOpenSpaceFilter?: InputMaybe<Scalars['Boolean']>;
  hasProcoreFilter?: InputMaybe<Scalars['Boolean']>;
  ofacStatusFilter?: InputMaybe<Array<OfacStatus | `${OfacStatus}`>>;
  skip: Scalars['Int'];
  sortCreatedAt?: InputMaybe<SortOrder | `${SortOrder}`>;
  sortName?: InputMaybe<SortOrder | `${SortOrder}`>;
  sortState?: InputMaybe<SortOrder | `${SortOrder}`>;
  sortUpdatedAt?: InputMaybe<SortOrder | `${SortOrder}`>;
  stateSetFilter?: InputMaybe<Array<Scalars['String']>>;
  take: Scalars['Int'];
};

export type PolicyLimitPolicyAggregateLimitIlfPremOpsOptionsObject = {
  __typename?: 'PolicyLimitPolicyAggregateLimitILFPremOpsOptionsObject';
  policyAggregate: Scalars['String'];
  policyAggregateLabel: Scalars['String'];
  policyLimit: Scalars['String'];
  policyLimitLabel: Scalars['String'];
};

export type PolicyNumbersForQuote = {
  __typename?: 'PolicyNumbersForQuote';
  autoLiability?: Maybe<Scalars['String']>;
  generalLiability: Scalars['String'];
};

export type PolicyObjectV2 = {
  __typename?: 'PolicyObjectV2';
  PrimaryQuote?: Maybe<PrimaryQuote>;
  assignee?: Maybe<AssigneeObject>;
  assigneeId?: Maybe<Scalars['ID']>;
  broker?: Maybe<Scalars['String']>;
  brokerOrganizationMemberId?: Maybe<Scalars['ID']>;
  brokerage?: Maybe<Scalars['String']>;
  brokerageId?: Maybe<Scalars['ID']>;
  effectiveDate: Scalars['Date'];
  namedInsured?: Maybe<Scalars['String']>;
  policyHolderId: Scalars['ID'];
  policyId: Scalars['ID'];
  policyName: Scalars['String'];
  product: Scalars['String'];
  productLabel?: Maybe<Scalars['String']>;
};

export enum PolicyProduct {
  ExcessPractice = 'EXCESS_PRACTICE',
  ExcessProject = 'EXCESS_PROJECT',
  PrimaryOwnersAndContractorsProtective = 'PRIMARY_OWNERS_AND_CONTRACTORS_PROTECTIVE',
  PrimaryPractice = 'PRIMARY_PRACTICE',
  PrimaryProject = 'PRIMARY_PROJECT',
  PrimaryRailroad = 'PRIMARY_RAILROAD',
}

export type Policyholder = {
  __typename?: 'Policyholder';
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  caseStudiesGenerated: Array<CaseStudiesGeneratedOutput>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  createdBy: User;
  deletedAt?: Maybe<Scalars['DateTime']>;
  fein?: Maybe<Scalars['String']>;
  hasAutodesk: Scalars['Boolean'];
  hasOpenspace: Scalars['Boolean'];
  hasProcore: Scalars['Boolean'];
  hasSamsara: Scalars['Boolean'];
  id: Scalars['ID'];
  insuredContactEmail?: Maybe<Scalars['String']>;
  insuredContactName?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  ofacStatus: OfacStatus | `${OfacStatus}`;
  procoreUsage?: Maybe<ProcoreUsage>;
  slackChannelId?: Maybe<Scalars['String']>;
  state: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  website?: Maybe<Scalars['String']>;
  zipcode?: Maybe<Scalars['String']>;
};

export type PolicyholdersPaginated = {
  __typename?: 'PolicyholdersPaginated';
  pagination: Pagination;
  policyholders: Array<Policyholder>;
};

export type PpiOptionsObject = {
  __typename?: 'PpiOptionsObject';
  label: Scalars['String'];
  state: State | `${State}`;
  value: Scalars['String'];
  vehicleType: VehicleTypesEnum | `${VehicleTypesEnum}`;
};

export enum PremiumClass {
  Preferred = 'PREFERRED',
  Specialized = 'SPECIALIZED',
}

export type PremiumDrivers = {
  __typename?: 'PremiumDrivers';
  isoClass: Scalars['String'];
  payroll: Scalars['Float'];
  pcoLossCosts: Scalars['Float'];
  premiumLossCosts: Scalars['Float'];
  state: Scalars['String'];
};

export type PresignedUrlObject = {
  __typename?: 'PresignedUrlObject';
  url?: Maybe<Scalars['String']>;
};

export type PresignedUrlObjectWithPolicyData = {
  __typename?: 'PresignedUrlObjectWithPolicyData';
  policyNumber?: Maybe<Scalars['String']>;
  policyholderName?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type Pricing = {
  __typename?: 'Pricing';
  lineItems: Array<PricingLineItem>;
};

export type PricingLineItem = {
  __typename?: 'PricingLineItem';
  children?: Maybe<Array<PricingLineItem>>;
  label: Scalars['String'];
  table?: Maybe<PricingLineItemTable>;
  tooltip?: Maybe<Scalars['String']>;
  type: PricingLineItemType | `${PricingLineItemType}`;
  value?: Maybe<Scalars['JSON']>;
  valueFormatted: Scalars['String'];
};

export type PricingLineItemMatrix = {
  __typename?: 'PricingLineItemMatrix';
  children?: Maybe<Array<PricingLineItemMatrix>>;
  label: Scalars['String'];
  table?: Maybe<PricingLineItemTable>;
  tooltip?: Maybe<Scalars['String']>;
  type: PricingLineItemType | `${PricingLineItemType}`;
  values?: Maybe<Array<PricingLineItemMatrixValueObject>>;
};

export type PricingLineItemMatrixValueObject = {
  __typename?: 'PricingLineItemMatrixValueObject';
  key: Scalars['String'];
  type: PricingLineItemType | `${PricingLineItemType}`;
  value: Scalars['JSON'];
  valueFormatted: Scalars['String'];
};

export type PricingLineItemTable = {
  __typename?: 'PricingLineItemTable';
  columns: Array<PricingLineItemTableColumn>;
  data: Array<Scalars['JSONObject']>;
};

export type PricingLineItemTableColumn = {
  __typename?: 'PricingLineItemTableColumn';
  dataIndex: Scalars['String'];
  summary?: Maybe<Scalars['String']>;
  summaryFormatted?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  type?: Maybe<PricingLineItemType | `${PricingLineItemType}`>;
};

export enum PricingLineItemType {
  Dollar = 'DOLLAR',
  Float = 'FLOAT',
  Integer = 'INTEGER',
  Percent = 'PERCENT',
  Text = 'TEXT',
  Tier = 'TIER',
}

export type PricingMatrix = {
  __typename?: 'PricingMatrix';
  columns: Array<ColumnsMatrixSchemaObject>;
  lineItems: Array<PricingLineItemMatrix>;
};

export type PrimaryAutoCostOfHire = {
  __typename?: 'PrimaryAutoCostOfHire';
  costOfHire: Scalars['Float'];
  id: Scalars['ID'];
  state: Scalars['String'];
};

export type PrimaryAutoCostOfHireInput = {
  costOfHire: Scalars['Float'];
  state: State | `${State}`;
};

export type PrimaryAutoCoverageOptions = {
  __typename?: 'PrimaryAutoCoverageOptions';
  biOptionPPT?: Maybe<Scalars['String']>;
  biOptionTTT?: Maybe<Scalars['String']>;
  biOptionTrailer?: Maybe<Scalars['String']>;
  compOptionPPT?: Maybe<Scalars['String']>;
  compOptionTTT?: Maybe<Scalars['String']>;
  compOptionTrailer?: Maybe<Scalars['String']>;
  exposureId: Scalars['String'];
  id: Scalars['String'];
  isProject: Scalars['Boolean'];
  medPayOptionPPT?: Maybe<Scalars['String']>;
  medPayOptionTTT?: Maybe<Scalars['String']>;
  medPayOptionTrailer?: Maybe<Scalars['String']>;
  pipOptionPPT?: Maybe<Scalars['String']>;
  pipOptionTTT?: Maybe<Scalars['String']>;
  pipOptionTrailer?: Maybe<Scalars['String']>;
  ppiOptionMiOnly?: Maybe<Scalars['String']>;
  specCauseOfLossPPT?: Maybe<Scalars['String']>;
  specCauseOfLossTTT?: Maybe<Scalars['String']>;
  specCauseOfLossTrailer?: Maybe<Scalars['String']>;
  state: Scalars['String'];
  uimbiLimit?: Maybe<Scalars['String']>;
  uimbiOption?: Maybe<Scalars['String']>;
  umbiLimit?: Maybe<Scalars['String']>;
  umbiOption?: Maybe<Scalars['String']>;
  umpdLimit?: Maybe<Scalars['String']>;
  umpdOption?: Maybe<Scalars['String']>;
  vehicleTypeFlags: CoverageOptionVehicleTypes;
};

export type PrimaryAutoCoverageOptionsInput = {
  biOptionPPT?: InputMaybe<Scalars['String']>;
  biOptionTTT?: InputMaybe<Scalars['String']>;
  biOptionTrailer?: InputMaybe<Scalars['String']>;
  compOptionPPT?: InputMaybe<Scalars['String']>;
  compOptionTTT?: InputMaybe<Scalars['String']>;
  compOptionTrailer?: InputMaybe<Scalars['String']>;
  medPayOptionPPT?: InputMaybe<Scalars['String']>;
  medPayOptionTTT?: InputMaybe<Scalars['String']>;
  medPayOptionTrailer?: InputMaybe<Scalars['String']>;
  pipOptionPPT?: InputMaybe<Scalars['String']>;
  pipOptionTTT?: InputMaybe<Scalars['String']>;
  pipOptionTrailer?: InputMaybe<Scalars['String']>;
  ppiOptionMiOnly?: InputMaybe<Scalars['String']>;
  specCauseOfLossPPT?: InputMaybe<Scalars['String']>;
  specCauseOfLossTTT?: InputMaybe<Scalars['String']>;
  specCauseOfLossTrailer?: InputMaybe<Scalars['String']>;
  state: Scalars['String'];
  uimbiLimit?: InputMaybe<Scalars['String']>;
  uimbiOption?: InputMaybe<Scalars['String']>;
  umbiLimit?: InputMaybe<Scalars['String']>;
  umbiOption?: InputMaybe<Scalars['String']>;
  umpdLimit?: InputMaybe<Scalars['String']>;
  umpdOption?: InputMaybe<Scalars['String']>;
};

export type PrimaryAutoExposure = {
  __typename?: 'PrimaryAutoExposure';
  attachmentPoint?: Maybe<Scalars['Float']>;
  autoHackingCoverageOption?: Maybe<
    AutoHackingCoverageOptionEnum | `${AutoHackingCoverageOptionEnum}`
  >;
  autoLoanLeaseGapCoverage?: Maybe<Scalars['Boolean']>;
  collisionDeductible?: Maybe<
    CollisionDeductibleOptionsObjectValueEnum | `${CollisionDeductibleOptionsObjectValueEnum}`
  >;
  collisionDeductibleCostOfHireBasis?: Maybe<
    | CollisionDeductibleCostOfHireBasisObjectValueEnum
    | `${CollisionDeductibleCostOfHireBasisObjectValueEnum}`
  >;
  combinedSingleLimit?: Maybe<
    CombinedSingleLimitOptionsObjectValueEnum | `${CombinedSingleLimitOptionsObjectValueEnum}`
  >;
  comprehensiveDeductible?: Maybe<
    | ComprehensiveDeductibleOptionsObjectValueEnum
    | `${ComprehensiveDeductibleOptionsObjectValueEnum}`
  >;
  comprehensiveDeductibleCostOfHireBasis?: Maybe<
    | ComprehensiveDeductibleCostOfHireBasisObjectValueEnum
    | `${ComprehensiveDeductibleCostOfHireBasisObjectValueEnum}`
  >;
  costOfHires?: Maybe<Array<PrimaryAutoCostOfHire>>;
  createdAt: Scalars['DateTime'];
  defenseExpenseTreatment?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  driveOtherCar?: Maybe<Scalars['Boolean']>;
  extendCoverageToIndividualEmployees?: Maybe<Scalars['Boolean']>;
  extendToCoverOwner?: Maybe<Scalars['Boolean']>;
  finalSummarySelectedLossRate?: Maybe<Scalars['Decimal']>;
  finalSummarySelectedLossRateType?: Maybe<
    LossRateSelectionTypeEnum | `${LossRateSelectionTypeEnum}`
  >;
  fleetExposure?: Maybe<Array<PrimaryAutoVehicleObject>>;
  hiredAutosCoverage?: Maybe<HiredAutosCoverageEnum | `${HiredAutosCoverageEnum}`>;
  id: Scalars['ID'];
  individualNamedInsured?: Maybe<Scalars['Boolean']>;
  isCredibilityCapEnabled: Scalars['Boolean'];
  isExperienceRatedPathEnabledAutoLiability: Scalars['Boolean'];
  isExperienceRatedPathEnabledAutoPhysDam: Scalars['Boolean'];
  largeLossRunsLiability?: Maybe<Array<PrimaryAutoLiabilityLargeLossRunObject>>;
  largeLossRunsPhysDam?: Maybe<Array<PrimaryAutoPhysDamLargeLossRunObject>>;
  liabilityDeductible?: Maybe<
    LiablityDeductibleOptionsObjectValueEnum | `${LiablityDeductibleOptionsObjectValueEnum}`
  >;
  lossRunsLiability?: Maybe<Array<PrimaryAutoLiabilityLossRunObject>>;
  lossRunsPhysDam?: Maybe<Array<PrimaryAutoPhysDamLossRunObject>>;
  naicsClassCode?: Maybe<Scalars['String']>;
  nonOwnershipLiability?: Maybe<Scalars['Boolean']>;
  numberOfEmployees?: Maybe<Scalars['String']>;
  numberOfIndividualNamedInsureds?: Maybe<Scalars['Float']>;
  numberOfPartners?: Maybe<Scalars['Float']>;
  partnershipOrLlc?: Maybe<Scalars['Boolean']>;
  pollutionLiability?: Maybe<Scalars['Boolean']>;
  pptCollisionDeductible: Scalars['Boolean'];
  pptComprehensiveDeductible: Scalars['Boolean'];
  primaryAutoLiabilityIndividualRiskRatingSurvey?: Maybe<PrimaryAutoLiabilityIndividualRiskRatingSurveyObject>;
  quoteId: Scalars['ID'];
  rentalReimbursementCoverage: Scalars['Boolean'];
  rentalReimbursementDays?: Maybe<
    RentalReimbursementDaysObjectValueEnum | `${RentalReimbursementDaysObjectValueEnum}`
  >;
  rentalReimbursementLimit?: Maybe<
    RentalReimbursementObjectValueEnum | `${RentalReimbursementObjectValueEnum}`
  >;
  selectedFrequency?: Maybe<Scalars['Decimal']>;
  selectedFrequencyType?: Maybe<LossRateSelectionTypeEnum | `${LossRateSelectionTypeEnum}`>;
  selectedLossRate?: Maybe<Scalars['Decimal']>;
  selectedLossRateType?: Maybe<LossRateSelectionTypeEnum | `${LossRateSelectionTypeEnum}`>;
  subjectToLossAggregate?: Maybe<Scalars['Boolean']>;
  towAndLaborCost?: Maybe<TowAndLaborCostObjectValueEnum | `${TowAndLaborCostObjectValueEnum}`>;
  towAndLaborCostCoverage: Scalars['Boolean'];
  tttCollisionDeductible: Scalars['Boolean'];
  tttComprehensiveDeductible: Scalars['Boolean'];
  underlyingLimitExcessRating?: Maybe<Scalars['Float']>;
  updatedAt: Scalars['DateTime'];
  vehicleFleet?: Maybe<Array<PrimaryAutoVehicleAggregate>>;
};

export type PrimaryAutoIndividualRiskRatingSurveyInput = {
  basicLimitPremiumTotalLiability?: InputMaybe<Scalars['Boolean']>;
  basicLimitPremiumTotalLiabilityRationale?: InputMaybe<Scalars['String']>;
  broaderCoverageNeeded?: InputMaybe<Scalars['Boolean']>;
  broaderCoverageNeededRationale?: InputMaybe<Scalars['String']>;
  coverageLimits?: InputMaybe<Scalars['Boolean']>;
  coverageLimitsRationale?: InputMaybe<Scalars['String']>;
  coverageToRestrictUncommonHazard?: InputMaybe<Scalars['Boolean']>;
  coverageToRestrictUncommonHazardRationale?: InputMaybe<Scalars['String']>;
  facultativeReinsurance?: InputMaybe<Scalars['Boolean']>;
  facultativeReinsuranceRationale?: InputMaybe<Scalars['String']>;
  increasedLimitsPremium?: InputMaybe<Scalars['Boolean']>;
  increasedLimitsPremiumRationale?: InputMaybe<Scalars['String']>;
  manualRateInappropriate?: InputMaybe<Scalars['Boolean']>;
  manualRateInappropriateRationale?: InputMaybe<Scalars['String']>;
  restrictedCoverage?: InputMaybe<Scalars['Boolean']>;
  restrictedCoverageRationale?: InputMaybe<Scalars['String']>;
};

export type PrimaryAutoLiabilityIndividualRiskRatingSurveyObject = {
  __typename?: 'PrimaryAutoLiabilityIndividualRiskRatingSurveyObject';
  basicLimitPremiumTotalLiability?: Maybe<Scalars['Boolean']>;
  basicLimitPremiumTotalLiabilityRationale?: Maybe<Scalars['String']>;
  broaderCoverageNeeded?: Maybe<Scalars['Boolean']>;
  broaderCoverageNeededRationale?: Maybe<Scalars['String']>;
  coverageLimits?: Maybe<Scalars['Boolean']>;
  coverageLimitsRationale?: Maybe<Scalars['String']>;
  coverageToRestrictUncommonHazard?: Maybe<Scalars['Boolean']>;
  coverageToRestrictUncommonHazardRationale?: Maybe<Scalars['String']>;
  facultativeReinsurance?: Maybe<Scalars['Boolean']>;
  facultativeReinsuranceRationale?: Maybe<Scalars['String']>;
  increasedLimitsPremium?: Maybe<Scalars['Boolean']>;
  increasedLimitsPremiumRationale?: Maybe<Scalars['String']>;
  manualRateInappropriate?: Maybe<Scalars['Boolean']>;
  manualRateInappropriateRationale?: Maybe<Scalars['String']>;
  restrictedCoverage?: Maybe<Scalars['Boolean']>;
  restrictedCoverageRationale?: Maybe<Scalars['String']>;
};

export type PrimaryAutoLiabilityLargeLossRunObject = {
  __typename?: 'PrimaryAutoLiabilityLargeLossRunObject';
  accidentDate: Scalars['Date'];
  alaeOutstanding: Scalars['Float'];
  effectiveDate: Scalars['Date'];
  expirationDate: Scalars['Date'];
  id: Scalars['ID'];
  indemnityOutstanding: Scalars['Float'];
  paidAlae: Scalars['Float'];
  paidIndemnity: Scalars['Float'];
};

export type PrimaryAutoLiabilityLossRunObject = {
  __typename?: 'PrimaryAutoLiabilityLossRunObject';
  alaeOutstanding: Scalars['Float'];
  effectiveDate: Scalars['Date'];
  evaluationDate: Scalars['Date'];
  expirationDate: Scalars['Date'];
  historicalExposure: Scalars['Float'];
  id: Scalars['ID'];
  indemnityOutstanding: Scalars['Float'];
  nonZeroClaimCount: Scalars['Float'];
  paidAlae: Scalars['Float'];
  paidIndemnity: Scalars['Float'];
  totalClaimCount: Scalars['Float'];
};

export type PrimaryAutoPhysDamLargeLossRunObject = {
  __typename?: 'PrimaryAutoPhysDamLargeLossRunObject';
  accidentDate: Scalars['Date'];
  effectiveDate: Scalars['Date'];
  expirationDate: Scalars['Date'];
  id: Scalars['ID'];
  indemnityOutstanding: Scalars['Float'];
  paidIndemnity: Scalars['Float'];
};

export type PrimaryAutoPhysDamLossRunObject = {
  __typename?: 'PrimaryAutoPhysDamLossRunObject';
  effectiveDate: Scalars['Date'];
  evaluationDate: Scalars['Date'];
  expirationDate: Scalars['Date'];
  historicalExposure: Scalars['Float'];
  id: Scalars['ID'];
  indemnityOutstanding: Scalars['Float'];
  nonZeroClaimCount: Scalars['Float'];
  paidIndemnity: Scalars['Float'];
  totalClaimCount: Scalars['Float'];
};

export type PrimaryAutoVehicleAggregate = {
  __typename?: 'PrimaryAutoVehicleAggregate';
  count: Scalars['Int'];
  sizeClass: Scalars['String'];
  state: Scalars['String'];
  typeOfVehicle: Scalars['String'];
};

export type PrimaryAutoVehicleInputV2 = {
  hiredOwned?: InputMaybe<Scalars['String']>;
  make?: InputMaybe<Scalars['String']>;
  model?: InputMaybe<Scalars['String']>;
  modelYear?: InputMaybe<Scalars['String']>;
  ocnStatedAmount?: InputMaybe<Scalars['String']>;
  preciseAmount?: InputMaybe<Scalars['Float']>;
  radius?: InputMaybe<Scalars['String']>;
  secondaryClassDetailPrivateClassDetail?: InputMaybe<Scalars['String']>;
  secondaryClassPrivateClass?: InputMaybe<Scalars['String']>;
  sizeClass?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  statedAmount?: InputMaybe<Scalars['String']>;
  subjectToPhysicalDamage?: InputMaybe<Scalars['Boolean']>;
  typeOfVehicle?: InputMaybe<Scalars['String']>;
  use?: InputMaybe<Scalars['String']>;
  vin?: InputMaybe<Scalars['String']>;
  zipCode?: InputMaybe<Scalars['String']>;
};

export type PrimaryAutoVehicleObject = {
  __typename?: 'PrimaryAutoVehicleObject';
  createdAt: Scalars['Date'];
  exposureId: Scalars['String'];
  hiredOwned?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  make?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  modelYear?: Maybe<Scalars['String']>;
  ocnStatedAmount?: Maybe<Scalars['String']>;
  preciseAmount?: Maybe<Scalars['Float']>;
  radius?: Maybe<Scalars['String']>;
  secondaryClassDetailPrivateClassDetail?: Maybe<Scalars['String']>;
  secondaryClassPrivateClass?: Maybe<Scalars['String']>;
  sizeClass?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  statedAmount?: Maybe<Scalars['String']>;
  subjectToPhysicalDamage?: Maybe<Scalars['Boolean']>;
  typeOfVehicle?: Maybe<Scalars['String']>;
  updatedAt: Scalars['Date'];
  use?: Maybe<Scalars['String']>;
  vin?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
};

export type PrimaryGeneralLiabilityExpectedPayroll = {
  __typename?: 'PrimaryGeneralLiabilityExpectedPayroll';
  exposureBase: Scalars['String'];
  exposureId: Scalars['ID'];
  id: Scalars['ID'];
  isoClass: Scalars['String'];
  ncciClass?: Maybe<Scalars['String']>;
  payroll: Scalars['Float'];
  state: Scalars['String'];
  territory: Scalars['String'];
};

export type PrimaryGeneralLiabilityExpectedSubcontractedCost = {
  __typename?: 'PrimaryGeneralLiabilityExpectedSubcontractedCost';
  exposureId: Scalars['ID'];
  id: Scalars['ID'];
  isoClass: Scalars['String'];
  ncciClass?: Maybe<Scalars['String']>;
  payroll: Scalars['Float'];
  state: Scalars['String'];
  territory: Scalars['String'];
};

export type PrimaryGeneralLiabilityExposure = {
  __typename?: 'PrimaryGeneralLiabilityExposure';
  createdAt: Scalars['DateTime'];
  damageToPremisesRentedToYou?: Maybe<Scalars['Float']>;
  deductibleOption?: Maybe<Scalars['String']>;
  deductiblePco?: Maybe<Scalars['Float']>;
  deductiblePremOps?: Maybe<Scalars['Float']>;
  defenseExpenseTreatment?: Maybe<Scalars['String']>;
  defenseInsideLimits?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  eachOccurrenceLimit?: Maybe<Scalars['Float']>;
  employeeBenefitLiabilityCoverage: Scalars['Boolean'];
  employeeBenefitLiabilityLimit?: Maybe<Scalars['String']>;
  employeeBenefitLiabilityNumberOfEmployees?: Maybe<Scalars['String']>;
  employeeBenefitLiabilityRetroDate?: Maybe<Scalars['Date']>;
  employerLiabilityBodilyInjuryByAccidentEachAccident?: Maybe<Scalars['Float']>;
  employerLiabilityBodilyInjuryByDiseaseAggregate?: Maybe<Scalars['Float']>;
  employerLiabilityBodilyInjuryByDiseaseEachEmployee?: Maybe<Scalars['Float']>;
  employerLiabilityCoverage?: Maybe<Scalars['Boolean']>;
  expectedPayrolls?: Maybe<Array<PrimaryGeneralLiabilityExpectedPayroll>>;
  expectedSubcontractedCosts?: Maybe<Array<PrimaryGeneralLiabilityExpectedSubcontractedCost>>;
  finalSummarySelectedLossRate?: Maybe<Scalars['Decimal']>;
  finalSummarySelectedLossRateType?: Maybe<
    LossRateSelectionTypeEnum | `${LossRateSelectionTypeEnum}`
  >;
  generalAggregateLimit?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  isCredibilityCapEnabled: Scalars['Boolean'];
  isExperienceRatedPathEnabled: Scalars['Boolean'];
  medicalExpenseLimit?: Maybe<Scalars['Float']>;
  numberOfProjectsLocations?: Maybe<Scalars['Float']>;
  overallAggregateCap?: Maybe<Scalars['Float']>;
  pcoAggregateLimit?: Maybe<Scalars['Float']>;
  personalAdvertisingInjuryLimit?: Maybe<Scalars['Float']>;
  primaryGeneralLiabilityIndividualRiskRatingSurvey?: Maybe<PrimaryGeneralLiabilityIndividualRiskRatingSurveyObject>;
  primaryGeneralLiabilityLargeLossRun?: Maybe<Array<PrimaryGeneralLiabilityLargeLossRunObject>>;
  primaryGeneralLiabilityLossRun?: Maybe<Array<PrimaryGeneralLiabilityLossRunObject>>;
  primaryUnmannedAircraft?: Maybe<Array<PrimaryUnmannedAircraftObject>>;
  programVolume?: Maybe<Scalars['Float']>;
  projectsGeneralAggregateOption?: Maybe<Scalars['String']>;
  quoteId: Scalars['ID'];
  residentialExclusion?: Maybe<Scalars['Boolean']>;
  selectedFrequency?: Maybe<Scalars['Decimal']>;
  selectedFrequencyType?: Maybe<LossRateSelectionTypeEnum | `${LossRateSelectionTypeEnum}`>;
  selectedLossRate?: Maybe<Scalars['Decimal']>;
  selectedLossRateType?: Maybe<LossRateSelectionTypeEnum | `${LossRateSelectionTypeEnum}`>;
  subjectToLossAggregate?: Maybe<Scalars['Boolean']>;
  updatedAt: Scalars['DateTime'];
  wildfireExclusion?: Maybe<Scalars['Boolean']>;
};

export type PrimaryGeneralLiabilityIndividualRiskRatingSurveyInput = {
  aggregateLimitsChanged?: InputMaybe<Scalars['Boolean']>;
  aggregateLimitsChangedRationale?: InputMaybe<Scalars['String']>;
  basicLimitPremiumTotalLiability?: InputMaybe<Scalars['Boolean']>;
  basicLimitPremiumTotalLiabilityRationale?: InputMaybe<Scalars['String']>;
  broaderCoverageNeeded?: InputMaybe<Scalars['Boolean']>;
  broaderCoverageNeededRationale?: InputMaybe<Scalars['String']>;
  coverageLimits?: InputMaybe<Scalars['Boolean']>;
  coverageLimitsRationale?: InputMaybe<Scalars['String']>;
  coverageToRestrictUncommonHazard?: InputMaybe<Scalars['Boolean']>;
  coverageToRestrictUncommonHazardRationale?: InputMaybe<Scalars['String']>;
  facultativeReinsurance?: InputMaybe<Scalars['Boolean']>;
  facultativeReinsuranceRationale?: InputMaybe<Scalars['String']>;
  increasedLimitsPremium?: InputMaybe<Scalars['Boolean']>;
  increasedLimitsPremiumRationale?: InputMaybe<Scalars['String']>;
  manualRateInappropriate?: InputMaybe<Scalars['Boolean']>;
  manualRateInappropriateRationale?: InputMaybe<Scalars['String']>;
  premiumPco?: InputMaybe<Scalars['Boolean']>;
  premiumPcoRationale?: InputMaybe<Scalars['String']>;
};

export type PrimaryGeneralLiabilityIndividualRiskRatingSurveyObject = {
  __typename?: 'PrimaryGeneralLiabilityIndividualRiskRatingSurveyObject';
  aggregateLimitsChanged?: Maybe<Scalars['Boolean']>;
  aggregateLimitsChangedRationale?: Maybe<Scalars['String']>;
  basicLimitPremiumTotalLiability?: Maybe<Scalars['Boolean']>;
  basicLimitPremiumTotalLiabilityRationale?: Maybe<Scalars['String']>;
  broaderCoverageNeeded?: Maybe<Scalars['Boolean']>;
  broaderCoverageNeededRationale?: Maybe<Scalars['String']>;
  coverageLimits?: Maybe<Scalars['Boolean']>;
  coverageLimitsRationale?: Maybe<Scalars['String']>;
  coverageToRestrictUncommonHazard?: Maybe<Scalars['Boolean']>;
  coverageToRestrictUncommonHazardRationale?: Maybe<Scalars['String']>;
  facultativeReinsurance?: Maybe<Scalars['Boolean']>;
  facultativeReinsuranceRationale?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  increasedLimitsPremium?: Maybe<Scalars['Boolean']>;
  increasedLimitsPremiumRationale?: Maybe<Scalars['String']>;
  manualRateInappropriate?: Maybe<Scalars['Boolean']>;
  manualRateInappropriateRationale?: Maybe<Scalars['String']>;
  premiumPco?: Maybe<Scalars['Boolean']>;
  premiumPcoRationale?: Maybe<Scalars['String']>;
};

export type PrimaryGeneralLiabilityLargeLossRunObject = {
  __typename?: 'PrimaryGeneralLiabilityLargeLossRunObject';
  accidentDate: Scalars['Date'];
  alaeOutstanding: Scalars['Float'];
  effectiveDate: Scalars['Date'];
  expirationDate: Scalars['Date'];
  id: Scalars['ID'];
  indemnityOutstanding: Scalars['Float'];
  paidAlae: Scalars['Float'];
  paidIndemnity: Scalars['Float'];
};

export type PrimaryGeneralLiabilityLossRunObject = {
  __typename?: 'PrimaryGeneralLiabilityLossRunObject';
  alaeOutstanding: Scalars['Float'];
  effectiveDate: Scalars['Date'];
  evaluationDate: Scalars['Date'];
  expirationDate: Scalars['Date'];
  historicalExposure: Scalars['Float'];
  id: Scalars['ID'];
  indemnityOutstanding: Scalars['Float'];
  nonZeroClaimCount: Scalars['Float'];
  paidAlae: Scalars['Float'];
  paidIndemnity: Scalars['Float'];
  totalClaimCount: Scalars['Float'];
};

export type PrimaryLossAggregateObject = {
  __typename?: 'PrimaryLossAggregateObject';
  aggregateLimit: Scalars['Float'];
  aggregateLimitCorrespondingToFivePercentProbabilityOfAttachment: Scalars['Float'];
  aggregateLimitCorrespondingToTenPercentProbabilityOfAttachment: Scalars['Float'];
  autoLiabilityDeductibleLayerLossPick?: Maybe<Scalars['Float']>;
  containmentFactorForFivePercentInsuranceChargeProbabilityOfAttachment: Scalars['Float'];
  containmentFactorForTenPercentInsuranceChargeProbabilityOfAttachment: Scalars['Float'];
  defaultInsuranceChargeProbabilityOfAttachment: Scalars['Float'];
  expectedLossGroup: Scalars['Float'];
  finalCharge: Scalars['Float'];
  generalLiabilityDeductibleLayerLossPick: Scalars['Float'];
  maxInsuranceChargeProbabilityOfAttachment: Scalars['Float'];
  maximumDeductible: Scalars['Float'];
  selectedContainmentFactor: Scalars['Float'];
  selectedInsuranceChargeProbabilityOfAttachment: Scalars['Float'];
  suggestedContainmentFactor: Scalars['Float'];
  suggestedInsuranceChargeProbabilityOfAttachment: Scalars['Float'];
  totalDeductibleLossPick: Scalars['Float'];
};

export type PrimaryOcpExposure = {
  __typename?: 'PrimaryOcpExposure';
  createdAt: Scalars['DateTime'];
  deletedAt: Scalars['DateTime'];
  id: Scalars['ID'];
  ocpLimit?: Maybe<Scalars['String']>;
  ocpTotalCost?: Maybe<Scalars['Float']>;
  policyState?: Maybe<Scalars['String']>;
  quoteId: Scalars['ID'];
  typeOfOperations?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type PrimaryOwnersAndContractorsProtectivePricingObject = {
  __typename?: 'PrimaryOwnersAndContractorsProtectivePricingObject';
  isLive: Scalars['Boolean'];
  pricingDebug: Scalars['JSON'];
  pricingDisplay: Pricing;
};

export type PrimaryPracticePricingObject = {
  __typename?: 'PrimaryPracticePricingObject';
  autoIndividualRiskRatingSupportingInfo?: Maybe<AutoIndividualRiskRatingSupportingInfoObject>;
  autoLiabilityLossDevelopmentCalcsDetail?: Maybe<Pricing>;
  autoLiabilityPassedIndividualRiskRating?: Maybe<Scalars['Boolean']>;
  finalPremium?: Maybe<Scalars['MoneyFloat']>;
  finalPricingDisplay?: Maybe<Pricing>;
  finalPricingDisplayMatrix?: Maybe<PricingMatrix>;
  generalLiabilityIndividualRiskRatingSupportingInfo: GeneralLiabilityIndividualRiskRatingSupportingInfoObject;
  generalLiabilityLossDevelopmentCalcsDetail?: Maybe<Pricing>;
  generalLiabilityPassedIndividualRiskRating?: Maybe<Scalars['Boolean']>;
  intermediatePricingDisplay?: Maybe<Pricing>;
  isLive: Scalars['Boolean'];
  lossAggregate?: Maybe<PrimaryLossAggregateObject>;
  pricingDebug: Scalars['JSON'];
  programStructure: ProgramStructureObject;
  shouldUseAutoLiabilityLossDevelopment: Scalars['Boolean'];
  shouldUseGeneralLiabilityLossDevelopment: Scalars['Boolean'];
};

export type PrimaryProjectPricingObject = {
  __typename?: 'PrimaryProjectPricingObject';
  autoIndividualRiskRatingSupportingInfo?: Maybe<AutoIndividualRiskRatingSupportingInfoObject>;
  autoLiabilityLossDevelopmentCalcsDetail?: Maybe<Pricing>;
  autoLiabilityPassedIndividualRiskRating?: Maybe<Scalars['Boolean']>;
  finalPremium?: Maybe<Scalars['MoneyFloat']>;
  finalPricingDisplay?: Maybe<Pricing>;
  finalPricingDisplayMatrix?: Maybe<PricingMatrix>;
  generalLiabilityIndividualRiskRatingSupportingInfo: GeneralLiabilityIndividualRiskRatingSupportingInfoObject;
  generalLiabilityLossDevelopmentCalcsDetail?: Maybe<Pricing>;
  generalLiabilityPassedIndividualRiskRating?: Maybe<Scalars['Boolean']>;
  intermediatePricingDisplay?: Maybe<Pricing>;
  isLive: Scalars['Boolean'];
  lossAggregate?: Maybe<PrimaryLossAggregateObject>;
  pricingDebug: Scalars['JSON'];
  programStructure: ProgramStructureObject;
  shouldUseAutoLiabilityLossDevelopment: Scalars['Boolean'];
  shouldUseGeneralLiabilityLossDevelopment: Scalars['Boolean'];
};

export type PrimaryQuote = {
  __typename?: 'PrimaryQuote';
  indicationDueDate?: Maybe<Scalars['String']>;
  indicationTime?: Maybe<Scalars['Float']>;
  isOpen: Scalars['Boolean'];
  status: QuoteStatus | `${QuoteStatus}`;
};

export type PrimaryRailroadExposure = {
  __typename?: 'PrimaryRailroadExposure';
  createdAt: Scalars['DateTime'];
  deletedAt: Scalars['DateTime'];
  id: Scalars['ID'];
  numberOfPassengerAndFreightTrainsPerDay?: Maybe<Scalars['String']>;
  policyState?: Maybe<Scalars['String']>;
  quoteId: Scalars['ID'];
  railroadLimit?: Maybe<Scalars['String']>;
  railroadOpsExtendedCoverage?: Maybe<Scalars['Boolean']>;
  railroadOpsTotalCost?: Maybe<Scalars['Float']>;
  typeOfOperationsForRailroadOps?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type PrimaryRailroadPricingObject = {
  __typename?: 'PrimaryRailroadPricingObject';
  isLive: Scalars['Boolean'];
  pricingDebug: Scalars['JSON'];
  pricingDisplay: Pricing;
};

export type PrimaryUnderwritingAdjustmentsInput = {
  adjustmentNotes: Scalars['String'];
  autoBrokerageCommissionPercentage?: InputMaybe<Scalars['Float']>;
  autoLiabilityUnderwritingAdjustment: Scalars['Float'];
  autoProfitLoadPercentage?: InputMaybe<Scalars['Float']>;
  autoShepherdFeePercentage?: InputMaybe<Scalars['Float']>;
  autoTpaChargePerPU?: InputMaybe<Scalars['Float']>;
  dunAndBradstreetScore: Scalars['Int'];
  generalLiabilityBrokerageCommissionPercentage?: InputMaybe<Scalars['Float']>;
  generalLiabilityProfitLoadPercentage?: InputMaybe<Scalars['Float']>;
  generalLiabilityShepherdFeePercentage?: InputMaybe<Scalars['Float']>;
  generalLiabilityTpaChargePer15MilOfVolume?: InputMaybe<Scalars['Float']>;
  generalLiabilityUnderwritingAdjustment: Scalars['Float'];
  projectGeneralLiabilityExcessOfWrapCharge: Scalars['Float'];
  quoteDetailId: Scalars['ID'];
  selectedContainmentFactor?: InputMaybe<Scalars['Float']>;
  tpaChargesNonClaimsHandling: Scalars['Float'];
  tria?: InputMaybe<Scalars['Boolean']>;
};

export type PrimaryUnderwritingAdjustmentsObject = {
  __typename?: 'PrimaryUnderwritingAdjustmentsObject';
  adjustmentNotes?: Maybe<Scalars['String']>;
  autoBrokerageCommissionPercentage?: Maybe<Scalars['Float']>;
  autoLiabilityUnderwritingAdjustment: Scalars['Float'];
  autoProfitLoadPercentage?: Maybe<Scalars['Float']>;
  autoShepherdFeePercentage?: Maybe<Scalars['Float']>;
  autoTpaChargePerPU?: Maybe<Scalars['Float']>;
  dunAndBradstreetScore: Scalars['Int'];
  generalLiabilityBrokerageCommissionPercentage?: Maybe<Scalars['Float']>;
  generalLiabilityProfitLoadPercentage?: Maybe<Scalars['Float']>;
  generalLiabilityShepherdFeePercentage?: Maybe<Scalars['Float']>;
  generalLiabilityTpaChargePer15MilOfVolume?: Maybe<Scalars['Float']>;
  generalLiabilityUnderwritingAdjustment: Scalars['Float'];
  projectGeneralLiabilityExcessOfWrapCharge: Scalars['Float'];
  quoteDetailId: Scalars['ID'];
  selectedContainmentFactor?: Maybe<Scalars['Float']>;
  tpaChargesNonClaimsHandling: Scalars['Float'];
  tria?: Maybe<Scalars['Boolean']>;
};

export type PrimaryUnmannedAircraftObject = {
  __typename?: 'PrimaryUnmannedAircraftObject';
  exposureId: Scalars['ID'];
  maximumTakeOffWeight: Scalars['String'];
  ownershipAndOperation: Scalars['String'];
  primaryPlace: Scalars['String'];
  state: Scalars['String'];
  usage: Scalars['String'];
};

export type ProcoreCompaniesPayload = {
  __typename?: 'ProcoreCompaniesPayload';
  id: Scalars['SafeInt'];
  name: Scalars['String'];
};

export type ProcoreUsage = {
  __typename?: 'ProcoreUsage';
  adoptionDate?: Maybe<Scalars['Date']>;
  annualRevenue?: Maybe<Scalars['Float']>;
  coreToolsEngagement?: Maybe<Scalars['Float']>;
  csEngagement?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  overallUsage?: Maybe<Scalars['Float']>;
  policyholder: Policyholder;
  policyholderId: Scalars['ID'];
  projectFinancials?: Maybe<Scalars['Float']>;
  projectManagement?: Maybe<Scalars['Float']>;
  qualitySafety?: Maybe<Scalars['Float']>;
  totalActiveUsers?: Maybe<Scalars['Float']>;
};

export type ProgramStructureObject = {
  __typename?: 'ProgramStructureObject';
  autoRiskTier?: Maybe<Scalars['String']>;
  combinedSingleLimitAutoLiability?: Maybe<Scalars['Float']>;
  deductibleOptionAutoLiability?: Maybe<Scalars['Float']>;
  deductibleOptionGeneralLiability?: Maybe<Scalars['String']>;
  deductiblePcoGeneralLiability?: Maybe<Scalars['Float']>;
  deductiblePremOpsGeneralLiability?: Maybe<Scalars['Float']>;
  defenseExpenseTreatmentAutoLiability?: Maybe<Scalars['String']>;
  defenseExpenseTreatmentGeneralLiability?: Maybe<Scalars['String']>;
  eachOccurrenceLimitGeneralLiability?: Maybe<Scalars['Float']>;
  generalAggregateLimitGeneralLiability?: Maybe<Scalars['Float']>;
  generalLiabilityRiskTier?: Maybe<Scalars['String']>;
  pcoAggregateLimitGeneralLiability?: Maybe<Scalars['Float']>;
  programVolumeGeneralLiability?: Maybe<Scalars['Float']>;
  projectsGeneralAggregateLabel?: Maybe<Scalars['String']>;
  totalPayrollSum?: Maybe<Scalars['Float']>;
  totalPowerUnitsAutoLiability?: Maybe<Scalars['Float']>;
  totalPowerUnitsPhysDam?: Maybe<Scalars['Float']>;
};

export type ProjectExcessGeneralLiabilityBuildoutSchedule = {
  __typename?: 'ProjectExcessGeneralLiabilityBuildoutSchedule';
  exposureId: Scalars['ID'];
  percentage: Scalars['Float'];
  year: Scalars['String'];
};

export type ProjectExcessGeneralLiabilityExpectedPayroll = {
  __typename?: 'ProjectExcessGeneralLiabilityExpectedPayroll';
  exposureId: Scalars['ID'];
  isoClass: Scalars['String'];
  ncciClass?: Maybe<Scalars['String']>;
  payroll: Scalars['Float'];
  state: Scalars['String'];
  territory: Scalars['String'];
};

export type ProjectExcessGeneralLiabilityExpectedSubcontractedCost = {
  __typename?: 'ProjectExcessGeneralLiabilityExpectedSubcontractedCost';
  exposureId: Scalars['ID'];
  isoClass: Scalars['String'];
  ncciClass?: Maybe<Scalars['String']>;
  payroll: Scalars['Float'];
  state: Scalars['String'];
  territory: Scalars['String'];
};

export type ProjectExcessGeneralLiabilityExposure = {
  __typename?: 'ProjectExcessGeneralLiabilityExposure';
  additionalStates?: Maybe<Array<Scalars['String']>>;
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  attachmentPoint?: Maybe<Scalars['Float']>;
  buildoutSchedules?: Maybe<Array<ProjectExcessGeneralLiabilityBuildoutSchedule>>;
  cdSensitiveProjectTypeOne?: Maybe<Scalars['Boolean']>;
  cdSensitiveProjectTypeTwo?: Maybe<Scalars['Boolean']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  expectedPayrolls?: Maybe<Array<ProjectExcessGeneralLiabilityExpectedPayroll>>;
  expectedSubcontractedCosts?: Maybe<Array<ProjectExcessGeneralLiabilityExpectedSubcontractedCost>>;
  id: Scalars['ID'];
  isExperienceRatingEnabled: Scalars['Boolean'];
  largeLossRuns?: Maybe<Array<LargeLossRun>>;
  lossRuns?: Maybe<Array<AggregateLossRun>>;
  numberOfProjectsLocations?: Maybe<Scalars['Float']>;
  overallAggregateCap?: Maybe<Scalars['Float']>;
  programType?: Maybe<Scalars['String']>;
  programVolume?: Maybe<Scalars['Float']>;
  projectName?: Maybe<Scalars['String']>;
  projectsGeneralAggregateOption?: Maybe<Scalars['String']>;
  quoteId: Scalars['ID'];
  residentialExclusion?: Maybe<Scalars['Boolean']>;
  scheduleRatings?: Maybe<Array<ScheduleRatingsObject>>;
  state?: Maybe<Scalars['String']>;
  underlyingPolicyLimit?: Maybe<Scalars['Float']>;
  underlyingPolicyPremium?: Maybe<Scalars['Float']>;
  updatedAt: Scalars['DateTime'];
  wildfireExclusion?: Maybe<Scalars['Boolean']>;
  zipcode?: Maybe<Scalars['String']>;
};

export type ProjectExcessQuoteRiskTierDetailsGeneralLiabilityObject = {
  __typename?: 'ProjectExcessQuoteRiskTierDetailsGeneralLiabilityObject';
  isLive: Scalars['Boolean'];
  pricingDisplay: Pricing;
  riskTier: RiskTierGlModelOutput;
};

export type ProjectExcessQuoteRiskTierDetailsObject = {
  __typename?: 'ProjectExcessQuoteRiskTierDetailsObject';
  generalLiability?: Maybe<ProjectExcessQuoteRiskTierDetailsGeneralLiabilityObject>;
};

export type ProjectExcessQuoteRiskTierObject = {
  __typename?: 'ProjectExcessQuoteRiskTierObject';
  product: PolicyProduct | `${PolicyProduct}`;
  riskTiers: ProjectExcessQuoteRiskTierDetailsObject;
};

export type ProjectGeneralAggregateOptionsObject = {
  __typename?: 'ProjectGeneralAggregateOptionsObject';
  label: Scalars['String'];
  value: ProjectGeneralAggregateValueEnum | `${ProjectGeneralAggregateValueEnum}`;
};

export enum ProjectGeneralAggregateValueEnum {
  None = 'NONE',
  OverallAggregateCap = 'OVERALL_AGGREGATE_CAP',
  PerProjectPerLocationAggregate = 'PER_PROJECT_PER_LOCATION_AGGREGATE',
}

export type ProjectPrimaryAutoExposureObject = {
  __typename?: 'ProjectPrimaryAutoExposureObject';
  autoHackingCoverageOption?: Maybe<
    AutoHackingCoverageOptionEnum | `${AutoHackingCoverageOptionEnum}`
  >;
  autoLiabilityScheduleRatings?: Maybe<Array<ScheduleRatingsObject>>;
  autoLoanLeaseGapCoverage?: Maybe<Scalars['Boolean']>;
  autoPhysicalDamageScheduleRatings?: Maybe<Array<ScheduleRatingsObject>>;
  collisionDeductible?: Maybe<
    CollisionDeductibleOptionsObjectValueEnum | `${CollisionDeductibleOptionsObjectValueEnum}`
  >;
  collisionDeductibleCostOfHireBasis?: Maybe<
    | CollisionDeductibleCostOfHireBasisObjectValueEnum
    | `${CollisionDeductibleCostOfHireBasisObjectValueEnum}`
  >;
  combinedSingleLimit?: Maybe<
    CombinedSingleLimitOptionsObjectValueEnum | `${CombinedSingleLimitOptionsObjectValueEnum}`
  >;
  comprehensiveDeductible?: Maybe<
    | ComprehensiveDeductibleOptionsObjectValueEnum
    | `${ComprehensiveDeductibleOptionsObjectValueEnum}`
  >;
  comprehensiveDeductibleCostOfHireBasis?: Maybe<
    | ComprehensiveDeductibleCostOfHireBasisObjectValueEnum
    | `${ComprehensiveDeductibleCostOfHireBasisObjectValueEnum}`
  >;
  costOfHires?: Maybe<Array<PrimaryAutoCostOfHire>>;
  createdAt: Scalars['DateTime'];
  defenseExpenseTreatment?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  driveOtherCar?: Maybe<Scalars['Boolean']>;
  extendCoverageToIndividualEmployees?: Maybe<Scalars['Boolean']>;
  extendToCoverOwner?: Maybe<Scalars['Boolean']>;
  finalSummarySelectedLossRate?: Maybe<Scalars['Decimal']>;
  finalSummarySelectedLossRateType?: Maybe<
    LossRateSelectionTypeEnum | `${LossRateSelectionTypeEnum}`
  >;
  fleetExposure?: Maybe<Array<PrimaryAutoVehicleObject>>;
  hiredAutosCoverage?: Maybe<HiredAutosCoverageEnum | `${HiredAutosCoverageEnum}`>;
  id: Scalars['ID'];
  individualNamedInsured?: Maybe<Scalars['Boolean']>;
  isCredibilityCapEnabled: Scalars['Boolean'];
  isExperienceRatedPathEnabledAutoLiability?: Maybe<Scalars['Boolean']>;
  isExperienceRatedPathEnabledAutoPhysDam?: Maybe<Scalars['Boolean']>;
  liabilityDeductible?: Maybe<
    LiablityDeductibleOptionsObjectValueEnum | `${LiablityDeductibleOptionsObjectValueEnum}`
  >;
  naicsClassCode?: Maybe<Scalars['String']>;
  nonOwnershipLiability?: Maybe<Scalars['Boolean']>;
  numberOfEmployees?: Maybe<Scalars['String']>;
  numberOfIndividualNamedInsureds?: Maybe<Scalars['Float']>;
  numberOfPartners?: Maybe<Scalars['Float']>;
  partnershipOrLlc?: Maybe<Scalars['Boolean']>;
  pollutionLiability?: Maybe<Scalars['Boolean']>;
  pptCollisionDeductible: Scalars['Boolean'];
  pptComprehensiveDeductible: Scalars['Boolean'];
  projectPrimaryAutoLiabilityIndividualRiskRatingSurvey?: Maybe<PrimaryAutoLiabilityIndividualRiskRatingSurveyObject>;
  quoteId: Scalars['ID'];
  rentalReimbursementCoverage: Scalars['Boolean'];
  rentalReimbursementDays?: Maybe<
    RentalReimbursementDaysObjectValueEnum | `${RentalReimbursementDaysObjectValueEnum}`
  >;
  rentalReimbursementLimit?: Maybe<
    RentalReimbursementObjectValueEnum | `${RentalReimbursementObjectValueEnum}`
  >;
  selectedFrequency?: Maybe<Scalars['Decimal']>;
  selectedFrequencyType?: Maybe<LossRateSelectionTypeEnum | `${LossRateSelectionTypeEnum}`>;
  selectedLossRate?: Maybe<Scalars['Decimal']>;
  selectedLossRateType?: Maybe<LossRateSelectionTypeEnum | `${LossRateSelectionTypeEnum}`>;
  subjectToLossAggregate?: Maybe<Scalars['Boolean']>;
  towAndLaborCost?: Maybe<TowAndLaborCostObjectValueEnum | `${TowAndLaborCostObjectValueEnum}`>;
  towAndLaborCostCoverage: Scalars['Boolean'];
  tttCollisionDeductible: Scalars['Boolean'];
  tttComprehensiveDeductible: Scalars['Boolean'];
  underlyingLimitExcessRating?: Maybe<Scalars['Float']>;
  updatedAt: Scalars['DateTime'];
  vehicleFleet?: Maybe<Array<PrimaryAutoVehicleAggregate>>;
};

export type ProjectPrimaryGeneralLiabilityExposure = {
  __typename?: 'ProjectPrimaryGeneralLiabilityExposure';
  additionalStates?: Maybe<Array<Scalars['String']>>;
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  buildoutSchedule?: Maybe<Array<ProjectExcessGeneralLiabilityBuildoutSchedule>>;
  cdSensitiveProjectTypeOne?: Maybe<Scalars['Boolean']>;
  cdSensitiveProjectTypeTwo?: Maybe<Scalars['Boolean']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  damageToPremisesRentedToYou?: Maybe<Scalars['Float']>;
  deductibleOption?: Maybe<Scalars['String']>;
  deductiblePco?: Maybe<Scalars['Float']>;
  deductiblePremOps?: Maybe<Scalars['Float']>;
  defenseExpenseTreatment?: Maybe<Scalars['String']>;
  defenseInsideLimits?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  eachOccurrenceLimit?: Maybe<Scalars['Float']>;
  employeeBenefitLiabilityCoverage: Scalars['Boolean'];
  employeeBenefitLiabilityLimit?: Maybe<Scalars['String']>;
  employeeBenefitLiabilityNumberOfEmployees?: Maybe<Scalars['String']>;
  employeeBenefitLiabilityRetroDate?: Maybe<Scalars['Date']>;
  employerLiabilityBodilyInjuryByAccidentEachAccident?: Maybe<Scalars['Float']>;
  employerLiabilityBodilyInjuryByDiseaseAggregate?: Maybe<Scalars['Float']>;
  employerLiabilityBodilyInjuryByDiseaseEachEmployee?: Maybe<Scalars['Float']>;
  employerLiabilityCoverage?: Maybe<Scalars['Boolean']>;
  expectedPayrolls?: Maybe<Array<PrimaryGeneralLiabilityExpectedPayroll>>;
  expectedSubcontractedCosts?: Maybe<Array<PrimaryGeneralLiabilityExpectedSubcontractedCost>>;
  finalSummarySelectedLossRate?: Maybe<Scalars['Decimal']>;
  finalSummarySelectedLossRateType?: Maybe<
    LossRateSelectionTypeEnum | `${LossRateSelectionTypeEnum}`
  >;
  generalAggregateLimit?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  isCredibilityCapEnabled: Scalars['Boolean'];
  isExperienceRatedPathEnabled: Scalars['Boolean'];
  medicalExpenseLimit?: Maybe<Scalars['Float']>;
  numberOfProjectsLocations?: Maybe<Scalars['Float']>;
  overallAggregateCap?: Maybe<Scalars['Float']>;
  pcoAggregateLimit?: Maybe<Scalars['Float']>;
  personalAdvertisingInjuryLimit?: Maybe<Scalars['Float']>;
  programType?: Maybe<Scalars['String']>;
  programVolume?: Maybe<Scalars['Float']>;
  projectName?: Maybe<Scalars['String']>;
  projectPrimaryGeneralLiabilityIndividualRiskRatingSurvey?: Maybe<PrimaryGeneralLiabilityIndividualRiskRatingSurveyObject>;
  projectPrimaryUnmannedAircraft?: Maybe<Array<PrimaryUnmannedAircraftObject>>;
  projectsGeneralAggregateOption?: Maybe<Scalars['String']>;
  quoteId: Scalars['ID'];
  residentialExclusion?: Maybe<Scalars['Boolean']>;
  scheduleRatings?: Maybe<Array<ScheduleRatingsObject>>;
  selectedFrequency?: Maybe<Scalars['Decimal']>;
  selectedFrequencyType?: Maybe<LossRateSelectionTypeEnum | `${LossRateSelectionTypeEnum}`>;
  selectedLossRate?: Maybe<Scalars['Decimal']>;
  selectedLossRateType?: Maybe<LossRateSelectionTypeEnum | `${LossRateSelectionTypeEnum}`>;
  state?: Maybe<Scalars['String']>;
  subjectToLossAggregate?: Maybe<Scalars['Boolean']>;
  updatedAt: Scalars['DateTime'];
  wildfireExclusion?: Maybe<Scalars['Boolean']>;
  zipcode?: Maybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  additionalPolicyNumbers: Array<AdditionalPolicyNumber>;
  allDefaultAutoData: Array<DefaultAutoData>;
  allOrganizationMembers: Array<OrganizationMember>;
  assignees: Array<AssigneeObject>;
  autoFleetSummary: Array<AutoFleetSummaryRowObject>;
  autoRiskInputTableConstants: AutoRiskInputTableConstant;
  autodeskHubs?: Maybe<Array<AutodeskHub>>;
  availableFormsForQuote: Array<PartialFormPdfObject>;
  brokerage: Organization;
  brokerages: Array<Organization>;
  carrier: Organization;
  carriers: Array<Organization>;
  containmentFactors: Array<Scalars['Float']>;
  costOfHires: Array<PrimaryAutoCostOfHire>;
  coverageOptionsConstants: CoverageOptionsConstants;
  defaultAutoData: Array<Maybe<DefaultAutoDataLookup>>;
  defaultAutoZipCodesByMostCommonPayrollTerritory?: Maybe<Array<StateZipCode>>;
  defaultAutoZipCodesByState: Array<StateZipCode>;
  digitalQuote: DigitalQuote;
  excessAutoCoverageOptions?: Maybe<Array<ExcessAutoCoverageOptions>>;
  excessAutoExposure: ExcessAutoExposure;
  excessAutoRiskTierVehicleSummary?: Maybe<Array<AutoRiskTierVehicleSummary>>;
  excessAutoVehiclesV2: Array<ExcessAutoVehicleV2>;
  excessAutoVehiclesV2Summary: AutoSummary;
  excessGeneralLiabilityExposure: ExcessGeneralLiabilityExposure;
  /** @deprecated Now on the excess general liability object */
  excessGeneralLiabilityScheduleRatings: Array<ScheduleRatingsObject>;
  expectedPayrollTableConstants: ExpectedPayrollTableConstant;
  expectedPayrolls: Array<PayrollObject>;
  expectedSubcontractedCostTableConstants: ExpectedSubcontractedCostConstant;
  files: Array<File>;
  formGroup?: Maybe<FormGroupObject>;
  formGroups: Array<FormGroupObject>;
  formPdf?: Maybe<FormPdfObject>;
  formPdfs: Array<FormPdfObject>;
  formPdfsNotOnGroup: Array<FormPdfObject>;
  getFeesPerQuote: QuoteFeesObject;
  hasNpns: HasNpnsObject;
  health: Scalars['String'];
  hello: Scalars['String'];
  hqZipCode?: Maybe<StateZipCode>;
  insightsLink: InsightsLinkObject;
  insightsReport: InsightsReportObject;
  insuranceProducts: Array<InsuranceProduct>;
  invoiceDownloadUrl?: Maybe<PresignedUrlObjectWithPolicyData>;
  largeLossRun: Array<LargeLossRunObject>;
  largeLossRunPhysDam: Array<LargeLossRunPhysDamObject>;
  licenseChecks: Array<AgentSyncCheckObject>;
  licensedStates: Array<LicensedState>;
  lossDevelopmentModelConfigs?: Maybe<QueryLossDevelopmentModelConfigsResult>;
  lossRun: Array<LossRun>;
  lossRunPhysDam: Array<LossRunPhysDam>;
  marketplaceOffers: Array<MarketplaceOffer>;
  myOpenPolicies: Array<PolicyObjectV2>;
  naicsClassCodesConstants: Array<NaicsClassCode>;
  ocpConstants: OcpOptionsObject;
  organizationIntegrations?: Maybe<Array<OrganizationIntegration>>;
  organizationLocation: OrganizationLocation;
  organizationLocations: Array<OrganizationLocation>;
  organizationMembers: Array<OrganizationMember>;
  organizationUsers: Array<User>;
  paginatedPolicies: Array<PolicyObjectV2>;
  policies: Array<Policy>;
  policy: Policy;
  policyChangeEndorsements: Array<PolicyChangeEndorsement>;
  policyClearanceResults: Array<PolicyClearanceResultObject>;
  policyFiles: Array<File>;
  policyFillData: Array<LabelValueObject>;
  policyHolderPolicies: Array<Policy>;
  policyHoldersV3: Array<Policyholder>;
  policyInvoices: Array<Invoice>;
  policyNumbersToDisplayForQuote: PolicyNumbersForQuote;
  policyholder: Policyholder;
  policyholderInvoices: Array<Invoice>;
  policyholders: Array<Policyholder>;
  policyholdersNames: Array<Policyholder>;
  policyholdersV2: PolicyholdersPaginated;
  primaryAutoCoverageOptions: Array<PrimaryAutoCoverageOptions>;
  primaryAutoExposure: PrimaryAutoExposure;
  primaryAutoLiabilityScheduleRatings: Array<ScheduleRatingsObject>;
  primaryAutoPhysicalDamageScheduleRatings: Array<ScheduleRatingsObject>;
  primaryAutoVehicleSummary: AutoSummary;
  primaryAutoVehicles: Array<PrimaryAutoVehicleObject>;
  primaryGeneralLiabilityConstants: CoverageInputsConstantsObject;
  primaryGeneralLiabilityExposure: PrimaryGeneralLiabilityExposure;
  primaryGeneralLiabilityScheduleRatings: Array<ScheduleRatingsObject>;
  primaryOcpExposure: QueryPrimaryOcpExposureResult;
  primaryRailroadExposure: QueryPrimaryRailroadExposureResult;
  primaryUnderwritingAdjustments: PrimaryUnderwritingAdjustmentsObject;
  procoreCompanies?: Maybe<Array<ProcoreCompaniesPayload>>;
  projectExcessGeneralLiabilityExposure: ProjectExcessGeneralLiabilityExposure;
  projectPrimaryAutoExposure: ProjectPrimaryAutoExposureObject;
  projectPrimaryGeneralLiabilityExposure: ProjectPrimaryGeneralLiabilityExposure;
  queryAllShepherdStateLicenses: Array<LicenseRecordObject>;
  quote: Quote;
  quoteActivities: Array<QuoteActivity>;
  quoteDetail: QuoteDetail;
  quotePdfs: Array<QuotePdf>;
  quotePricing: QueryQuotePricingResult;
  quoteRiskTier: QueryQuoteRiskTierResult;
  quoteRiskTierV2?: Maybe<QueryQuoteRiskTierV2Result>;
  quoteSubjectivities: Array<Subjectivity>;
  quotes: Array<Quote>;
  railroadConstants: RailroadOptionsObject;
  referralTriggers: QueryReferralTriggersResult;
  selectedForms: Array<SelectedForm>;
  sentryError: Scalars['String'];
  session: Session;
  shepherdAdminUsers: Array<User>;
  subcontractedCost: Array<SubcontractedObject>;
  subjectivitiesConstants: Array<SubjectivitiesConstantOutput>;
  underlyingPolicies: Array<UnderlyingPolicy>;
  underlyingPolicyAttachmentOrderOptions: Array<LabelValueObject>;
  unmannedAircraft: Array<UnmannedAircraftObject>;
  userHistoryShepsy: Array<ShepsyResponseOutput>;
  vehicleFleetTableConstants: VehicleFleetTableConstant;
  workersCompensationExposure: WorkersCompensationExposure;
  zipCodesByState: ZipCodesByState;
};

export type QueryAdditionalPolicyNumbersArgs = {
  policyId: Scalars['ID'];
};

export type QueryAutoFleetSummaryArgs = {
  exposureId: Scalars['ID'];
  variant: AutoFleetSummaryVariant;
};

export type QueryAutoRiskInputTableConstantsArgs = {
  policyId?: InputMaybe<Scalars['ID']>;
};

export type QueryAutodeskHubsArgs = {
  integrationOrganizationId: Scalars['ID'];
};

export type QueryAvailableFormsForQuoteArgs = {
  input: AvailableFormsInput;
};

export type QueryBrokerageArgs = {
  id: Scalars['ID'];
};

export type QueryBrokeragesArgs = {
  archived?: InputMaybe<Scalars['Boolean']>;
};

export type QueryCarrierArgs = {
  id: Scalars['ID'];
};

export type QueryCostOfHiresArgs = {
  exposureId: Scalars['ID'];
  variant: CostOfHireVariant;
};

export type QueryCoverageOptionsConstantsArgs = {
  state: State;
};

export type QueryDefaultAutoDataArgs = {
  input: QueryDefaultAutoDataInput;
};

export type QueryDefaultAutoZipCodesByMostCommonPayrollTerritoryArgs = {
  input: QueryDefaultAutoZipCodesByMostCommonPayrollTerritoryInput;
};

export type QueryDigitalQuoteArgs = {
  quoteId: Scalars['ID'];
};

export type QueryExcessAutoCoverageOptionsArgs = {
  input: ExcessAutoCoverageOptionsIdInput;
};

export type QueryExcessAutoExposureArgs = {
  excessAutoExposureId: Scalars['ID'];
};

export type QueryExcessAutoRiskTierVehicleSummaryArgs = {
  input: ExcessAutoVehicleV2IdInput;
};

export type QueryExcessAutoVehiclesV2Args = {
  input: ExcessAutoVehicleV2IdInput;
};

export type QueryExcessAutoVehiclesV2SummaryArgs = {
  input: ExcessAutoVehicleV2IdInput;
};

export type QueryExcessGeneralLiabilityExposureArgs = {
  excessGeneralLiabilityExposureId: Scalars['ID'];
};

export type QueryExcessGeneralLiabilityScheduleRatingsArgs = {
  quoteId: Scalars['ID'];
};

export type QueryExpectedPayrollTableConstantsArgs = {
  policyId: Scalars['ID'];
};

export type QueryExpectedPayrollsArgs = {
  exposureId: Scalars['ID'];
  variant: ExpectedPayrollVariant;
};

export type QueryExpectedSubcontractedCostTableConstantsArgs = {
  policyId: Scalars['ID'];
};

export type QueryFormGroupArgs = {
  input: IdInput;
};

export type QueryFormPdfArgs = {
  input: IdInput;
};

export type QueryFormPdfsArgs = {
  input: FormPdfsInput;
};

export type QueryFormPdfsNotOnGroupArgs = {
  input: IdInput;
};

export type QueryGetFeesPerQuoteArgs = {
  quoteDetailId: Scalars['ID'];
};

export type QueryHasNpnsArgs = {
  input: QueryHasNpnsInput;
};

export type QueryHelloArgs = {
  name?: InputMaybe<Scalars['String']>;
};

export type QueryHqZipCodeArgs = {
  input: QueryHqZipCodeInput;
};

export type QueryInsightsLinkArgs = {
  quoteId: Scalars['ID'];
};

export type QueryInsightsReportArgs = {
  quoteId: Scalars['ID'];
};

export type QueryInvoiceDownloadUrlArgs = {
  id: Scalars['ID'];
};

export type QueryLargeLossRunArgs = {
  exposureId: Scalars['ID'];
  variant: LossRunVariant;
};

export type QueryLargeLossRunPhysDamArgs = {
  exposureId: Scalars['ID'];
  variant: LossRunVariant;
};

export type QueryLicenseChecksArgs = {
  input: QueryLicenseChecksInput;
};

export type QueryLossDevelopmentModelConfigsArgs = {
  autoLiabilitySelectedLossRateUserProvided?: InputMaybe<Scalars['Float']>;
  generalLiabilitySelectedLossRateUserProvided?: InputMaybe<Scalars['Float']>;
  quoteId: Scalars['ID'];
};

export type QueryLossRunArgs = {
  exposureId: Scalars['ID'];
  variant: LossRunVariant;
};

export type QueryLossRunPhysDamArgs = {
  exposureId: Scalars['ID'];
  variant: LossRunVariant;
};

export type QueryMarketplaceOffersArgs = {
  quoteId: Scalars['ID'];
};

export type QueryMyOpenPoliciesArgs = {
  assigneeId: Scalars['ID'];
};

export type QueryNaicsClassCodesConstantsArgs = {
  policyId: Scalars['ID'];
};

export type QueryOcpConstantsArgs = {
  effectiveDate?: InputMaybe<Scalars['Date']>;
};

export type QueryOrganizationLocationArgs = {
  id: Scalars['ID'];
};

export type QueryOrganizationLocationsArgs = {
  archived?: InputMaybe<Scalars['Boolean']>;
  organizationId: Scalars['ID'];
};

export type QueryOrganizationMembersArgs = {
  input?: InputMaybe<BatchGetOrganizationMembersInput>;
};

export type QueryPaginatedPoliciesArgs = {
  input: PaginatedPolicyInput;
};

export type QueryPolicyArgs = {
  policyId: Scalars['ID'];
};

export type QueryPolicyChangeEndorsementsArgs = {
  quoteId: Scalars['ID'];
};

export type QueryPolicyClearanceResultsArgs = {
  input: QueryPolicyClearanceResultsInput;
};

export type QueryPolicyFilesArgs = {
  policyId: Scalars['ID'];
};

export type QueryPolicyFillDataArgs = {
  input: QuoteIdInput;
};

export type QueryPolicyHolderPoliciesArgs = {
  archived?: InputMaybe<Scalars['Boolean']>;
  policyholderId: Scalars['ID'];
};

export type QueryPolicyHoldersV3Args = {
  input: PolicyHoldersV3Input;
};

export type QueryPolicyInvoicesArgs = {
  policyId: Scalars['ID'];
};

export type QueryPolicyNumbersToDisplayForQuoteArgs = {
  quoteId: Scalars['ID'];
};

export type QueryPolicyholderArgs = {
  policyholderId: Scalars['ID'];
};

export type QueryPolicyholderInvoicesArgs = {
  policyholderId: Scalars['ID'];
};

export type QueryPolicyholdersV2Args = {
  archived?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type QueryPrimaryAutoCoverageOptionsArgs = {
  input: ExposureIdInput;
};

export type QueryPrimaryAutoExposureArgs = {
  primaryAutoExposureId: Scalars['ID'];
};

export type QueryPrimaryAutoLiabilityScheduleRatingsArgs = {
  quoteId: Scalars['ID'];
};

export type QueryPrimaryAutoPhysicalDamageScheduleRatingsArgs = {
  quoteId: Scalars['ID'];
};

export type QueryPrimaryAutoVehicleSummaryArgs = {
  input: ExposureIdInput;
};

export type QueryPrimaryAutoVehiclesArgs = {
  input: ExposureIdInput;
};

export type QueryPrimaryGeneralLiabilityConstantsArgs = {
  policyId: Scalars['ID'];
};

export type QueryPrimaryGeneralLiabilityExposureArgs = {
  primaryGeneralLiabilityExposureId: Scalars['ID'];
};

export type QueryPrimaryGeneralLiabilityScheduleRatingsArgs = {
  quoteId: Scalars['ID'];
};

export type QueryPrimaryOcpExposureArgs = {
  exposureId: Scalars['ID'];
};

export type QueryPrimaryRailroadExposureArgs = {
  exposureId: Scalars['ID'];
};

export type QueryPrimaryUnderwritingAdjustmentsArgs = {
  input: QuoteDetailIdInput;
};

export type QueryProcoreCompaniesArgs = {
  integrationOrganizationId: Scalars['ID'];
};

export type QueryProjectExcessGeneralLiabilityExposureArgs = {
  projectExcessGeneralLiabilityExposureId: Scalars['ID'];
};

export type QueryProjectPrimaryAutoExposureArgs = {
  id: Scalars['ID'];
};

export type QueryProjectPrimaryGeneralLiabilityExposureArgs = {
  projectPrimaryGeneralLiabilityExposureId: Scalars['ID'];
};

export type QueryQuoteArgs = {
  quoteId: Scalars['ID'];
};

export type QueryQuoteActivitiesArgs = {
  quoteId: Scalars['ID'];
};

export type QueryQuoteDetailArgs = {
  quoteId: Scalars['ID'];
};

export type QueryQuotePdfsArgs = {
  quoteId: Scalars['ID'];
};

export type QueryQuotePricingArgs = {
  forceLivePricing?: InputMaybe<Scalars['Boolean']>;
  quoteId: Scalars['ID'];
};

export type QueryQuoteRiskTierArgs = {
  quoteId: Scalars['ID'];
};

export type QueryQuoteRiskTierV2Args = {
  quoteId: Scalars['ID'];
};

export type QueryQuoteSubjectivitiesArgs = {
  quoteId: Scalars['ID'];
};

export type QueryQuotesArgs = {
  policyId: Scalars['ID'];
};

export type QueryRailroadConstantsArgs = {
  effectiveDate?: InputMaybe<Scalars['Date']>;
};

export type QueryReferralTriggersArgs = {
  quoteId: Scalars['ID'];
};

export type QuerySelectedFormsArgs = {
  input?: InputMaybe<SelectedFormsInput>;
};

export type QuerySubcontractedCostArgs = {
  exposureId: Scalars['ID'];
  variant: SubcontractedCostVariant;
};

export type QueryUnderlyingPoliciesArgs = {
  input: BatchGetUnderlyingPoliciesInput;
};

export type QueryUnmannedAircraftArgs = {
  exposureId: Scalars['ID'];
  variant: UnmannedAircraftVariant;
};

export type QueryWorkersCompensationExposureArgs = {
  primaryWorkersCompensationExposureId: Scalars['ID'];
};

export type QueryDefaultAutoDataInput = {
  toFind: Array<DefaultAutoDataInput>;
};

export type QueryDefaultAutoZipCodesByMostCommonPayrollTerritoryInput = {
  product: PolicyProduct | `${PolicyProduct}`;
  quoteId: Scalars['ID'];
  states: Array<Scalars['String']>;
};

export type QueryHasNpnsInput = {
  policyId: Scalars['String'];
};

export type QueryHqZipCodeInput = {
  product: PolicyProduct | `${PolicyProduct}`;
  quoteId: Scalars['ID'];
};

export type QueryLicenseChecksInput = {
  policyId: Scalars['String'];
};

export type QueryLossDevelopmentModelConfigsResult =
  | BaseError
  | QueryLossDevelopmentModelConfigsSuccess
  | ZodError;

export type QueryLossDevelopmentModelConfigsSuccess = {
  __typename?: 'QueryLossDevelopmentModelConfigsSuccess';
  data: LossDevelopmentModelConfigsObject;
};

export type QueryPolicyClearanceResultsInput = {
  policyId: Scalars['ID'];
};

export type QueryPrimaryOcpExposureResult = BaseError | QueryPrimaryOcpExposureSuccess | ZodError;

export type QueryPrimaryOcpExposureSuccess = {
  __typename?: 'QueryPrimaryOcpExposureSuccess';
  data: PrimaryOcpExposure;
};

export type QueryPrimaryRailroadExposureResult =
  | BaseError
  | QueryPrimaryRailroadExposureSuccess
  | ZodError;

export type QueryPrimaryRailroadExposureSuccess = {
  __typename?: 'QueryPrimaryRailroadExposureSuccess';
  data: PrimaryRailroadExposure;
};

export type QueryQuotePricingResult = BaseError | QueryQuotePricingSuccess | ZodError;

export type QueryQuotePricingSuccess = {
  __typename?: 'QueryQuotePricingSuccess';
  data: QuotePricing;
};

export type QueryQuoteRiskTierResult = BaseError | QueryQuoteRiskTierSuccess | ZodError;

export type QueryQuoteRiskTierSuccess = {
  __typename?: 'QueryQuoteRiskTierSuccess';
  data: Array<Maybe<Pricing>>;
};

export type QueryQuoteRiskTierV2Result = BaseError | QueryQuoteRiskTierV2Success | ZodError;

export type QueryQuoteRiskTierV2Success = {
  __typename?: 'QueryQuoteRiskTierV2Success';
  data: QuoteRiskTierObject;
};

export type QueryReferralTriggersResult = BaseError | QueryReferralTriggersSuccess | ZodError;

export type QueryReferralTriggersSuccess = {
  __typename?: 'QueryReferralTriggersSuccess';
  data: QuoteReferralCheck;
};

export type Quote = {
  __typename?: 'Quote';
  acceptedAt?: Maybe<Scalars['DateTime']>;
  autodeskDataRequested?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['DateTime'];
  declinedAt?: Maybe<Scalars['DateTime']>;
  declinedReason?: Maybe<DeclinedReason | `${DeclinedReason}`>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  discardedAt?: Maybe<Scalars['DateTime']>;
  /** @deprecated do not use */
  files?: Maybe<Array<File>>;
  formVendor?: Maybe<FormVendor | `${FormVendor}`>;
  id: Scalars['ID'];
  indicatedAt?: Maybe<Scalars['DateTime']>;
  indicatedLostAt?: Maybe<Scalars['DateTime']>;
  indicatedLostNotes?: Maybe<Scalars['String']>;
  indicatedLostReason?: Maybe<IndicatedLostReason | `${IndicatedLostReason}`>;
  indicatedTime?: Maybe<Scalars['Date']>;
  issuedAt?: Maybe<Scalars['DateTime']>;
  modelVersion: QuoteModelVersion;
  name: Scalars['String'];
  openspaceDataRequested?: Maybe<Scalars['Boolean']>;
  policy: Policy;
  policyChange?: Maybe<Scalars['Boolean']>;
  policyId: Scalars['ID'];
  primaryQuoteSelection?: Maybe<PrimaryUnderwritingAdjustmentsObject>;
  procoreDataRequested?: Maybe<Scalars['Boolean']>;
  /** @deprecated do not use */
  quoteDetail: QuoteDetail;
  quoteDriveFolderId?: Maybe<Scalars['String']>;
  quoteDriveFolderUrl?: Maybe<Scalars['String']>;
  quoteLostPrice?: Maybe<Scalars['Float']>;
  /** @deprecated do not use */
  quotePdfs: Array<QuotePdf>;
  quoteSelection: QuoteSelection;
  quotedAt?: Maybe<Scalars['DateTime']>;
  quotedLostAt?: Maybe<Scalars['DateTime']>;
  quotedLostNotes?: Maybe<Scalars['String']>;
  quotedLostReason?: Maybe<QuotedLostReason | `${QuotedLostReason}`>;
  reasonForTechDataAbsence?: Maybe<ReasonForTechDataAbsence | `${ReasonForTechDataAbsence}`>;
  referredAt?: Maybe<Scalars['DateTime']>;
  referredToCore?: Maybe<Scalars['Boolean']>;
  referredToTreaty?: Maybe<Scalars['Boolean']>;
  status: QuoteStatus | `${QuoteStatus}`;
  statusTransitions: QuoteStatusTransitions;
  timeToIndicated?: Maybe<Scalars['Float']>;
  updatedAt: Scalars['DateTime'];
};

export type QuoteActivity = {
  __typename?: 'QuoteActivity';
  activity?: Maybe<ActivityObject>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  quote: Quote;
  quoteId: Scalars['ID'];
  type: QuoteActivityType | `${QuoteActivityType}`;
  updatedAt: Scalars['DateTime'];
  user: User;
  userId: Scalars['ID'];
};

export enum QuoteActivityType {
  Action = 'ACTION',
  Comment = 'COMMENT',
}

export type QuoteDetail = {
  __typename?: 'QuoteDetail';
  excessAutoExposure?: Maybe<ExcessAutoExposure>;
  excessGeneralLiabilityExposure?: Maybe<ExcessGeneralLiabilityExposure>;
  id: Scalars['ID'];
  isLead?: Maybe<Scalars['Boolean']>;
  primaryAutoExposure?: Maybe<PrimaryAutoExposure>;
  primaryGeneralLiabilityExposure?: Maybe<PrimaryGeneralLiabilityExposure>;
  primaryOcpExposure?: Maybe<PrimaryOcpExposure>;
  primaryRailroadExposure?: Maybe<PrimaryRailroadExposure>;
  primaryWorkersCompensationExposure?: Maybe<WorkersCompensationExposure>;
  projectExcessGeneralLiabilityExposure?: Maybe<ProjectExcessGeneralLiabilityExposure>;
  projectPrimaryAutoExposure?: Maybe<ProjectPrimaryAutoExposureObject>;
  projectPrimaryGeneralLiabilityExposure?: Maybe<ProjectPrimaryGeneralLiabilityExposure>;
  version: Scalars['Int'];
};

export type QuoteDetailIdInput = {
  quoteDetailId: Scalars['ID'];
};

export type QuoteFeesObject = {
  __typename?: 'QuoteFeesObject';
  brokerCommissionPercentage?: Maybe<Scalars['Float']>;
  employersLiabilityFeePercentage?: Maybe<Scalars['Float']>;
  frontingFeePercentage?: Maybe<Scalars['Float']>;
  profitLoadFeePercentage?: Maybe<Scalars['Float']>;
  shepherdMgaFeePercentage?: Maybe<Scalars['Float']>;
  totalExpenseLoadPercentage: Scalars['Float'];
  tpaFeePercentage?: Maybe<Scalars['Float']>;
  triaFeePercentage?: Maybe<Scalars['Float']>;
};

export type QuoteIdInput = {
  quoteId: Scalars['ID'];
};

export type QuoteModelVersion = {
  __typename?: 'QuoteModelVersion';
  major: Scalars['Int'];
  minor: Scalars['Int'];
  patch: Scalars['Int'];
  version: Scalars['String'];
};

export type QuotePdf = {
  __typename?: 'QuotePdf';
  createdAt: Scalars['DateTime'];
  file: File;
  fileId: Scalars['ID'];
  id: Scalars['ID'];
  status: Scalars['String'];
  type?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export enum QuotePdfStatus {
  Active = 'ACTIVE',
  Void = 'VOID',
}

export type QuotePricing =
  | ExcessPracticePricingObject
  | ExcessProjectPricingObject
  | PrimaryOwnersAndContractorsProtectivePricingObject
  | PrimaryPracticePricingObject
  | PrimaryProjectPricingObject
  | PrimaryRailroadPricingObject;

export type QuoteReferralCheck = {
  __typename?: 'QuoteReferralCheck';
  referralRequired?: Maybe<Scalars['Boolean']>;
  referralTriggers: Array<Scalars['String']>;
};

export type QuoteRiskTierObject = ExcessQuoteRiskTierObject | ProjectExcessQuoteRiskTierObject;

export type QuoteSelection = {
  __typename?: 'QuoteSelection';
  brokerCommissionOverride?: Maybe<Scalars['Float']>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  employersLiability: Scalars['Boolean'];
  excessOfWrap: Scalars['Float'];
  generalAggregateLimit?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  insuredRetentionAmount?: Maybe<Scalars['Float']>;
  policyLimit?: Maybe<Scalars['Float']>;
  productsCompletedOperationsAggregateLimit?: Maybe<Scalars['Float']>;
  profitLoadFeeOverride?: Maybe<Scalars['Float']>;
  quotaShare?: Maybe<Scalars['Float']>;
  quoteId: Scalars['ID'];
  selfAuditStatus?: Maybe<SelfAuditStatusOptions | `${SelfAuditStatusOptions}`>;
  shepherdMgaFeesOverride?: Maybe<Scalars['Float']>;
  stampingFeeOverride?: Maybe<Scalars['Float']>;
  surplusLinesTaxesOverride?: Maybe<Scalars['Float']>;
  tria: Scalars['Boolean'];
  underwriterAuditor?: Maybe<User>;
  underwriterAuditorId?: Maybe<Scalars['ID']>;
  updatedAt: Scalars['DateTime'];
  useCredibilityWeightedPath?: Maybe<Scalars['Boolean']>;
  uwAdjustment: Scalars['Float'];
  uwAdjustmentNotes?: Maybe<Scalars['String']>;
};

export enum QuoteStatus {
  Accepted = 'ACCEPTED',
  Blocked = 'BLOCKED',
  Cancelled = 'CANCELLED',
  Declined = 'DECLINED',
  Discarded = 'DISCARDED',
  Draft = 'DRAFT',
  Indicated = 'INDICATED',
  IndicatedLost = 'INDICATED_LOST',
  Issued = 'ISSUED',
  PendingClearance = 'PENDING_CLEARANCE',
  Quoted = 'QUOTED',
  QuotedLost = 'QUOTED_LOST',
}

export type QuoteStatusChangedActivityDataObject = {
  __typename?: 'QuoteStatusChangedActivityDataObject';
  notes?: Maybe<Scalars['String']>;
  previousValue: QuoteStatus | `${QuoteStatus}`;
  reason?: Maybe<Scalars['String']>;
  updatedValue: QuoteStatus | `${QuoteStatus}`;
};

export type QuoteStatusTransitions = {
  __typename?: 'QuoteStatusTransitions';
  actions: Array<QuoteStatus | `${QuoteStatus}`>;
  canChangeExperienceRating: Scalars['Boolean'];
  canEdit: Scalars['Boolean'];
  canEditUnderlyingPolices: Scalars['Boolean'];
  canIssueBinder: Scalars['Boolean'];
  canIssuePolicy: Scalars['Boolean'];
  canIssueQuote: Scalars['Boolean'];
  canMarkAsReferral: Scalars['Boolean'];
  canViewDigitalQuote: Scalars['Boolean'];
  primary?: Maybe<QuoteStatus | `${QuoteStatus}`>;
};

export enum QuotedLostReason {
  AdditionalLimits = 'ADDITIONAL_LIMITS',
  Attachment = 'ATTACHMENT',
  Broker = 'BROKER',
  Capacity = 'CAPACITY',
  FinancialRatingPaper = 'FINANCIAL_RATING_PAPER',
  Nonadmitted = 'NONADMITTED',
  Other = 'OTHER',
  Price = 'PRICE',
  Renewed = 'RENEWED',
  RequestedStructureOutOfAppetite = 'REQUESTED_STRUCTURE_OUT_OF_APPETITE',
  Terms = 'TERMS',
}

export type RadiusConstant = {
  __typename?: 'RadiusConstant';
  label: Scalars['String'];
  radius: Scalars['String'];
  value: Scalars['String'];
  vehicleClass: Scalars['String'];
};

export enum RailroadLimitEnum {
  Rr_25000_75000 = 'RR_25000_75000',
  Rr_50000_150000 = 'RR_50000_150000',
  Rr_100000_300000 = 'RR_100000_300000',
  Rr_159999_500000 = 'RR_159999_500000',
  Rr_300000_1000000 = 'RR_300000_1000000',
  Rr_500000_1500000 = 'RR_500000_1500000',
  Rr_1000000_3000000 = 'RR_1000000_3000000',
  Rr_1500000_5000000 = 'RR_1500000_5000000',
  Rr_2000000_6000000 = 'RR_2000000_6000000',
  Rr_5000000_15000000 = 'RR_5000000_15000000',
}

export type RailroadLimitObject = {
  __typename?: 'RailroadLimitObject';
  label: Scalars['String'];
  value: RailroadLimitEnum | `${RailroadLimitEnum}`;
};

export type RailroadOptionsObject = {
  __typename?: 'RailroadOptionsObject';
  numberOfTrains: Array<NumberOfTrainsObject>;
  railroadLimit: Array<RailroadLimitObject>;
  typeOfOperations: Array<RailroadTypeOfOperationsObject>;
};

export enum RailroadTypeOfOperationsEnum {
  Iso_16291 = 'ISO_16291',
  Iso_16292 = 'ISO_16292',
  Iso_27111 = 'ISO_27111',
  Iso_27112 = 'ISO_27112',
  Iso_40011 = 'ISO_40011',
  Iso_40012 = 'ISO_40012',
  Iso_40013 = 'ISO_40013',
  Iso_40014 = 'ISO_40014',
  Iso_91181 = 'ISO_91181',
  Iso_93040 = 'ISO_93040',
  Iso_93161 = 'ISO_93161',
  Iso_93163 = 'ISO_93163',
}

export type RailroadTypeOfOperationsObject = {
  __typename?: 'RailroadTypeOfOperationsObject';
  label: Scalars['String'];
  value: RailroadTypeOfOperationsEnum | `${RailroadTypeOfOperationsEnum}`;
};

export enum ReasonForTechDataAbsence {
  NoIntentionToQuote = 'NO_INTENTION_TO_QUOTE',
  PricingNotCompetitive = 'PRICING_NOT_COMPETITIVE',
  QuotedLayerTooHigh = 'QUOTED_LAYER_TOO_HIGH',
}

export type RentalReimbursementDaysObject = {
  __typename?: 'RentalReimbursementDaysObject';
  label: Scalars['String'];
  value: RentalReimbursementDaysObjectValueEnum | `${RentalReimbursementDaysObjectValueEnum}`;
};

export enum RentalReimbursementDaysObjectValueEnum {
  RentalReimbursementDaysValues_30 = 'RENTAL_REIMBURSEMENT_DAYS_VALUES_30',
  RentalReimbursementDaysValues_45 = 'RENTAL_REIMBURSEMENT_DAYS_VALUES_45',
  RentalReimbursementDaysValues_60 = 'RENTAL_REIMBURSEMENT_DAYS_VALUES_60',
}

export type RentalReimbursementObject = {
  __typename?: 'RentalReimbursementObject';
  label: Scalars['String'];
  value: RentalReimbursementObjectValueEnum | `${RentalReimbursementObjectValueEnum}`;
};

export enum RentalReimbursementObjectValueEnum {
  RentalReimbursementLimitPerDayValues_30 = 'RENTAL_REIMBURSEMENT_LIMIT_PER_DAY_VALUES_30',
  RentalReimbursementLimitPerDayValues_75 = 'RENTAL_REIMBURSEMENT_LIMIT_PER_DAY_VALUES_75',
}

export enum RevenueType {
  OneBillionPlus = 'ONE_BILLION_PLUS',
  ThreeHundredMillionOneBillion = 'THREE_HUNDRED_MILLION_ONE_BILLION',
  UnderThreeHundredMillion = 'UNDER_THREE_HUNDRED_MILLION',
}

export type RiskTierAutoModel = {
  __typename?: 'RiskTierAutoModel';
  attachmentPoint?: Maybe<Scalars['Float']>;
  vehicleFleet: Array<VehicleFleet>;
};

export type RiskTierAutoModelInput = {
  attachmentPoint?: InputMaybe<Scalars['Float']>;
  vehicleFleet: Array<VehicleFleetInput>;
};

export type RiskTierAutoModelOutput = {
  __typename?: 'RiskTierAutoModelOutput';
  attachmentCapacities?: Maybe<Array<AttachmentCapacityOutput>>;
  autoWeightedAvg: Scalars['Float'];
  capacity?: Maybe<Scalars['Int']>;
  largeFleetOverride?: Maybe<TierEnum | `${TierEnum}`>;
  maxCapacityAtTier?: Maybe<Scalars['Int']>;
  minAttach?: Maybe<Scalars['Int']>;
  riskTierByVehicleSchedule: Array<RiskTierAutoOutput>;
  riskTierFinal?: Maybe<TierEnum | `${TierEnum}`>;
  totalVehiclesOverride?: Maybe<TierEnum | `${TierEnum}`>;
};

export type RiskTierAutoOutput = {
  __typename?: 'RiskTierAutoOutput';
  count: Scalars['Int'];
  pctOverall: Scalars['Float'];
  sizeClass: Scalars['String'];
  state: State | `${State}`;
  stateTier: Scalars['Int'];
  typeOfVehicle: Scalars['String'];
  weightedTier: Scalars['Float'];
};

export type RiskTierGlModelOutput = {
  __typename?: 'RiskTierGlModelOutput';
  attachmentCapacities?: Maybe<Array<AttachmentCapacityOutput>>;
  capacity?: Maybe<Scalars['Int']>;
  cdOverride?: Maybe<TierEnum | `${TierEnum}`>;
  isoOverride?: Maybe<TierEnum | `${TierEnum}`>;
  maxCapacityAtTier?: Maybe<Scalars['Int']>;
  minAttach?: Maybe<Scalars['Int']>;
  payrollWeightedAvg?: Maybe<Scalars['Float']>;
  riskTierFinal: TierEnum | `${TierEnum}`;
  tier5PayrollOverride?: Maybe<TierEnum | `${TierEnum}`>;
  wildfireOverride?: Maybe<TierEnum | `${TierEnum}`>;
};

export type SafeOrganizationIntegrationPayload = {
  id: Scalars['ID'];
  provider: Scalars['String'];
  providerOrganizationId: Scalars['String'];
  providerOrganizationName: Scalars['String'];
  providerUserId: Scalars['String'];
};

export enum ScheduleRatingTypes {
  AutoLiability = 'AUTO_LIABILITY',
  AutoPhysicalDamage = 'AUTO_PHYSICAL_DAMAGE',
  CommercialGeneralLiability = 'COMMERCIAL_GENERAL_LIABILITY',
}

export type ScheduleRatingsObject = {
  __typename?: 'ScheduleRatingsObject';
  appliedModification?: Maybe<Scalars['Float']>;
  creditMax?: Maybe<Scalars['Float']>;
  debitMax?: Maybe<Scalars['Float']>;
  identifier?: Maybe<Scalars['String']>;
  rationale?: Maybe<Scalars['String']>;
  riskCharacteristic: Scalars['String'];
  usageDescription?: Maybe<Scalars['String']>;
};

export type SclOptionsObject = {
  __typename?: 'SclOptionsObject';
  label: Scalars['String'];
  value: Scalars['String'];
};

export type SecondaryClassPrivateClass = {
  __typename?: 'SecondaryClassPrivateClass';
  label: Scalars['String'];
  secondaryClass: Scalars['String'];
  value: Scalars['String'];
  vehicleClass: Scalars['String'];
};

export type SecondaryClassPrivateClassDetail = {
  __typename?: 'SecondaryClassPrivateClassDetail';
  class: Scalars['String'];
  classDetail: Scalars['String'];
  classValue: Scalars['String'];
  label: Scalars['String'];
  value: Scalars['String'];
  vehicleClass: Scalars['String'];
};

export type SeedFleetResponse = {
  __typename?: 'SeedFleetResponse';
  ok: Scalars['Boolean'];
};

export type SeedVehiclesAndCoveragesInput = {
  coverages: Array<PrimaryAutoCoverageOptionsInput>;
  exposureId: Scalars['ID'];
  isProject: Scalars['Boolean'];
  vehicles: Array<PrimaryAutoVehicleInputV2>;
};

export type SelectOption = {
  __typename?: 'SelectOption';
  label: Scalars['String'];
  value: Scalars['Option'];
};

export type SelectedForm = {
  __typename?: 'SelectedForm';
  formPdf: PartialFormPdfObject;
  formPdfId: Scalars['ID'];
  id: Scalars['ID'];
  quoteId?: Maybe<Scalars['ID']>;
  uwComment?: Maybe<Scalars['String']>;
};

export type SelectedFormsInput = {
  quoteId: Scalars['ID'];
};

export enum SelfAuditStatusOptions {
  AuditComplete = 'AUDIT_COMPLETE',
  AuditOutstanding = 'AUDIT_OUTSTANDING',
  FilePrepComplete = 'FILE_PREP_COMPLETE',
  FilePrepOutstanding = 'FILE_PREP_OUTSTANDING',
}

export type Session = {
  __typename?: 'Session';
  emailDomain?: Maybe<Scalars['String']>;
  organization?: Maybe<Organization>;
  organizations: Array<Organization>;
  token: Scalars['String'];
  user: User;
};

export type SessionMutationPayload = {
  __typename?: 'SessionMutationPayload';
  session: Session;
};

export enum ShepherdLine {
  Excess = 'EXCESS',
  Primary = 'PRIMARY',
}

export type ShepsyInput = {
  prompt: Scalars['String'];
};

export type ShepsyResponseOutput = {
  __typename?: 'ShepsyResponseOutput';
  createdAt: Scalars['DateTime'];
  error?: Maybe<Scalars['String']>;
  feedback?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  prompt: Scalars['String'];
  response?: Maybe<Scalars['String']>;
  userId: Scalars['ID'];
};

export type SizeClassConstant = {
  __typename?: 'SizeClassConstant';
  label: Scalars['String'];
  sizeClass: Scalars['String'];
  value: Scalars['String'];
  vehicleClass: Scalars['String'];
};

export enum SortOrder {
  Asc = 'ASC',
  Desc = 'DESC',
}

export enum State {
  Ak = 'AK',
  Al = 'AL',
  Ar = 'AR',
  Az = 'AZ',
  Ca = 'CA',
  Co = 'CO',
  Ct = 'CT',
  Dc = 'DC',
  De = 'DE',
  Fl = 'FL',
  Ga = 'GA',
  Hi = 'HI',
  Ia = 'IA',
  Id = 'ID',
  Il = 'IL',
  In = 'IN',
  Ks = 'KS',
  Ky = 'KY',
  La = 'LA',
  Ma = 'MA',
  Md = 'MD',
  Me = 'ME',
  Mi = 'MI',
  Mn = 'MN',
  Mo = 'MO',
  Ms = 'MS',
  Mt = 'MT',
  Nc = 'NC',
  Nd = 'ND',
  Ne = 'NE',
  Nh = 'NH',
  Nj = 'NJ',
  Nm = 'NM',
  Nv = 'NV',
  Ny = 'NY',
  Oh = 'OH',
  Ok = 'OK',
  Or = 'OR',
  Pa = 'PA',
  Ri = 'RI',
  Sc = 'SC',
  Sd = 'SD',
  Tn = 'TN',
  Tx = 'TX',
  Ut = 'UT',
  Va = 'VA',
  Vt = 'VT',
  Wa = 'WA',
  Wi = 'WI',
  Wv = 'WV',
  Wy = 'WY',
}

export type StateConstant = {
  __typename?: 'StateConstant';
  abbreviation: Scalars['String'];
  label: Scalars['String'];
  name: Scalars['String'];
  value: Scalars['String'];
};

export type StateExceptionRulesEditInput = {
  id: Scalars['ID'];
  state: Scalars['String'];
  status: FormStatus | `${FormStatus}`;
};

export type StateExceptionRulesInput = {
  state: Scalars['String'];
  status: FormStatus | `${FormStatus}`;
};

export type StateForm = {
  __typename?: 'StateForm';
  id: Scalars['ID'];
  state: Scalars['String'];
  status: FormStatus | `${FormStatus}`;
};

export type StateZipCode = {
  __typename?: 'StateZipCode';
  state: Scalars['String'];
  zipCode: Scalars['String'];
};

export type StatedAmount = {
  __typename?: 'StatedAmount';
  label: Scalars['String'];
  statedAmount: Scalars['String'];
  value: Scalars['String'];
};

export enum SubcontractedCostVariant {
  ExcessPractice = 'EXCESS_PRACTICE',
  ExcessProject = 'EXCESS_PROJECT',
  PrimaryPractice = 'PRIMARY_PRACTICE',
  PrimaryProject = 'PRIMARY_PROJECT',
}

export type SubcontractedInput = {
  isoClass: Scalars['String'];
  ncciClass?: InputMaybe<Scalars['String']>;
  payroll: Scalars['Float'];
  state: State | `${State}`;
  territory: Scalars['String'];
};

export enum SubcontractedIsoClass {
  Iso_91581 = 'ISO_91581',
  Iso_91582 = 'ISO_91582',
  Iso_91583 = 'ISO_91583',
  Iso_91584 = 'ISO_91584',
  Iso_91585 = 'ISO_91585',
  Iso_91587 = 'ISO_91587',
  Iso_91588 = 'ISO_91588',
  Iso_91588Pco = 'ISO_91588_PCO',
  Iso_91588Prem = 'ISO_91588_PREM',
  Iso_91589 = 'ISO_91589',
  Iso_91591 = 'ISO_91591',
}

export type SubcontractedIsoClassConstant = {
  __typename?: 'SubcontractedIsoClassConstant';
  description: Scalars['String'];
  isoCglCode: Scalars['String'];
  label: Scalars['String'];
  value: Scalars['String'];
};

export type SubcontractedObject = {
  __typename?: 'SubcontractedObject';
  id: Scalars['ID'];
  isoClass: Scalars['String'];
  ncciClass?: Maybe<Scalars['String']>;
  payroll: Scalars['Float'];
  state: Scalars['String'];
  territory: Scalars['String'];
};

export type SubjectivitiesConstantOutput = {
  __typename?: 'SubjectivitiesConstantOutput';
  text: Scalars['String'];
  type: SubjectivityType | `${SubjectivityType}`;
};

export type Subjectivity = {
  __typename?: 'Subjectivity';
  completed?: Maybe<Scalars['Boolean']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  text: Scalars['String'];
  type: SubjectivityType | `${SubjectivityType}`;
};

export type SubjectivityInputObject = {
  completed?: InputMaybe<Scalars['Boolean']>;
  text: Scalars['String'];
  type: SubjectivityType | `${SubjectivityType}`;
};

export enum SubjectivityType {
  Subjectivity_1 = 'SUBJECTIVITY_1',
  Subjectivity_2 = 'SUBJECTIVITY_2',
  Subjectivity_3 = 'SUBJECTIVITY_3',
  Subjectivity_4 = 'SUBJECTIVITY_4',
  Subjectivity_5 = 'SUBJECTIVITY_5',
  SubjectivityCustom = 'SUBJECTIVITY_CUSTOM',
}

export type SubmissionDetails = {
  __typename?: 'SubmissionDetails';
  casualtyPro: Scalars['Boolean'];
  effectiveDate: Scalars['Date'];
  indicationTime: Scalars['Float'];
  insuredName: Scalars['String'];
  type: PolicyProduct | `${PolicyProduct}`;
  underwriterEmail: Scalars['String'];
  underwriterFirstName?: Maybe<Scalars['String']>;
  underwriterImageUrl: Scalars['String'];
  underwriterInitials: Scalars['String'];
  underwriterLastName?: Maybe<Scalars['String']>;
  underwriterTitle: Scalars['String'];
};

export type Success = {
  __typename?: 'Success';
  success: Scalars['Boolean'];
};

export type TerritoryConstant = {
  __typename?: 'TerritoryConstant';
  label: Scalars['String'];
  state: State | `${State}`;
  territory?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export enum TierEnum {
  Declined = 'DECLINED',
  False = 'FALSE',
  Na = 'NA',
  ReferToGl = 'REFER_TO_GL',
  Tier_0 = 'TIER_0',
  Tier_1 = 'TIER_1',
  Tier_2 = 'TIER_2',
  Tier_3 = 'TIER_3',
  Tier_4 = 'TIER_4',
  Tier_5 = 'TIER_5',
  True = 'TRUE',
}

export enum TimePeriodEnum {
  Annually = 'ANNUALLY',
  Monthly = 'MONTHLY',
  Quarterly = 'QUARTERLY',
}

export type TowAndLaborCostObject = {
  __typename?: 'TowAndLaborCostObject';
  label: Scalars['String'];
  state: Scalars['String'];
  value: TowAndLaborCostObjectValueEnum | `${TowAndLaborCostObjectValueEnum}`;
};

export enum TowAndLaborCostObjectValueEnum {
  Cw50 = 'CW50',
  Cw100 = 'CW100',
  Cw200 = 'CW200',
  Cw300 = 'CW300',
  Cw700 = 'CW700',
  Cw1000 = 'CW1000',
}

export enum TriaOption {
  Na = 'NA',
  Rejected = 'REJECTED',
  Selected = 'SELECTED',
}

export type TypeOfVehicleConstant = {
  __typename?: 'TypeOfVehicleConstant';
  label: Scalars['String'];
  value: Scalars['String'];
  vehicleClassification: Scalars['String'];
  vehicleType: Scalars['String'];
};

export enum TypeOfVehicleEnum {
  Ppt = 'PPT',
  Ttt = 'TTT',
}

export type UimbiCoverageOptionsConstant = {
  __typename?: 'UimbiCoverageOptionsConstant';
  label: Scalars['String'];
  state: State | `${State}`;
  value: Scalars['String'];
};

export type UimbiLimitOptionsConstant = {
  __typename?: 'UimbiLimitOptionsConstant';
  label: Scalars['String'];
  state: State | `${State}`;
  value: Scalars['String'];
};

export type UmbiCoverageOptionsConstant = {
  __typename?: 'UmbiCoverageOptionsConstant';
  label: Scalars['String'];
  state: State | `${State}`;
  value: Scalars['String'];
};

export type UmbiLimitOptionsConstant = {
  __typename?: 'UmbiLimitOptionsConstant';
  label: Scalars['String'];
  state: State | `${State}`;
  value: Scalars['String'];
};

export type UmpdCoverageOptionsConstantObject = {
  __typename?: 'UmpdCoverageOptionsConstantObject';
  label: Scalars['String'];
  state: State | `${State}`;
  value: Scalars['String'];
};

export type UmpdLimitOptionsConstantObject = {
  __typename?: 'UmpdLimitOptionsConstantObject';
  label: Scalars['String'];
  state: State | `${State}`;
  value: Scalars['String'];
};

export type UnderlyingPolicy = {
  __typename?: 'UnderlyingPolicy';
  attachmentOrder: Scalars['String'];
  attachmentOrderRank: Scalars['Int'];
  attachmentPoint?: Maybe<Scalars['Float']>;
  displayType: Scalars['String'];
  effectiveDate: Scalars['Date'];
  expirationDate: Scalars['Date'];
  id: Scalars['String'];
  limits: Array<UnderlyingPolicyLimit>;
  policyNumber: Scalars['String'];
  quoteId: Scalars['ID'];
  type: UnderlyingPolicyType | `${UnderlyingPolicyType}`;
  underlyingPolicyCarrier?: Maybe<Organization>;
  underlyingPolicyCarrierId?: Maybe<Scalars['String']>;
};

export type UnderlyingPolicyLimit = {
  __typename?: 'UnderlyingPolicyLimit';
  amount: Scalars['Float'];
  type: UnderlyingPolicyLimitType | `${UnderlyingPolicyLimitType}`;
};

export type UnderlyingPolicyLimitInput = {
  amount: Scalars['Float'];
  type: UnderlyingPolicyLimitType | `${UnderlyingPolicyLimitType}`;
};

export enum UnderlyingPolicyLimitType {
  CombinedSingle = 'COMBINED_SINGLE',
  EachAccident = 'EACH_ACCIDENT',
  EachEmployee = 'EACH_EMPLOYEE',
  EachOccurrence = 'EACH_OCCURRENCE',
  FullLimit = 'FULL_LIMIT',
  GeneralAggregate = 'GENERAL_AGGREGATE',
  Policy = 'POLICY',
  ProductsCompletedOperationsAggregate = 'PRODUCTS_COMPLETED_OPERATIONS_AGGREGATE',
  QuotaShareLimit = 'QUOTA_SHARE_LIMIT',
}

export enum UnderlyingPolicyType {
  AircraftLiability = 'AIRCRAFT_LIABILITY',
  Auto = 'AUTO',
  AutoQuotaShare = 'AUTO_QUOTA_SHARE',
  EmployeeBenefitLiability = 'EMPLOYEE_BENEFIT_LIABILITY',
  EmployersLiability = 'EMPLOYERS_LIABILITY',
  ExcessLiability = 'EXCESS_LIABILITY',
  ExcessLiabilityQuotaShare = 'EXCESS_LIABILITY_QUOTA_SHARE',
  ForeignAutomobileLiability = 'FOREIGN_AUTOMOBILE_LIABILITY',
  ForeignEmployersLiability = 'FOREIGN_EMPLOYERS_LIABILITY',
  ForeignGeneralLiability = 'FOREIGN_GENERAL_LIABILITY',
  GeneralLiability = 'GENERAL_LIABILITY',
  GeneralLiabilityQuotaShare = 'GENERAL_LIABILITY_QUOTA_SHARE',
  MarineLiability = 'MARINE_LIABILITY',
  ProtectionAndIndemnity = 'PROTECTION_AND_INDEMNITY',
  RailroadLiability = 'RAILROAD_LIABILITY',
  Umbrella = 'UMBRELLA',
  WatercraftLiability = 'WATERCRAFT_LIABILITY',
}

export type UnderwriterAdjustmentUpdatedDataObject = {
  __typename?: 'UnderwriterAdjustmentUpdatedDataObject';
  previousValue?: Maybe<Scalars['Float']>;
  reason?: Maybe<Scalars['String']>;
  updatedValue: Scalars['Float'];
};

export type UnmannedAircraftInput = {
  maximumTakeOffWeight: Scalars['String'];
  ownershipAndOperation: Scalars['String'];
  primaryPlace: Scalars['String'];
  state: Scalars['String'];
  usage: Scalars['String'];
};

export enum UnmannedAircraftMaximumTakeOffWeightValueEnum {
  Weight_1LbOrLess = 'WEIGHT_1_LB_OR_LESS',
  WeightMoreThan_1LbTo_5Lb = 'WEIGHT_MORE_THAN_1_LB_TO_5_LB',
  WeightMoreThan_5LbTo_15Lb = 'WEIGHT_MORE_THAN_5_LB_TO_15_LB',
  WeightMoreThan_15LbTo_55Lb = 'WEIGHT_MORE_THAN_15_LB_TO_55_LB',
}

export type UnmannedAircraftObject = {
  __typename?: 'UnmannedAircraftObject';
  maximumTakeOffWeight: Scalars['String'];
  ownershipAndOperation: Scalars['String'];
  primaryPlace: Scalars['String'];
  state: Scalars['String'];
  usage: Scalars['String'];
};

export type UnmannedAircraftOwnershipAndOperationModifiersOptionsObject = {
  __typename?: 'UnmannedAircraftOwnershipAndOperationModifiersOptionsObject';
  label: Scalars['String'];
  value:
    | UnmannedAircraftOwnershipAndOperationValueEnum
    | `${UnmannedAircraftOwnershipAndOperationValueEnum}`;
};

export type UnmannedAircraftOwnershipAndOperationOptionsObject = {
  __typename?: 'UnmannedAircraftOwnershipAndOperationOptionsObject';
  label: Scalars['String'];
  value:
    | UnmannedAircraftPlaceOfOperationModifiersValueEnum
    | `${UnmannedAircraftPlaceOfOperationModifiersValueEnum}`;
};

export enum UnmannedAircraftOwnershipAndOperationValueEnum {
  OwnedUnmannedAircraftOperatedOnInsuredsBehalfByHiredOperator = 'OWNED_UNMANNED_AIRCRAFT_OPERATED_ON_INSUREDS_BEHALF_BY_HIRED_OPERATOR',
  TheInsuredIsAHiredOperatorpilotOperatingUnmannedAircraftProvidedByClient = 'THE_INSURED_IS_A_HIRED_OPERATORPILOT_OPERATING_UNMANNED_AIRCRAFT_PROVIDED_BY_CLIENT',
  UnmannedAircraftOperationsAreContractedOutToASubcontractor = 'UNMANNED_AIRCRAFT_OPERATIONS_ARE_CONTRACTED_OUT_TO_A_SUBCONTRACTOR',
  UnmannedAircraftOwnedAndOperatedByTheInsured = 'UNMANNED_AIRCRAFT_OWNED_AND_OPERATED_BY_THE_INSURED',
  UnmannedAircraftRentedOrLeasedToAndOperatedByTheInsured = 'UNMANNED_AIRCRAFT_RENTED_OR_LEASED_TO_AND_OPERATED_BY_THE_INSURED',
  UnmannedAircraftRentedOrLeasedToOthers = 'UNMANNED_AIRCRAFT_RENTED_OR_LEASED_TO_OTHERS',
}

export enum UnmannedAircraftPlaceOfOperationModifiersValueEnum {
  Indoors = 'INDOORS',
  OverBodiesOfWater = 'OVER_BODIES_OF_WATER',
  PrivateLand = 'PRIVATE_LAND',
  Rural = 'RURAL',
  Suburban = 'SUBURBAN',
  Urban = 'URBAN',
}

export type UnmannedAircraftTakeOffWeightOptionsObject = {
  __typename?: 'UnmannedAircraftTakeOffWeightOptionsObject';
  label: Scalars['String'];
  value:
    | UnmannedAircraftMaximumTakeOffWeightValueEnum
    | `${UnmannedAircraftMaximumTakeOffWeightValueEnum}`;
};

export enum UnmannedAircraftUsageModifiersEnum {
  AerialPhotographySurveillanceInspectionSurveyDataCollectionMediaServicesIncludingFilmAndTelevisionProduction = 'AERIAL_PHOTOGRAPHY_SURVEILLANCE_INSPECTION_SURVEY_DATA_COLLECTION_MEDIA_SERVICES_INCLUDING_FILM_AND_TELEVISION_PRODUCTION',
  DeliveryOfGoodsOrMerchandiseTransportOfCargo = 'DELIVERY_OF_GOODS_OR_MERCHANDISE_TRANSPORT_OF_CARGO',
  InternetAccessOtherCommunicationServices = 'INTERNET_ACCESS_OTHER_COMMUNICATION_SERVICES',
  TowingSignsOrBannersPullingTwineOrCableOrDistributionOfMaterials = 'TOWING_SIGNS_OR_BANNERS_PULLING_TWINE_OR_CABLE_OR_DISTRIBUTION_OF_MATERIALS',
  WeatherAndEnvironmentalMonitoringIncludingStormMonitoring = 'WEATHER_AND_ENVIRONMENTAL_MONITORING_INCLUDING_STORM_MONITORING',
}

export type UnmannedAircraftUsageModifiersOptionsObject = {
  __typename?: 'UnmannedAircraftUsageModifiersOptionsObject';
  label: Scalars['String'];
  value: UnmannedAircraftUsageModifiersEnum | `${UnmannedAircraftUsageModifiersEnum}`;
};

export enum UnmannedAircraftVariant {
  PrimaryPractice = 'PRIMARY_PRACTICE',
  PrimaryProject = 'PRIMARY_PROJECT',
}

export type UpdateDefaultAutoDataInput = {
  make: Scalars['String'];
  model: Scalars['String'];
  modelYear: Scalars['String'];
  sizeClass: Scalars['String'];
  statedAmount?: InputMaybe<Scalars['String']>;
};

export type UpdateExcessAutoCoverageOptionInput = {
  coverageOption: ExcessAutoCoverageOptionsInput;
  id: Scalars['ID'];
};

export type UpdateExcessAutoCoverageOptionsInput = {
  coverageOptions: Array<ExcessAutoCoverageOptionsInput>;
  exposureId: Scalars['ID'];
};

export type UpdateExcessAutoExposureInput = {
  attachmentPoint?: InputMaybe<Scalars['Float']>;
  autoLiabilityScheduleRatings?: InputMaybe<Array<CreateScheduleRatings>>;
  finalSummarySelectedLossRate?: InputMaybe<Scalars['Decimal']>;
  finalSummarySelectedLossRateType?: InputMaybe<
    LossRateSelectionTypeEnum | `${LossRateSelectionTypeEnum}`
  >;
  fleetExposure?: InputMaybe<Array<AutoVehicleInput>>;
  id: Scalars['ID'];
  individualNamedInsured?: InputMaybe<Scalars['Boolean']>;
  isCredibilityCapEnabled?: InputMaybe<Scalars['Boolean']>;
  isExperienceRatingEnabled?: InputMaybe<Scalars['Boolean']>;
  isUmUimIncluded?: InputMaybe<Scalars['Boolean']>;
  largeLossRuns?: InputMaybe<Array<AutoLargeLossRunInput>>;
  lossRuns?: InputMaybe<Array<AutoLossRunInput>>;
  naicsClassCode?: InputMaybe<Scalars['String']>;
  selectedFrequency?: InputMaybe<Scalars['Decimal']>;
  selectedFrequencyType?: InputMaybe<LossRateSelectionTypeEnum | `${LossRateSelectionTypeEnum}`>;
  selectedLossRate?: InputMaybe<Scalars['Decimal']>;
  selectedLossRateType?: InputMaybe<LossRateSelectionTypeEnum | `${LossRateSelectionTypeEnum}`>;
  underlyingPolicyLimit?: InputMaybe<Scalars['Float']>;
  vehicleFleet?: InputMaybe<Array<AutoVehicleAggregateInput>>;
};

export type UpdateExcessAutoVehicleV2Input = {
  exposureId: Scalars['ID'];
  fleet: Array<ExcessAutoVehicleV2Input>;
};

export type UpdateExcessGeneralLiabilityExposureInput = {
  attachmentPoint?: InputMaybe<Scalars['Float']>;
  finalSummarySelectedLossRate?: InputMaybe<Scalars['Decimal']>;
  finalSummarySelectedLossRateType?: InputMaybe<
    LossRateSelectionTypeEnum | `${LossRateSelectionTypeEnum}`
  >;
  id: Scalars['ID'];
  isCredibilityCapEnabled?: InputMaybe<Scalars['Boolean']>;
  isExperienceRatingEnabled?: InputMaybe<Scalars['Boolean']>;
  numberOfProjectsLocations?: InputMaybe<Scalars['Float']>;
  overallAggregateCap?: InputMaybe<Scalars['Float']>;
  programVolume?: InputMaybe<Scalars['Float']>;
  projectsGeneralAggregateOption?: InputMaybe<Scalars['String']>;
  residentialExclusion?: InputMaybe<Scalars['Boolean']>;
  selectedFrequency?: InputMaybe<Scalars['Decimal']>;
  selectedFrequencyType?: InputMaybe<LossRateSelectionTypeEnum | `${LossRateSelectionTypeEnum}`>;
  selectedLossRate?: InputMaybe<Scalars['Decimal']>;
  selectedLossRateType?: InputMaybe<LossRateSelectionTypeEnum | `${LossRateSelectionTypeEnum}`>;
  underlyingPolicyLimit?: InputMaybe<Scalars['Float']>;
  underlyingPolicyPremium?: InputMaybe<Scalars['Float']>;
  wildfireExclusion?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateFileInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type UpdateFormGroupInput = {
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  ordering?: InputMaybe<Array<Scalars['String']>>;
};

export type UpdateFormPdfInput = {
  active: Scalars['Boolean'];
  category?: InputMaybe<Scalars['String']>;
  defaultStatus: FormStatus | `${FormStatus}`;
  file?: InputMaybe<Scalars['UploadFile']>;
  formNumber: Scalars['String'];
  formType: Scalars['String'];
  googleDocUrl?: InputMaybe<Scalars['String']>;
  hasEndorsementNumber: Scalars['Boolean'];
  id: Scalars['ID'];
  isPdf?: InputMaybe<Scalars['Boolean']>;
  lineOfBusiness: LineOfBusiness | `${LineOfBusiness}`;
  notes: Scalars['String'];
  stateExceptionRules?: InputMaybe<Array<StateExceptionRulesEditInput>>;
  stateExceptions?: InputMaybe<Array<Scalars['String']>>;
  tria: TriaOption | `${TriaOption}`;
};

export type UpdateFormPdfTagsInput = {
  dataFillMap: Array<DataFillMapInput>;
  id: Scalars['ID'];
};

export type UpdateOrganizationInput = {
  city?: InputMaybe<Scalars['String']>;
  einNumber?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  logoUrl?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  npn?: InputMaybe<Scalars['String']>;
  parentOrganizationId?: InputMaybe<Scalars['ID']>;
  postalCode?: InputMaybe<Scalars['String']>;
  salesforceRecordId?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<State | `${State}`>;
  street?: InputMaybe<Scalars['String']>;
  street2?: InputMaybe<Scalars['String']>;
  type: OrganizationType | `${OrganizationType}`;
};

export type UpdateOrganizationLocationInput = {
  address1?: InputMaybe<Scalars['String']>;
  address2?: InputMaybe<Scalars['String']>;
  assigneeId?: InputMaybe<Scalars['ID']>;
  city?: InputMaybe<Scalars['String']>;
  excessSurplusLinesPaidByPartner?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  organizationId: Scalars['ID'];
  salesforceRecordId?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<State | `${State}`>;
  zipcode?: InputMaybe<Scalars['String']>;
};

export type UpdateOrganizationUserInput = {
  email: Scalars['EmailAddress'];
  firstName: Scalars['String'];
  id: Scalars['ID'];
  lastName: Scalars['String'];
  npn?: InputMaybe<Scalars['String']>;
  organizationId: Scalars['ID'];
  organizationLocationId?: InputMaybe<Scalars['ID']>;
  role: OrganizationMemberRole | `${OrganizationMemberRole}`;
  salesforceRecordId?: InputMaybe<Scalars['String']>;
};

export type UpdatePolicyChangeEndorsementInput = {
  brokerCommissionPercentage: Scalars['Float'];
  effectiveDate: Scalars['Date'];
  employersLiabilityFeePercentage: Scalars['Float'];
  frontingFeePercentage: Scalars['Float'];
  id: Scalars['ID'];
  issuedDate: Scalars['Date'];
  name: Scalars['String'];
  premium: Scalars['Float'];
  profitLoadFeePercentage: Scalars['Float'];
  quoteId: Scalars['ID'];
  shepherdMgaFeePercentage: Scalars['Float'];
  totalExpenseLoadPercentage: Scalars['Float'];
  tpaFeePercentage: Scalars['Float'];
  triaFeePercentage: Scalars['Float'];
};

export type UpdatePolicyInput = {
  assigneeId?: InputMaybe<Scalars['ID']>;
  brokerOrganizationMemberId?: InputMaybe<Scalars['ID']>;
  brokerageId?: InputMaybe<Scalars['ID']>;
  businessType?: InputMaybe<BusinessType | `${BusinessType}`>;
  effectiveDate?: InputMaybe<Scalars['Date']>;
  expirationDate?: InputMaybe<Scalars['Date']>;
  expiringEffectiveDate?: InputMaybe<Scalars['Date']>;
  id: Scalars['ID'];
  licensedUnderwriterId?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
  namedInsureds?: InputMaybe<Array<Scalars['String']>>;
  policyNumber?: InputMaybe<Scalars['String']>;
  submissionAt?: InputMaybe<Scalars['DateTime']>;
};

export type UpdatePolicyholderInput = {
  address1?: InputMaybe<Scalars['String']>;
  address2?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  fein?: InputMaybe<Scalars['String']>;
  hasAutodesk?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  insuredContactEmail?: InputMaybe<Scalars['EmailAddress']>;
  insuredContactName?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  state: State | `${State}`;
  website?: InputMaybe<Scalars['String']>;
  zipcode?: InputMaybe<Scalars['String']>;
};

export type UpdatePrimaryAutoCoverageOptionInput = {
  coverageOption: PrimaryAutoCoverageOptionsInput;
  id: Scalars['ID'];
  isProject: Scalars['Boolean'];
};

export type UpdatePrimaryAutoExposureInput = {
  attachmentPoint?: InputMaybe<Scalars['Float']>;
  autoHackingCoverageOption?: InputMaybe<
    AutoHackingCoverageOptionEnum | `${AutoHackingCoverageOptionEnum}`
  >;
  autoLoanLeaseGapCoverage?: InputMaybe<Scalars['Boolean']>;
  collisionDeductible?: InputMaybe<
    CollisionDeductibleOptionsObjectValueEnum | `${CollisionDeductibleOptionsObjectValueEnum}`
  >;
  collisionDeductibleCostOfHireBasis?: InputMaybe<
    | CollisionDeductibleCostOfHireBasisObjectValueEnum
    | `${CollisionDeductibleCostOfHireBasisObjectValueEnum}`
  >;
  combinedSingleLimit?: InputMaybe<
    CombinedSingleLimitOptionsObjectValueEnum | `${CombinedSingleLimitOptionsObjectValueEnum}`
  >;
  comprehensiveDeductible?: InputMaybe<
    | ComprehensiveDeductibleOptionsObjectValueEnum
    | `${ComprehensiveDeductibleOptionsObjectValueEnum}`
  >;
  comprehensiveDeductibleCostOfHireBasis?: InputMaybe<
    | ComprehensiveDeductibleCostOfHireBasisObjectValueEnum
    | `${ComprehensiveDeductibleCostOfHireBasisObjectValueEnum}`
  >;
  costOfHires?: InputMaybe<Array<PrimaryAutoCostOfHireInput>>;
  defenseExpenseTreatment?: InputMaybe<
    AutoLiabilityDefenseExpenseTreatmentsEnum | `${AutoLiabilityDefenseExpenseTreatmentsEnum}`
  >;
  driveOtherCar?: InputMaybe<Scalars['Boolean']>;
  extendCoverageToIndividualEmployees?: InputMaybe<Scalars['Boolean']>;
  extendToCoverOwner?: InputMaybe<Scalars['Boolean']>;
  finalSummarySelectedLossRate?: InputMaybe<Scalars['Decimal']>;
  finalSummarySelectedLossRateType?: InputMaybe<
    LossRateSelectionTypeEnum | `${LossRateSelectionTypeEnum}`
  >;
  hiredAutosCoverage?: InputMaybe<HiredAutosCoverageEnum | `${HiredAutosCoverageEnum}`>;
  id: Scalars['ID'];
  individualNamedInsured?: InputMaybe<Scalars['Boolean']>;
  isCredibilityCapEnabled?: InputMaybe<Scalars['Boolean']>;
  isExperienceRatedPathEnabledAutoLiability?: InputMaybe<Scalars['Boolean']>;
  isExperienceRatedPathEnabledAutoPhysDam?: InputMaybe<Scalars['Boolean']>;
  liabilityDeductible?: InputMaybe<
    LiablityDeductibleOptionsObjectValueEnum | `${LiablityDeductibleOptionsObjectValueEnum}`
  >;
  naicsClassCode?: InputMaybe<Scalars['String']>;
  nonOwnershipLiability?: InputMaybe<Scalars['Boolean']>;
  numberOfEmployees?: InputMaybe<Scalars['String']>;
  numberOfIndividualNamedInsureds?: InputMaybe<Scalars['Float']>;
  numberOfPartners?: InputMaybe<Scalars['Float']>;
  partnershipOrLlc?: InputMaybe<Scalars['Boolean']>;
  pollutionLiability?: InputMaybe<Scalars['Boolean']>;
  pptCollisionDeductible?: InputMaybe<Scalars['Boolean']>;
  pptComprehensiveDeductible?: InputMaybe<Scalars['Boolean']>;
  primaryAutoLiabilityIndividualRiskRatingSurvey?: InputMaybe<PrimaryAutoIndividualRiskRatingSurveyInput>;
  rentalReimbursementCoverage?: InputMaybe<Scalars['Boolean']>;
  rentalReimbursementDays?: InputMaybe<
    RentalReimbursementDaysObjectValueEnum | `${RentalReimbursementDaysObjectValueEnum}`
  >;
  rentalReimbursementLimit?: InputMaybe<
    RentalReimbursementObjectValueEnum | `${RentalReimbursementObjectValueEnum}`
  >;
  selectedFrequency?: InputMaybe<Scalars['Decimal']>;
  selectedFrequencyType?: InputMaybe<LossRateSelectionTypeEnum | `${LossRateSelectionTypeEnum}`>;
  selectedLossRate?: InputMaybe<Scalars['Decimal']>;
  selectedLossRateType?: InputMaybe<LossRateSelectionTypeEnum | `${LossRateSelectionTypeEnum}`>;
  subjectToLossAggregate?: InputMaybe<Scalars['Boolean']>;
  towAndLaborCost?: InputMaybe<
    TowAndLaborCostObjectValueEnum | `${TowAndLaborCostObjectValueEnum}`
  >;
  towAndLaborCostCoverage?: InputMaybe<Scalars['Boolean']>;
  tttCollisionDeductible?: InputMaybe<Scalars['Boolean']>;
  tttComprehensiveDeductible?: InputMaybe<Scalars['Boolean']>;
  underlyingLimitExcessRating?: InputMaybe<Scalars['Float']>;
  vehicleFleet?: InputMaybe<Array<AutoVehicleAggregateInput>>;
};

export type UpdatePrimaryAutoVehiclesInput = {
  exposureId: Scalars['ID'];
  fleet: Array<PrimaryAutoVehicleInputV2>;
  isProject: Scalars['Boolean'];
};

export type UpdatePrimaryGeneralLiabilityExposureInput = {
  damageToPremisesRentedToYou?: InputMaybe<Scalars['Float']>;
  deductibleOption?: InputMaybe<Scalars['String']>;
  deductiblePco?: InputMaybe<Scalars['Float']>;
  deductiblePremOps?: InputMaybe<Scalars['Float']>;
  defenseExpenseTreatment?: InputMaybe<
    GeneralLiabilityDefenseExpenseTreatmentsEnum | `${GeneralLiabilityDefenseExpenseTreatmentsEnum}`
  >;
  defenseInsideLimits?: InputMaybe<Scalars['Boolean']>;
  eachOccurrenceLimit?: InputMaybe<Scalars['Float']>;
  employeeBenefitLiabilityCoverage?: InputMaybe<Scalars['Boolean']>;
  employeeBenefitLiabilityLimit?: InputMaybe<Scalars['String']>;
  employeeBenefitLiabilityNumberOfEmployees?: InputMaybe<
    | EmployeeBenefitLiabilityNumberOfEmployeesValueEnum
    | `${EmployeeBenefitLiabilityNumberOfEmployeesValueEnum}`
  >;
  employeeBenefitLiabilityOptionAggregateLimit?: InputMaybe<Scalars['Float']>;
  employeeBenefitLiabilityOptionPerOccurenceLimit?: InputMaybe<Scalars['Float']>;
  employeeBenefitLiabilityRetroDate?: InputMaybe<Scalars['Date']>;
  employerLiabilityBodilyInjuryByAccidentEachAccident?: InputMaybe<Scalars['Float']>;
  employerLiabilityBodilyInjuryByDiseaseAggregate?: InputMaybe<Scalars['Float']>;
  employerLiabilityBodilyInjuryByDiseaseEachEmployee?: InputMaybe<Scalars['Float']>;
  employerLiabilityCoverage?: InputMaybe<Scalars['Boolean']>;
  finalSummarySelectedLossRate?: InputMaybe<Scalars['Decimal']>;
  finalSummarySelectedLossRateType?: InputMaybe<
    LossRateSelectionTypeEnum | `${LossRateSelectionTypeEnum}`
  >;
  generalAggregateLimit?: InputMaybe<Scalars['Float']>;
  generalLiabilityTpaChargePer15MilOfVolume?: InputMaybe<Scalars['Float']>;
  id: Scalars['ID'];
  isCredibilityCapEnabled?: InputMaybe<Scalars['Boolean']>;
  isExperienceRatedPathEnabled?: InputMaybe<Scalars['Boolean']>;
  medicalExpenseLimit?: InputMaybe<Scalars['Float']>;
  numberOfProjectsLocations?: InputMaybe<Scalars['Float']>;
  overallAggregateCap?: InputMaybe<Scalars['Float']>;
  pcoAggregateLimit?: InputMaybe<Scalars['Float']>;
  personalAdvertisingInjuryLimit?: InputMaybe<Scalars['Float']>;
  primaryGeneralLiabilityIndividualRiskRatingSurvey?: InputMaybe<PrimaryGeneralLiabilityIndividualRiskRatingSurveyInput>;
  programVolume?: InputMaybe<Scalars['Float']>;
  projectsGeneralAggregateOption?: InputMaybe<Scalars['String']>;
  residentialExclusion?: InputMaybe<Scalars['Boolean']>;
  selectedFrequency?: InputMaybe<Scalars['Decimal']>;
  selectedFrequencyType?: InputMaybe<LossRateSelectionTypeEnum | `${LossRateSelectionTypeEnum}`>;
  selectedLossRate?: InputMaybe<Scalars['Decimal']>;
  selectedLossRateType?: InputMaybe<LossRateSelectionTypeEnum | `${LossRateSelectionTypeEnum}`>;
  subjectToLossAggregate?: InputMaybe<Scalars['Boolean']>;
  wildfireExclusion?: InputMaybe<Scalars['Boolean']>;
};

export type UpdatePrimaryOcpExposure = {
  id: Scalars['ID'];
  ocpLimit?: InputMaybe<Scalars['String']>;
  ocpTotalCost?: InputMaybe<Scalars['Float']>;
  policyState?: InputMaybe<Scalars['String']>;
  typeOfOperations?: InputMaybe<Scalars['String']>;
};

export type UpdatePrimaryRailroadCoverageInput = {
  id: Scalars['ID'];
  numberOfPassengerAndFreightTrainsPerDay?: InputMaybe<Scalars['String']>;
  policyState?: InputMaybe<Scalars['String']>;
  railroadLimit?: InputMaybe<Scalars['String']>;
  railroadOpsExtendedCoverage?: InputMaybe<Scalars['Boolean']>;
  railroadOpsTotalCost?: InputMaybe<Scalars['Float']>;
  typeOfOperationsForRailroadOps?: InputMaybe<Scalars['String']>;
};

export type UpdateProcoreUsageInput = {
  adoptionDate?: InputMaybe<Scalars['Date']>;
  annualRevenue?: InputMaybe<Scalars['Float']>;
  coreToolsEngagement?: InputMaybe<Scalars['Float']>;
  csEngagement?: InputMaybe<Scalars['Float']>;
  id: Scalars['ID'];
  overallUsage?: InputMaybe<Scalars['Float']>;
  policyholderId: Scalars['ID'];
  projectFinancials?: InputMaybe<Scalars['Float']>;
  projectManagement?: InputMaybe<Scalars['Float']>;
  qualitySafety?: InputMaybe<Scalars['Float']>;
  totalActiveUsers?: InputMaybe<Scalars['Float']>;
};

export type UpdateProjectExcessGeneralLiabilityExposureInput = {
  additionalStates?: InputMaybe<Array<State | `${State}`>>;
  address1?: InputMaybe<Scalars['String']>;
  address2?: InputMaybe<Scalars['String']>;
  attachmentPoint?: InputMaybe<Scalars['Float']>;
  buildoutSchedules?: InputMaybe<Array<GeneralLiabilityBuildoutScheduleInput>>;
  cdSensitiveProjectTypeOne?: InputMaybe<Scalars['Boolean']>;
  cdSensitiveProjectTypeTwo?: InputMaybe<Scalars['Boolean']>;
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  isExperienceRatingEnabled?: InputMaybe<Scalars['Boolean']>;
  numberOfProjectsLocations?: InputMaybe<Scalars['Float']>;
  overallAggregateCap?: InputMaybe<Scalars['Float']>;
  programType?: InputMaybe<Scalars['String']>;
  programVolume?: InputMaybe<Scalars['Float']>;
  projectName?: InputMaybe<Scalars['String']>;
  projectsGeneralAggregateOption?: InputMaybe<Scalars['String']>;
  residentialExclusion?: InputMaybe<Scalars['Boolean']>;
  scheduleRatings?: InputMaybe<Array<CreateScheduleRatings>>;
  state?: InputMaybe<State | `${State}`>;
  underlyingPolicyLimit?: InputMaybe<Scalars['Float']>;
  underlyingPolicyPremium?: InputMaybe<Scalars['Float']>;
  wildfireExclusion?: InputMaybe<Scalars['Boolean']>;
  zipcode?: InputMaybe<Scalars['String']>;
};

export type UpdateProjectPrimaryAutoExposureInput = {
  autoHackingCoverageOption?: InputMaybe<
    AutoHackingCoverageOptionEnum | `${AutoHackingCoverageOptionEnum}`
  >;
  autoLiabilityScheduleRatings?: InputMaybe<Array<CreateScheduleRatings>>;
  autoLoanLeaseGapCoverage?: InputMaybe<Scalars['Boolean']>;
  autoPhysicalDamageScheduleRatings?: InputMaybe<Array<CreateScheduleRatings>>;
  collisionDeductible?: InputMaybe<
    CollisionDeductibleOptionsObjectValueEnum | `${CollisionDeductibleOptionsObjectValueEnum}`
  >;
  collisionDeductibleCostOfHireBasis?: InputMaybe<
    | CollisionDeductibleCostOfHireBasisObjectValueEnum
    | `${CollisionDeductibleCostOfHireBasisObjectValueEnum}`
  >;
  combinedSingleLimit?: InputMaybe<
    CombinedSingleLimitOptionsObjectValueEnum | `${CombinedSingleLimitOptionsObjectValueEnum}`
  >;
  comprehensiveDeductible?: InputMaybe<
    | ComprehensiveDeductibleOptionsObjectValueEnum
    | `${ComprehensiveDeductibleOptionsObjectValueEnum}`
  >;
  comprehensiveDeductibleCostOfHireBasis?: InputMaybe<
    | ComprehensiveDeductibleCostOfHireBasisObjectValueEnum
    | `${ComprehensiveDeductibleCostOfHireBasisObjectValueEnum}`
  >;
  costOfHires?: InputMaybe<Array<PrimaryAutoCostOfHireInput>>;
  defenseExpenseTreatment?: InputMaybe<
    AutoLiabilityDefenseExpenseTreatmentsEnum | `${AutoLiabilityDefenseExpenseTreatmentsEnum}`
  >;
  driveOtherCar?: InputMaybe<Scalars['Boolean']>;
  extendCoverageToIndividualEmployees?: InputMaybe<Scalars['Boolean']>;
  extendToCoverOwner?: InputMaybe<Scalars['Boolean']>;
  finalSummarySelectedLossRate?: InputMaybe<Scalars['Decimal']>;
  finalSummarySelectedLossRateType?: InputMaybe<
    LossRateSelectionTypeEnum | `${LossRateSelectionTypeEnum}`
  >;
  hiredAutosCoverage?: InputMaybe<HiredAutosCoverageEnum | `${HiredAutosCoverageEnum}`>;
  id: Scalars['ID'];
  individualNamedInsured?: InputMaybe<Scalars['Boolean']>;
  isCredibilityCapEnabled?: InputMaybe<Scalars['Boolean']>;
  isExperienceRatedPathEnabledAutoLiability?: InputMaybe<Scalars['Boolean']>;
  isExperienceRatedPathEnabledAutoPhysDam?: InputMaybe<Scalars['Boolean']>;
  liabilityDeductible?: InputMaybe<
    LiablityDeductibleOptionsObjectValueEnum | `${LiablityDeductibleOptionsObjectValueEnum}`
  >;
  naicsClassCode?: InputMaybe<Scalars['String']>;
  nonOwnershipLiability?: InputMaybe<Scalars['Boolean']>;
  numberOfEmployees?: InputMaybe<Scalars['String']>;
  numberOfIndividualNamedInsureds?: InputMaybe<Scalars['Float']>;
  numberOfPartners?: InputMaybe<Scalars['Float']>;
  partnershipOrLlc?: InputMaybe<Scalars['Boolean']>;
  pollutionLiability?: InputMaybe<Scalars['Boolean']>;
  pptCollisionDeductible?: InputMaybe<Scalars['Boolean']>;
  pptComprehensiveDeductible?: InputMaybe<Scalars['Boolean']>;
  projectPrimaryAutoLiabilityIndividualRiskRatingSurvey?: InputMaybe<PrimaryAutoIndividualRiskRatingSurveyInput>;
  rentalReimbursementCoverage?: InputMaybe<Scalars['Boolean']>;
  rentalReimbursementDays?: InputMaybe<
    RentalReimbursementDaysObjectValueEnum | `${RentalReimbursementDaysObjectValueEnum}`
  >;
  rentalReimbursementLimit?: InputMaybe<
    RentalReimbursementObjectValueEnum | `${RentalReimbursementObjectValueEnum}`
  >;
  selectedFrequency?: InputMaybe<Scalars['Decimal']>;
  selectedFrequencyType?: InputMaybe<LossRateSelectionTypeEnum | `${LossRateSelectionTypeEnum}`>;
  selectedLossRate?: InputMaybe<Scalars['Decimal']>;
  selectedLossRateType?: InputMaybe<LossRateSelectionTypeEnum | `${LossRateSelectionTypeEnum}`>;
  subjectToLossAggregate?: InputMaybe<Scalars['Boolean']>;
  towAndLaborCost?: InputMaybe<
    TowAndLaborCostObjectValueEnum | `${TowAndLaborCostObjectValueEnum}`
  >;
  towAndLaborCostCoverage?: InputMaybe<Scalars['Boolean']>;
  tttCollisionDeductible?: InputMaybe<Scalars['Boolean']>;
  tttComprehensiveDeductible?: InputMaybe<Scalars['Boolean']>;
  underlyingLimitExcessRating?: InputMaybe<Scalars['Float']>;
  vehicleFleet?: InputMaybe<Array<AutoVehicleAggregateInput>>;
};

export type UpdateProjectPrimaryGeneralLiabilityExposure = {
  additionalStates?: InputMaybe<Array<State | `${State}`>>;
  address1?: InputMaybe<Scalars['String']>;
  address2?: InputMaybe<Scalars['String']>;
  buildoutSchedule?: InputMaybe<Array<GeneralLiabilityBuildoutScheduleInput>>;
  cdSensitiveProjectTypeOne?: InputMaybe<Scalars['Boolean']>;
  cdSensitiveProjectTypeTwo?: InputMaybe<Scalars['Boolean']>;
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  damageToPremisesRentedToYou?: InputMaybe<Scalars['Float']>;
  deductibleOption?: InputMaybe<Scalars['String']>;
  deductiblePco?: InputMaybe<Scalars['Float']>;
  deductiblePremOps?: InputMaybe<Scalars['Float']>;
  defenseExpenseTreatment?: InputMaybe<
    GeneralLiabilityDefenseExpenseTreatmentsEnum | `${GeneralLiabilityDefenseExpenseTreatmentsEnum}`
  >;
  defenseInsideLimits?: InputMaybe<Scalars['Boolean']>;
  eachOccurrenceLimit?: InputMaybe<Scalars['Float']>;
  employeeBenefitLiabilityCoverage?: InputMaybe<Scalars['Boolean']>;
  employeeBenefitLiabilityLimit?: InputMaybe<Scalars['String']>;
  employeeBenefitLiabilityNumberOfEmployees?: InputMaybe<
    | EmployeeBenefitLiabilityNumberOfEmployeesValueEnum
    | `${EmployeeBenefitLiabilityNumberOfEmployeesValueEnum}`
  >;
  employeeBenefitLiabilityOptionAggregateLimit?: InputMaybe<Scalars['Float']>;
  employeeBenefitLiabilityOptionPerOccurenceLimit?: InputMaybe<Scalars['Float']>;
  employeeBenefitLiabilityRetroDate?: InputMaybe<Scalars['Date']>;
  employerLiabilityBodilyInjuryByAccidentEachAccident?: InputMaybe<Scalars['Float']>;
  employerLiabilityBodilyInjuryByDiseaseAggregate?: InputMaybe<Scalars['Float']>;
  employerLiabilityBodilyInjuryByDiseaseEachEmployee?: InputMaybe<Scalars['Float']>;
  employerLiabilityCoverage?: InputMaybe<Scalars['Boolean']>;
  finalSummarySelectedLossRate?: InputMaybe<Scalars['Decimal']>;
  finalSummarySelectedLossRateType?: InputMaybe<
    LossRateSelectionTypeEnum | `${LossRateSelectionTypeEnum}`
  >;
  generalAggregateLimit?: InputMaybe<Scalars['Float']>;
  id: Scalars['ID'];
  isCredibilityCapEnabled?: InputMaybe<Scalars['Boolean']>;
  isExperienceRatedPathEnabled?: InputMaybe<Scalars['Boolean']>;
  medicalExpenseLimit?: InputMaybe<Scalars['Float']>;
  numberOfProjectsLocations?: InputMaybe<Scalars['Float']>;
  overallAggregateCap?: InputMaybe<Scalars['Float']>;
  pcoAggregateLimit?: InputMaybe<Scalars['Float']>;
  personalAdvertisingInjuryLimit?: InputMaybe<Scalars['Float']>;
  programType?: InputMaybe<Scalars['String']>;
  programVolume?: InputMaybe<Scalars['Float']>;
  projectName?: InputMaybe<Scalars['String']>;
  projectPrimaryGeneralLiabilityIndividualRiskRatingSurvey?: InputMaybe<PrimaryGeneralLiabilityIndividualRiskRatingSurveyInput>;
  projectsGeneralAggregateOption?: InputMaybe<Scalars['String']>;
  residentialExclusion?: InputMaybe<Scalars['Boolean']>;
  scheduleRatings?: InputMaybe<CreateScheduleRatingsInput>;
  selectedFrequency?: InputMaybe<Scalars['Decimal']>;
  selectedFrequencyType?: InputMaybe<LossRateSelectionTypeEnum | `${LossRateSelectionTypeEnum}`>;
  selectedLossRate?: InputMaybe<Scalars['Decimal']>;
  selectedLossRateType?: InputMaybe<LossRateSelectionTypeEnum | `${LossRateSelectionTypeEnum}`>;
  state?: InputMaybe<State | `${State}`>;
  subjectToLossAggregate?: InputMaybe<Scalars['Boolean']>;
  wildfireExclusion?: InputMaybe<Scalars['Boolean']>;
  zipcode?: InputMaybe<Scalars['String']>;
};

export type UpdateQuoteInput = {
  autodeskDataRequested?: InputMaybe<Scalars['Boolean']>;
  formVendor?: InputMaybe<FormVendor | `${FormVendor}`>;
  id: Scalars['ID'];
  indicatedAt?: InputMaybe<Scalars['DateTime']>;
  name?: InputMaybe<Scalars['String']>;
  openspaceDataRequested?: InputMaybe<Scalars['Boolean']>;
  procoreDataRequested?: InputMaybe<Scalars['Boolean']>;
  quoteSelection: UpdateQuoteSelectionInput;
  reasonForTechDataAbsence?: InputMaybe<ReasonForTechDataAbsence | `${ReasonForTechDataAbsence}`>;
  referredToCore?: InputMaybe<Scalars['Boolean']>;
  referredToTreaty?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateQuoteSelectionInput = {
  brokerCommissionOverride?: InputMaybe<Scalars['Float']>;
  employersLiability?: InputMaybe<Scalars['Boolean']>;
  excessOfWrap?: InputMaybe<Scalars['Float']>;
  generalAggregateLimit?: InputMaybe<Scalars['Float']>;
  insuredRetentionAmount?: InputMaybe<Scalars['Float']>;
  policyLimit?: InputMaybe<Scalars['Float']>;
  productsCompletedOperationsAggregateLimit?: InputMaybe<Scalars['Float']>;
  profitLoadFeeOverride?: InputMaybe<Scalars['Float']>;
  quotaShare?: InputMaybe<Scalars['Float']>;
  quoteId?: InputMaybe<Scalars['String']>;
  selfAuditStatus?: InputMaybe<SelfAuditStatusOptions | `${SelfAuditStatusOptions}`>;
  shepherdMgaFeesOverride?: InputMaybe<Scalars['Float']>;
  stampingFeeOverride?: InputMaybe<Scalars['Float']>;
  surplusLinesTaxesOverride?: InputMaybe<Scalars['Float']>;
  tria?: InputMaybe<Scalars['Boolean']>;
  underwriterAuditorId?: InputMaybe<Scalars['ID']>;
  useCredibilityWeightedPath?: InputMaybe<Scalars['Boolean']>;
  uwAdjustment?: InputMaybe<Scalars['Float']>;
  uwAdjustmentNotes?: InputMaybe<Scalars['String']>;
};

export type UpdateQuoteStatusInput = {
  autodeskDataRequested?: InputMaybe<Scalars['Boolean']>;
  declinedReason?: InputMaybe<DeclinedReason | `${DeclinedReason}`>;
  id: Scalars['ID'];
  indicatedLostNotes?: InputMaybe<Scalars['String']>;
  indicatedLostReason?: InputMaybe<IndicatedLostReason | `${IndicatedLostReason}`>;
  openspaceDataRequested?: InputMaybe<Scalars['Boolean']>;
  procoreDataRequested?: InputMaybe<Scalars['Boolean']>;
  quoteLostPrice?: InputMaybe<Scalars['Float']>;
  quotedLostNotes?: InputMaybe<Scalars['String']>;
  quotedLostReason?: InputMaybe<QuotedLostReason | `${QuotedLostReason}`>;
  reasonForTechDataAbsence?: InputMaybe<ReasonForTechDataAbsence | `${ReasonForTechDataAbsence}`>;
  referredToCore?: InputMaybe<Scalars['Boolean']>;
  referredToTreaty?: InputMaybe<Scalars['Boolean']>;
  riskTierFinal?: InputMaybe<TierEnum | `${TierEnum}`>;
  status: QuoteStatus | `${QuoteStatus}`;
};

export type UpdateSelectedFormInput = {
  id: Scalars['ID'];
  uwComment: Scalars['String'];
};

export type UpdateSelectedFormsInput = {
  formIds: Array<Scalars['String']>;
  formVendor?: InputMaybe<FormVendor | `${FormVendor}`>;
  quoteId: Scalars['ID'];
};

export type UpdateShepsyFeedbackInput = {
  feedback?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
};

export type UpdateSubjectivitiesInput = {
  quoteId: Scalars['ID'];
  subjectivities: Array<SubjectivityInputObject>;
};

export type UpdateUnderlyingPolicyInput = {
  attachmentOrderRank?: InputMaybe<Scalars['Int']>;
  displayType?: InputMaybe<Scalars['String']>;
  effectiveDate?: InputMaybe<Scalars['Date']>;
  expirationDate?: InputMaybe<Scalars['Date']>;
  id: Scalars['ID'];
  limits?: InputMaybe<Array<UnderlyingPolicyLimitInput>>;
  policyNumber?: InputMaybe<Scalars['String']>;
  quoteId?: InputMaybe<Scalars['ID']>;
  type?: InputMaybe<UnderlyingPolicyType | `${UnderlyingPolicyType}`>;
  underlyingPolicyCarrierId?: InputMaybe<Scalars['String']>;
};

export type UpdateWorkersCompensationExposureInput = {
  assessments?: InputMaybe<Scalars['Float']>;
  brokerageCommissionRate?: InputMaybe<Scalars['Float']>;
  grossCommissionRate?: InputMaybe<Scalars['Float']>;
  policyNumber?: InputMaybe<Scalars['String']>;
  premiumPaymentTerms?: InputMaybe<TimePeriodEnum | `${TimePeriodEnum}`>;
  totalPolicyPremium?: InputMaybe<Scalars['Float']>;
  workersCompensationExposureId: Scalars['ID'];
};

export type User = {
  __typename?: 'User';
  createdAt: Scalars['DateTime'];
  email: Scalars['EmailAddress'];
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  imageUrl?: Maybe<Scalars['String']>;
  initials: Scalars['String'];
  lastName?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  npn?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  role: UserRole | `${UserRole}`;
  salesforceRecordId?: Maybe<Scalars['String']>;
  salesforceRecordUrl?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type UserMutationPayload = {
  __typename?: 'UserMutationPayload';
  user: User;
};

export enum UserRole {
  Admin = 'ADMIN',
  Gray = 'GRAY',
  Test = 'TEST',
  User = 'USER',
}

export type VehicleFleet = {
  __typename?: 'VehicleFleet';
  count: Scalars['Int'];
  sizeClass: AutoSizeClassEnum | `${AutoSizeClassEnum}`;
  state: State | `${State}`;
  typeOfVehicle: TypeOfVehicleEnum | `${TypeOfVehicleEnum}`;
};

export type VehicleFleetInput = {
  count: Scalars['Int'];
  sizeClass: AutoSizeClassEnum | `${AutoSizeClassEnum}`;
  state: State | `${State}`;
  typeOfVehicle: TypeOfVehicleEnum | `${TypeOfVehicleEnum}`;
};

export type VehicleFleetTableConstant = {
  __typename?: 'VehicleFleetTableConstant';
  states: Array<StateConstant>;
  vehicleType: Array<VehicleType>;
};

export type VehicleType = {
  __typename?: 'VehicleType';
  label: Scalars['String'];
  value: Scalars['String'];
};

export enum VehicleTypesEnum {
  Ppt = 'PPT',
  Ttt = 'TTT',
}

export type VehicleUseConstant = {
  __typename?: 'VehicleUseConstant';
  label: Scalars['String'];
  use: Scalars['String'];
  value: Scalars['String'];
  vehicleClass: Scalars['String'];
};

export type WorkersCompensationExposure = {
  __typename?: 'WorkersCompensationExposure';
  assessments?: Maybe<Scalars['Float']>;
  brokerageCommissionRate?: Maybe<Scalars['Float']>;
  grossCommissionRate?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  policyNumber?: Maybe<Scalars['String']>;
  premiumPaymentTerms?: Maybe<TimePeriodEnum | `${TimePeriodEnum}`>;
  quoteDetailId: Scalars['ID'];
  totalPolicyPremium?: Maybe<Scalars['Float']>;
};

export type ZipCodesByState = {
  __typename?: 'ZipCodesByState';
  AK: Array<Scalars['String']>;
  AL: Array<Scalars['String']>;
  AR: Array<Scalars['String']>;
  AZ: Array<Scalars['String']>;
  CA: Array<Scalars['String']>;
  CO: Array<Scalars['String']>;
  CT: Array<Scalars['String']>;
  DC: Array<Scalars['String']>;
  DE: Array<Scalars['String']>;
  FL: Array<Scalars['String']>;
  GA: Array<Scalars['String']>;
  HI: Array<Scalars['String']>;
  IA: Array<Scalars['String']>;
  ID: Array<Scalars['String']>;
  IL: Array<Scalars['String']>;
  IN: Array<Scalars['String']>;
  KS: Array<Scalars['String']>;
  KY: Array<Scalars['String']>;
  LA: Array<Scalars['String']>;
  MA: Array<Scalars['String']>;
  MD: Array<Scalars['String']>;
  ME: Array<Scalars['String']>;
  MI: Array<Scalars['String']>;
  MN: Array<Scalars['String']>;
  MO: Array<Scalars['String']>;
  MS: Array<Scalars['String']>;
  MT: Array<Scalars['String']>;
  NC: Array<Scalars['String']>;
  ND: Array<Scalars['String']>;
  NE: Array<Scalars['String']>;
  NH: Array<Scalars['String']>;
  NJ: Array<Scalars['String']>;
  NM: Array<Scalars['String']>;
  NV: Array<Scalars['String']>;
  NY: Array<Scalars['String']>;
  OH: Array<Scalars['String']>;
  OK: Array<Scalars['String']>;
  OR: Array<Scalars['String']>;
  PA: Array<Scalars['String']>;
  RI: Array<Scalars['String']>;
  SC: Array<Scalars['String']>;
  SD: Array<Scalars['String']>;
  TN: Array<Scalars['String']>;
  TX: Array<Scalars['String']>;
  UT: Array<Scalars['String']>;
  VA: Array<Scalars['String']>;
  VT: Array<Scalars['String']>;
  WA: Array<Scalars['String']>;
  WI: Array<Scalars['String']>;
  WV: Array<Scalars['String']>;
  WY: Array<Scalars['String']>;
};

export type ZodError = Error & {
  __typename?: 'ZodError';
  fieldErrors: Array<ZodFieldError>;
  message: Scalars['String'];
  stack?: Maybe<Scalars['String']>;
};

export type ZodFieldError = {
  __typename?: 'ZodFieldError';
  message: Scalars['String'];
  path: Array<Scalars['String']>;
};

export type CoverageOptionVehicleTypes = {
  __typename?: 'coverageOptionVehicleTypes';
  hasPPT: Scalars['Boolean'];
  hasTTT: Scalars['Boolean'];
  hasTrailer: Scalars['Boolean'];
};

export type FleetDetails = {
  __typename?: 'fleetDetails';
  label: Scalars['String'];
  value: Scalars['Int'];
};
