import { ApolloProvider } from '@apollo/client';
import {
  ColorScheme,
  ColorSchemeProvider,
  Global,
  MantineProvider,
  MantineThemeOverride,
} from '@mantine/core';
import { ModalsProvider } from '@mantine/modals';
import { Notifications } from '@mantine/notifications';
import * as snippet from '@segment/snippet';
import { DefaultSeo } from 'next-seo';
import Head from 'next/head';
import { useRouter } from 'next/router';
import Script from 'next/script';
import React, { useEffect } from 'react';
import { CookiesProvider } from 'react-cookie';

import { $TsFixMe } from '../../module';
import { useApollo } from '../apolloClient';
import SessionProvider from '../components/SessionProvider';

import { defaultSeo } from '../utils/seo';
import { IS_DEV, IS_PRODUCTION, IS_STAGING } from '../constants';
import { useLocalStorage } from '@mantine/hooks';
import NewVersionDeployed from '../components/NewVersionDeployed';
import ConfettiProvider from '../components/ConfettiProvider';
import ShepsySpotlight from '../components/ShepsySpotlight';
import LogRocketRun from '../components/LogRocketRun';
import '../styles/fonts.css'; // imports Flecha

const tabConfig = (): {
  favi16: string;
  favi32: string;
  faviApple: string;
  manifest: string;
  title: string;
} => {
  const favi16 = 'favicon-16x16.png';
  const favi32 = 'favicon-32x32.png';
  const faviApple = 'apple-touch-icon.png';
  const manifest = 'site.webmanifest';
  if (IS_STAGING) {
    return {
      title: 'Shepherd (STAGING)',
      favi16: `/green-favi/${favi16}`,
      favi32: `/green-favi/${favi32}`,
      faviApple: `/green-favi/${faviApple}`,
      manifest: `/green-favi/${manifest}`,
    };
  }

  if (IS_DEV) {
    return {
      title: 'Shepherd (LOCAL)',
      favi16: `/justin-favi/${favi16}`,
      favi32: `/justin-favi/${favi32}`,
      faviApple: `/justin-favi/${faviApple}`,
      manifest: `/justin-favi/${manifest}`,
    };
  }
  return {
    title: 'Shepherd',
    favi16: `/white-favi/${favi16}`,
    favi32: `/white-favi/${favi32}`,
    faviApple: `/white-favi/${faviApple}`,
    manifest: `/white-favi/${manifest}`,
  };
};

const theme: MantineThemeOverride = {
  primaryColor: 'dark',
  fontFamily:
    'Inter,-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue,Arial, Noto Sans',
  fontSizes: {
    sm: '13px',
    md: '14px',
  },
  components: {
    Card: {
      styles: {
        root: {
          overflow: 'initial',
        },
      },
    },
    Table: {
      defaultProps: { verticalSpacing: 'md', highlightOnHover: true },
      styles: (currentTheme) => ({
        root: {
          '& th': {
            backgroundColor:
              currentTheme.colorScheme === 'light'
                ? currentTheme.colors.gray[2]
                : currentTheme.colors.dark[9],
            height: 50,
          },
          '& th:first-of-type, & td:first-of-type': {
            paddingLeft: currentTheme.spacing.md,
          },
          '& tfoot': {
            backgroundColor:
              currentTheme.colorScheme === 'light'
                ? currentTheme.colors.gray[2]
                : currentTheme.colors.dark[9],
            height: 50,
          },
        },
      }),
    },
  },
};

const renderSegmentSnippet = () => {
  const opts = {
    apiKey: process.env.NEXT_PUBLIC_SEGMENT_KEY,
    // note: the page option only covers SSR tracking.
    // Page.js is used to track other events using `window.analytics.page()`
    page: true,
  };

  if (process.env.NODE_ENV === 'development') {
    return '';
  }

  return snippet.min(opts);
};

function ShepherdApp({ Component, pageProps, ...all }: $TsFixMe) {
  const apolloClient = useApollo(pageProps.initialApolloState);
  const router = useRouter();
  const [colorScheme, setColorScheme] = useLocalStorage<ColorScheme>({
    key: 'mantine-color-scheme',
    defaultValue: 'light',
    getInitialValueInEffect: true,
  });

  const toggleColorScheme = (value?: ColorScheme) =>
    setColorScheme(value || (colorScheme === 'dark' ? 'light' : 'dark'));

  useEffect(() => {
    const handleRouteChange = () => {
      if (IS_PRODUCTION) {
        global.window.analytics.page();
      }
    };

    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
    // eslint-disable-next-line
  }, []);

  const { title, favi16, favi32, faviApple, manifest } = tabConfig();

  return (
    <CookiesProvider>
      <DefaultSeo {...defaultSeo} />
      <Head>
        <title>{title}</title>

        <link rel="apple-touch-icon" sizes="180x180" href={faviApple} />
        <link rel="icon" type="image/png" sizes="32x32" href={favi32} />
        <link rel="icon" type="image/png" sizes="16x16" href={favi16} />
        <link rel="manifest" href={manifest} />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#ffffff" />
        {/* Inject the Segment snippet into the <head> of the document  */}

        {IS_PRODUCTION && (
          <>
            <script
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: renderSegmentSnippet() }}
            />
          </>
        )}
      </Head>
      <Script
        async
        defer
        src={`https://maps.googleapis.com/maps/api/js?key=${process.env.NEXT_PUBLIC_GOOGLE_PLACES_API_KEY}&libraries=places`}
      />
      <ApolloProvider client={apolloClient}>
        <SessionProvider>
          <ColorSchemeProvider colorScheme={colorScheme} toggleColorScheme={toggleColorScheme}>
            <MantineProvider
              theme={{
                ...theme,
                colorScheme,
                primaryColor: colorScheme === 'light' ? 'dark' : 'blue',
              }}
              withGlobalStyles
              withNormalizeCSS
            >
              <Notifications />
              <ModalsProvider>
                <ConfettiProvider>
                  <ShepsySpotlight>
                    {!pageProps.skipTracking && <LogRocketRun />}
                    <Component {...pageProps} />
                    <Global
                      styles={() => ({
                        '*, *::before, *::after': {
                          boxSizing: 'border-box',
                        },

                        'html, body': {
                          padding: 0,
                          margin: 0,
                        },

                        ':root': {
                          colorScheme,
                        },
                      })}
                    />
                    <NewVersionDeployed />
                  </ShepsySpotlight>
                </ConfettiProvider>
              </ModalsProvider>
            </MantineProvider>
          </ColorSchemeProvider>
        </SessionProvider>
      </ApolloProvider>
    </CookiesProvider>
  );
}

export default ShepherdApp;
